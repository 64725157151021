<template>
  <!-- Profile dropdown -->
  <div class="ml-3 relative" v-click-outside="closeUserMenu">
    <div>
      <button
        @click="showDropdown = !showDropdown"
        class="flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-hm-gray-light focus:ring-hm-brand" id="user-menu" aria-haspopup="true">
        <span class="sr-only">Open user menu</span>
        <!-- <img class="h-8 w-8 rounded-full" :src="avatar" alt=""> -->
        <avatar :username="userProfile.displayName || userEmail" :size="36"></avatar>
      </button>
    </div>
    <transition
      name="custom-classes-transition"
      enter-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="showDropdown"
        class="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg pt-0 pb-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <div class="">
          <div class="sm:flex sm:space-x-2 py-2 px-3 mb-1  mt-0 rounded-md">
            <div class="flex-shrink-0">
              <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="userProfile.imageUrl" alt="" /> -->
              <!-- <avatar :username="userProfile.displayName || userEmail" :size="44"></avatar> -->
            </div>
            <div class="mt-3 sm:mt-0 text-left pr-5">
              <p class="text-md font-bold text-gray-900 sm:text-md">{{ userProfile.displayName }}</p>
              <p class="text-xs font-medium text-gray-600">{{ userProfile.email }}</p>
            </div>
          </div>
          <div class="px-1">
            <router-link tag="a" :to="'/account-profile'"
              class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 48 48" stroke-width="3" height="16" width="16"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M8.25718 3.53896C5.70188 3.74657 3.74658 5.70187 3.53897 8.25717C3.27668 11.4854 3 16.636 3 24C3 31.364 3.27667 36.5146 3.53896 39.7428C3.74657 42.2981 5.70187 44.2534 8.25716 44.461C11.4854 44.7233 16.636 45 24 45C31.364 45 36.5146 44.7233 39.7428 44.461C42.2981 44.2534 44.2534 42.2981 44.461 39.7428C44.7233 36.5146 45 31.364 45 24C45 16.636 44.7233 11.4854 44.461 8.25718C44.2534 5.70188 42.2981 3.74658 39.7428 3.53897C36.5146 3.27668 31.364 3 24 3C16.636 3 11.4854 3.27667 8.25718 3.53896Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M28.2925 25.7521C30.5215 24.3321 32 21.8387 32 19C32 14.5817 28.4182 11 24 11C19.5817 11 16 14.5817 16 19C16 21.8389 17.4787 24.3324 19.7079 25.7524C16.0006 27.1019 13.1401 30.1491 12.1179 33.9257C11.7661 35.2258 12.5129 36.6188 13.8568 36.7076C16.188 36.8616 19.5272 37 24.0002 37C28.4731 37 31.8123 36.8616 34.1435 36.7076C35.4881 36.6188 36.2351 35.225 35.8829 33.9243C34.8604 30.148 31.9998 27.1013 28.2925 25.7521Z"></path></svg>
              {{$t('ui.profile')}}
            </router-link>
            <router-link tag="a" :to="'/credits'"
              class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 48 48" stroke-width="3" height="16" width="16"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M13 23H27"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M13 31H23"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M35 23H33"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M35 31H33"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M34.6441 3.2258C34.9373 3.24621 37.0769 3.52188 40.7997 7.24463C44.5933 11.0382 44.804 13.1032 44.8157 13.3209C44.8159 13.3263 44.8161 13.3317 44.8163 13.3371C44.8164 13.3391 44.8164 13.3402 44.8164 13.3402C44.9254 16.0746 44.9997 19.577 44.9997 24.0448C44.9997 33.9592 44.6341 40.2168 44.3343 43.6807C44.2011 45.2199 42.4569 45.9006 41.2701 44.9116L37.6498 41.8947L34.7156 44.8289C33.995 45.5495 32.8478 45.613 32.052 44.9764L28.1999 41.8947L25.4141 44.6805C24.633 45.4615 23.3667 45.4615 22.5857 44.6805L19.7999 41.8947L15.9478 44.9764C15.152 45.613 14.0048 45.5495 13.2842 44.8289L10.35 41.8947L6.7297 44.9116C5.54285 45.9006 3.79863 45.2199 3.66544 43.6807C3.36569 40.2168 3 33.9592 3 24.0448C3 14.9014 3.31102 9.80128 3.59451 7.05167C3.7882 5.17308 5.12816 3.83312 7.00675 3.63943C9.75636 3.35594 14.8565 3.04492 23.9999 3.04492C28.4348 3.04492 31.9185 3.11809 34.6441 3.2258Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M34.1007 3.25C34.0447 4.16979 34 5.47205 34 7.28553C34 8.68438 34.0266 9.77905 34.0645 10.6286C34.1414 12.3534 35.2603 13.6736 36.9785 13.8424C37.908 13.9337 39.1301 14.0003 40.7172 14.0003C42.4754 14.0003 43.7855 13.9185 44.7462 13.8121"></path></svg>
              {{$t('ui.creditsUsage')}}
            </router-link>
            <a href="#"
              @click="logOut"
              class="flex items-center p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md pl-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="ml-1 mr-2 h-5 w-5" fill="none" viewBox="0 0 48 48" stroke-width="3" height="16" width="16"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M30.7483 10.5C30.7114 9.6281 30.6731 8.84887 30.6354 8.15935C30.4982 5.65515 28.7055 3.64378 26.2097 3.39786C24.0613 3.18617 21.0271 3 17 3C12.9729 3 9.9387 3.18617 7.79032 3.39786C5.29445 3.64378 3.50168 5.65643 3.36456 8.16063C3.18817 11.3823 3 16.5622 3 24C3 31.4378 3.18817 36.6177 3.36457 39.8394C3.50168 42.3436 5.29446 44.3562 7.79032 44.6021C9.93871 44.8138 12.9729 45 17 45C21.0271 45 24.0613 44.8138 26.2097 44.6021C28.7055 44.3562 30.4982 42.3448 30.6354 39.8407C30.6731 39.1512 30.7114 38.372 30.7483 37.5001"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M36.6562 33.5853C35.1669 34.8876 33.2849 34.0693 33.1777 32.0938C33.1228 31.0816 33.074 29.8278 33.0415 28.2833L13.5791 27.5756C12.3287 27.5301 11.2318 26.7072 11.0865 25.4644C11.035 25.0237 11 24.5293 11 24C11 23.4707 11.035 22.9763 11.0865 22.5356C11.2318 21.2928 12.3287 20.4699 13.5791 20.4244L33.0415 19.7167C33.074 18.1727 33.1228 16.9192 33.1777 15.9071C33.2849 13.9312 35.1674 13.1129 36.657 14.4155C37.5379 15.1858 38.6195 16.1807 39.9176 17.4544C42.2072 19.7008 43.4864 21.3691 44.1887 22.4591C44.8067 23.4184 44.8067 24.5813 44.1885 25.5405C43.4862 26.6304 42.207 28.2986 39.9176 30.5453C38.6191 31.8197 37.5372 32.8149 36.6562 33.5853Z"></path></svg>
              <span>{{$t('ui.logout')}}</span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Avatar from 'vue-avatar';
import { mapState } from 'vuex';

export default {
  name: 'TopNavUserDropdown',
  components: {
    Avatar,
  },
  props: {
    name: {
      type: String,
      default: 'John Doe',
    },
    email: {
      type: String,
      default: '....',
    },
  },
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      libraryId: null,
      notifications: [
        {
          time: new Date(),
          title: 'Article added',
          message: 'Lorem ipsum sit dolor',
          type: 'info',
        },
      ],
      logoutChannel: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
    }),
    queryParams() {
      const input = {
        first: 125,
        orderBy: 'DISPLAY_NAME_ASC',
        offset: 0,
        condition: {
          isDeleted: false,
        },
      };
      return input;
    },
    avatar() {
      return 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixqx=94Y6oZJI7Y&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80';
      // return `https://avatars.dicebear.com/api/initials/${this.userEmail}.svg`;
    },
  },
  watch: {
    userEmail() {
      console.log('%c nav dropdown watch', 'color: lime', this.userEmail);
    },
  },
  mounted() {
    console.log('%c nav dropdown mounted', 'color: lime', this.userEmail);
    if (this.userEmail) this.createLogoutChannel(this.userEmail);
  },
  methods: {
    toggleMainMenu() {
      // console.log('toggleMainMenu');
      this.showMobileNav = !this.showMobileNav;
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
    createLogoutChannel(email) {
      console.log('%c nav dropdown createLogoutChannel', 'color: lime', email);
      this.logoutChannel = new BroadcastChannel('hm-logout-channel');
      this.logoutChannel.onmessage = (e) => {
        console.log('%c nav dropdown on message', 'color: lime');
        if (e.data.cmd === 'logout') {
          this.lougOut();
          this.$router.push({ path: '/login' });
        }
      };
    },
    async logOut() {
      await this.$Amplify.Auth.signOut();
      // this.setIsLoggedIn(false);
      this.$router.push({ path: '/login' });
      this.logoutChannel.postMessage({ cmd: 'logout', user: 'Joseph Well' });
    },
  },
};
</script>
