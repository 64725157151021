// disable-es-lint
<template>
    <div class="bg  bg-white flex flex-wrap content-center bg-[../assets/images/add-project-bg.png]">
      <div class="px-6 md:px-6 lg:px-0 mx-auto">
        <header class="py-8">
          <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
            <div class="flex-1 min-w-0">
              <nav class="flex" aria-label="Breadcrumb">
                <ol class="flex items-center space-x-4" role="list">
                  <li>
                    <div>
                      <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class=" text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                    </div>
                  </li>
                  <li>
                    <div class="flex items-center">
                      <!-- Heroicon name: solid/chevron-right -->
                      <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                      </svg>
                      <router-link tag="a" to="/account-profile" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.profile')}}</router-link>
                    </div>
                  </li>
                </ol>
              </nav>
              <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate flex space-x-6 gap-4 items-center">
                <router-link tag="a" to="/account-profile" class=" text-sm font-medium text-gray-500 hover:text-gray-700"><svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 24 24"><path d="M19.14.29A.42.42,0,0,0,18.61,0,99.88,99.88,0,0,0,8.55,7.76a26.76,26.76,0,0,0-3.1,3.13,2.37,2.37,0,0,0-.61,1.46,2.18,2.18,0,0,0,.46.92,16.73,16.73,0,0,0,1.87,2c3.1,3,8.7,7.46,10,8.62a.38.38,0,1,0,.51-.55c-1.12-1.08-5.35-4.92-8.41-7.91a38.47,38.47,0,0,1-2.68-2.79c-.18-.22-.24-.55-.36-.53.1-.15.35-.4.53-.62a41.33,41.33,0,0,1,4-3.93A105,105,0,0,1,18.87.83.44.44,0,0,0,19.14.29ZM6.26,12.19s0,.1,0,.09,0-.06,0-.13Z" fill="#191919"></path></svg></router-link>
                {{$t('ui.settingsChangePlanTitle')}}
              </h1>
              <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                <!-- Header details -->
                <div class="text-sm mt-4 -mb-4 flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-14 h-10 inline-block mr-3" width="24.706" height="24.149" viewBox="0 0 24.706 24.149">
                    <g id="Group_5442" data-name="Group 5442" transform="translate(0.544 0.192)">
                      <g id="Group_5441" data-name="Group 5441" transform="translate(0.05 0.323)">
                        <path id="Path_43362" data-name="Path 43362" d="M23.578,10.285A9.738,9.738,0,0,0,21.7,5.564,12.813,12.813,0,0,0,12.7.39a10.97,10.97,0,0,0-9.61,3.854A12.734,12.734,0,0,0,.693,16.494c1.36,3.647,4.751,6.5,10.142,6.9a16.144,16.144,0,0,0,2.848,0,12.172,12.172,0,0,0,2.8-.6.365.365,0,0,0,.217-.444.345.345,0,0,0-.434-.217,11.088,11.088,0,0,1-2.651.522,14.705,14.705,0,0,1-2.71,0c-4.928-.493-8.072-3.124-9.274-6.5A11.7,11.7,0,0,1,3.955,5.012a9.856,9.856,0,0,1,8.594-3.43,11.945,11.945,0,0,1,8.3,4.632A8.8,8.8,0,0,1,22.682,10.4a12.014,12.014,0,0,1-.3,4.7,12.2,12.2,0,0,1-1.4,3.351,9.274,9.274,0,0,1-2.474,2.622.286.286,0,0,0-.079.414.306.306,0,0,0,.424.089,9.748,9.748,0,0,0,2.74-2.77,12.813,12.813,0,0,0,1.557-3.519A12.813,12.813,0,0,0,23.578,10.285Z" transform="translate(-0.05 -0.323)" fill="#008aff" stroke="#008aff" stroke-width="1"/>
                        <path id="Path_43363" data-name="Path 43363" d="M14.8,5.125a.306.306,0,0,0-.118-.591c-1.429.158-1.823.986-1.774,1.656a1.853,1.853,0,0,0,.67,1.232,2.3,2.3,0,0,0,1.222.532,1.971,1.971,0,0,0,2.02-1.074,1.7,1.7,0,0,0-.463-2.139.365.365,0,0,0-.493,0,.355.355,0,0,0,0,.493.867.867,0,0,1,.079,1.133.986.986,0,0,1-1.035.394,1.311,1.311,0,0,1-.877-.473c-.118-.158-.246-.306-.207-.483A1.383,1.383,0,0,1,14.8,5.125Z" transform="translate(-3.401 -1.421)"/>
                        <path id="Path_43364" data-name="Path 43364" d="M13.261,20.489a.759.759,0,0,0,.2.424,3.814,3.814,0,0,0,1.971.542,8.713,8.713,0,0,0,2.76-.177.857.857,0,0,0,.424-.483,2.789,2.789,0,0,0,.128-.69l.108-.66a.986.986,0,0,0-.394-.986,2.888,2.888,0,0,0-1.262-.394,1.106,1.106,0,0,1-.542-.089,1.084,1.084,0,0,1,0-.177c0-1.035,0-3.361-.069-4.829a5.243,5.243,0,0,0-.2-1.321.562.562,0,0,0-.286-.325,5.638,5.638,0,0,0-1.892-.483,7.54,7.54,0,0,0-1.9.049.355.355,0,0,0-.306.384.345.345,0,0,0,.384.3,7.993,7.993,0,0,1,1.745,0c1.971.266,1.547.522,1.567,1.291,0,1.39-.079,3.657-.108,4.78v.65a.582.582,0,0,0,.168.4,1.488,1.488,0,0,0,.739.355c.256.059.562.1.838.158s.286.158.375.168l-.1.591v.315a8.87,8.87,0,0,1-1.646.049,8.388,8.388,0,0,1-1.34-.138H14.4a2.316,2.316,0,0,0,0-.256c-.089-1.242-.089-4.11-.1-5.411V13.9a.493.493,0,0,0-.138-.335,1.616,1.616,0,0,0-.867-.434c-.493-.089-.986.069-1.636-.079,0-.059-.089-.177-.1-.286a3.519,3.519,0,0,1,0-.848h0v-.049h0a.246.246,0,0,0,0-.187.3.3,0,0,0-.325-.256.306.306,0,0,0-.227.118,1.212,1.212,0,0,0-.128.473,3.578,3.578,0,0,0,.089,1.262.66.66,0,0,0,.375.414,11.078,11.078,0,0,0,1.774.2.986.986,0,0,1,.3.128,3.4,3.4,0,0,1,0,.375c0,1.045-.168,3.371-.177,4.859a9.58,9.58,0,0,0,.02,1.242Z" transform="translate(-2.87 -3.056)"/>
                      </g>
                    </g>
                  </svg>
                  <span>{{$t('ui.changePlanInfo')}}</span>
                </div>
              </div>
            </div>
            <div class="mt-5 flex xl:mt-0 xl:ml-4">
              <!-- Header Actions -->
              <!-- <Button
                size="sm"
                class="mx-auto"
                :loading="loading"
                @click.native="showPackageComparision = true">{{$t('ui.packagesCompare')}}</Button> -->
            </div>
          </div>
        </header>
        <!-- packages -->
        <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-center">
          <div class="text-center" v-if="!subscriptions">
            <svg class="spinner m-auto" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
          <div class="relative md:block hidden" v-if="subscriptions">
            <div class="mt-6 space-y-4 sm:mt-8 sm:space-y-0 sm:gap-6 max-w-5xl md:w-[832px] mx-auto flex"
              :class="{'opacity-50 pointer-events-none': loading}">
              <div class="flex-shrink-0 w-64">
                <PackageSmall :name="subscriptionPrice.product.name" :package="subscriptions[subscriptionPlan]" :desc="$t(`packages.${subscriptionPlan.toLowerCase()}Desc`)" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: [subscriptionMetadata.chars_monthly.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')] },
                  { name: 'extraPackagePrice', value: [displayPrice(addOnPrice.unit_amount), '10 000'] },
                  { name: 'license', value: [license] },
                ]" symbol="$" :isMonthly="subscriptionPrice.recurring.interval" :current="true">
                </PackageSmall>
              </div>
              <div class="inline-block main-carousel flex-1" refs="mainCarousel">
                  <div class="h-full" v-if="upgradablePlans.indexOf('Startermonth') > -1">
                  <PackageSmall v-if="upgradablePlans.indexOf('Startermonth') > -1" :name="$t('packages.Starter') + ' Monthly'" :package="subscriptions.Starter" :desc="$t('packages.starterDesc')" :features="[
                    { name: 'packageUnlimited', value: ['unlimited'] },
                    { name: 'packageCharacterLimit', value: ['100 000'] },
                    { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                    { name: 'license', value: ['starter'] },
                  ]" symbol="$" :isMonthly="'month'">
                    <Button
                      class="block w-full"
                      :loading="loading"
                      size="sm2"
                      @click.native="showChangeConfirmation = subscriptions.Starter.month">{{$t('ui.changeTo')}}</Button>
                  </PackageSmall>
                  </div>
                  <div class="h-full" v-if="upgradablePlans.indexOf('Starteryear') > -1">
                  <PackageSmall v-if="upgradablePlans.indexOf('Starteryear') > -1" :name="$t('packages.Starter') + ' Starter Yearly'" :package="subscriptions.Starter" :desc="$t('packages.starterDesc')" :features="[
                    { name: 'packageUnlimited', value: ['unlimited'] },
                    { name: 'packageCharacterLimit', value: ['100 000'] },
                    { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                    { name: 'license', value: ['starter'] },
                  ]" symbol="$" :isMonthly="'year'">
                    <Button
                      class="block w-full"
                      :loading="loading"
                      size="sm2"
                      @click.native="showChangeConfirmation = subscriptions.Starter.year">{{$t('ui.changeTo')}} {{$t('ui.packageStarter')}}</Button>
                  </PackageSmall>
                  </div>
                  <div class="h-full" v-if="upgradablePlans.indexOf('Businessmonth') > -1 && currentInterval === 'month'">
                  <PackageSmall v-if="upgradablePlans.indexOf('Businessmonth') > -1" :name="$t('packages.Business') + ' Monthly'" :package="subscriptions.Business" :desc="$t('packages.businessDesc')" :features="[
                    { name: 'packageUnlimited', value: ['unlimited'] },
                    { name: 'packageCharacterLimit', value: ['250 000'] },
                    { name: 'extraPackagePrice', value: ['1.49', '10 000'] },
                    { name: 'license', value: ['full'] },
                  ]" symbol="$" :isMonthly="'month'">
                    <Button
                      class="block w-full"
                      :loading="loading"
                      size="sm2"
                      @click.native="showChangeConfirmation = subscriptions.Business.month">{{$t('ui.changeTo')}}</Button>
                  </PackageSmall>
                  </div>
                  <div class="h-full" v-if="upgradablePlans.indexOf('Businessyear') > -1">
                  <PackageSmall v-if="upgradablePlans.indexOf('Businessyear') > -1" :name="$t('packages.Business') + ' Yearly'" :package="subscriptions.Business" :desc="$t('packages.businessDesc')" :features="[
                    { name: 'packageUnlimited', value: ['unlimited'] },
                    { name: 'packageCharacterLimit', value: ['250 000'] },
                    { name: 'extraPackagePrice', value: ['1.49', '10 000'] },
                    { name: 'license', value: ['full'] },
                  ]" symbol="$" :isMonthly="'year'">
                    <Button
                      class="block w-full"
                      :loading="loading"
                      size="sm2"
                      @click.native="showChangeConfirmation = subscriptions.Business.year">{{$t('ui.changeTo')}}</Button>
                  </PackageSmall>
                  </div>
                  <div class="h-full" v-if="upgradablePlans.indexOf('Premiummonth') > -1 && currentInterval === 'month'">
                  <PackageSmall v-if="upgradablePlans.indexOf('Premiummonth') > -1" :name="$t('packages.Premium') + ' Monthly'" :package="subscriptions.Premium" :desc="$t('packages.premiumDesc')" :features="[
                    { name: 'packageUnlimited', value: ['unlimited'] },
                    { name: 'packageCharacterLimit', value: ['1 000 000'] },
                    { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
                    { name: 'license', value: ['full'] },
                  ]" symbol="$" :isMonthly="'month'">
                    <Button
                      class="block w-full"
                      :loading="loading"
                      size="sm2"
                      @click.native="showChangeConfirmation = subscriptions.Premium.month">{{$t('ui.changeTo')}}</Button>
                  </PackageSmall>
                  </div>
                  <div class="h-full" v-if="upgradablePlans.indexOf('Premiumyear') > -1">
                  <PackageSmall v-if="upgradablePlans.indexOf('Premiumyear') > -1" :name="$t('packages.Premium') + ' Yearly'" :package="subscriptions.Premium" :desc="$t('packages.basicDesc')" :features="[
                    { name: 'packageUnlimited', value: ['unlimited'] },
                    { name: 'packageCharacterLimit', value: ['1 000 000'] },
                    { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
                    { name: 'license', value: ['full'] },
                  ]" symbol="$" :isMonthly="'year'">
                    <Button
                      class="block w-full"
                      :loading="loading"
                      size="sm2"
                      @click.native="showChangeConfirmation = subscriptions.Premium.year">{{$t('ui.changeTo')}}</Button>
                  </PackageSmall>
                  </div>
              </div>
            </div>
          </div>

           <div class="relative md:hidden" v-if="subscriptions">
            <div class="mt-6 space-y-4 sm:mt-8 sm:space-y-0 sm:gap-6 max-w-5xl md:w-[832px] mx-auto flex"
              :class="{'opacity-50 pointer-events-none': loading}">
              <div class="mt-6 space-y-4 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 max-w-5xl mx-auto xl:grid-cols-3">
                <PackageSmall :name="subscriptionPrice.product.name" :package="subscriptions[subscriptionPlan]" :desc="$t(`packages.${subscriptionPlan.toLowerCase()}Desc`)" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: [subscriptionMetadata.chars_monthly] },
                  { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                  { name: 'license', value: ['starter'] },
                ]" symbol="$" :isMonthly="subscriptionPrice.recurring.interval" :current="true">
                </PackageSmall>
                <PackageSmall v-if="upgradablePlans.indexOf('Startermonth') > -1" name="Starter Monthly" :package="subscriptions.Starter" :desc="$t('packages.starterDesc')" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: ['100 000'] },
                  { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                  { name: 'license', value: ['starter'] },
                ]" symbol="$" :isMonthly="'month'">
                  <Button
                    class="block w-full"
                    :loading="loading"
                    size="sm2"
                    @click.native="showChangeConfirmation = subscriptions.Starter.month">{{$t('ui.changeTo')}}</Button>
                </PackageSmall>
                <PackageSmall v-if="upgradablePlans.indexOf('Starteryear') > -1" name="Starter Yearly" :package="subscriptions.Starter" :desc="$t('packages.starterDesc')" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: ['100 000'] },
                  { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                  { name: 'license', value: ['full'] },
                ]" symbol="$" :isMonthly="'year'">
                  <Button
                    class="block w-full"
                    :loading="loading"
                    size="sm2"
                    @click.native="showChangeConfirmation = subscriptions.Starter.year">{{$t('ui.changeTo')}} {{$t('ui.packageStarter')}}</Button>
                </PackageSmall>
                <PackageSmall v-if="upgradablePlans.indexOf('Businessmonth') > -1 && currentInterval === 'month'" name="Business Monthly" :package="subscriptions.Business" :desc="$t('packages.businessDesc')" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: ['250 000'] },
                  { name: 'extraPackagePrice', value: ['1.59', '10 000'] },
                  { name: 'license', value: ['full'] },
                ]" symbol="$" :isMonthly="'month'">
                  <Button
                    class="block w-full"
                    :loading="loading"
                    size="sm2"
                    @click.native="showChangeConfirmation = subscriptions.Business.month">{{$t('ui.changeTo')}}</Button>
                </PackageSmall>
                <PackageSmall v-if="upgradablePlans.indexOf('Businessyear') > -1" name="Business Yearly" :package="subscriptions.Business" :desc="$t('packages.businessDesc')" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: ['250 000'] },
                  { name: 'extraPackagePrice', value: ['1.59', '10 000'] },
                  { name: 'license', value: ['full'] },
                ]" symbol="$" :isMonthly="'year'">
                  <Button
                    class="block w-full"
                    :loading="loading"
                    size="sm2"
                    @click.native="showChangeConfirmation = subscriptions.Business.year">{{$t('ui.changeTo')}}</Button>
                </PackageSmall>
                <PackageSmall v-if="upgradablePlans.indexOf('Premiummonth') > -1 && currentInterval === 'month'" name="Premium Monthly" :package="subscriptions.Premium" :desc="$t('packages.premiumDesc')" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: ['1 000 000'] },
                  { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
                  { name: 'license', value: ['full'] },
                ]" symbol="$" :isMonthly="'month'">
                  <Button
                    class="block w-full"
                    :loading="loading"
                    size="sm2"
                    @click.native="showChangeConfirmation = subscriptions.Premium.month">{{$t('ui.changeTo')}}</Button>
                </PackageSmall>
                <PackageSmall v-if="upgradablePlans.indexOf('Premiumyear') > -1" name="Premium Yearly" :package="subscriptions.Premium" :desc="$t('packages.premiumDesc')" :features="[
                  { name: 'packageUnlimited', value: ['unlimited'] },
                  { name: 'packageCharacterLimit', value: ['1 000 000'] },
                  { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
                  { name: 'license', value: ['full'] },
                ]" symbol="$" :isMonthly="'year'">
                  <Button
                    class="block w-full"
                    :loading="loading"
                    size="sm2"
                    @click.native="showChangeConfirmation = subscriptions.Premium.year">{{$t('ui.changeTo')}}</Button>
                </PackageSmall>
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mb-10">
        </div>
        <Modal :show.sync="showPackageComparision" width="6xl">
          <div slot="header"></div>
          <PackageComparision></PackageComparision>
          <div class="text-center mt-12 mb-5">
            <Button @click.native="showPackageComparision = false">{{$t('ui.ok')}}</Button>
          </div>
        </Modal>
        <Modal :show.sync="showCancelConfirmation" width="3xl">
          <div slot="header"></div>
          <div class="flex py-3 px-4 space-x-4">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-28 h-28" width="36.703" height="42.567" viewBox="0 0 36.703 42.567">
                <g id="Group_5392" data-name="Group 5392" transform="translate(-4.348 -3.043)">
                  <g id="Sketch-annotation-element-stroke-illustration-line-warning-non-filled" transform="translate(4.978 3.808)">
                    <path id="Vector" d="M37.5,38.6c4.136-2.132,3.124-7.2.643-10.238C34.288,23.638,23.4,5.962,17.9,4.07,13.206,2.455,11.547,8.7,10.88,11.7,9.243,19.038,7.132,26.327,5.812,33.738c-.552,3.1-1.792,6.31.492,9.094,2.574,3.139,6.671,2.141,10.173,1.494,3.476-.642,18.138-4.242,21.018-5.727Z" transform="translate(-4.978 -3.808)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5" fill-rule="evenodd"/>
                    <path id="Vector_2" d="M14.817,20.769c.292-3.492.665-6.752.04-9.847a3.075,3.075,0,0,0-2.395-2.643c-1.984-.122-2.47,1.969-2.144,3.673C11,15.5,12.826,17.464,14.817,20.769Z" transform="translate(2.955 2.944)" fill="none" stroke="#fea900" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5" fill-rule="evenodd"/>
                    <path id="Vector_3" d="M14.309,14.436c-1.132-.452-2.471.768-2.706,1.817a3.407,3.407,0,0,0,1.866,4.079c3.251,1.376,4.723-4.088,2-5.515" transform="translate(4.874 12.113)" fill="none" stroke="#fea900" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5"/>
                    <path id="Vector_4" d="M12.953,4.828c-.664.056-1,1.3-1.1,1.783C11.4,8.6,6.419,29.284,5.874,32.254a4.47,4.47,0,0,0,4.337,5.337" transform="translate(-2.859 0.559)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5"/>
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <div class="text-3xl font-bold text-black sm:text-4xl lg:text-4xl">{{$t('ui.cancelTitle')}}</div>
              <div>{{$t('ui.cancelDesc')}}</div>
              <div class="text-left mt-6 space-x-3">
                <Button @click.native="showCancelConfirmation = false" >{{$t('ui.cancelAbort')}}</Button>
                <Button @click.native="cancel" type="default">{{$t('ui.cancelAnyway')}}</Button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal :show.sync="showChangeConfirmation">
          <div slot="header"></div>
          <div class="py-3 px-4 space-y-4">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-24 h-24 mx-auto" viewBox="0 0 24 24"><g><path d="M4.5,20.08a11.42,11.42,0,0,1-3.16-.6C1.47,18.27,1.29,25.06.71,1A.31.31,0,0,0,.1,1,178.14,178.14,0,0,0,.38,19.16,2,2,0,0,0,.56,20c.63.69,2.85.77,3.89.81A.34.34,0,1,0,4.5,20.08Z" fill="#fea900" fill-rule="evenodd"></path><path d="M23.27.89a1.14,1.14,0,0,0-.65-.42C19.73-.44,5.07.52,2.05.74a.34.34,0,0,0,0,.68C21,.57,22.42,1.69,22.37,1.6c.82,1.65.52,16.29.25,18.22-.16-.07-.66.19-1.12.23-.63.06-1.39.1-2.09.13a.3.3,0,1,0,0,.6c.59,0,3.36,0,3.73-.39C23.69,19.85,24.66,2.9,23.27.89Z" fill="#fea900" fill-rule="evenodd"></path><path d="M3.76,4.38a147.1,147.1,0,0,0,16,0,.35.35,0,0,0,0-.69c-5.31-.09-5.66-.53-16,.09A.3.3,0,1,0,3.76,4.38Z" fill="#191919" fill-rule="evenodd"></path><path d="M3.49,8.24c.79,0,3,.13,5.59.2,4.32.11,8.48-.17,10.41-.2a.35.35,0,0,0,0-.69c-5.36-.09-5.64-.53-16,.09A.3.3,0,0,0,3.49,8.24Z" fill="#191919" fill-rule="evenodd"></path><path d="M11.19,11.14c-3.92-.14-5.89.19-7.12.16a.3.3,0,1,0-.05.6A55.45,55.45,0,0,0,15,12a.34.34,0,1,0,0-.68C14.24,11.31,12.79,11.2,11.19,11.14Z" fill="#191919" fill-rule="evenodd"></path><path d="M16.08,15.16c-2.16-1.71-2.21-.1-1.6-.1s3.5,2.38,2.66,5.13c-.76,2.54-4.56,3.16-6.84,2.08a3.42,3.42,0,0,1-1.75-4.13,5.52,5.52,0,0,1,1.73-2.52,2.74,2.74,0,0,1,2-.73.3.3,0,0,0,0-.6,3.43,3.43,0,0,0-2.5.8,6.38,6.38,0,0,0-2.08,2.74,4.4,4.4,0,0,0,3.53,6c2.84.55,6.12-.49,6.85-3.32A5.27,5.27,0,0,0,16.08,15.16Z" fill="#191919" fill-rule="evenodd"></path><path d="M12.28,20a4.61,4.61,0,0,1-1.18-.72.3.3,0,0,0-.53.28,5.34,5.34,0,0,0,1,1.11c2.07,1.77,2.91-3.4,3.05-3.57a.35.35,0,0,0-.49-.49c-.1.09-.64,1.16-1.15,2.16A4.94,4.94,0,0,1,12.28,20Z" fill="#191919" fill-rule="evenodd"></path></g></svg>
            </div>
            <div class="text-center">
              <div class="text-3xl font-bold text-black sm:text-4xl lg:text-4xl mb-2">{{$t('ui.changePlanTitle')}}</div>
              <div v-if="showChangeConfirmation.product" v-html="$t('ui.changePlanDesc', [showChangeConfirmation.product.name])"></div>
              <div class="text-center mt-6 space-x-3">
                <Button @click.native="showChangeConfirmation = false" type="default">{{$t('ui.cancelChange')}}</Button>
                <Button @click.native="buyPackage(showChangeConfirmation)">{{$t('ui.changePlanConfirm')}}</Button>
              </div>
            </div>
          </div>
        </Modal>
        <div v-if="!subscriptionStatus.canceled" class="text-hm-blue cursor-pointer mx-auto mb-5 p-2 text-sm flex justify-center items-center space-x-2" @click="showCancelConfirmation = true">
          <span>{{$t('ui.cancelSubscription')}}</span>
        </div>
      </div>
      <pre class="break-words max-w-md fixed text-[10px] bg-white bg-opacity-50" v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612'">{{addOnPrice}}</pre>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import Flickity from 'flickity';

export default {
  data() {
    return {
      loading: true,
      isMonthly: 'month',
      showPackageComparision: false,
      showChangeConfirmation: false,
      showCancelConfirmation: false,
      showCancelConfirmationMessage: false,
      options: {
        currentPage: 0,
        infinite: 2,
        slidesToScroll: 2,
        loop: true,
        pageDots: false,
        contain: true,
        adaptiveHeight: false,
      },
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
      addWebsiteModel: (state) => state.website.addWebsiteModel,
      pluginLink: (state) => state.utils.pluginUrl,
      selectedWebsite: (state) => state.website.selectedWebsite,
      prices: (state) => state.billing.prices,
      subscriptions: (state) => state.billing.subscriptions,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      subscriptionAddOns: (state) => state.billing.subscriptionAddOns,
    }),
    displayPrice() {
      return (unitAmount) => {
        const price = unitAmount / 100;
        const isInt = Number.isInteger(price);
        const priceString = isInt ? `${price}.00` : `${price}`;
        return priceString;
      };
    },
    symbol() {
      return this.$t(`currency.${this.addOnPrice.currency}`);
    },
    upgradablePlans() {
      const plans = [
        'Basicmonth',
        'Basicyear',
        'Startermonth',
        'Starteryear',
        'Businessmonth',
        'Businessyear',
        'Premiummonth',
        'Premiumyear',
      ];

      const interval = this.subscriptionPlan && this.subscriptionPlan.recurring ? this.subscriptionPrice.recurring.interval : 'month';

      const planName = `${this.subscriptionPlan}${interval}`;

      return plans.slice(plans.indexOf(planName) + 1, plans.length);
    },
    addOnPrice() {
      console.log('subscriptionAddOns', this.subscriptionAddOns);
      if (!this.subscriptionPlan) return {};
      return this.subscriptionAddOns[this.subscriptionPlan];
    },
    license() {
      return this.subscriptionPlan.toLowerCase() === 'starter' ? 'starter' : 'full';
    },
    subscriptionPlan() {
      return this.subscriptionStatus.plan;
    },
    currentInterval() {
      if (!this.subscriptionPrice.recurring) return 'month';
      return this.subscriptionPrice.recurring.interval;
    },
    subscriptionMetadata() {
      return this.subscriptionPrice.product.metadata || {};
    },
    subscriptionPrice() {
      if (!this.subscriptions[this.subscriptionPlan]) return {};
      return this.subscriptions[this.subscriptionPlan][this.subscriptionStatus.interval];
    },
  },
  watch: {
    subscriptions(newSubs, oldSubs) {
      if (newSubs.ok) {
        this.loading = false;
      }
      const carousel = this.$refs.mainCarousel;
      console.log('subscriptions watch', newSubs, oldSubs, carousel, this.$refs);
      setTimeout(() => {
        this.fickity = new Flickity('.main-carousel', {
          groupCells: 2,
          cellAlign: 'left',
          pageDots: false,
          adaptiveHeight: false,
        });
      }, 1000);
    },
  },
  async created() {
    this.setSidebarVisibility(false);
    this.loading = true;
    if (!this.subscriptions) {
      await this.fetchPrices();
    }
    console.log('subs => ', this.subscriptions);
    this.loading = false;
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  mounted() {
    setTimeout(() => {
      this.fickity = new Flickity('.main-carousel', {
        groupCells: 2,
        cellAlign: 'left',
        pageDots: false,
        adaptiveHeight: false,
      });
    }, 100);
  },
  methods: {
    ...mapActions({
      fetchPrices: 'billing/fetchPrices',
      getPaymentLink: 'billing/getPaymentLink',
      cancelSubscription: 'billing/cancelSubscription',
      // getSubscriptionStatus: 'billing/getSubscriptionStatus',
      updateSubscription: 'billing/updateSubscription',
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    async fetchPaymentLink() {
      const data = {
        customer: this.customerId,
        domain: 'localhost',
        productId: '',
        priceId: '',
      };

      const result = await this.getPaymentLink(data);
      console.log('payment link result', result);
    },
    async buyPackage(subPackage) {
      this.loading = true;
      console.log('buyPackage', subPackage);
      const { id } = subPackage;
      const result = await this.updateSubscription(id);
      console.log('buyPackage result', result);
      this.loading = false;
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.errorPlanChange'),
        });
        return;
      }

      if (result.paymentURL) {
        window.location.replace(result.paymentURL);
        return;
      }
      this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list/subscription-updated` });

      // this.goToDashboard();
    },
    // async buyPackage(subPackage) {
    //   this.loading = true;
    //   console.log('buyPackage', subPackage);
    //   const { product, id } = subPackage;
    //   const domain = `https://${window.location.host}/articles/${this.selectedWebsite.id}/list`;
    //   // const domain = `https://${window.location.host}/add-project`;
    //   const data = {
    //     customer: this.customerId,
    //     domain,
    //     productId: product.id,
    //     priceId: id,
    //   };

    //   // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
    //   // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

    //   const result = await this.getPaymentLink(data);
    //   if (!result.url) return;
    //   window.location.replace(result.url);
    //   this.loading = false;
    //   console.log('payment link result', data, result);
    // },
    goToDashboard() {
      this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list` });
    },

    async cancel() {
      this.loading = true;
      const result = await this.cancelSubscription();

      if (!result.ok) {
        return;
      }

      this.loading = false;
      this.showCancelConfirmation = false;
      this.showCancelConfirmationMessage = false;
      // await this.getSubscriptionStatus();
      this.$router.push({ path: '/account-profile/subscription-cancaled' });
      console.log('cancelSubscription', result);
    },

    slide(data) {
      console.log(data);
    },
    onTap(data) {
      console.log(data);
    },
    onInit(data) {
      console.log(data);
    },
  },
};
</script>
<style>
.flickity-cell {
    width: 277px;
    overflow: hidden;
    position: absolute;
    left: 0px;
}
.flickity-page-dots {
  display: none !important;
}
.flickity-button {
    position: absolute;
    background: transparent;
}
.flickity-prev-next-button.previous {
  left: -32px;
}
.flickity-prev-next-button.next {
    right: -16px;
}
.flickity-resize .carousel-cell {
  min-height: 100%;
}
</style>
