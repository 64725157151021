<template>
  <div>
    <header class="py-8">
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.creditsUsage')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.creditsUsage')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">
      <!-- trial upsell -->
      <div v-if="this.subscriptions.Premium">
        <transition name="fadeHeight" mode="out-in">
        <div v-if="subscriptionStatus.ok && isTrial" class="">
          <div class="text-black border-2 border-black rounded-xl bg-white relative md:flex justify-between p-6 mb-4">
            <div>
              <div class="text-xl font-bold">{{$t('ui.trialUpsellTitle')}}</div>
              <div class="text-sm font-normal">{{$t('ui.trialUpsellDesc')}}</div>
            </div>
            <div class="flex mt-4 md:mt-0">
              <router-link to="/subscribe" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-6 py-3 text-sm">{{$t('ui.subscribe')}}</router-link>
            </div>
          </div>
        </div>
        </transition>
        <!-- sub buy characters -->
        <transition name="fadeHeight" mode="out-in">
        <div v-if="isSubscriptionActiveNotTrial" class="">
          <div class="text-black border-2 border-black rounded-xl bg-white relative md:flex justify-between p-6 mb-4">
            <div>
              <div class="text-xl font-bold">{{$t('ui.creditsAddOnTitle')}}</div>
              <div class="text-sm font-normal">{{$t('ui.creditsAddOnDesc', [displayPrice(subscriptionAddOn.unit_amount)])}}</div>
            </div>
            <div class="flex mt-4 md:mt-0">
              <Button
                  :loading="loading"
                  @click.native="buyPackage(subscriptionAddOn)"
                  class="px-8"
                  >{{$t('ui.buy')}}</Button>
            </div>
          </div>
        </div>
        </transition>
        <transition name="fadeHeight" mode="out-in">
        <div v-if="!isSubscriptionActive && !isTrial && currentSubPrice.product.name">
          <div class="text-black border-2 border-black rounded-xl bg-white relative md:flex justify-between p-6 mb-4">
            <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product.images">
              <svg xmlns="http://www.w3.org/2000/svg" width="36.703" height="42.567" viewBox="0 0 36.703 42.567" class="w-11 h-11"><g id="Group_5392" data-name="Group 5392" transform="translate(-4.348 -3.043)"><g id="Sketch-annotation-element-stroke-illustration-line-warning-non-filled" transform="translate(4.978 3.808)"><path id="Vector" d="M37.5,38.6c4.136-2.132,3.124-7.2.643-10.238C34.288,23.638,23.4,5.962,17.9,4.07,13.206,2.455,11.547,8.7,10.88,11.7,9.243,19.038,7.132,26.327,5.812,33.738c-.552,3.1-1.792,6.31.492,9.094,2.574,3.139,6.671,2.141,10.173,1.494,3.476-.642,18.138-4.242,21.018-5.727Z" transform="translate(-4.978 -3.808)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5" fill-rule="evenodd"></path><path id="Vector_2" d="M14.817,20.769c.292-3.492.665-6.752.04-9.847a3.075,3.075,0,0,0-2.395-2.643c-1.984-.122-2.47,1.969-2.144,3.673C11,15.5,12.826,17.464,14.817,20.769Z" transform="translate(2.955 2.944)" fill="none" stroke="#fea900" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5" fill-rule="evenodd"></path><path id="Vector_3" d="M14.309,14.436c-1.132-.452-2.471.768-2.706,1.817a3.407,3.407,0,0,0,1.866,4.079c3.251,1.376,4.723-4.088,2-5.515" transform="translate(4.874 12.113)" fill="none" stroke="#fea900" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5"></path><path id="Vector_4" d="M12.953,4.828c-.664.056-1,1.3-1.1,1.783C11.4,8.6,6.419,29.284,5.874,32.254a4.47,4.47,0,0,0,4.337,5.337" transform="translate(-2.859 0.559)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5" stroke-width="1.5"></path></g></g></svg>
              <div>
                <div>{{currentSubPrice.product.name}} <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">{{$t('ui.subInactive')}}</span></div>
                <div class="opacity-50 text-[14px]">{{$t(`ui.subscriptioninactiveUpsell`)}}</div>
              </div>
            </div>
            <div class="mt-4 md:mt-0">
              <router-link to="/subscribe" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-6 py-3 text-sm">{{$t('ui.subscribeAgain')}}</router-link>
            </div>
          </div>
        </div>
        </transition>
      </div>
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <div>

              </div>
              <table class="min-w-full table-auto">
                <thead class="">
                  <tr>
                    <th scope="col" class="hidden md:block x-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.date')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.operation')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-right">
                      {{$t('ui.amount')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.balance')}}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(item, index) in items" :key="item.billedAt + index">
                    <td class="px-6 pl-0 py-4 text-sm hidden md:block">
                      {{moment.utc(item.billedAt).local().format('DD-MM-YYYY HH:mm')}}
                    </td>
                    <td class="px-6 pl-0 py-4 ">
                      <div class="md:hidden block text-sm">
                        {{moment.utc(item.billedAt).local().format('DD-MM-YYYY HH:mm')}}
                      </div>
                      <div class="text-sm text-gray-900 font-bold">{{$t('ui.' + item.operationType)}}</div>
                      <div class="text-gray-500 text-sm min-w-[400px] ">{{operationDetails(item)}}</div>
                      <!-- {{item.operationDetails}} -->
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap  text-sm text-right"
                      :class="{ 'text-red-500': isNegative(item) < 0, 'text-green-600': isNegative(item) > 0 }">
                      <span v-show="isNegative(item) < 0">-</span>{{item.amount | intFormat}}
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500 text-right"
                      v-tippy="{ content: $t('ui.charactersAmounBillingTooltip', [formattedInt(item.creditsAfter), formattedInt(item.additionalCreditsAfter)]) }">
                      {{(parseInt(item.creditsAfter) + parseInt(item.additionalCreditsAfter))  | intFormat}}
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="max-w-5xl mx-auto pt-10 divide-y divide-gray-300">
        <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      moment,
      // items: [
      //   {
      //     date: new Date(),
      //     operation: 'Credits added',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     operation: 'Article generated',
      //     amount: -12,
      //     balance: 88,
      //   },
      // ],
      pageCount: 0,
      pageSize: 25,
      page: 1,
      loading: false,
      offset: 0,
      selectedOrder: 'BILLED_AT',
      orderDirString: 'DESC',
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
    };
  },
  computed: {
    ...mapState({
      customerList: (state) => state.admin.customerList,
      selectedWebsite: (state) => state.website.selectedWebsite,
      totalCount: (state) => state.billing.count,
      hasNextPage: (state) => state.billing.hasNextPage,
      items: (state) => state.billing.billingHistory,
      customer: (state) => state.auth.customer,
      customerId: (state) => state.auth.customerId,
      userProfile: (state) => state.auth.userProfile,
      subscriptions: (state) => state.billing.subscriptions,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      subscriptionAddOns: (state) => state.billing.subscriptionAddOns,
    }),
    isSubscriptionActiveNotTrial() {
      return this.subscriptionStatus && this.subscriptionStatus.active && this.subscriptionStatus.plan !== 'Trial';
    },
    isSubscriptionActive() {
      return this.subscriptionStatus && this.subscriptionStatus.active;
    },
    isTrial() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Trial';
    },
    formattedInt() {
      return (str) => str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    subscriptionPlan() {
      return this.subscriptionStatus.plan;
    },
    subscriptionPrice() {
      if (!this.subscriptions[this.subscriptionPlan]) return {};
      return this.subscriptions[this.subscriptionPlan][this.subscriptionStatus.interval];
    },
    subscriptionAddOn() {
      return this.subscriptionAddOns[this.subscriptionPlan] ? this.subscriptionAddOns[this.subscriptionPlan] : { unit_amount: 0 };
    },
    symbol() {
      return this.$t(`currency.${this.currentSubPrice.currency}`);
    },
    currentSubPrice() {
      if (!this.subscriptionStatus) return {};
      if (!this.subscriptionStatus.plan || !this.subscriptions) return { currency: 'usd', product: { name: '', metadata: { chars_monthly: '' } } };
      if (this.subscriptionStatus.plan === 'Trial') return { currency: 'free', product: { name: 'Trial', metadata: { chars_monthly: '24 000' } } };
      const subName = this.subscriptionStatus.plan;
      const subInterval = this.subscriptionStatus.interval;
      console.log(this.subscriptions);
      if (this.subscriptions[subName]) return this.subscriptions[subName][subInterval];
      return {};
    },
    displayPrice() {
      return (unitAmount) => {
        const price = unitAmount / 100;
        const isInt = Number.isInteger(price);
        const priceString = isInt ? `${this.symbol}${price}.00` : `${this.symbol}${price}`;
        return priceString;
      };
    },
    stripTag() {
      return (unsafe) => {
        const doc = new DOMParser().parseFromString(unsafe, 'text/html');
        const str = doc.documentElement.textContent;
        return str.replace(/(<([^>]+)>)/gi, '');
      };
    },
    operationDetails() {
      return (item) => {
        const type = item.operationType;
        let details = '';
        switch (type) {
          case 'block_credits_for_article_generation':
            details = item.operationDetails.episodeTitle;
            break;
          case 'coupon_redeem':
            details = item.operationDetails.couponCode;
            break;
          case 'give_credits_back_after_fail':
            details = `${item.operationDetails.reason} for ${item.operationDetails.episodeTitle}`;
            break;
          case 'ad_hoc_credits_add':
            details = `${item.operationDetails.reason}`;
            break;
          default:
            details = '';
            break;
        }
        return this.stripTag(details);
      };
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          visibilityLevel: 'hm_admin',
          // isDeleted: false,
        },
      };
      if (this.customer) {
        input.condition.customerId = this.customer;
      }

      return input;
    },
    isNegative() {
      return (item) => (item.creditsAfter + item.additionalCreditsAfter) - (item.creditsBefore + item.additionalCreditsBefore);
    },
  },
  created() {
    this.clearState();
  },
  watch: {
    // eslint-disable-next-line
    customer: function () {
      // this.clearState();
      this.loadData(this.page);
    },
  },
  async mounted() {
    this.clearState();
    this.loadData(1);
    if (!Object.prototype.hasOwnProperty.call(this.subscriptions, 'Premium')) await this.fetchPrices();
  },
  methods: {
    ...mapActions({
      fetch: 'billing/fetchHistory',
      getPaymentLink: 'billing/getPaymentLink',
      fetchPrices: 'billing/fetchPrices',
    }),
    ...mapMutations({
      clearState: 'billing/clearBillingState',
    }),
    goTo(path) {
      this.$router.push({ path });
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },

    async buyPackage(plan) {
      this.loading = true;
      console.log('buyplan', plan, this.customerId);
      const { product, id } = plan;
      const domain = `https://${window.location.host}/articles/${this.selectedWebsite.id}/list`;
      const data = {
        customer: this.customerId,
        domain,
        productId: product.id,
        priceId: id,
      };

      // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
      // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

      const result = await this.getPaymentLink(data);
      this.loading = false;
      if (!result.url) return;
      window.location.replace(result.url);
      console.log('payment link result', data, result);
    },

    // Billing History
    async loadData(page) {
      this.loading = true;
      this.offset = page * this.pageSize - this.pageSize;

      // console.log('page', page, this.queryParams);
      await this.fetch(this.queryParams);
      this.loading = false;
    },
  },
};
</script>
