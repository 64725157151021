<template>
  <div class="bg min-h-screen bg-white flex flex-wrap content-center bg-[../assets/images/add-project-bg.png]">
    <div class="project-type mx-auto">
      <!-- title -->
      <div class="pt-12 sm:pt-16 lg:pt-24">
        <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <p class="text-3xl font-bold text-black sm:text-4xl lg:text-5xl">
              {{$t('ui.chooseProjectType')}}
            </p>
            <p class="text-xl ">
              {{$t('ui.chooseProjectTypeDesc')}}
            </p>
          </div>
        </div>
      </div>
      <!-- boxes -->
      <div class="mt-8 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div class="relative">
          <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
              <div class="flex flex-col border-2 border-black rounded-lg overflow-hidden bg-white transition-all ease-in-out">
                <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div class="text-blue-400">
                    <Website-Icon></Website-Icon>
                  </div>
                  <div class="mt-4 flex items-baseline text-4xl font-bold">
                    {{$t('ui.addBlogWebsiteTitle')}}
                  </div>
                  <p class="mt-5 mb-5 text-1xl text-black" v-html="$t('ui.addBlogWebsiteDesc')">
                  </p>
                </div>
                <div class="flex-1 flex flex-col justify-end px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <div class="">
                    <Button class="w-full" @click.native="selectType('website')">{{ $t("ui.addBlogChooseWebsite") }}</Button>
                  </div>
                </div>
              </div>

              <div class="flex flex-col border-2 border-black rounded-lg overflow-hidden bg-white transition-all ease-in-out">
                <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div class="text-yellow-600">
                    <Project-Icon></Project-Icon>
                  </div>
                  <div class="mt-4 flex items-baseline text-4xl font-bold">
                    {{$t('ui.addBlogProjectTitle')}}
                  </div>
                  <p class="mt-5 mb-5 text-1xl text-black" v-html="$t('ui.addBlogProjectDesc')">
                  </p>
                </div>
                <div class="flex-1 flex flex-col justify-end px-6 pt-6 pb-8 space-y-6 sm:p-10 sm:pt-6">
                  <div class="">
                    <Button class="w-full" @click.native="selectType('project')">{{ $t("ui.addBlogChooseProject") }}</Button>
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/" class="mx-auto text-sm -xs block text-center text-hm-blue cursor-pointer mt-6">
              {{$t('ui.goBack')}}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="steps hidden sm:block sm:fixed left-3 top-auto h-screen">
      <div class="flex items-center h-full">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="397" viewBox="0 0 29 397">
          <g id="steps" transform="translate(-24 -311)">
            <g id="Ellipse_91431" data-name="Ellipse 91431" transform="translate(24 311)" stroke="#000" stroke-width="2">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <g id="Ellipse_91432" data-name="Ellipse 91432" transform="translate(24 451)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <g id="Ellipse_91433" data-name="Ellipse 91433" transform="translate(24 601)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <text id="Account" transform="translate(43 403) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500"><tspan x="0" y="0">Account</tspan></text>
            <text id="Payment" transform="translate(43 546) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Payment</tspan></text>
            <text id="Dashboard" transform="translate(43 708) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Dashboard</tspan></text>
            <text id="_1" data-name="1" transform="translate(35 331)" fill="#fff" font-size="14" font-family="Inter" font-weight="700"><tspan x="0" y="0">1</tspan></text>
            <text id="_2" data-name="2" transform="translate(34 471)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">2</tspan></text>
            <text id="_3" data-name="3" transform="translate(34 621)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">3</tspan></text>
            <line id="Line_273" data-name="Line 273" y2="29" transform="translate(37.5 411.5)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
            <line id="Line_274" data-name="Line 274" y2="29" transform="translate(37.5 560)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapState({
      isSidebarVisible: (state) => state.ui.isSidebarVisible,
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
    }),
    isLanguageValid() {
      return this.websiteLanguage !== null;
    },
  },
  created() {
    this.setSidebarVisibility(false);
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  mounted() {
    this.setSidebarVisibility(false);
  },
  methods: {
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
      setWebsiteModel: 'website/setWebsiteModel',
    }),
    selectType(type) {
      console.log('type', type);
      this.$router.push({ path: `/add-project/name/${type}` });
    },
  },
};
</script>
