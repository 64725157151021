/* eslint-disable */
/**
 * Build styles
 */
import './alternative-voice.css';
require('./alternative-voice.css');
const { make } = require('./alternative-voice-util');

export default class AlternativeVoiceBlockTune {

    /**
     * Default alignment
     *
     * @public
     * @returns {string}
     */
    static get DEFAULT_VOICE() {
        return 'default';
    }
    static get CSS() {
        return {
            button: 'hm-tune-text-button'
        };
    }

    static get isTune() {
        return true;
    }

    getAlignment() {
        console.log('get Aligment', this.settings);
        if (!!this.settings?.blocks && this.settings.blocks.hasOwnProperty(this.block.name)) {
            return this.settings.blocks[this.block.name];
        }
        if (!!this.settings?.default) {
            return this.settings.default;
        }
        return AlternativeVoiceBlockTune.DEFAULT_VOICE;
    }
    /**
     *
     * @param api
     * @param data 既に設定されているデータ
     * @param settings tuneに設定項目
     * @param block tuneに設定されてるblock
     */
    constructor({ api, data, config, block }) {
        this.api = api;
        this.block = block;
        /**
         config:{
            default: "right",
            blocks: {
              header: 'center',
              list: 'right'
            }
          },
         */
        console.log('get Aligment', data);
        this.settings = config;
        this.data = data || { useAlternative: this.getAlignment() };
        this.alignmentSettings = [
            {
                name: 'default',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.991" viewBox="0 0 16 15.991">
                <path id="Path_43714" data-name="Path 43714" d="M5.533.979A.881.881,0,0,0,4.563.2c-.411.044-.818.092-1.218.138l-.084.01A3.337,3.337,0,0,0,.334,3.278c-.048.424-.1.857-.141,1.294a.881.881,0,1,0,1.752.181c.044-.428.092-.853.14-1.278a1.576,1.576,0,0,1,1.379-1.38l.082-.01c.4-.046.8-.093,1.205-.136A.881.881,0,0,0,5.533.979Zm5.311,0A.881.881,0,0,1,11.814.2c.411.044.818.092,1.218.138l.084.01a3.337,3.337,0,0,1,2.927,2.933c.048.424.1.857.141,1.294a.881.881,0,1,1-1.752.181c-.044-.428-.092-.853-.14-1.278a1.575,1.575,0,0,0-1.379-1.38l-.082-.01c-.4-.046-.8-.093-1.205-.136A.881.881,0,0,1,10.844.979ZM8.188,3.174a.881.881,0,0,1,.881.881V12.32a.881.881,0,1,1-1.762,0V4.055A.881.881,0,0,1,8.188,3.174ZM4.729,5.445a.881.881,0,0,1,.881.881V10.05a.881.881,0,0,1-1.762,0V6.326A.881.881,0,0,1,4.729,5.445Zm7.8.881a.881.881,0,0,0-1.762,0V10.05a.881.881,0,0,0,1.762,0Zm-.715,9.852a.881.881,0,1,1-.189-1.752c.4-.043.8-.09,1.2-.136h0l.082-.01a1.575,1.575,0,0,0,1.379-1.38c.048-.425.1-.85.14-1.278a.881.881,0,1,1,1.752.181c-.045.437-.094.87-.141,1.294a3.337,3.337,0,0,1-2.927,2.933l-.084.01C12.633,16.087,12.225,16.134,11.814,16.178Zm-7.252,0a.881.881,0,1,0,.189-1.752c-.4-.043-.8-.09-1.205-.136h0l-.082-.01A1.576,1.576,0,0,1,2.085,12.9c-.048-.425-.1-.85-.14-1.278A.881.881,0,1,0,.193,11.8c.045.437.094.87.141,1.294a3.337,3.337,0,0,0,2.927,2.933l.084.01C3.744,16.087,4.152,16.134,4.563,16.178Z" transform="translate(-0.188 -0.192)" fill-rule="evenodd"/>
              </svg>
              
                        </svg> <span>Use default voice</span>
                    `
            },
            {
                name: 'alternative',
                icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.991" viewBox="0 0 16 15.991">
                            <path id="Path_43714" data-name="Path 43714" d="M5.533.979A.881.881,0,0,0,4.563.2c-.411.044-.818.092-1.218.138l-.084.01A3.337,3.337,0,0,0,.334,3.278c-.048.424-.1.857-.141,1.294a.881.881,0,1,0,1.752.181c.044-.428.092-.853.14-1.278a1.576,1.576,0,0,1,1.379-1.38l.082-.01c.4-.046.8-.093,1.205-.136A.881.881,0,0,0,5.533.979Zm5.311,0A.881.881,0,0,1,11.814.2c.411.044.818.092,1.218.138l.084.01a3.337,3.337,0,0,1,2.927,2.933c.048.424.1.857.141,1.294a.881.881,0,1,1-1.752.181c-.044-.428-.092-.853-.14-1.278a1.575,1.575,0,0,0-1.379-1.38l-.082-.01c-.4-.046-.8-.093-1.205-.136A.881.881,0,0,1,10.844.979ZM8.188,3.174a.881.881,0,0,1,.881.881V12.32a.881.881,0,1,1-1.762,0V4.055A.881.881,0,0,1,8.188,3.174ZM4.729,5.445a.881.881,0,0,1,.881.881V10.05a.881.881,0,0,1-1.762,0V6.326A.881.881,0,0,1,4.729,5.445Zm7.8.881a.881.881,0,0,0-1.762,0V10.05a.881.881,0,0,0,1.762,0Zm-.715,9.852a.881.881,0,1,1-.189-1.752c.4-.043.8-.09,1.2-.136h0l.082-.01a1.575,1.575,0,0,0,1.379-1.38c.048-.425.1-.85.14-1.278a.881.881,0,1,1,1.752.181c-.045.437-.094.87-.141,1.294a3.337,3.337,0,0,1-2.927,2.933l-.084.01C12.633,16.087,12.225,16.134,11.814,16.178Zm-7.252,0a.881.881,0,1,0,.189-1.752c-.4-.043-.8-.09-1.205-.136h0l-.082-.01A1.576,1.576,0,0,1,2.085,12.9c-.048-.425-.1-.85-.14-1.278A.881.881,0,1,0,.193,11.8c.045.437.094.87.141,1.294a3.337,3.337,0,0,0,2.927,2.933l.084.01C3.744,16.087,4.152,16.134,4.563,16.178Z" transform="translate(-0.188 -0.192)" fill-rule="evenodd"/>
                        </svg>
              
                        </svg> <span>Use alternative voice</span>
                    `
            },
        ];
        this._CSS = {
            alignment: {
                default: 'ce-tune-alignment--left',
                alternative: 'ce-tune-alignment--center',
            }
        };
    }

    /**
     * block自体をwrapしてくれる
     * constructorで与えられたalignmentを代入しようとすると、holderが確定してなく
     * renderでやろうとすると、tuneを表示時に処理が走る
     * @param blockContent
     */
    wrap(blockContent) {
        this.wrapper = make("div");
        this.wrapper.classList.toggle(this._CSS.alignment[this.data.useAlternative]);
        this.wrapper.dataset.alternative = this.data.useAlternative === 'alternative';
        this.wrapper.append(blockContent);
        
        // this.wrapper.parentElement.dataset.alternative = this.data.useAlternative === 'alternative';
        setTimeout(() => {
            this.wrapper.parentElement.dataset.alternative = this.data.useAlternative === 'alternative';
        },50);
        return this.wrapper;
    }

    /**
     * rendering block tune
     * @returns {*}
     */
    render() {
        const wrapper = make("div");
        console.log('render ===>', wrapper);
        this.wrapper.parentElement.dataset.alternative = this.data.useAlternative === 'alternative';
        this.alignmentSettings.map(align => {
            const button = document.createElement('button');
            button.classList.add(this.api.styles.settingsButton, AlternativeVoiceBlockTune.CSS.button);
            button.innerHTML = align.icon;
            button.type = 'button';

            button.classList.toggle(this.api.styles.settingsButtonActive, align.name === this.data.useAlternative);
            wrapper.appendChild(button);
            return button;
        }).forEach((element, index, elements) => {
            element.addEventListener('click', () => {
                this.data = {
                    useAlternative: this.alignmentSettings[index].name
                };
                elements.forEach((el, i) => {
                    const { name } = this.alignmentSettings[i];
                    el.classList.toggle(this.api.styles.settingsButtonActive, name === this.data.useAlternative);
                    //toggle alignment style class for block
                    this.wrapper.classList.toggle(this._CSS.alignment[name], name === this.data.useAlternative);
                    this.wrapper.dataset.alternative = name === this.data.useAlternative;
                    this.wrapper.parentElement.dataset.alternative = name === this.data.useAlternative;
                });
            });
        });
        return wrapper;
    }
    /**
     * save
     * @returns {*}
     */
    save() {
        return this.data;
    }
}

