<template>
    <div class="bg min-h-screen bg-white flex flex-wrap content-center bg-[../assets/images/add-project-bg.png]">
      <div class="px-6 md:px-6 lg:px-0 pt-12 sm:pt-16 lg:pt-24 mx-auto">
        <!-- title -->
        <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8 flex flex-col">
          <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <p class="text-3xl font-bold text-black sm:text-4xl lg:text-5xl">
              {{$t('ui.paymentPackagesTitle')}}
            </p>
            <p class="text-sm sm:text-xl ">
              {{$t('ui.paymentPackagesDesc')}}
            </p>
          </div>
          <div class="relative mt-6 bg-white border-2 border-black w-auto rounded-lg p-0.5 flex self-center sm:mt-8 ">
            <button type="button" class="relative bg-black border border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-white whitespace-nowrap focus:outline-none sm:w-auto px-3 sm:px-8"
            :class="{'text-white bg-black': isMonthly === 'month', 'text-black bg-white': isMonthly === 'year'}"
            @click="isMonthly = 'month'">{{$t('ui.monthly')}}</button>
            <button type="button" class="ml-0.5 relative border border-transparent rounded-md py-2 w-1/2 text-sm font-medium  whitespace-nowrap focus:outline-none sm:w-auto px-3 sm:px-8"
            :class="{'text-white bg-black': isMonthly === 'year', 'text-black bg-white': isMonthly === 'month'}"
            @click="isMonthly = 'year'">{{$t('ui.yearly')}} <span class="text-xs font-bold bg-green-500 inline-block text-white py-0 px-1 rounded relative -top-px">{{$t('ui.saveTag')}}</span></button>
          </div>
        </div>

        <!-- packages -->
        <div class="mt-8 pb-12 sm:mt-12 sm:pb-10 lg:mt-16 lg:pb-14">
          <div class="text-center" v-if="!subscriptions.Basic">
            <svg class="spinner m-auto" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
          <div class="relative" v-if="subscriptions.Starter">
            <div class=" mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 max-w-5xl mx-auto xl:grid-cols-3"
              :class="{'opacity-50 pointer-events-none': loading}">
              <Package :name="$t('packages.Starter')" :package="subscriptions.Starter" :desc="$t('packages.starterDesc')" :features="[
                { name: 'packageCharacterLimit', value: ['100 000'] },
                { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                { name: 'packageUnlimited', value: ['unlimited'] },
                { name: 'license', value: ['starter'] },
              ]" symbol="$" :isMonthly="isMonthly">
                <Button
                  class="block w-full"
                  :loading="loading"
                  @click.native="buyPackage(subscriptions.Starter[isMonthly])">{{$t('ui.buy')}} {{$t('packages.packageStarter')}}</Button>
              </Package>
              <Package :name="$t('packages.Business')" :popular="true" :package="subscriptions.Business" :desc="$t('packages.businessDesc')" :features="[
                { name: 'packageCharacterLimit', value: ['250 000'] },
                { name: 'extraPackagePrice', value: ['1.49', '10 000'] },
                { name: 'packageUnlimited', value: ['unlimited'] },
                { name: 'license', value: ['full'] },
              ]" symbol="$" :isMonthly="isMonthly">
                <Button
                  type="brand"
                  class="block w-full"
                  :loading="loading"
                  @click.native="buyPackage(subscriptions.Business[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packageBusiness')}}</Button>
              </Package>
              <Package :name="$t('packages.Premium')" :package="subscriptions.Premium" :desc="$t('packages.premiumDesc')" :features="[
                { name: 'packageCharacterLimit', value: ['1 000 000'] },
                { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
                { name: 'packageUnlimited', value: ['unlimited'] },
                { name: 'license', value: ['full'] },
              ]" symbol="$" :isMonthly="isMonthly">
                <Button
                  class="block w-full"
                  :loading="loading"
                  @click.native="buyPackage(subscriptions.Premium[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packagePremium')}}</Button>
              </Package>
              </div>
          </div>
        </div>
        <router-link to="/" class="mx-auto text-sm -xs block text-center text-hm-blue cursor-pointer mt-15">
          {{$t('ui.goBack')}}
        </router-link>
        <div class="text-center mb-10">
          <!-- <Button
            class="mx-auto"
            :loading="loading"
            @click.native="showPackageComparision = true">{{$t('ui.packagesCompare')}}</Button> -->
        </div>
        <Modal :show.sync="showPackageComparision" width="6xl">
          <div slot="header"></div>
          <PackageComparision></PackageComparision>
          <div class="text-center mt-12 mb-5">
            <Button @click.native="showPackageComparision = false">{{$t('ui.ok')}}</Button>
          </div>
        </Modal>
      </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      isMonthly: 'month',
      showPackageComparision: false,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
      addWebsiteModel: (state) => state.website.addWebsiteModel,
      pluginLink: (state) => state.utils.pluginUrl,
      prices: (state) => state.billing.prices,
      subscriptions: (state) => state.billing.subscriptions,
      selectedWebsite: (state) => state.website.selectedWebsite,
    }),
  },
  created() {
    this.setSidebarVisibility(false);
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  async mounted() {
    if (!this.subscriptions && !this.subscriptions.Premium) await this.fetchPrices();
    console.log('subs => ', this.subscriptions);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchPrices: 'billing/fetchPrices',
      getPaymentLink: 'billing/getPaymentLink',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    async fetchPaymentLink() {
      const data = {
        customer: this.customerId,
        domain: 'localhost',
        productId: '',
        priceId: '',
      };

      const result = await this.getPaymentLink(data);
      console.log('payment link result', result);
    },
    async buyPackage(subPackage) {
      this.loading = true;
      console.log('buyPackage', subPackage);
      const { product, id } = subPackage;
      const domain = `https://${window.location.host}/articles/${this.selectedWebsite.id}/list`;
      const data = {
        customer: this.customerId,
        domain,
        productId: product.id,
        priceId: id,
      };

      // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
      // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

      const result = await this.getPaymentLink(data);
      this.loading = false;
      if (!result.url) return;
      window.location.replace(result.url);
      console.log('payment link result', data, result);
    },
    goToDashboard() {
      this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list` });
    },
  },
};
</script>
