/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Admin',
  customerList: [],
  couponList: [],
  userList: [],
  userListById: {},
  regionList: [],
  hasNextPage: true,
  pageInfo: {},
  totalCount: 0,

  selectedCustomer: {},
  customerWebsiteList: [],
  loadings: {},
};

const mutations = {
  setCustomerList: (state, payload) => {
    Vue.set(state, 'customerList', payload);
    // console.log('payload', state.customerList, payload);
  },
  setCouponList: (state, payload) => {
    Vue.set(state, 'couponList', payload);
  },
  setUserList: (state, payload) => {
    Vue.set(state, 'userList', payload);
    payload.forEach((user) => Vue.set(state.userListById, user.id, user));
  },
  setRegionList: (state, payload) => {
    Vue.set(state, 'regionList', payload);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'totalCount', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
  clearPagin: (state) => {
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
  },
  setSelectedCustomer: (state, payload) => {
    Vue.set(state, 'selectedCustomer', payload);
  },
  setCustomerWebsites: (state, payload) => {
    Vue.set(state, 'customerWebsiteList', payload);
  },
  dummy: (state, payload) => {
    Vue.set(state, 'customerWebsiteList', payload);
  },
};

const actions = {
  async generateCoupon({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GenerateCoupon, {
          input: {
            creditAmount: payload.amount,
            expirationDate: payload.expiryDate,
          },
        }),
      );
      const { generateCoupon } = response.data;
      return generateCoupon;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async fetchCoupons({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllCoupons, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allCoupons;
      commit('setCouponList', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchUsers({ commit }) {
    // console.log('fetch loadMore');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllUsers),
      );
      const { nodes } = response.data.allUsers;
      commit('setUserList', nodes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomers({ commit }, { payload }) {
    // console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllCustomers, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allCustomers;
      commit('setCustomerList', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomerWebsites({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllFeeds, { ...payload }),
      );
      const { nodes } = response.data.allFeeds;
      commit('setCustomerWebsites', nodes);
      // console.log('fetchCustomerWebsites', payload, response);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomer({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const { customerById } = response.data;
      commit('setSelectedCustomer', customerById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
