var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out",class:{
  'px-6 py-3 text-sm': _vm.size === '',
  'px-4 py-1 text-xs': _vm.size === 'sm',
  'px-4 py-2 text-xs': _vm.size === 'sm2',
  'px-6 py-3 text-base': _vm.size === 'md',
  'text-white bg-black hover:bg-hm-brand hover:text-black': _vm.type === '',
  'text-white bg-green-500 hover:bg-green-700': _vm.type === 'secondary',
  'text-black bg-hm-brand hover:bg-yellow-300': _vm.type === 'brand',
  'text-white bg-red-500 hover:bg-red-800': _vm.type === 'danger',
  'text-black border-black hover:bg-white border-2': _vm.type === 'default',
  'text-black border-black bg-white hover:bg-gray-200': _vm.type === 'white',
  'loading': _vm.loading,
  'pointer-events-none opacity-50': _vm.disabled,
  },attrs:{"type":_vm.nativeType}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }