<template>
  <transition name="modal-fade">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="fixed z-[999] inset-0 overflow-y-auto" v-show="show">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <!-- <transition
          name="custom-classes-transition"
          enter-class="opacity-0"
          enter-active-class="ease-out duration-300"
          enter-to-class="opacity-100"
          leave-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-to-class="opacity-0"
                    > -->
          <div
            v-show="show"
            @click="close"
            class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-white opacity-75"></div>
          </div>
        <!-- </transition> -->

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <!-- <transition
          name="custom-classes-transition"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-active-class="ease-out duration-300"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    > -->
          <div
            v-show="show"
            class="inline-block align-bottom bg-white rounded-xl px-4 pt-5 pb-4 text-left overflow-hidden border-2 border-black transform transition-all sm:my-8 sm:align-middle  w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline"
            :class="{'sm:max-w-lg': !width, 'sm:max-w-2xl': width === '2xl', 'sm:max-w-3xl': width === '3xl', 'sm:max-w-4xl': width === '4xl', 'sm:max-w-6xl': width === '6xl'}">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button type="button"
                  @click="close"
                  class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                <span class="sr-only">Close</span>
                <!-- Heroicon name: outline/x -->
                <svg xmlns="http://www.w3.org/2000/svg" width="32.126" height="32.138" viewBox="0 0 32.126 32.138"
                  class="hover:text-hm-blue cursor-pointer h-6 w-6">
                  <g id="Group_5436" data-name="Group 5436" transform="translate(-1623.816 -238.04)">
                    <path id="Path_266" data-name="Path 266" d="M19.563,3.5a27.326,27.326,0,0,1,7.306.821,11.292,11.292,0,0,1,5.106,2.83,11.292,11.292,0,0,1,2.83,5.106,27.326,27.326,0,0,1,.821,7.306,27.345,27.345,0,0,1-.821,7.308,11.309,11.309,0,0,1-2.829,5.11,11.294,11.294,0,0,1-5.106,2.834,27.272,27.272,0,0,1-7.307.823,27.272,27.272,0,0,1-7.307-.823A11.294,11.294,0,0,1,7.15,31.981a11.309,11.309,0,0,1-2.829-5.11A27.345,27.345,0,0,1,3.5,19.563a27.329,27.329,0,0,1,.821-7.306A11.292,11.292,0,0,1,7.15,7.151a11.292,11.292,0,0,1,5.106-2.83A27.328,27.328,0,0,1,19.563,3.5Z" transform="translate(1620.316 234.54)" fill="currentColor"/>
                    <g id="Group_5388" data-name="Group 5388" transform="translate(1640 242.687) rotate(45)">
                      <path id="Path_43306" data-name="Path 43306" d="M14.8,6.8H10A.8.8,0,0,1,9.2,6V1.2a1.2,1.2,0,1,0-2.4,0V6a.8.8,0,0,1-.8.8H1.2a1.2,1.2,0,1,0,0,2.4H6a.8.8,0,0,1,.8.8v4.8a1.2,1.2,0,1,0,2.4,0V10a.8.8,0,0,1,.8-.8h4.8a1.2,1.2,0,0,0,0-2.4Z" fill="#fff"/>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
            <div class="">
              <!-- TYPE -->
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 w-full" v-if="type === 'warning'">
                <!-- Heroicon name: outline/exclamation -->
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:text-left"
                :class="{'sm:ml-4': type }">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                  <slot name="header">
                    This is the default tile!
                  </slot>
                </h3>
                <div class="mt-2">
                  <slot>
                    I'm the default body!
                  </slot>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <slot name="footer">
              </slot>
            </div>
          </div>
        <!-- </transition> -->
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'Modal',
  props: {
    type: String,
    show: Boolean,
    width: String,
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>
<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
</style>
