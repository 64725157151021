<template>
  <button class="flex-shrink-0 play-pause rounded-md flex justify-center align-middle relative items-center -ml-0.5"
  :class="{
    'h-9 w-9': size === '',
    'h-5 w-5': size === 'sm',
    'h-6 w-6': size === 'md',
    'px-6 py-3 text-base': size === 'md',
    'text-white bg-black hover:bg-hm-brand hover:text-black': !isPlaying,
    'bg-hm-brand text-black': isPlaying,
    'loading': loading,
    'pointer-events-none opacity-50': disabled,
    }">
    <svg xmlns="http://www.w3.org/2000/svg"
      :class="{
      'h-2 w-2': size === 'sm',
      'h-3 w-3': size === 'md',
      }"
      width="12" height="15.999" viewBox="0 0 12 15.999" v-if="!isPlaying">
      <g id="triangle-right" transform="translate(0 0)">
        <path id="Path_43134" data-name="Path 43134" d="M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z" transform="translate(-2 0)" fill="currentColor"/>
      </g>
    </svg>
    <svg
    :class="{
      'h-2 w-2': size === 'sm',
      'h-3 w-3': size === 'md',
      }"
      id="button-pause" v-if="isPlaying" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <rect id="Rectangle_2499" data-name="Rectangle 2499" width="4" height="12" rx="0.5" transform="translate(8)" fill="#212121"/>
      <rect id="Rectangle_2500" data-name="Rectangle 2500" width="4" height="12" rx="0.5" fill="currentColor"/>
    </svg>
  </button>
</template>
<script>

export default {
  name: 'PlayButton',
  props: {
    nativeType: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      default: '',
      type: String,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
