/* eslint-disable no-shadow */
import Vue from 'vue';

const state = {
  name: 'UI',

  loading: false,
  loadings: {
    channels: false,
    feeds: false,
    channel: false,
    saving: false,
  },

  sidebar: null,

  isSidebarVisible: true,
  isInUseAsMode: false,

  urlRegex: new RegExp('^(https?:\\/\\/)?'
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
    + '((\\d{1,3}\\.){3}\\d{1,3}))'
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
    + '(\\?[;&a-z\\d%_.~+=-]*)?'
    + '(\\#[-a-z\\d_]*)?$', 'i'),
};

const getters = {
  // eslint-disable-next-line
  uuidv4: () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  },
};

const mutations = {
  setSidebar: (state, payload) => {
    Vue.set(state, 'sidebar', payload);
  },
  setUseAsMode: (state, payload) => {
    Vue.set(state, 'isInUseAsMode', payload);
    localStorage.setItem('useAs', JSON.stringify(payload));
  },
  setSidebarVisibility: (state, payload) => {
    Vue.set(state, 'isSidebarVisible', payload);
  },
  togglePlayer: (state, payload) => {
    Vue.set(state, 'showPlayer', payload);
  },

  setPlayerData: (state, payload) => {
    Vue.set(state, 'playerData', payload);
  },

  setLoading: (state, payload) => {
    Vue.set(state, 'loading', payload);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },

  setRoutes: (state, payload) => {
    const matched = [
      ...payload.matched,
    ];
    const currentRoute = matched.pop();
    state.currentRoute.name = currentRoute.name;
    // Vue.set(state, '')
  },
  setRouteName: (state, payload) => {
    state.currentRoute.name = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
