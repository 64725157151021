/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../../graphql';
import SelectionUtils from './selection';
import $ from './dom';
// import * as _ from './utils';

import './alias-inline.css';

require('./alias-inline.css');
/**
 * Link Tool
 *
 * Inline Toolbar Tool
 *
 * Wrap selected text with <a> tag
 */
export default class AliasInlineTool {
  /**
   * @param {API} api - Editor.js API
   */
  constructor({ api, config }) {
    /**
     * Native Document's commands for link/unlink
     */
    this.commandLink = 'createLink';
    this.commandUnlink = 'unlink';
    this.config = config;
    this.sayAsOption = {
      id: 'custom-text',
      label: 'Custom Text',
      type: 'alias',
      description: 'Use custom text to get the pronunciation right.',
    };
    /**
     * Enter key code
     */
    this.ENTER_KEY = 13;
    /**
     * Styles
     */
    this.CSS = {
      button: 'ce-inline-tool',
      buttonActive: 'ce-inline-tool--active',
      buttonModifier: 'ce-inline-tool--alias',
      buttonUnlink: 'ce-inline-tool--unalias',
      label: 'ce-inline-tool-label',
      title: 'ce-inline-tool-title',
      desc: 'ce-inline-tool-desc',
      input: 'ce-inline-tool-input',
      select: 'ce-inline-tool-select',
      inputWrapper: 'ce-inline-tool-input-wrapper',
      inputShowed: 'ce-inline-tool-input--showed',
      wrapper: 'ce-inline-tool-sayas-wrapper',
    };

    /**
     * Say as options 
     */
    this.sayAsOptions = [
      {
        id: 'custom-text',
        label: 'Custom Text',
        type: 'alias',
        description: 'Use custom text to get the pronunciation right.',
      },
      // {
      //   id: 'digits',
      //   label: 'Digits',
      //   type: 'say-as',
      //   description: 'Spells out each digit individually, as in 1-2-3-4.',
      // },
      // {
      //   id: 'currency',
      //   label: 'Currency',
      //   type: 'say-as',
      //   description: 'lorem ipsum sid tolor',
      // },
      {
        id: 'date',
        label: 'Date',
        type: 'say-as',
        description: 'Interprets the text as a date.',
      },
      {
        id: 'time',
        label: 'Time',
        type: 'say-as',
        description: 'Interprets the text as a time.',
      },
      {
        id: 'characters',
        label: 'Characters',
        type: 'say-as',
        description: 'Spells out each letter of the text, as in a-b-c.',
      },
      {
        id: 'telephone',
        label: 'Telephone',
        type: 'say-as',
        description: 'Interprets the numerical text as a 7-digit or 10-digit telephone number.',
      },
      {
        id: 'cardinal',
        label: 'Cardinal',
        type: 'say-as',
        description: 'Interprets the numerical text as a cardinal number, as in 1,234.',
      },
      {
        id: 'ordinal',
        label: 'Ordinal',
        type: 'say-as',
        description: 'Interprets the numerical text as an ordinal number, as in 1,234th.',
      },
      {
        id: 'fraction',
        label: 'Fraction',
        type: 'say-as',
        description: 'Interprets the numerical text as a fraction, such as 3/20, or 2½.',
      },
      // {
      //   id: 'bleep',
      //   label: 'Bleep',
      //   type: 'say-as',
      //   description: 'lorem ipsum sid tolor',
      // },
    ];

    /**
     * Elements
     */
    this.nodes = {
      button: null,
      input: null,
      wrapper: null,
      enter: null,
      title: null,
      desc: null,
      selectLabel: null,
      select: null,
      selectDesc: null,
    };
    this.safariBulshitFlag = false;
    /**
     * Input opening state
     */
    this.listeners = api.listeners;
    this.inputOpened = false;
    this.toolbar = api.toolbar;
    this.inlineToolbar = api.inlineToolbar;
    this.notifier = api.notifier;
    this.i18n = api.i18n;
    this.selection = new SelectionUtils();

    this.tag = 'ALIAS';
    this.api = api;
  }

  /**
   * Sanitizer Rule
   * Leave <a> tags
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      style: false,
      alias: {
        alias: true,
        'interpret-as': 'custom-text',
        interpretAs: true,
      },
      a: {
        href: true,
        'interpret-as': 'custom-text',
        interpretAs: true,
        title: false,
        dataset: false,
        'data-alias': false,
        target: '_blank',
        rel: 'nofollow',
      },
    };
  }

  /**
   * Create button for Inline Toolbar
   */
  render() {
    const text = document.createElement('span');
    text.innerText = this.i18n.t('Say as');
    this.nodes.button = document.createElement('button');
    this.nodes.button.type = 'button';
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.appendChild(text);
    this.nodes.button.appendChild($.svg('cross', 11, 11));
    return this.nodes.button;
  }

  /**
   * Input for the link
   */
  renderActions() {
    this.nodes.wrapper = document.createElement('div');

    // title and desc
    this.nodes.title = document.createElement('div');
    this.nodes.title.classList = this.CSS.title;
    this.nodes.desc = document.createElement('div');
    this.nodes.desc.classList = this.CSS.desc;
    this.nodes.title.innerText = this.i18n.t('Pronunciation');
    this.nodes.desc.innerText = this.i18n.t('Change how the text is pronounced');
    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.desc);
    document.querySelector('body').addEventListener('click', (click) => {
      // console.log('click docuemnt event', click);
    })
    document.querySelector('body').addEventListener('mousedown', (click) => {
      const { target } = click;

      // console.log('click docuemnt event mousedown', target, click);
      if (target.id === 'hm-preview-btn' || target.id === 'hm-enter-btn') {
        console.log('%cclick docuemnt event target previe', 'color:orange', target, click);
        click.preventDefault();
      }
    });
    // end title and desc

    // custom text wrapper
    this.nodes.inputWrapper = document.createElement('div');
    this.nodes.inputWrapper.classList = this.CSS.inputWrapper;

    // audio
    this.nodes.audioPlayer = document.createElement('audio');
    this.nodes.audioPlayer.classList = 'hidden'
    // select
    this.nodes.selectLabel = document.createElement('label');
    this.nodes.selectLabel.classList = this.CSS.label;
    this.nodes.selectLabel.innerText = this.i18n.t('Say as');
    this.nodes.select = document.createElement('select');
    this.nodes.select.classList = this.CSS.select;
    this.sayAsOptions.forEach(item => {
      this.nodes.select.innerHTML += `<option id="${item.id}" value="${item.id}">${item.label}</option>`;
    });
    this.nodes.selectDesc = document.createElement('div');
    if (this.sayAsOption.desc) this.nodes.selectDesc.innerText = this.i18n.t(this.sayAsOption.desc);
    this.nodes.selectDesc.classList = this.CSS.desc;

    this.nodes.select.addEventListener('change', (event) => {
      const selectedAsSayOption = this.sayAsOptions.find((el) => el.id === this.nodes.select.value);
      this.nodes.selectDesc.innerText = selectedAsSayOption.description;
      this.sayAsOption = selectedAsSayOption;
      if (this.sayAsOption.id === 'custom-text') this.nodes.input.classList.remove('hidden');
      if (this.sayAsOption.id !== 'custom-text') this.nodes.input.classList.add('hidden');
      console.log('select changed value', this.nodes.select.value, selectedAsSayOption);
    });

    this.nodes.wrapper.appendChild(this.nodes.audioPlayer);
    this.nodes.wrapper.appendChild(this.nodes.selectLabel);
    this.nodes.wrapper.appendChild(this.nodes.select);
    this.nodes.wrapper.appendChild(this.nodes.selectDesc);
    // end select

  
    // this.nodes.btnWrapper = document.createElement('div');
    // this.nodes.btnWrapper.classList = 'flex justify-between items-center';

    this.nodes.enterBtn = document.createElement('button');
    this.nodes.previewBtn = document.createElement('button');
    this.nodes.previewBtn.setAttribute('tabindex', 1)
    this.nodes.wrapper.classList = `relative ${this.CSS.wrapper}`;
    this.nodes.enterBtn.classList = 'text-white font-bold border-2 border-white px-4 py-1 rounded-md cursor-pointer hover:text-hm-brand hober:border-hm-brand mt-5 relative float-right top-[-4px]';
    this.nodes.previewBtn.classList = 'text-white font-bold border-2 border-white px-4 py-1 rounded-md cursor-pointer hover:text-hm-brand hober:border-hm-brand mt-4 mr-[61px]';
    const templatePreview = document.createElement('template');
    templatePreview.innerHTML = `<span class="flex space-x-2 items-center pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="18" width="18"><path fill="currentColor" fill-rule="evenodd" d="M8.41939 2.54077C8.38683 1.74465 7.98921 1.07181 7.36858 0.794796C6.71042 0.501023 5.9697 0.724485 5.46269 1.39875C5.28018 1.64146 5.1043 1.89634 4.93831 2.15681C4.61246 2.66812 4.07027 2.99882 3.48135 3.03695C3.15299 3.05821 2.87106 3.08764 2.62575 3.12255C1.44631 3.29042 0.598632 4.25862 0.404588 5.37064C0.316852 5.87343 0.25 6.44245 0.25 6.99999C0.25 7.55753 0.316852 8.12655 0.404588 8.62934C0.598632 9.74136 1.44631 10.7096 2.62575 10.8774C2.87106 10.9123 3.15299 10.9418 3.48135 10.963L3.51366 10.4641L3.48135 10.963C4.07027 11.0012 4.61246 11.3319 4.93831 11.8432C5.1043 12.1036 5.28018 12.3585 5.46269 12.6012C5.9697 13.2755 6.71042 13.499 7.36859 13.2052C7.98921 12.9282 8.38683 12.2553 8.41939 11.4592C8.46414 10.3651 8.5 8.89669 8.5 6.99999C8.5 5.10329 8.46414 3.63488 8.41939 2.54077ZM12.2094 2.77942C11.8995 2.5046 11.4255 2.53306 11.1506 2.84298C10.8758 3.1529 10.9043 3.62692 11.2142 3.90173C11.9512 4.55526 12.5009 5.59439 12.5009 6.99991C12.5009 8.40543 11.9512 9.44456 11.2142 10.0981C10.9043 10.3729 10.8758 10.8469 11.1506 11.1568C11.4255 11.4668 11.8995 11.4952 12.2094 11.2204C13.2756 10.2749 14.0009 8.82347 14.0009 6.99991C14.0009 5.17635 13.2756 3.72488 12.2094 2.77942ZM10.754 5.42748C10.4474 5.14899 9.97306 5.17179 9.69457 5.47841C9.41608 5.78504 9.43888 6.25936 9.7455 6.53785C9.83225 6.61664 9.92102 6.75913 9.92102 7.00005C9.92102 7.24096 9.83225 7.38346 9.7455 7.46224C9.43888 7.74073 9.41608 8.21506 9.69457 8.52168C9.97306 8.8283 10.4474 8.8511 10.754 8.57261C11.162 8.20204 11.421 7.65255 11.421 7.00005C11.421 6.34754 11.162 5.79806 10.754 5.42748Z" clip-rule="evenodd"></path></svg> <span>${this.i18n.t('Preview')}</span></span>`;
    this.nodes.previewBtn.appendChild(templatePreview.content.firstChild);
    this.nodes.previewBtn.setAttribute('id', 'hm-preview-btn');
    this.nodes.enterBtn.setAttribute('id', 'hm-enter-btn');

    this.nodes.input = document.createElement('input');
    this.nodes.input.placeholder = this.i18n.t('Enter alias');
    this.nodes.input.classList.add(this.CSS.input);
    this.nodes.input.addEventListener('keydown', (event) => {
      if (event.keyCode === this.ENTER_KEY) {
        this.enterPressed(event);
      }
    });
    this.nodes.input.addEventListener('focus', (event) => {
      this.nodes.wrapper.focus();
      // console.log('click docuemnt event focus', event);
    });
    this.nodes.input.addEventListener('keydown', (event) => {
      if (event.keyCode === this.ENTER_KEY) {
        this.enterPressed(event);
      }
    });
    this.nodes.input.addEventListener('blur', (event) => {
      // console.log('click docuemnt event blur', event);
      this.safariBulshitFlag = true;
      this.nodes.wrapper.focus();
    });
    this.nodes.input.addEventListener('focusout', (event) => {
      // console.log('click docuemnt event onfocusout', event);
      this.safariBulshitFlag = true;
      this.nodes.wrapper.focus();
    });
    
    this.listeners.on(this.nodes.enterBtn, 'click', async (event) => {
      await this.enterPressed(event);
    }, false);
    this.listeners.on(this.nodes.previewBtn, 'click', async (event) => {
      await this.playPreview(event);
    }, false);
    const template = document.createElement('template');
    const enterSign = `<span class="flex items-center space-x-2 pointer-events-none"><span>${this.i18n.t('Apply')}</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z" fill="currentColor"></path></g></svg></span>`;
    template.innerHTML = enterSign;
    this.nodes.enterBtn.appendChild(template.content.firstChild);
    
    this.nodes.wrapper.appendChild(this.nodes.input);
    // this.nodes.wrapper.appendChild(this.nodes.inputWrapper);
    this.nodes.wrapper.appendChild(this.nodes.previewBtn);
    this.nodes.wrapper.appendChild(this.nodes.enterBtn);
    // this.nodes.wrapper.appendChild(this.nodes.btnWrapper);
    return this.nodes.wrapper;
  }

  /**
   * Handle clicks on the Inline Toolbar icon
   *
   * @param {Range} range - range to wrap with link
   */
  surround(range) {
    // console.log('surround');
    if (!range) {
      return;
    }
    this.range = range;
    let termWrapper = this.api.selection.findParentTag(this.tag, AliasInlineTool.CSS);
    this.selection.save();
    /**
     * Range will be null when user makes second click on the 'link icon' to close opened input
     */
    // if (range) {
    //   /**
    //    * Save selection before change focus to the input
    //    */
    //   if (!this.inputOpened) {
    //     /** Create blue background instead of selection */
    //     this.selection.setFakeBackground();
    //     this.selection.save();
    //   } else {
    //     this.selection.restore();
    //     this.selection.removeFakeBackground();
    //   }
    //   const parentAnchor = this.selection.findParentTag('A');
    //   /**
    //    * Unlink icon pressed
    //    */
    if (termWrapper) {
      this.removeLocalAlias(termWrapper);
      // this.unlink();
      this.unwrap(termWrapper);
      this.closeActions();
      this.checkState();
      // this.toolbar.close();
      return;
    }
    // }
    // this.toggleActions();
    this.openActions();
  }

  async removeLocalAlias(parentAnchor) {
    const language = document.querySelector('#article-edit').dataset.language;
    const link = parentAnchor.getAttribute('alias');
    const payload = {
      originalText: parentAnchor.text,
      alias: link,
      language: language,
      feedItemId: this.config.feedItemId,
      textType: this.config.textType,
    };
    console.log('add local alias start', this.config, payload, parentAnchor, link);
    const response = await API.graphql(
      graphqlOperation(
        GQL.RemoveLocalAlias,
        { input: payload },
      ),
    );
  }

  /**
   * Check selection and set activated state to button if there are <a> tag
   *
   * @param {Selection} selection - selection to check
   */
  checkState(selection) {
    const anchorTag = this.selection.findParentTag(this.tag);
    if (anchorTag && anchorTag.getAttribute('interpret-as')) {
      this.selection.expandToTag(anchorTag);
      this.nodes.button.classList.add(this.CSS.buttonUnlink);
      this.nodes.button.classList.add(this.CSS.buttonActive);
      this.openActions();
      /**
       * Fill input value with link href
       */
      const aliasValue = anchorTag.getAttribute('alias');
      const data = anchorTag.getAttribute('interpret-as');
      // this.nodes.input.value = data !== 'null' ? data : '';
      // console.log('================== option', this.sayAsOption);
      this.nodes.input.value = aliasValue !== 'null' ? aliasValue : '';
      
      this.sayAsOption = this.sayAsOptions.find((el) => el.id === data);
      
      this.selection.save();
      this.range = this.selection.savedSelectionRange;
      if (!this.sayAsOption) {
        this.sayAsOption = {
          id: 'custom-text',
          label: 'Custom Text',
          type: 'alias',
          description: 'Use custom text to get the pronunciation right.',
        };
      }
      if (this.sayAsOption.id === 'custom-text') this.nodes.inputWrapper.classList.remove('hidden');
      if (this.sayAsOption.id !== 'custom-text') {
        this.nodes.inputWrapper.classList.add('hidden');
      }
      this.nodes.select.value = this.sayAsOption.id;
    } else {
      this.nodes.button.classList.remove(this.CSS.buttonUnlink);
      this.nodes.button.classList.remove(this.CSS.buttonActive);
    }
    return !!anchorTag;
  }

  /**
   * Function called with Inline Toolbar closing
   */
  clear() {
    console.log('clear', this.safariBulshitFlag, this.toolbar);
    // this.toolbar.open();
    if (this.safariBulshitFlag) {
      this.toolbar.open();
      this.safariBulshitFlag = false;
      return;
    }
    this.closeActions();
  }
  /**
   * Set a shortcut
   */

  get shortcut() {
    return 'CMD+K';
  }

  /**
   * Show/close link input
   */
  toggleActions() {
    if (!this.inputOpened) {
      this.openActions(true);
      document.querySelector('body').classList.add('disable-break');
    } else {
      this.closeActions(false);
      document.querySelector('body').classList.remove('disable-break');
    }
  }

  /**
   * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
   */
  openActions(needFocus = false) {
    console.log('opn say as action');
    document.querySelector('body').classList.add('disable-break');
    this.nodes.wrapper.classList.add(this.CSS.inputShowed);
    if (needFocus) {
      this.nodes.wrapper.focus();
    }
    this.inputOpened = true;
    if (document.querySelector('.ce-inline-tool-prosody-wrapper')) document.querySelector('.ce-inline-tool-prosody-wrapper').classList.remove(this.CSS.inputShowed);
    if (document.querySelector('.ce-inline-tool-lang-wrapper')) document.querySelector('.ce-inline-tool-lang-wrapper').classList.remove(this.CSS.inputShowed);
  }

  /**
   * Close input
   *
   * @param {boolean} clearSavedSelection — we don't need to clear saved selection
   *                                        on toggle-clicks on the icon of opened Toolbar
   */
  closeActions(clearSavedSelection = true) {
    // console.log('close', clearSavedSelection);
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();
      currentSelection.save();
      this.selection.restore();
      this.selection.removeFakeBackground();
      // and recover new selection after removing fake background
      currentSelection.restore();
    }
    this.nodes.wrapper.classList.remove(this.CSS.inputShowed);
    this.nodes.input.value = '';
    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    document.querySelector('body').classList.remove('disable-break');
    this.inputOpened = false;
  }

  /**
   * Enter pressed on input
   *
   * @param {KeyboardEvent} event - enter keydown event
   */
  enterPressed(event) {
    // console.log('enterPressed', event, this.nodes);
    let option = this.nodes.select.value || 'custom-text';
    let value = this.nodes.input.value || '';

    if (option !== 'custom-text') value = option;

    console.log('enterPressed', option, value);
    // this.range = range;
    // let termWrapper = this.api.selection.findParentTag(this.tag, AliasInlineTool.CSS);
    // if (value === '') {
    //   this.selection.restore();
    //   const parentAnchor = this.selection.findParentTag('A');
    //   if (parentAnchor) {
    //     // found closest 'A' tag that wraps current selection
    //     this.selection.expandToTag(parentAnchor);
    //   }
    //   this.removeLocalAlias(parentAnchor);
    //   this.unlink();
    //   return;
    // }
    // if (option === 'custom-text') {
    //   this.selection.restore();
    //   this.unlink();
    //   event.preventDefault();
    //   this.closeActions();
    //   return;
    // }
    // if (!this.validateURL(value)) {
    //   this.notifier.show({
    //     message: 'Pasted link is not valid.',
    //     style: 'error',
    //   });
    //   _.log('Incorrect Link pasted', 'warn', value);
    //   return;
    // }
    value = this.prepareLink(value);
    this.selection.restore();
    this.selection.removeFakeBackground();
    // this.insertLink(value, option);
    this.wrap(value);
    /**
     * Preventing events that will be able to happen
     */
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.selection.collapseToEnd();
    this.inlineToolbar.close();
  }

  async playPreview(event) {
    let option = this.nodes.select.value || 'custom-text';
    let value = this.nodes.input.value || '';
    let tagName = 'sub';
    let tagProperty = 'alias';

    if (option !== 'custom-text') {
      tagName = 'say-as';
      tagProperty = 'interpret-as'
      value = option;
    }

    this.selection.restore();
    const parentAnchor = this.selection.findParentTag(this.tag);
    if (parentAnchor) {
      // found closest 'A' tag that wraps current selection
      this.selection.expandToTag(parentAnchor);
    }
    const text = window.getSelection().toString();
    const previewTag = document.createElement(tagName);
    previewTag.setAttribute(tagProperty, value);
    previewTag.innerHTML = text;
  

    // const previewText = parentAnchor.innerText;
    // const parent = previewTag.
    if (!value) return;
    const range = this.selection.savedSelectionRange;
    const parent = range.commonAncestorContainer.parentElement
    let isAlternative = false;
    if (parent) {
      isAlternative = !!parent.closest('[data-alternative="true"]');
      console.log('ALETERNATIVE', text, isAlternative, parent.closest('[data-alternative="true"]'));
    }
    console.log('playPreview', text, isAlternative, previewTag, previewTag.outerHTML, option, value);
    const affix = isAlternative ? ' Alternative' : '';
    const payload = {
      feedId: this.config.feedId,
      text: previewTag.outerHTML,
      textType: this.config.textType || 'Content',
    };

    payload.textType += affix;
    console.log('play preview', payload);
    const response = await API.graphql(
      graphqlOperation(
        GQL.GenerateTextPreview,
        { input: payload },
      ),
    );
    const { generateTextPreview } = response.data;
    if (!generateTextPreview.ok) return;
    const { url } = generateTextPreview;

    this.nodes.audioPlayer.setAttribute('src', url);
    this.nodes.audioPlayer.load();
    this.nodes.audioPlayer.play();
    console.log('play preview response', this.nodes.audioPlayer, url);
  }

  /**
   * Detects if passed string is URL
   *
   * @param {string} str - string to validatewwe
   * @returns {boolean}
   */
  validateURL(str) {
    /**
     * Don't allow spaces
     */
    return true;
    // return !/\s/.test(str);
  }

  /**
   * Process link before injection
   * - sanitize
   * - add protocol for links like 'google.com'
   *
   * @param {string} link - raw user input
   */
  prepareLink(link) {
    link = link.trim();
    if (link === '') return ' '; // return space for empty string;
    // link = this.addProtocol(link);
    return link;
  }

  /**
   * Inserts <a> tag with "href"
   *
   * @param {string} link - "href" value
   */
  async insertLink(link, option) {
    /**
     * Edit all link, not selected part
     */
    const anchorTag = this.selection.findParentTag('A');
    // console.log('anchor tag', anchorTag, this.selection);
    if (anchorTag) {
      this.selection.expandToTag(anchorTag);
    }
    document.execCommand(this.commandLink, false, link);
    console.log('insertLink', this.commandLink, link);
    const aTag = this.selection.findParentTag('A');
    aTag.setAttribute('interpret-as', option);
    const language = document.querySelector('#article-edit').dataset.language;
    const payload = {
      originalText: aTag.text,
      alias: link,
      language: language,
      feedItemId: this.config.feedItemId,
      textType: this.config.textType,
    };
    console.log('add local alias start', this.config, payload, aTag, link);
    const response = await API.graphql(
      graphqlOperation(
        GQL.AddLocalAlias,
        { input: payload },
      ),
    );
    console.log('add local alias', response);
    // aTag.title = link;
    // if (option === 'custom-text') aTag.removeAttribute('alias');
  }

  /**
   * Removes <a> tag
   */
  unlink() {
    document.execCommand(this.commandUnlink, false, false);
  }

  wrap(link) {
    /**
     * Create a wrapper for highlighting
     */
    const anchorTag = this.selection.findParentTag(this.tag);

    let marker = anchorTag || document.createElement(this.tag);

    link = link.replaceAll('%', '');
    // link += '%';
    marker.setAttribute('alias', link);
    let option = this.nodes.select.value || 'custom-text';
    marker.setAttribute('interpret-as', option);

    marker.classList.add(AliasInlineTool.CSS);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    const range = this.range;
    
    if (!anchorTag) {
      console.log('wraped already', anchorTag);
      marker.appendChild(range.extractContents());
      range.insertNode(marker);
    }

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(marker);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    let sel = window.getSelection();
    this.range = sel.getRangeAt(0);

    let unwrappedContent = this.range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    this.range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(this.range);
  }

  static title = 'Link';
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns {boolean}
 */
AliasInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
// AliasInlineTool.title = 'Alias';
