<template>
  <div id="reset" class="h-screen overflow-y-auto bg-black">
    <div class="max-w-xl text-center mx-auto my-16 space-y-4" v-show="errorMessage">
      <div>
        <svg class="mx-auto" xmlns="http://www.w3.org/2000/svg" width="102.397" height="103.079" viewBox="0 0 102.397 103.079">
          <g id="Group_5694" data-name="Group 5694" transform="translate(-0.083)">
            <path id="Path_43835" data-name="Path 43835" d="M100.847,49.615A190,190,0,0,0,79.713,24.529,248.159,248.159,0,0,0,56,2.019,8.591,8.591,0,0,0,51.7,0a5.112,5.112,0,0,0-1.718.687c-1.074.644-2.706,1.8-3.952,2.749a1.418,1.418,0,0,0-.344,2.019A1.461,1.461,0,0,0,47.71,5.8l4.038-2.663c0,.387,1.332.644,2.234,1.332A246.786,246.786,0,0,1,77.049,27.02a246.961,246.961,0,0,1,18.3,21.865,16.71,16.71,0,0,1,2.835,4.768c-.215.3-.859,1.2-1.5,1.933C94.533,58.078,90.8,61.729,86.5,65.9,74.6,77.323,57.976,92.014,54.883,94.505a23.841,23.841,0,0,1-3.308,2.62c-.773.515-1.546,1.074-2.277.859a21.822,21.822,0,0,1-4.682-3.48c-5.069-4.3-12.458-12.114-19.631-19.846A229.005,229.005,0,0,1,4.838,50.689c-.516-.816-.6-1.8-.9-1.976A157.994,157.994,0,0,1,18.2,33.034c7.818-7.99,17.183-17.183,24.314-23.2a1.332,1.332,0,0,0,0-1.847,1.289,1.289,0,0,0-1.8,0c-7.131,5.885-16.8,14.82-24.829,22.681A122.04,122.04,0,0,0,.156,47.854a5.885,5.885,0,0,0,.988,4.3A171.827,171.827,0,0,0,16.909,72.383c7.947,9.236,17.183,18.944,23.369,24.743a28.609,28.609,0,0,0,7.56,5.713,6.358,6.358,0,0,0,4.3-.3,23.025,23.025,0,0,0,5.971-4.3C61.2,95.536,77.822,80.63,89.636,69.075a126.979,126.979,0,0,0,11.555-12.329,5.456,5.456,0,0,0,1.289-2.792,9.279,9.279,0,0,0-1.632-4.339Z" transform="translate(0 0)" fill="#fea900" fill-rule="evenodd"/>
            <path id="Path_43836" data-name="Path 43836" d="M10.595,23.256a88.705,88.705,0,0,0,3.136,13.4,6.787,6.787,0,0,0,6.873,4.3,7.6,7.6,0,0,0,4.038-2.1,20.018,20.018,0,0,0,4.3-9.923,76.333,76.333,0,0,0,.945-15.465,8.033,8.033,0,0,0-1.761-4.9A9.536,9.536,0,0,0,24.04,5.73a12.028,12.028,0,0,0-4.3-.687,9.665,9.665,0,0,0-7.947,4.3,12.114,12.114,0,0,0-1.89,4.3,33.721,33.721,0,0,0,.687,9.622Zm3.823-8.591A8.033,8.033,0,0,1,15.75,12.13a5.8,5.8,0,0,1,1.976-1.675A5.885,5.885,0,0,1,20,9.94a7.432,7.432,0,0,1,2.406.387,5.155,5.155,0,0,1,2.062,1.332,3.48,3.48,0,0,1,.73,2.062,71.007,71.007,0,0,1,0,14.391,16.581,16.581,0,0,1-2.835,8.334,4.3,4.3,0,0,1-2.148,1.332,3.179,3.179,0,0,1-3.694-2.148,79.77,79.77,0,0,1-1.976-12.887,30.629,30.629,0,0,1-.258-8.076Z" transform="translate(32.089 16.608)" fill="#fefefe" fill-rule="evenodd"/>
            <path id="Path_43837" data-name="Path 43837" d="M18.012,17.9a1.293,1.293,0,0,0-.515-2.534c-6.057.687-7.689,4.3-7.517,7a7.818,7.818,0,0,0,2.835,5.2,9.837,9.837,0,0,0,5.2,2.234,8.591,8.591,0,0,0,8.591-4.51,7.088,7.088,0,0,0-1.976-9.021,1.463,1.463,0,1,0-1.89,2.234A3.737,3.737,0,0,1,23,23.315a4.3,4.3,0,0,1-4.3,1.632,5.456,5.456,0,0,1-3.694-2.019c-.515-.6-1.031-1.246-.859-1.976s1.418-2.32,3.866-3.05Z" transform="translate(32.575 50.571)" fill="#fefefe" fill-rule="evenodd"/>
          </g>
        </svg>

      </div>
      <div>
        <div class="font-bold text-base text-white">
          {{$t('ui.loginFailed')}}
        </div>
        <div class="text-gray-500 text-sm">
          {{$t('ui.loginFailedInfo')}}
        </div>
      </div>
    </div>
    <div class="max-w-xl text-center mx-auto my-16 space-y-4" v-show="!errorMessage">
      <div>
        <svg class="mx-auto" width="94.559" height="99.317" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><path d="M20.06,12.45a.35.35,0,0,0-.26.41A6.88,6.88,0,0,1,19.37,17a8.59,8.59,0,0,1-2.55,3.34,11.7,11.7,0,0,1-6.41,2.53A8.79,8.79,0,0,1,4,21a7,7,0,0,1-2.44-5.64A10.19,10.19,0,0,1,3.71,9.26a7.93,7.93,0,0,1,5-3.11,4.11,4.11,0,0,1,3.56,1.32.29.29,0,0,0,.42,0,.3.3,0,0,0,0-.42A4.76,4.76,0,0,0,8.65,5.43,8.81,8.81,0,0,0,3,8.67,11.06,11.06,0,0,0,.46,15.29a8,8,0,0,0,2.81,6.56A9.86,9.86,0,0,0,10.5,24a12.61,12.61,0,0,0,6.89-2.9A9.39,9.39,0,0,0,20.1,17.3a7.59,7.59,0,0,0,.37-4.59A.34.34,0,0,0,20.06,12.45Z" fill="#fea900" fill-rule="evenodd"></path><path d="M23.2,0c-.24,0-.2.09-.86,1-1.05,1.5-3.41,4.63-5.63,7.67-1.6,2.17-3.13,4.3-4.17,5.67a12.59,12.59,0,0,1-1.15,1.42c-.07.06-.16,0-.26,0a2.76,2.76,0,0,1-.8-.43,18.37,18.37,0,0,1-2.84-2.93c-.35-.43-.45-.69-.68-.51s-.35.16-.2.38l.21.3a24.85,24.85,0,0,0,2.31,2.82,5.26,5.26,0,0,0,1.63,1.22A1.22,1.22,0,0,0,12,16.57a18.55,18.55,0,0,0,2.16-2.46c.82-1.06,1.81-2.38,2.81-3.78,2.35-3.29,4.81-7,5.94-8.79a6.77,6.77,0,0,0,.67-1.2A.35.35,0,0,0,23.2,0Z" fill="#fefefe" fill-rule="evenodd"></path></g></svg>
      </div>
      <div>
        <div class="font-bold text-base text-white">
          {{$t('ui.loginSuccessfull')}}
        </div>
        <div class="text-gray-500 text-sm">
          {{$t('ui.loginSuccessfullInfo')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';

export default {
  data() {
    return {
      loading: false,
      visibleStep: 1,
      username: null,
      isBtnLoading: false,
      error: null,
      email: null,
      code: null,
      password: null,
      confirmPassword: null,
      errorMessage: null,
      showLoginMessage: false,
      submitted: false,
      customer: null,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
    }),
  },
  async created() {
    const { query } = this.$route;
    // eslint-disable-next-line camelcase
    const { code, error_description } = query;
    console.log('oauth created', code);

    if (code) {
      const response = await this.getToken(code);
      console.log('res', response);
    }
    // eslint-disable-next-line camelcase
    if (error_description) {
      // eslint-disable-next-line
      console.log('error login,', error_description);
      this.errorMessage = true;
    }
  },

  methods: {
    ...mapActions({
      fetchUser: 'auth/fetchUser',
      fetchCustomer: 'customer/fetchCustomer',
      fetchAuthCustomer: 'auth/fetchCustomer',
      fetchWebsites: 'website/fetchWebsites',
      createDemoProject: 'website/createDemoProject',
    }),
    ...mapMutations({
      setSession: 'auth/setSession',
      clearCustomerState: 'customer/clearState',
    }),
    goToLogin() {
      this.$router.push({ path: 'login' });
    },
    getToken(code) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const hostedAuthTokenApiUrl = `${this.$appConfig.hostedAuthUrl}/token`;
        xhr.open('POST', hostedAuthTokenApiUrl, true);
        xhr.responseType = 'json';
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        const { clientId } = this.$appConfig;
        const grantType = 'authorization_code';
        const redirectUri = this.$appConfig.domain;
        const params = `grant_type=${grantType}&client_id=${clientId}&code=${code}&redirect_uri=${redirectUri}/oauth&scope=email%2Copenid`;
        xhr.onload = () => {
          console.log('xhr response', xhr);
          if (xhr.status !== 200) {
            console.log('xhr response !200', xhr);
            resolve({ ok: false });
          } else {
            console.log('xhr response 200', xhr);
            this.loginWithToken(xhr.response);
            resolve(xhr.response);
          }
        };
        xhr.onerror = () => {
          reject(new Error('something bad happened', xhr.statusText));
        };
        xhr.send(params);
      });
    },
    async loginWithToken(response) {
      const accessToken = response.access_token;
      const idToken = response.id_token;
      const refreshToken = response.refresh_token;
      const expiresIn = response.expires_in;

      const date = new Date();
      const expiresAt = expiresIn * 1000 + date.getTime();
      const { clientId, userPoolId } = this.$appConfig;
      const poolData = {
        UserPoolId: userPoolId,
        ClientId: clientId,
      };

      // pass the poolData object to CognitoUserPool
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(
        poolData,
      );
      // create a CognitoAccessToken using the response accessToken
      const AccessToken = new
      AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: accessToken,
      });
      // create a CognitoIdToken using the response idToken
      const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: idToken,
      });
      // create a RefreshToken using the response refreshToken
      const RefreshToken = new
      AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: refreshToken,
      });

      // create a session object with all the tokens
      const sessionData = {
        IdToken,
        AccessToken,
        RefreshToken,
      };

      // now we an create a Cognito session using
      const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

      // create an object containing the username and user pool.
      // You can get the username from the CognitoAccessToken
      const userData = {
        Username: AccessToken.payload.username,
        Pool: userPool,
      };

      console.log('loginWithToken', accessToken, expiresAt, this.$Amplify.Auth);
      if (!accessToken) {
        return;
      }

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      // set the cognito user session w/ the CognitoUserSession
      cognitoUser.setSignInUserSession(session);

      // get the Amplify authenticated user
      this.$Amplify.Auth.currentAuthenticatedUser()
        .then((user) => {
          this.initSession(user.signInUserSession);
        })
        .catch((err) => console.log(err));
    },
    async initSession(session) {
      this.setSession(session);
      const userResult = await this.fetchUser(this.userId);
      const savedWebsite = localStorage.getItem(`w_${this.userId}`);
      await this.fetchCustomer(userResult.customerId);
      const websites = await this.fetchWebsites();
      await this.fetchAuthCustomer(userResult.customerId);
      this.isBtnLoading = false;
      if (savedWebsite) {
        this.$router.push({ path: `/articles/${savedWebsite}/list` });
        return;
      }
      if (!websites[0]) {
        localStorage.setItem('firstTime', 'firstTime');
        this.createDemoContent();
        // this.$router.push({ path: '/add-project' });
        return;
      }
      this.$router.push({ path: `/articles/${websites[0].id}/list` });
    },
    async createDemoContent() {
      const resut = await this.createDemoProject();
      localStorage.setItem('demoContentFeedId', resut.data.feedItemId);
      localStorage.setItem('firstTime', 'firstTime');
      this.$router.push({ path: `/articles/${resut.data.feedId}/list` });
    },
  },
};
</script>
<style scoped>
.bg-hm-black {
  background-image: url('../assets/images/bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
