export default {
  subs: {
    Premium: {
      month: {
        id: 'price_1L5rMWK1ORtygzBgcY4sB48f',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1654089204,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          fakturowniaIdPL: '107488003',
          fakturowniaIdZeroTax: '107490595',
          interval: 'month',
        },
        nickname: null,
        product: {
          id: 'prod_LnSHEHYYK39AWH',
          object: 'product',
          active: true,
          attributes: [],
          created: 1654089204,
          default_price: null,
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX3Rlc3RfbGNSR0U0VElPMUh5cHdPbG9zZmpRZjJ300pzRVauNC',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '1000000',
            interval: 'month',
            plan: 'Premium',
            type: 'subscription',
          },
          name: 'Premium',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1658739150,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900',
      },
      year: {
        id: 'price_1LPMszK1ORtygzBgED3ZWv2b',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658738613,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          fakturowniaIdPL: '107317639',
          interval: 'year',
        },
        nickname: null,
        product: {
          id: 'prod_M7c7Wz3lvLpPYy',
          object: 'product',
          active: true,
          attributes: [],
          created: 1658738613,
          default_price: 'price_1LPMszK1ORtygzBgED3ZWv2b',
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX2xpdmVfYzc1WjYwYTBUdEg0dTJ3RVJHNnp5S3Jq00qdebYGto',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '1000000',
            interval: 'year',
            plan: 'Premium',
            type: 'subscription',
          },
          name: 'Premium Year',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1658738614,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 88800,
        unit_amount_decimal: '88800',
      },
    },
    Business: {
      month: {
        id: 'price_1Ld2ZKK1ORtygzBgW9acuM5P',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1661997106,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          interval: 'month',
        },
        nickname: null,
        product: {
          id: 'prod_LnSGU43bOSP18F',
          object: 'product',
          active: true,
          attributes: [],
          created: 1654089195,
          default_price: 'price_1L7cUVK1ORtygzBg83PHnNz5',
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX3Rlc3RfNTFUVVBPSFJLUHJ5bERwT2ZhbkpqcE5O00XQGZDBil',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '250000',
            interval: 'month',
            plan: 'Business',
            type: 'Subscription',
          },
          name: 'Business',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1661997107,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2900,
        unit_amount_decimal: '2900',
      },
      year: {
        id: 'price_1Ld2bMK1ORtygzBgPEEqdgFl',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1661997232,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          interval: 'year',
        },
        nickname: null,
        product: {
          id: 'prod_M7c9yObGAdMgIY',
          object: 'product',
          active: true,
          attributes: [],
          created: 1658738758,
          default_price: null,
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX2xpdmVfMEQwM2lzQ1NnWlh3ZFk4eWFKS0RLWkdZ00qQ42mjii',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '250000',
            interval: 'year',
            plan: 'Business',
            type: 'Subscription',
          },
          name: 'Business Year',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1661997299,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 24900,
        unit_amount_decimal: '24900',
      },
    },
    Basic: {
      month: {
        id: 'price_1L7cTKK1ORtygzBgJRpzMg9G',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1654508622,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          fakturowniaIdPL: '108025381',
          interval: 'month',
        },
        nickname: null,
        product: {
          id: 'prod_LnSGici4QkVJOp',
          object: 'product',
          active: true,
          attributes: [],
          created: 1654089187,
          default_price: 'price_1L7cTKK1ORtygzBgJRpzMg9G',
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX3Rlc3RfRjZUdkpXeXZNQ0tISFpJTnFzZ1dQZTRY00XchEIC6X',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '100000',
            interval: 'month',
            plan: 'Basic',
            type: 'Subscription',
          },
          name: 'Basic',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1661997376,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1900,
        unit_amount_decimal: '1900',
      },
      year: {
        id: 'price_1LPMyHK1ORtygzBgHCFBGjYO',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658738941,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          fakturowniaIdPL: '107331811',
          interval: 'year',
        },
        nickname: null,
        product: {
          id: 'prod_M7cCdhJGqfWgFZ',
          object: 'product',
          active: true,
          attributes: [],
          created: 1658738940,
          default_price: 'price_1LPMyHK1ORtygzBgHCFBGjYO',
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX2xpdmVfNkp0ZXBXUjNPZXJHYmtpM1Q5UGQ2ZERq00hHxd0MRL',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '100000',
            interval: 'year',
            plan: 'Basic',
            type: 'Subscription',
          },
          name: 'Basic Year',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1661997335,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 16800,
        unit_amount_decimal: '16800',
      },
    },
    Starter: {
      month: {
        id: 'price_1Ld2WwK1ORtygzBgCyCBs4Fw',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1661996958,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          interval: 'month',
        },
        nickname: null,
        product: {
          id: 'prod_MLk0EbuQORCt00',
          object: 'product',
          active: true,
          attributes: [],
          created: 1661996958,
          default_price: 'price_1Ld2WwK1ORtygzBgCyCBs4Fw',
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX3Rlc3Rfc3RsRFU2aWxDR09sSjVTNlk0NEh5ZkhV00cq1ZMDVg',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '100000',
            interval: 'month',
            plan: 'Starter',
            type: 'Subscription',
          },
          name: 'Starter Monthly',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1661996958,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 699,
        unit_amount_decimal: '699',
      },
      year: {
        id: 'price_1LdUngK1ORtygzBghrmeUUlZ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1662105628,
        currency: 'usd',
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          interval: 'year',
        },
        nickname: null,
        product: {
          id: 'prod_MLk0IY4bsdlImc',
          object: 'product',
          active: true,
          attributes: [],
          created: 1661996952,
          default_price: null,
          description: null,
          images: [
            'https://files.stripe.com/links/MDB8YWNjdF8xSndwSVhLMU9SdHlnekJnfGZsX3Rlc3RfNFpTYkd5VlRLeUpneTFrV2hFOEVwelY3009DlUgwCv',
          ],
          livemode: true,
          metadata: {
            chars_monthly: '100000',
            interval: 'year',
            plan: 'Starter',
            type: 'Subscription',
          },
          name: 'Starter Year',
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: 'txcd_10103000',
          type: 'service',
          unit_label: null,
          updated: 1662105637,
          url: null,
        },
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          trial_period_days: null,
          usage_type: 'licensed',
        },
        tax_behavior: 'inclusive',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 5900,
        unit_amount_decimal: '5900',
      },
    },
    Enterprise: {
      month: {},
      year: {},
    },
  },
  addons: {
    Premium: {
      id: 'price_1L5rMiK1ORtygzBgEUX6vAl7',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1654089216,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: true,
      lookup_key: null,
      metadata: {
        fakturowniaIdPL: '107308834',
      },
      nickname: null,
      product: {
        id: 'prod_LnSHMMfBtYe9Kb',
        object: 'product',
        active: true,
        attributes: [],
        created: 1654089216,
        default_price: 'price_1L5rMiK1ORtygzBgEUX6vAl7',
        description: null,
        images: [],
        livemode: true,
        metadata: {
          chars: '10000',
          plan: 'Premium',
          type: 'Add-on',
        },
        name: 'Premium Add-On Chars',
        package_dimensions: null,
        shippable: null,
        statement_descriptor: null,
        tax_code: 'txcd_10103000',
        type: 'service',
        unit_label: null,
        updated: 1654089216,
        url: null,
      },
      recurring: null,
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 99,
      unit_amount_decimal: '99',
    },
    Business: {
      id: 'price_1LdIcqK1ORtygzBg0zsA9je0',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1662058828,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: true,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: {
        id: 'prod_LnSHvEe4ddK4rl',
        object: 'product',
        active: true,
        attributes: [],
        created: 1654089214,
        default_price: null,
        description: null,
        images: [],
        livemode: true,
        metadata: {
          chars: '10000',
          plan: 'Business',
          type: 'Add-on',
        },
        name: 'Business Add-On Chars',
        package_dimensions: null,
        shippable: null,
        statement_descriptor: null,
        tax_code: 'txcd_10103000',
        type: 'service',
        unit_label: null,
        updated: 1662105543,
        url: null,
      },
      recurring: null,
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 149,
      unit_amount_decimal: '149',
    },
    Basic: {
      id: 'price_1L5rMdK1ORtygzBgn8b7MMyZ',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1654089211,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: true,
      lookup_key: null,
      metadata: {
        fakturowniaId: '107193799',
        fakturowniaIdPL: '107481963',
        fakturowniaIdZeroTax: '107197001',
      },
      nickname: null,
      product: {
        id: 'prod_LnSHzQLrCG0N5D',
        object: 'product',
        active: true,
        attributes: [],
        created: 1654089211,
        default_price: 'price_1L5rMdK1ORtygzBgn8b7MMyZ',
        description: null,
        images: [],
        livemode: true,
        metadata: {
          chars: '10000',
          plan: 'Basic',
          type: 'Add-on',
        },
        name: 'Basic Add-On Chars',
        package_dimensions: null,
        shippable: null,
        statement_descriptor: null,
        tax_code: 'txcd_10103000',
        type: 'service',
        unit_label: null,
        updated: 1662058806,
        url: null,
      },
      recurring: null,
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 269,
      unit_amount_decimal: '269',
    },
    Enterprise: {
      id: 'price_1LAURiK1ORtygzBgk1BH9G8c',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1655192754,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: true,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: {
        id: 'prod_LsEvq90r8qJJnm',
        object: 'product',
        active: true,
        attributes: [],
        created: 1655192754,
        default_price: 'price_1LAURiK1ORtygzBgk1BH9G8c',
        description: null,
        images: [],
        livemode: true,
        metadata: {
          chars: '10000',
          plan: 'Enterprise',
          type: 'Add-on',
        },
        name: 'Enterprise Add-On Chars',
        package_dimensions: null,
        shippable: null,
        statement_descriptor: null,
        tax_code: 'txcd_10103000',
        type: 'service',
        unit_label: null,
        updated: 1655192754,
        url: null,
      },
      recurring: null,
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 99,
      unit_amount_decimal: '99',
    },
    Starter: {
      id: 'price_1Ld2X9K1ORtygzBgdZTV3S2u',
      object: 'price',
      active: true,
      billing_scheme: 'per_unit',
      created: 1661996971,
      currency: 'usd',
      custom_unit_amount: null,
      livemode: true,
      lookup_key: null,
      metadata: {},
      nickname: null,
      product: {
        id: 'prod_MLk1uT7CfQFwxA',
        object: 'product',
        active: true,
        attributes: [],
        created: 1661996970,
        default_price: 'price_1Ld2X9K1ORtygzBgdZTV3S2u',
        description: null,
        images: [],
        livemode: true,
        metadata: {
          chars: '10000',
          plan: 'Starter',
          type: 'Add-on',
        },
        name: 'Starter Add-On Chars',
        package_dimensions: null,
        shippable: null,
        statement_descriptor: null,
        tax_code: 'txcd_10103000',
        type: 'service',
        unit_label: null,
        updated: 1661996971,
        url: null,
      },
      recurring: null,
      tax_behavior: 'inclusive',
      tiers_mode: null,
      transform_quantity: null,
      type: 'one_time',
      unit_amount: 249,
      unit_amount_decimal: '249',
    },
  },
};
