<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="mx-auto h-16 w-16"
    width="78.214"
    height="77.801"
    viewBox="0 0 78.214 77.801"
  >
    <g id="Group_5636" data-name="Group 5636" transform="translate(-0.003 -0.06)">
      <path
        id="Path_43744"
        data-name="Path 43744"
        d="M21.878,6.955h6.38a91.513,91.513,0,0,0,9.765-.586,1.107,1.107,0,1,0,0-2.213c-.749,0-5.794-.618-9.765-.846a40.949,40.949,0,0,0-4.3,0L12.536,4.481a.976.976,0,1,0,0,1.953C15.336,6.336,17.712,6.824,21.878,6.955Z"
        transform="translate(26.062 7.199)"
        fill="#191919"
      />
      <path
        id="Path_43745"
        data-name="Path 43745"
        d="M30.163,13.986c-.749,0-5.794-.618-9.765-.846H16.1L4.676,14.247a.976.976,0,1,0,0,1.953c2.539,0,4.948.488,9.114.618h6.673c3.939,0,9.114-.488,9.765-.586a1.107,1.107,0,0,0,1.009-1.2,1.074,1.074,0,0,0-1.074-1.042Z"
        transform="translate(8.338 29.495)"
        fill="#fea900"
      />
      <path
        id="Path_43746"
        data-name="Path 43746"
        d="M38.293,7.491c-3.027-.2-6.51-.456-10.09-.521h-4.3L12.676,8.044a.976.976,0,0,0,0,1.953c2.148.293,4.459.456,6.8.553h8.756c3.45-.2,7-.651,10.025-.879a1.139,1.139,0,0,0,0-2.246Z"
        transform="translate(26.377 15.582)"
        fill="#191919"
      />
      <path
        id="Path_43747"
        data-name="Path 43747"
        d="M20.318,16.65h-4.3l-11.1,1.139a.977.977,0,0,0-1.042.944,1.042,1.042,0,0,0,.944,1.009c2.148.293,4.459.456,6.8.553l4.427.163h4.329c3.45-.2,7-.684,10.025-.879a1.139,1.139,0,0,0,1.107-1.139,1.107,1.107,0,0,0-1.107-1.107C27.348,16.976,23.8,16.715,20.318,16.65Z"
        transform="translate(8.743 37.41)"
        fill="#fea900"
      />
      <g id="Group_5635" data-name="Group 5635" transform="translate(0.003 0.06)">
        <path
          id="Path_43748"
          data-name="Path 43748"
          d="M60.181,3.9A2.962,2.962,0,0,0,59.4,2.046,14.745,14.745,0,0,0,53.248.613C45.86-.135,33.328.093,31.635.093c-4.231,0-10.546.358-15.3.846A20.767,20.767,0,0,0,9.013,2.534,1.823,1.823,0,0,0,8.46,3.673,11.3,11.3,0,0,0,8.2,5.691c-.26,3.255-.456,10.676-.391,17.251A81.375,81.375,0,0,0,8.46,34.6a4.1,4.1,0,0,0,.846,2.083,2.6,2.6,0,0,0,1.107.618,18.325,18.325,0,0,0,4,.293c4.362,0,11.2-.488,14.257-.521h.781c.749,1.074,1.595,3.06,2.962,5.208a21.352,21.352,0,0,0,2.929,3.678A16.275,16.275,0,0,0,44,49.829a9.928,9.928,0,0,0,2.278.2,1.335,1.335,0,0,0,1.237-1.009,1.269,1.269,0,0,0-.26-.976,13.02,13.02,0,0,1-2.8-5.859,11.913,11.913,0,0,1,0-4.524c.553-2.246,2.441-.716,11.555-1.2a6.217,6.217,0,0,0,3.548-.976,1.627,1.627,0,0,0,.391-.879,10.321,10.321,0,0,1,.2-2.083c.228-4.069.488-13.02.488-19.888A67.934,67.934,0,0,0,60.181,3.9Zm-2.7,28.742a7.487,7.487,0,0,1,0,.911,7.519,7.519,0,0,1-1.4.391c-8.691.749-14.615-2.278-13.834,7.063a16.731,16.731,0,0,0,1.888,6.51A13.573,13.573,0,0,1,37.2,44.263a20.865,20.865,0,0,1-2.506-3.255C33.1,38.4,32.221,36,31.44,34.953a1.725,1.725,0,0,0-1.009-.521,17.445,17.445,0,0,0-2.051,0c-3.255,0-10.025.26-14.192,0a11.057,11.057,0,0,1-2.376-.163,39.71,39.71,0,0,0-.391-4.459c-.26-6.51,0-17.317.26-22.59V5.333h.358c1.53-.325,3.939-.586,6.51-.846,4.264-.358,9.277-.651,12.825-.684,1.269,0,9.147-.228,16.015,0a82.515,82.515,0,0,1,8.365.521,8.722,8.722,0,0,1,1.009.358,12.176,12.176,0,0,1,0,1.269c.228,2.311.326,5.989.391,9.928A139.912,139.912,0,0,1,57.48,32.642Z"
          transform="translate(17.572 -0.06)"
          fill="#191919"
        />
        <path
          id="Path_43749"
          data-name="Path 43749"
          d="M50.056,31.791a1.107,1.107,0,0,0-1.3.911,14.94,14.94,0,0,1-3.906,7.714,15.461,15.461,0,0,1-7.421,4.231,26.884,26.884,0,0,1-3.418.521c-2.734.228-5.306.2-5.306.2a1.562,1.562,0,0,0-1.172.521c-.651.684-1.823,2.539-3.255,4.2a10.286,10.286,0,0,1-2.083,2.051,13.508,13.508,0,0,1-4.655,1.53,11.62,11.62,0,0,0,.781-3.255,7.519,7.519,0,0,0-.814-4.362,3,3,0,0,0-1.595-1.269A25.1,25.1,0,0,1,5.69,39.9c-1.986-2.051-2.7-5.078-2.409-10.318A19.237,19.237,0,0,1,8.75,17.664a22.557,22.557,0,0,1,11.816-6.51,1.009,1.009,0,0,0,.781-1.172.976.976,0,0,0-1.107-.976A24.933,24.933,0,0,0,6.992,15.711,22.459,22.459,0,0,0,.157,29.187C-.429,35.7.678,39.57,3.086,42.207a27.667,27.667,0,0,0,11.36,5.859,3.255,3.255,0,0,1,0,.651,7.682,7.682,0,0,1-.26,2.9,7.747,7.747,0,0,1-1.172,2.962,1.725,1.725,0,0,0-.228,2.181,1.888,1.888,0,0,0,1.367.911,5.11,5.11,0,0,0,1.269,0,21.125,21.125,0,0,0,8.886-2.637,11.881,11.881,0,0,0,2.213-2.083c1.269-1.53,2.376-3.255,3.255-4.394,1.009,0,2.864-.163,4.817-.391a26.038,26.038,0,0,0,3.776-.716,18.456,18.456,0,0,0,8.593-5.273A17.284,17.284,0,0,0,51.2,33.125a1.107,1.107,0,0,0-1.139-1.335Z"
          transform="translate(-0.003 20.094)"
          fill="#fea900"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AlternativeVoiceIcon',
  props: {
    title: {
      type: String,
      default: 'voice id user',
    },
  },
};
</script>
