<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="91.062" height="67.345" viewBox="0 0 91.062 67.345">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_2639" data-name="Rectangle 2639" width="91.062" height="67.345"/>
      </clipPath>
    </defs>
    <g id="Group_5380" data-name="Group 5380" transform="translate(0 -7.325)">
      <g id="Group_5379" data-name="Group 5379" transform="translate(0 7.325)" clip-path="url(#clip-path)">
        <path id="Path_43257" data-name="Path 43257" d="M82.673,66.09c-6.014.291-12.413.429-19.68.429-8.171,0-16.963-.191-26.27-.394-9.607-.209-19.542-.425-29.069-.425-1.447,0-2.728.018-3.918.056a2.344,2.344,0,0,1-2.42-2.343c0-6.808-.125-13.684-.246-20.335C.95,36.455.825,29.607.825,22.855c0-7.545.155-12.345.292-16.58.023-.712.045-1.406.067-2.092a2.022,2.022,0,0,1,2.009-1.95h.018c2.689.025,5.485.038,8.309.038,16.315,0,32.913-.419,48.965-.825C69.514,1.219,78.849.983,88.022.825h.036a2.1,2.1,0,0,1,2.106,2.17c-.128,4.116-.191,8.417-.191,13.15,0,6.6.125,13.279.273,19.836a.412.412,0,0,1,.412-.4l.4,0c-.143-6.43-.263-12.973-.263-19.437,0-4.724.062-9.017.191-13.124a2.941,2.941,0,0,0-.843-2.15A2.97,2.97,0,0,0,88.009,0C78.831.158,69.494.394,60.465.622c-16.047.405-32.64.825-48.944.825-2.822,0-5.615-.013-8.3-.038H3.193A2.852,2.852,0,0,0,.359,4.158C.338,4.844.316,5.538.293,6.249.155,10.49,0,15.3,0,22.855c0,6.759.125,13.611.245,20.238.121,6.647.246,13.52.246,20.32a3.169,3.169,0,0,0,3.271,3.168c1.18-.037,2.453-.055,3.892-.055,5.866,0,17.894.194,17.894.194l.829.016,8.572.177.826.018.93.02c6.743.147,19.375.353,19.375.353l.826.009s4.094.032,6.087.032c7.116,0,13.4-.133,19.311-.411-.006-.138-.014-.276-.021-.413a.412.412,0,0,1,.389-.43" transform="translate(0 0)" stroke="#000" stroke-width="1"/>
        <path id="Path_43258" data-name="Path 43258" d="M97.55,51.193c.123-14.826.2-25.925.208-31.253v-.015c0-2.617-1.536-4.119-4.214-4.119l-.129,0c-4.824.121-9.742.137-14.5.152-8.229.026-16.739.053-25.157.625-3.7.251-7.671.369-12.495.369-3.1,0-6.251-.048-9.3-.095s-6.169-.094-9.249-.094c-2.266,0-4.19.022-5.883.067A1.793,1.793,0,0,0,15.092,18.6c-.153,8.311-.227,15.8-.227,22.9,0,10.53.163,20.158.5,29.432a1.86,1.86,0,0,0,1.806,1.79c5.614.148,11.316.131,16.832.114q2.7-.008,5.4-.011c9.378,0,13.266.169,16.7.318,2.584.112,4.815.209,8.867.209,3.419,0,19.074-.451,30.4-.912a2.54,2.54,0,0,0,2.428-2.607c-.163-5.337-.242-11.2-.242-17.908,0-.018,0-.274.006-.73m-.125-3.83a.412.412,0,0,1-.579-.073c-.029-.037-.059-.072-.088-.109q-.016,1.953-.033,4c0,.467-.006.726-.006.736,0,6.722.079,12.588.243,17.934a1.713,1.713,0,0,1-1.637,1.757c-11.316.461-26.953.911-30.365.911-4.034,0-6.257-.1-8.831-.208C52.69,72.167,48.792,72,39.4,72q-2.7,0-5.4.011c-5.509.017-11.206.034-16.808-.114a1.034,1.034,0,0,1-1-1c-.334-9.264-.5-18.882-.5-29.4,0-7.094.074-14.581.226-22.887a.969.969,0,0,1,.945-.953c1.685-.045,3.6-.067,5.861-.067,3.074,0,6.206.048,9.236.094,3.047.046,6.2.095,9.308.095,4.843,0,8.831-.118,12.551-.37,8.391-.57,16.887-.6,25.1-.623,4.76-.015,9.682-.031,14.516-.152l.108,0c1.546,0,3.389.571,3.389,3.294-.01,4.689-.068,13.817-.166,26.01.247.26.49.542.73.849a.413.413,0,0,1-.073.579" transform="translate(-10.777 -11.46)" stroke="#000" stroke-width="1"/>
        <path id="Path_43259" data-name="Path 43259" d="M231.08,103.46q-.026-.41-.051-.823c-.125.007-.25.012-.373.012a9.307,9.307,0,0,1-5.115-1.706c.109.432.223.833.343,1.209a9.7,9.7,0,0,0,4.772,1.322c.141,0,.282,0,.425-.013" transform="translate(-163.512 -73.181)" stroke="#000" stroke-width="1"/>
        <path id="Path_43260" data-name="Path 43260" d="M272.2,75.078h-.019c-.146-.007-.293-.018-.44-.026a9.511,9.511,0,0,1-2.64,5.193c-.071.458-.149.916-.24,1.37a10.5,10.5,0,0,0,3.745-6.889.411.411,0,0,1-.406.353" transform="translate(-194.918 -54.174)" stroke="#000" stroke-width="1"/>
        <path id="Path_43261" data-name="Path 43261" d="M207.99,46.74l.422-.009a9.2,9.2,0,0,1,9.071-8.7,7.932,7.932,0,0,1,2.02.26c.222.059.448.114.675.169a15.63,15.63,0,0,1,1.761.505,8.832,8.832,0,0,1,5.444,7.921c0,.047,0,.094,0,.141.138.008.277.018.414.024a.412.412,0,0,1,.393.418c.014-.193.023-.388.023-.583a9.562,9.562,0,0,0-5.979-8.694,16.164,16.164,0,0,0-1.854-.534c-.222-.054-.443-.109-.661-.166a8.766,8.766,0,0,0-2.23-.287,10.035,10.035,0,0,0-9.9,9.653c0,.088,0,.174.007.262a.411.411,0,0,1,.4-.383" transform="translate(-150.494 -26.974)" stroke="#000" stroke-width="1"/>
        <path id="Path_43262" data-name="Path 43262" d="M248.531,101.373a11.062,11.062,0,0,0,4.535-1.942q.143-.617.261-1.24a10.568,10.568,0,0,1-4.847,2.356q.026.414.052.825" transform="translate(-180.143 -71.187)" stroke="#000" stroke-width="1"/>
        <path id="Path_43263" data-name="Path 43263" d="M208.457,73.9l-.424.009a.417.417,0,0,1-.417-.364,10.337,10.337,0,0,0,4.22,8.056c-.111-.416-.219-.859-.323-1.338a9.247,9.247,0,0,1-3.056-6.363" transform="translate(-150.518 -53.319)" stroke="#000" stroke-width="1"/>
        <path id="Path_43264" data-name="Path 43264" d="M208.02,72.592l.424-.009c.654-.013,1.34-.023,2.076-.027-.014-.123-.028-.243-.042-.369a.412.412,0,1,1,.82-.092c.018.156.035.306.053.458h.213c1.343,0,2.71.013,4.032.026l1.9.017c-.006-.136-.012-.268-.017-.392a.413.413,0,0,1,.395-.429.406.406,0,0,1,.43.4c.005.136.012.282.019.432q.672,0,1.342,0c1.243,0,2.532-.016,2.669-.018.629,0,1.266.031,1.9.072,0-.125,0-.25-.006-.375a.412.412,0,1,1,.825-.025c0,.153,0,.305.007.458l.536.041c.568.045,1.152.087,1.734.12.147.009.293.02.44.026h.019a.433.433,0,0,0,.412-.394.416.416,0,0,0-.393-.43c-.137-.006-.276-.017-.414-.024-.579-.033-1.163-.075-1.732-.12-1.087-.085-2.211-.174-3.34-.174-.134,0-1.419.018-2.656.018-1.356,0-2.729-.013-4.057-.026s-2.693-.026-4.04-.026c-1.15,0-2.18.01-3.141.03l-.422.009a.411.411,0,0,0-.4.383c0,.013,0,.026,0,.04s0,.026,0,.039a.417.417,0,0,0,.417.363" transform="translate(-150.505 -52.001)" stroke="#000" stroke-width="1"/>
        <path id="Path_43265" data-name="Path 43265" d="M236.471,125.456l.541.012c.039.74.091,1.463.139,2.083-2.184-.092-4.7-.179-7.2-.179q-.5,0-.992,0c-.012-.331-.017-.687-.017-1.077q0-.429.007-.859c.886-.038,1.839-.056,2.9-.056,1.369,0,2.764.031,4.114.06l.5.011a.412.412,0,0,1-.369-.591c.038-.079.074-.16.111-.24l-.227,0c-.647-.014-1.306-.029-1.967-.04.007.128.014.261.021.384a.413.413,0,0,1-.39.434h-.022a.413.413,0,0,1-.412-.391c-.008-.141-.016-.292-.024-.439-.447-.005-.894-.009-1.338-.009-.5,0-.975,0-1.434.013l.123.2a.413.413,0,1,1-.7.44l-.385-.612c-.318.011-.631.023-.933.038a.413.413,0,0,0-.392.405c-.007.42-.013.84-.013,1.26,0,.565.012,1.06.035,1.516a.412.412,0,0,0,.418.391c3.108-.042,6.334.074,9.007.188h.017a.412.412,0,0,0,.411-.445c-.063-.8-.142-1.851-.193-2.906a.413.413,0,0,0-.4-.393l-.3-.006c-.088.193-.175.386-.266.578a.412.412,0,0,1-.37.234" transform="translate(-165.383 -90.304)" stroke="#000" stroke-width="1"/>
        <path id="Path_43266" data-name="Path 43266" d="M260.487,78.417a31.86,31.86,0,0,0,.378-4.861c0-.164,0-.329-.007-.493s0-.305-.007-.458a.412.412,0,1,0-.825.024c0,.125,0,.25.006.375,0,.184.008.368.008.552a31.09,31.09,0,0,1-.55,5.8q-.118.623-.261,1.24a30.778,30.778,0,0,1-2.1,6.077c-.037.08-.073.161-.111.24a.412.412,0,0,0,.744.356c.092-.191.179-.385.267-.578A31.5,31.5,0,0,0,259.7,82.1c-.09-.008-.18-.017-.273-.017a.424.424,0,0,1-.431-.407.4.4,0,0,1,.394-.417h.037a3.929,3.929,0,0,1,.487.033q.186-.746.338-1.5c.09-.455.169-.912.24-1.37" transform="translate(-186.306 -52.347)" stroke="#000" stroke-width="1"/>
        <path id="Path_43267" data-name="Path 43267" d="M222.9,87.1a.413.413,0,0,0,.349-.633l-.123-.2a21.1,21.1,0,0,1-2.664-5.3c-.119-.376-.233-.777-.343-1.209a56.212,56.212,0,0,1-1.182-7.209c-.017-.152-.035-.3-.053-.458a.412.412,0,1,0-.82.092c.014.126.028.246.042.369.31,2.705.625,4.753.98,6.39.1.479.211.922.322,1.338a19.716,19.716,0,0,0,2.757,6.011l.385.612a.413.413,0,0,0,.349.193" transform="translate(-158.09 -52.004)" stroke="#000" stroke-width="1"/>
        <path id="Path_43268" data-name="Path 43268" d="M244.879,81.481c-.027-.431-.054-.862-.081-1.292-.175-2.772-.355-5.629-.441-7.47-.007-.15-.013-.3-.019-.432a.406.406,0,0,0-.429-.4.413.413,0,0,0-.4.429c.005.124.011.256.017.392.085,1.847.267,4.731.444,7.527.028.444.056.89.084,1.335q.026.413.051.823c.089,1.428.173,2.805.238,3.986.008.148.016.3.024.439a.413.413,0,0,0,.412.391h.022a.412.412,0,0,0,.39-.434c-.007-.123-.014-.256-.021-.384-.066-1.208-.152-2.623-.244-4.091q-.026-.411-.052-.825" transform="translate(-176.542 -52.12)" stroke="#000" stroke-width="1"/>
        <path id="Path_43269" data-name="Path 43269" d="M76.231,96.457a6.752,6.752,0,0,0-5.706-2.743,11.5,11.5,0,0,0-9.777,6.154,3.42,3.42,0,0,0-2.569-1.1,3.855,3.855,0,0,0-2.562,1.055,2.464,2.464,0,0,0-2.461-1.787,3.255,3.255,0,0,0-1.648.469c-1.389-2.324-3.456-3.538-4.859-3.538A8.293,8.293,0,0,0,39.92,99.59a5.254,5.254,0,0,0-8.628,1.351,6.7,6.7,0,0,0-2.585-.5,5.132,5.132,0,0,0-5.158,3.343.413.413,0,1,0,.787.246,4.322,4.322,0,0,1,4.371-2.764,5.814,5.814,0,0,1,2.6.59.413.413,0,0,0,.564-.21,4.483,4.483,0,0,1,4.284-2.922,4.766,4.766,0,0,1,3.572,1.887.413.413,0,0,0,.707-.122,7.491,7.491,0,0,1,6.211-4.7c1.206,0,3.144,1.228,4.359,3.5a.413.413,0,0,0,.625.125,2.5,2.5,0,0,1,1.524-.561c1.143,0,1.435.6,1.854,1.659l.08.2a.412.412,0,0,0,.7.111,3.2,3.2,0,0,1,2.391-1.239,2.708,2.708,0,0,1,2.318,1.255.412.412,0,0,0,.7-.063,10.757,10.757,0,0,1,9.328-6.246,5.919,5.919,0,0,1,5.094,2.471c.228.252.464.513.712.762a.412.412,0,1,0,.583-.583c-.233-.233-.452-.475-.683-.732" transform="translate(-17.058 -67.941)" stroke="#000" stroke-width="1"/>
        <path id="Path_43270" data-name="Path 43270" d="M278.327,111.53c-.239-.308-.482-.589-.729-.849a6.867,6.867,0,0,0-5.3-2.3,6.171,6.171,0,0,0-3.176.825c-.826-2.454-2.166-3.84-3.909-4.056a3.936,3.936,0,0,0-.487-.033h-.037a.4.4,0,0,0-.394.417.424.424,0,0,0,.431.407c.094,0,.183.009.273.017,1.973.178,2.991,2.257,3.5,4.017a.413.413,0,0,0,.65.211,5.116,5.116,0,0,1,3.149-.981,6.272,6.272,0,0,1,5.292,2.725c.029.037.059.071.088.109a.413.413,0,0,0,.652-.506" transform="translate(-191.607 -76.205)" stroke="#000" stroke-width="1"/>
        <path id="Path_43271" data-name="Path 43271" d="M63.313,159.786q0-1.006.006-2.01c0-.99-.007-1.8-.022-2.553a.412.412,0,0,0-.127-.289.4.4,0,0,0-.294-.115c-.641.013-1.315.02-2,.02-2.489,0-5.024-.086-7.475-.168-2.87-.1-5.838-.2-8.761-.162a.413.413,0,0,0-.407.415q0,.271,0,.542c0,1.065-.017,2.146-.034,3.191s-.034,2.128-.034,3.193a54.612,54.612,0,0,0,.233,5.476.413.413,0,0,0,.4.371c1.663.057,3.362.149,5.005.237,1.718.092,3.472.182,5.23.24a.412.412,0,0,1-.342-.459,3.62,3.62,0,0,0,.024-.376c-1.635-.058-3.267-.142-4.868-.228l-.314-.016c.005.118.013.237.024.354a.413.413,0,0,1-.372.449l-.039,0a.412.412,0,0,1-.41-.374c-.015-.157-.023-.316-.029-.475-1.166-.061-2.349-.12-3.519-.164-.133-1.466-.195-3.079-.195-5.036,0-1.059.017-2.137.034-3.18s.034-2.133.034-3.2v-.136c2.77-.021,5.588.073,8.315.165,2.458.083,5,.169,7.5.169.548,0,1.087,0,1.608-.012.01.632.015,1.319.015,2.125q0,1-.006,2.006t-.006,2.012c0,1.632.017,3.541.137,5.472-1.362.1-2.8.145-4.371.145-.9,0-1.806-.02-2.713-.046a4.476,4.476,0,0,1-.033.456.41.41,0,0,1-.388.355c1.046.034,2.093.059,3.134.059,1.754,0,3.337-.058,4.843-.178a.412.412,0,0,0,.378-.44c-.142-2.044-.161-4.091-.161-5.824q0-1,.006-2.008" transform="translate(-32.016 -112.01)" stroke="#000" stroke-width="1"/>
        <path id="Path_43272" data-name="Path 43272" d="M154.86,157.574a.413.413,0,0,0-.4-.41c-5.874-.219-11.767-.238-17.791-.24a.413.413,0,0,0-.413.422c.045,1.927.045,3.915.045,5.668v.321c0,.859,0,1.77.005,2.7a1.016,1.016,0,0,1,.823-.417c0-.787,0-1.555,0-2.285v-.321c0-1.631,0-3.463-.036-5.265,5.734,0,11.352.024,16.945.225.023,2.216.132,4.439.237,6.592.016.339.033.682.05,1.025l.184.01.243.013a.412.412,0,1,1-.046.824l-.244-.013-.1-.006c.042.924.081,1.854.108,2.777-2.131.008-4.28-.06-6.365-.124-1.991-.062-4.051-.125-6.082-.125-1.777,0-3.369.049-4.85.149-.021-.884-.032-1.775-.038-2.652-.114.011-.147.051-.168.083a.407.407,0,0,1-.656.037c.008.988.022,1.994.049,2.986a.412.412,0,0,0,.444.4c1.581-.12,3.289-.178,5.22-.178,2.018,0,4.071.063,6.057.124s4.058.125,6.1.125q.361,0,.721,0a.413.413,0,0,0,.409-.423c-.043-1.69-.127-3.408-.208-5.07-.111-2.273-.226-4.623-.24-6.952" transform="translate(-98.786 -113.767)" stroke="#000" stroke-width="1"/>
        <path id="Path_43273" data-name="Path 43273" d="M239.217,156.63a.412.412,0,0,0-.4-.387l-1.336-.036c-3.377-.09-6.869-.184-10.3-.184-2.747,0-5.206.06-7.517.182a.413.413,0,0,0-.39.437c.093,1.516.134,3.137.134,5.256,0,.88-.007,1.759-.013,2.639s-.014,1.764-.014,2.646q0,.9.012,1.807a.413.413,0,0,0,.413.407q1.154-.009,2.3-.009c3.307,0,6.682.051,9.945.1l1.636.024a.412.412,0,0,1-.409-.372c-.014-.14-.029-.3-.044-.46l-1.17-.018c-1.116-.017-2.247-.033-3.383-.048l.02.386a.413.413,0,1,1-.824.042l-.023-.438c-1.916-.024-3.842-.041-5.748-.041q-.949,0-1.9.006-.007-.693-.007-1.386c0-.88.007-1.76.013-2.64s.014-1.763.014-2.645c0-1.945-.035-3.475-.112-4.89,2.176-.107,4.491-.159,7.06-.159,3.422,0,6.908.094,10.28.184l.958.026c.1,1.642.161,3.3.224,4.908.088,2.219.178,4.509.347,6.784-.782-.006-1.569-.015-2.356-.024q-.042.242-.086.481a.412.412,0,0,1-.4.338c1.1.014,2.2.026,3.287.034a.412.412,0,0,0,.414-.445c-.19-2.406-.287-4.844-.38-7.2-.068-1.735-.139-3.528-.247-5.3" transform="translate(-158.967 -113.114)" stroke="#000" stroke-width="1"/>
        <path id="Path_43274" data-name="Path 43274" d="M273.745,202.456c.021-.113.04-.229.06-.344l-1.731-.024c.012.129.024.254.035.367a.412.412,0,0,1-.37.451l-.039,0,2.444.033a.433.433,0,0,1-.067-.006.413.413,0,0,1-.332-.48" transform="translate(-196.978 -146.511)" stroke="#000" stroke-width="1"/>
        <path id="Path_43275" data-name="Path 43275" d="M149.366,174.939h.031c1.134-.087,2.293-.116,3.414-.145.7-.018,1.424-.036,2.137-.068a.412.412,0,0,0,.349-.224c0-.008.005-.016.009-.024a.409.409,0,0,1-.236-.721,18.635,18.635,0,0,1-1.3-2.908c-.3-.777-.6-1.581-.991-2.354a.412.412,0,0,0-.733-.009c-.43.81-.847,1.633-1.251,2.428-.56,1.1-1.139,2.243-1.757,3.362a.4.4,0,0,1,.005.051.408.408,0,0,1-.081.243.406.406,0,0,0,.061.182.412.412,0,0,0,.347.19m2.16-3.655c.28-.55.565-1.114.857-1.676.22.508.418,1.026.612,1.533a21.259,21.259,0,0,0,1.222,2.786c-.479.016-.958.029-1.427.04-.886.023-1.8.046-2.7.1.5-.933.976-1.87,1.439-2.781" transform="translate(-107.992 -121.988)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43276" data-name="Path 43276" d="M155.57,192.981a13.881,13.881,0,0,0-2.2.1,17.277,17.277,0,0,1-1.741.116c-.2,0-.432,0-.556-.012a.413.413,0,0,0-.824.023.7.7,0,0,0,.466.741,2.988,2.988,0,0,0,.915.073,17.962,17.962,0,0,0,1.824-.12,12.926,12.926,0,0,1,2.071-.1.413.413,0,0,0,.049-.824" transform="translate(-108.925 -139.776)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43277" data-name="Path 43277" d="M136.278,189.549a.413.413,0,0,0,.572-.114c.021-.031.054-.071.168-.083a.521.521,0,0,1,.06,0,2.583,2.583,0,0,1,.454.063,1.884,1.884,0,0,0,.568.067s.495-.054,1.2-.054a.415.415,0,0,0,.413-.413.4.4,0,0,0-.005-.051.411.411,0,0,0-.407-.361c-.668,0-1.148.044-1.266.056a2.526,2.526,0,0,1-.345-.053,3.2,3.2,0,0,0-.613-.078c-.023,0-.042.005-.065.006a1.016,1.016,0,0,0-.823.417c-.008.011-.019.019-.027.031a.421.421,0,0,0,.114.572" transform="translate(-98.666 -136.676)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43278" data-name="Path 43278" d="M174.282,187.539a1.837,1.837,0,0,1-.435.1c-.054-.01-.195-.048-.31-.078a7.418,7.418,0,0,0-1.642-.3,2.409,2.409,0,0,0-1,.2.4.4,0,0,0-.1.065.412.412,0,0,0,.435.689,1.6,1.6,0,0,1,.663-.131,7.028,7.028,0,0,1,1.433.273,2.22,2.22,0,0,0,.5.105,2.364,2.364,0,0,0,.692-.132,1.529,1.529,0,0,1,.347-.08c1.5,0,2.929.078,4.431.161l.1.005.244.013a.412.412,0,1,0,.046-.824l-.243-.013-.184-.01c-1.483-.083-2.9-.158-4.393-.158a2.032,2.032,0,0,0-.585.115" transform="translate(-123.72 -135.758)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43279" data-name="Path 43279" d="M68.448,168.269a2.979,2.979,0,0,0-1.407-2.581,1.482,1.482,0,0,0-.331-.178c-.034-.014-.07-.028-.107-.048a2.272,2.272,0,0,0-1.055-.26,2.85,2.85,0,0,0-2.686,2.928,2.752,2.752,0,0,0,.911,2.017,4.409,4.409,0,0,1,.367-.354.412.412,0,1,1,.534.628c-.069.059-.13.127-.195.191a3.081,3.081,0,0,0,1.3.275,2.316,2.316,0,0,0,.758-.133c-.061-.072-.122-.144-.185-.215a.413.413,0,0,1,.614-.551c.109.121.213.246.314.371a2.714,2.714,0,0,0,1.172-2.092m-2.673,1.794a1.994,1.994,0,0,1-2.089-1.932,2.017,2.017,0,0,1,1.861-2.1,1.452,1.452,0,0,1,.675.168c.061.031.116.055.171.078a.715.715,0,0,1,.161.083,2.181,2.181,0,0,1,1.067,1.914,2,2,0,0,1-1.848,1.794" transform="translate(-45.573 -119.768)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43280" data-name="Path 43280" d="M62.869,182.457c.065-.064.126-.132.195-.191a.413.413,0,1,0-.535-.628,4.451,4.451,0,0,0-.367.354,6.37,6.37,0,0,0-1.522,4.373c0,.1,0,.191.007.287.006.159.014.318.029.475a.413.413,0,0,0,.41.374l.039,0a.413.413,0,0,0,.372-.449c-.011-.117-.019-.235-.024-.354s-.008-.221-.008-.331a5.455,5.455,0,0,1,1.4-3.907" transform="translate(-43.963 -131.613)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43281" data-name="Path 43281" d="M77.972,187.838a4.551,4.551,0,0,0,.033-.456c0-.041,0-.082,0-.122a7.592,7.592,0,0,0-1.82-4.64c-.1-.125-.2-.249-.314-.371a.412.412,0,0,0-.614.551c.064.071.125.142.185.215a6.93,6.93,0,0,1,1.738,4.245c0,.032-.006.064-.007.1a3.62,3.62,0,0,1-.024.377.411.411,0,0,0,.342.459.363.363,0,0,0,.067.007c.007,0,.013,0,.02,0a.411.411,0,0,0,.388-.356" transform="translate(-54.487 -132.028)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43282" data-name="Path 43282" d="M89.07,177.023a2.623,2.623,0,0,0,2.684-2.671,2.457,2.457,0,0,0-.133-.876,2.78,2.78,0,0,0-2.253-1.764,2.109,2.109,0,0,0-.326-.025,2.74,2.74,0,0,0-2.426,2.893,2.371,2.371,0,0,0,2.454,2.444m-.027-4.512a1.286,1.286,0,0,1,.2.016,1.958,1.958,0,0,1,1.6,1.222,1.615,1.615,0,0,1,.086.6A1.794,1.794,0,0,1,89.07,176.2a1.547,1.547,0,0,1-1.629-1.619c0-.979.658-2.068,1.6-2.068" transform="translate(-62.795 -124.469)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43283" data-name="Path 43283" d="M95.145,177.247c-.009-.119-.014-.237-.014-.356,0-.088,0-.177.009-.266a.412.412,0,1,0-.823-.056c-.008.108-.011.215-.011.322,0,.149.008.3.019.441l.142-.016c.221-.028.448-.055.678-.069" transform="translate(-68.37 -127.73)"/>
        <path id="Path_43284" data-name="Path 43284" d="M94.729,183.116l.024.163c.023.15.045.3.066.457a.412.412,0,1,0,.818-.109c-.021-.158-.044-.315-.068-.469-.006-.042-.012-.083-.018-.125-.218.01-.439.037-.672.067l-.149.017" transform="translate(-68.677 -132.695)"/>
        <path id="Path_43285" data-name="Path 43285" d="M91.528,180.928l.149-.017c.233-.03.454-.056.672-.067a3.026,3.026,0,0,1,.37,0,.407.407,0,0,0,.441-.382.412.412,0,0,0-.382-.441,4.017,4.017,0,0,0-.527,0c-.23.014-.458.041-.678.069l-.142.016a5.784,5.784,0,0,1-.7.056.413.413,0,0,0,0,.825,6.52,6.52,0,0,0,.8-.062" transform="translate(-65.476 -130.506)"/>
        <path id="Path_43286" data-name="Path 43286" d="M250.5,177.448c0-.063,0-.127,0-.19q1.742.1,3.518.035c-.014,0-.028,0-.042,0a.413.413,0,0,1-.36-.459c.014-.119.027-.237.041-.356-1.192.033-2.373.013-3.536-.071a.416.416,0,0,0-.309.108.411.411,0,0,0-.133.3q0,.318,0,.636c0,1.766.095,3.548.187,5.271l.035.661.023.438a.413.413,0,0,0,.824-.042l-.02-.386-.037-.716c-.091-1.713-.186-3.484-.186-5.228" transform="translate(-181.01 -127.89)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43287" data-name="Path 43287" d="M267.748,183.819c-.011-.113-.023-.237-.035-.367-.145-1.529-.355-4.139-.378-6.661a.413.413,0,0,0-.442-.408c-.266.019-.53.03-.795.043-.019.163-.037.326-.056.489a.412.412,0,0,1-.357.358c.276-.011.553-.021.831-.038.04,2.364.229,4.74.367,6.2.015.164.03.32.044.46a.412.412,0,0,0,.409.372l.042,0a.413.413,0,0,0,.37-.451" transform="translate(-192.617 -127.874)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43288" data-name="Path 43288" d="M268.97,176.995q.043-.239.086-.481c.439-2.5.8-5.233,1.136-8.558a.412.412,0,0,0-.374-.452c-1.735-.154-3.484-.461-5.027-.732a.412.412,0,0,0-.48.356c-.059.476-.112.952-.165,1.428q-.052.467-.107.933c-.014.119-.026.237-.041.356a.413.413,0,0,0,.36.459.853.853,0,0,0,.1,0,.411.411,0,0,0,.357-.358c.02-.163.037-.326.056-.489.031-.269.063-.538.093-.807.037-.329.074-.658.112-.988,1.326.232,2.783.477,4.252.626-.327,3.178-.683,5.808-1.111,8.218-.02.114-.04.23-.06.343a.412.412,0,0,0,.332.48.432.432,0,0,0,.067.006.415.415,0,0,0,.412-.338" transform="translate(-191.391 -120.902)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43289" data-name="Path 43289" d="M255.247,184.538a16.238,16.238,0,0,0,2.449.2.413.413,0,0,0,0-.825,15.414,15.414,0,0,1-2.326-.191.413.413,0,0,0-.122.816" transform="translate(-184.794 -133.191)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43290" data-name="Path 43290" d="M255.755,190.416c.326-.016.647-.011.965-.007.156,0,.312,0,.468,0a8.781,8.781,0,0,0,.9-.039.413.413,0,0,0-.082-.821,7.965,7.965,0,0,1-.814.034c-.152,0-.3,0-.456,0-.337,0-.674-.01-1.019.008a.413.413,0,0,0,.021.824h.021" transform="translate(-185.104 -137.422)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43291" data-name="Path 43291" d="M258.738,194.573c-.328-.017-.662-.012-1-.008-.152,0-.3,0-.457,0a7.464,7.464,0,0,1-.793-.034.413.413,0,1,0-.086.821,8.269,8.269,0,0,0,.879.039c.156,0,.313,0,.469,0,.317,0,.634-.009.943.007h.022a.413.413,0,0,0,.021-.824" transform="translate(-185.62 -141.033)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43292" data-name="Path 43292" d="M226.632,171.729a13.9,13.9,0,0,0,2.4.149c.329,0,.657,0,.981.014h.015a.413.413,0,0,0,.015-.825c-.333-.012-.67-.013-1.008-.015a13.27,13.27,0,0,1-2.261-.136.412.412,0,0,0-.145.812" transform="translate(-164.057 -123.907)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43293" data-name="Path 43293" d="M229.129,176.361c-.463,0-.942-.008-1.4-.042a.413.413,0,1,0-.061.823c.483.036.976.04,1.453.044a17.239,17.239,0,0,1,1.741.072l.046,0a.413.413,0,0,0,.045-.823,17.975,17.975,0,0,0-1.826-.077" transform="translate(-164.779 -127.828)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43294" data-name="Path 43294" d="M226.718,182.591c.478-.016.966-.057,1.438-.1a17.685,17.685,0,0,1,2.1-.1.412.412,0,0,0,.028-.825,18.672,18.672,0,0,0-2.194.1c-.461.039-.939.08-1.4.1a.413.413,0,0,0,.013.825h.014" transform="translate(-164.058 -131.631)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43295" data-name="Path 43295" d="M230.835,186.215c-.414-.014-.827,0-1.241.008-.265.007-.53.014-.8.014a7.569,7.569,0,0,1-1.312-.095.412.412,0,0,0-.14.813,8.452,8.452,0,0,0,1.452.107c.273,0,.545-.007.818-.015.4-.011.794-.022,1.191-.008a.412.412,0,0,0,.028-.825" transform="translate(-164.573 -134.946)" stroke="#000" stroke-width="0.2"/>
        <path id="Path_43296" data-name="Path 43296" d="M42.412,54.155c.509.565,1.034,1.148,1.6,1.689a.413.413,0,0,0,.535.032c1.195-.9,2.469-1.758,3.668-2.548a.413.413,0,0,0,.086-.613l-.271-.317a32.464,32.464,0,0,0-3.6-3.776.413.413,0,0,0-.58.046c-1.118,1.307-2.061,2.351-2.969,3.286a.412.412,0,0,0,.009.584c.528.512,1.034,1.073,1.523,1.616m1.794-4.634a33.81,33.81,0,0,1,3.16,3.376c-1,.662-2.037,1.368-3.035,2.108-.45-.452-.884-.934-1.306-1.4s-.829-.92-1.269-1.367c.759-.794,1.55-1.67,2.45-2.715" transform="translate(-29.553 -35.179)" stroke="#000" stroke-width="1"/>
        <path id="Path_43297" data-name="Path 43297" d="M72.977,53.339c0,.982.057,1.533.372,1.825a1.111,1.111,0,0,0,.913.2,3.032,3.032,0,0,1,1.313.133.413.413,0,1,0,.261-.783,3.849,3.849,0,0,0-1.637-.174,2.145,2.145,0,0,1-.3.01,3.55,3.55,0,0,1-.1-1.208,25.581,25.581,0,0,0-.3-3.775.412.412,0,1,0-.816.121,24.847,24.847,0,0,1,.293,3.654" transform="translate(-52.691 -35.678)" stroke="#000" stroke-width="0.3"/>
        <path id="Path_43298" data-name="Path 43298" d="M83.523,55.015c.651,0,2.077-.706,2.077-2.8,0-1.926-.756-3.03-2.073-3.03a.9.9,0,0,0-.549.2,3.016,3.016,0,0,0-1.224,2.553c0,.877.526,3.082,1.77,3.082m-.069-4.961c.051-.036.071-.047.073-.049,1.032,0,1.248,1.2,1.248,2.205,0,1.51-.917,1.98-1.252,1.98-.429,0-.946-1.386-.946-2.257a2.214,2.214,0,0,1,.877-1.88" transform="translate(-59.269 -35.655)" stroke="#000" stroke-width="0.3"/>
        <path id="Path_43299" data-name="Path 43299" d="M109.448,55.015c.651,0,2.077-.706,2.077-2.8,0-1.926-.755-3.03-2.073-3.03a.9.9,0,0,0-.55.2,3.016,3.016,0,0,0-1.225,2.553c0,.877.527,3.082,1.77,3.082m-.069-4.961c.051-.036.071-.047.073-.049,1.031,0,1.248,1.2,1.248,2.205,0,1.51-.917,1.98-1.252,1.98-.429,0-.946-1.386-.946-2.257a2.214,2.214,0,0,1,.877-1.88" transform="translate(-78.065 -35.655)" stroke="#000" stroke-width="0.3"/>
        <path id="Path_43300" data-name="Path 43300" d="M98.391,54.19a1.538,1.538,0,0,0,1.445-1.563,1.2,1.2,0,0,0-.046-.334.415.415,0,0,0-.414-.3c-.342.015-.687.015-1.021.015a.413.413,0,0,0,0,.825c.2,0,.408,0,.617,0a.66.66,0,0,1-.581.533c-.586,0-.883-.858-.883-2.548,0-.216,0-.432.008-.648s.008-.436.008-.652c0-.331.117-.956.562-.956a.506.506,0,0,1,.5.484.413.413,0,1,0,.825,0,1.335,1.335,0,0,0-1.326-1.309c-.817,0-1.387.732-1.387,1.781,0,.211,0,.424-.008.637s-.008.442-.008.663c0,.834,0,3.373,1.708,3.373" transform="translate(-70.093 -34.608)" stroke="#000" stroke-width="0.3"/>
        <path id="Path_43301" data-name="Path 43301" d="M1.07,43.079C.95,36.455.825,29.607.825,22.855c0-7.545.155-12.345.292-16.58.023-.712.045-1.406.067-2.092a2.022,2.022,0,0,1,2.009-1.95h.018c2.689.025,5.485.038,8.309.038,16.315,0,16.3-.825,0-.825-2.822,0-5.615-.013-8.3-.038H3.193A2.852,2.852,0,0,0,.359,4.158C.338,4.844.316,5.538.293,6.249.155,10.49,0,15.3,0,22.855c0,6.759.125,13.611.245,20.238" transform="translate(91.061 68.345) rotate(180)" stroke="#000" stroke-width="1"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WebsiteIcon',
};
</script>
