import Vue from 'vue';
import VueRouter from 'vue-router';
import a404 from '../views/404.vue';
import a401 from '../views/401.vue';
import Disabled from '../views/Disabled.vue';
// import Home from '../views/Home.vue';
import Wrapper from '../views/Main.vue';

// AUTH
import Login from '../views/AuthLogin.vue';
import Oauth from '../views/AuthOAuth.vue';
import Register from '../views/AuthRegister.vue';
import Reset from '../views/AuthReset.vue';

// import Credits from '../views/Credits.vue';
import Alias from '../views/Alias.vue';
import Articles from '../views/Articles.vue';
import ArticlesList from '../views/ArticlesList.vue';
import ArticlesStatsList from '../views/ArticlesStatsList.vue';
import ArticlesEdit from '../views/ArticlesEdit.vue';
import Billing from '../views/Billing.vue';
import Voice from '../views/Voice.vue';
import Player from '../views/Player.vue';
import WebsiteSettings from '../views/WebsiteSettings.vue';
import WebsiteSettingsGeneral from '../views/WebsiteSettingsGeneral.vue';
import WebsiteSettingsPodcast from '../views/WebsiteSettingsPodcast.vue';
import WebsiteSettingsWordpress from '../views/WebsiteSettingsWordpress.vue';

import AddBlog from '../views/AddBlog.vue';
import AddBlogProjectContent from '../views/AddBlogProjectContent.vue';
import AddBlogProjectName from '../views/AddBlogProjectName.vue';
import AddBlogProjectSettings from '../views/AddBlogProjectSettings.vue';
import AddBlogProjectType from '../views/AddBlogProjectType.vue';
import AddBlogMembership from '../views/AddBlogMembership.vue';
import AddBlogPaymentSuccess from '../views/AddBlogPaymentSuccess.vue';
import AddBlogPaymentCancel from '../views/AddBlogPaymentCancel.vue';

import PaymentSuccess from '../views/PaymentSuccess.vue';
import PaymentCancel from '../views/PaymentCancel.vue';
import Subscribe from '../views/Subscribe.vue';

import AddBlogFailed from '../views/AddBlogFailed.vue';
import AddBlogSuccess from '../views/AddBlogSuccess.vue';

import AccountProfile from '../views/AccountProfile.vue';
import ChangePlan from '../views/ChangePlan.vue';

// import Terms from '../views/Terms.vue';

import isAuthenticated from '../guards/isAuthenticated';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        redirect: 'articles/none/list',
      },
      {
        path: 'articles/:websiteId',
        component: Articles,
        beforeEnter: isAuthenticated,
        children: [
          {
            path: '',
            redirect: 'list',
          },
          {
            path: 'list',
            name: 'articlesList',
            component: ArticlesList,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'list/:state',
            name: 'articlesList',
            component: ArticlesList,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'stats',
            name: 'stats',
            component: ArticlesStatsList,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'edit/:id',
            name: 'articlesEdit',
            component: ArticlesEdit,
            beforeEnter: isAuthenticated,
          },
        ],
      },
      {
        path: 'add-project',
        name: 'add-project',
        component: AddBlog,
        beforeEnter: isAuthenticated,
        children: [
          {
            path: '',
            redirect: 'type',
          },
          {
            path: 'type',
            name: 'projectType',
            component: AddBlogProjectType,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'name/:type',
            name: 'projectName',
            component: AddBlogProjectName,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'content/:type',
            name: 'projectContent',
            component: AddBlogProjectContent,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'settings/:type',
            name: 'projectSettings',
            component: AddBlogProjectSettings,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'membership',
            name: 'membership',
            component: AddBlogMembership,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'payment-success',
            name: 'payment-success',
            component: AddBlogPaymentSuccess,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'payment-cancel',
            name: 'payment-cancel',
            component: AddBlogPaymentCancel,
            beforeEnter: isAuthenticated,
          },
        ],
      },
      {
        path: 'payment',
        name: 'payment',
        component: AddBlogFailed,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'subscribe',
        name: 'subscribe',
        component: Subscribe,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'change-plan',
        name: 'change-plan',
        component: ChangePlan,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'payment-success',
        name: 'paymentSuccess',
        component: PaymentSuccess,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'payment-cancel',
        name: 'paymentCancel',
        component: PaymentCancel,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'add-blog-connect/:token/:websiteId',
        name: 'add-blog-connect',
        component: AddBlogSuccess,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'add-blog-contact',
        name: 'add-blog-contact',
        component: AddBlogFailed,
        beforeEnter: isAuthenticated,
      },
      // {
      //   path: 'credits',
      //   name: 'credits',
      //   component: Credits,
      //   beforeEnter: isAuthenticated,
      // },
      {
        path: 'pronunciation',
        name: 'pronunciation',
        component: Alias,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'player',
        name: 'player',
        component: Player,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'voice',
        name: 'voice',
        component: Voice,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'website-settings/:websiteId',
        name: 'website-settings',
        component: WebsiteSettings,
        children: [
          {
            path: '',
            redirect: 'general',
          },
          {
            path: 'general',
            name: 'settingsGeneral',
            component: WebsiteSettingsGeneral,
          },
          {
            path: 'podcast',
            name: 'settingsPodcast',
            component: WebsiteSettingsPodcast,
          },
          {
            path: 'wordpress',
            name: 'settingsWordpress',
            component: WebsiteSettingsWordpress,
          },
        ],
        beforeEnter: isAuthenticated,
      },
      {
        path: 'credits',
        name: 'billing',
        component: Billing,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'account-profile',
        name: 'profile',
        component: AccountProfile,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'account-profile/:state',
        name: 'profile',
        component: AccountProfile,
        beforeEnter: isAuthenticated,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/oauth',
    name: 'Oauth',
    component: Oauth,
  },
  // {
  //   path: '/terms',
  //   name: 'terms',
  //   component: Terms,
  // },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/resetpassword',
    name: 'Reset',
    component: Reset,
  },
  {
    path: '/401',
    name: '401',
    component: a401,
  },
  {
    path: '/disabled',
    name: 'disabled',
    component: Disabled,
  },
  {
    path: '*',
    name: '404',
    component: a404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
