<template>
  <div>
    <div class="">
      <table class="w-full h-px table-fixed">
        <caption class="sr-only">
          Pricing plan comparison
        </caption>
        <thead>
          <tr>
            <th class="pb-4 px-6 text-sm font-medium text-gray-900 text-left" scope="col">
              <span class="sr-only">Feature by</span>
              <span>Plans</span>
            </th>
            <th
              class="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
              scope="col"
            >
              {{$t('ui.trial')}}
            </th>
            <th
              class="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
              scope="col"
            >
              {{$t('packages.packageStarter')}}
            </th>

            <th
              class="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
              scope="col"
            >
              {{$t('packages.Business')}}
            </th>

            <th
              class="w-1/5 pb-4 px-6 text-lg leading-6 font-medium text-gray-900 text-left"
              scope="col"
            >
              {{$t('packages.Premium')}}
            </th>
          </tr>
        </thead>
        <tbody class="border-t  divide-y divide-gray-200">
          <tr>
            <th class="py-8 px-6 text-sm font-bold text-black text-left align-top" scope="row">
              Pricing
            </th>
            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p>
                  <span class="text-4xl font-extrabold text-gray-900">{{$t('currency.symbol', ['0'])}}</span>
                  <!-- space -->
                  <span class="text-base font-medium text-gray-500">/{{$t('ui.monthShorcut')}}</span>
                </p>
                <p class="mt-4 mb-4 text-sm text-gray-500">
                  {{$t('currency.symbol', ['0'])}} /{{$t('ui.yearShortcut')}}
                </p>
                <!-- <a
                  href="#"
                  class="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >Buy Basic</a
                > -->
              </div>
            </td>
            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p>
                  <span class="text-4xl font-extrabold text-gray-900">{{$t('currency.symbol', ['6.99'])}}</span>
                  <!-- space -->
                  <span class="text-base font-medium text-gray-500">/{{$t('ui.monthShorcut')}}</span>
                </p>
                <p class="mt-4 mb-4 text-sm text-gray-500">
                  {{$t('currency.symbol', ['59.00'])}} /{{$t('ui.yearShortcut')}}
                </p>
                <!-- <a
                  href="#"
                  class="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >Buy Basic</a
                > -->
              </div>
            </td>

            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p>
                  <span class="text-4xl font-extrabold text-gray-900">{{$t('currency.symbol', ['29.00'])}}</span>
                  <!-- space -->
                  <span class="text-base font-medium text-gray-500">/{{$t('ui.monthShorcut')}}</span>
                </p>
                <p class="mt-4 mb-4 text-sm text-gray-500">
                  {{$t('currency.symbol', ['249.00'])}} /{{$t('ui.yearShortcut')}}
                </p>
                <!-- <a
                  href="#"
                  class="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >Buy Essential</a
                > -->
              </div>
            </td>

            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p>
                  <span class="text-4xl font-extrabold text-gray-900">{{$t('currency.symbol', ['99.00'])}}</span>
                  <!-- space -->
                  <span class="text-base font-medium text-gray-500">/{{$t('ui.monthShorcut')}}</span>
                </p>
                <p class="mt-4 mb-4 text-sm text-gray-500">
                  {{$t('currency.symbol', ['888.00'])}} /{{$t('ui.yearShortcut')}}
                </p>
                <!-- <a
                  href="#"
                  class="absolute bottom-0 flex-grow block w-full bg-gray-800 border border-gray-800 rounded-md 5 py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                  >Buy Premium</a
                > -->
              </div>
            </td>
          </tr>

          <tr>
            <th class="py-8 px-6 text-sm font-bold text-black text-left align-top" scope="row">
              {{$t('packages.characterLimit')}}
            </th>
            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p class="mt-0 mb-4 text-sm text-gray-500">
                  20 000 / {{$t('ui.monthShorcut')}}
                </p>
              </div>
            </td>
            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p class="mt-0 mb-4 text-sm text-gray-500">
                  100 000 / {{$t('ui.monthShorcut')}}
                </p>
              </div>
            </td>

            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p class="mt-0 mb-4 text-sm text-gray-500">
                  250 000 / {{$t('ui.monthShorcut')}}
                </p>
              </div>
            </td>

            <td class="h-full py-8 px-6 align-top">
              <div class="relative h-full table">
                <p class="mt-0 mb-4 text-sm text-gray-500">
                  100 000 000 / {{$t('ui.monthShorcut')}}
                </p>
              </div>
            </td>
          </tr>

          <tr>
            <th
              class="bg-white py-3 pl-6 text-sm font-bold text-black text-left"
              colspan="5"
              scope="colgroup"
            >
              Features
            </th>
          </tr>

          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.premiumVoices')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.commercialRights')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>
          <!-- commercialRightsStarter -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.commercialRightsStarter')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- DisablingOutroWatermark -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.DisablingOutroWatermark')}}
            </th>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Trial</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Business</span>
            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

              <span class="sr-only">Included in Essential</span>
            </td>
          </tr>

          <!-- pronunciationsLibrary -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.pronunciationsLibrary')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- podcastHosting -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.podcastHosting')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- AudioHosting -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.AudioHosting')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- WordPressIntegration -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.WordPressIntegration')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- RSSIntegration -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.RSSIntegration')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- InteligentPronunciationsLibrary -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.InteligentPronunciationsLibrary')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- UnlimitedDownloads -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.UnlimitedDownloads')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- SocialSharingPage -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.SocialSharingPage')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- CustomSharingPage -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.CustomSharingPage')}}
            </th>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <span class="text-base">Coming Soon</span>
            </td>

            <td class="py-5 px-6">
              <span class="text-base">Coming Soon</span>
            </td>
          </tr>

          <!-- WhiteLabelledSharingPage -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.WhiteLabelledSharingPage')}}
            </th>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <span class="text-base">Coming Soon</span>
            </td>
          </tr>

          <!-- CustomizableAudioPlayers -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.CustomizableAudioPlayers')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- WhiteLabelledAudio -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.WhiteLabelledAudio')}}
            </th>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Trial</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Business</span>
            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

              <span class="sr-only">Included in Essential</span>
            </td>
          </tr>

          <!-- MultipleWebsites -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.MultipleWebsites')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- PriorityTechnicalSupport -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.PriorityTechnicalSupport')}}
            </th>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Trial</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Business</span>
            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

              <span class="sr-only">Included in Essential</span>
            </td>
          </tr>

          <!-- LiveChatSupport -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.LiveChatSupport')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- Analytics -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.Analytics')}}
            </th>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>

            <td class="py-5 px-6">
              <svg xmlns="http://www.w3.org/2000/svg" width="26.934" height="26.299" viewBox="0 0 26.934 26.299">
                <g id="Check" transform="translate(18112.807 -17123.436)">
                  <path id="Path_43697" data-name="Path 43697" d="M8.19,18.673c-3.851,5.671-1.867,14,1.96,16.337a.706.706,0,1,0,.794-1.167c-3.034-2.124-4.411-9.335-.98-14a11.413,11.413,0,0,1,17.831-.934c5.625,6.675,2.334,16.8-7.632,17.971a13.209,13.209,0,0,1-5.415-.373.8.8,0,0,0-.513,1.517,14.587,14.587,0,0,0,6.115.653A12.976,12.976,0,0,0,29.871,17.2,13.536,13.536,0,0,0,8.19,18.673Z" transform="translate(-18118.914 17110.979)" fill="#22c55d" fill-rule="evenodd"/>
                  <path id="Path_43698" data-name="Path 43698" d="M12.919,27.227c2.661.163,5.835-7.282,7-9.685a2.334,2.334,0,0,0,.63-1.354.84.84,0,0,0-1.517-.42c-.56.677-6.348,9.079-6.208,9.335-.467,0-2.8-2.731-3.151-2.964s-1.354.42-.817,1.19C9.769,24.707,11.356,27.157,12.919,27.227Z" transform="translate(-18115.453 17114.934)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
              </svg>

            </td>
          </tr>

          <!-- advanceAnalytics -->
          <tr>
            <th class="py-5 px-6 text-sm font-normal text-gray-500 text-left" scope="row">
              {{$t('packages.advanceAnalytics')}}
            </th>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Trial</span>
            </td>

            <td class="py-5 px-6">
              <svg
                class="h-5 w-5 text-gray-400"
                x-description="Heroicon name: solid/minus"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Not included in Starter</span>
            </td>

            <td class="py-5 px-6">
              <span class="text-base">Coming soon</span>
            </td>

            <td class="py-5 px-6">
              <span class="text-base">Coming soon</span>
            </td>
          </tr>

        </tbody>
        <!-- <tfoot>
          <tr class="border-t border-gray-200">
            <th class="sr-only" scope="row">Choose your plan</th>

            <td class="pt-5 px-6">
              <a
                href="#"
                class="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >Buy Basic</a
              >
            </td>

            <td class="pt-5 px-6">
              <a
                href="#"
                class="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >Buy Essential</a
              >
            </td>
            Dzień dobry.

            <td class="pt-5 px-6">
              <a
                href="#"
                class="block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >Buy Premium</a
              >
            </td>
          </tr>
        </tfoot> -->
      </table>
    </div>
    <div class="text-center">
      <span class="text-blue-500 cursor-pointer" @click="openCrisp" v-html="$t('packages.enterpriseContact')"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PackageComparision',
  props: {
    websites: Array,
  },
  methods: {
    openCrisp() {
      // eslint-disable-next-line
      $crisp.push(['do', 'chat:open']);
    },
  },
};
</script>
