<template>
    <div class="title-editor relative z-30"
      :class="{ 'fb-separator': hasFBSeparator, 'default': type === 'default', 'input': type === 'input' }">
      <div :id="selector"></div>
    </div>
</template>
<script>
// import EditorJS from '@editorjs/editorjs';
import EditorJS from './Editor/editor';
import Header from './Editor/title';
import AliasInlineTool from './Editor/alias-inline';
import ProsodyInlineTool from './Editor/prosody';
import BreakTool from './Editor/break-inline';

export default {
  name: 'TitleEditor',
  props: [
    'content',
    'hasSeparator',
    'type',
    'feedId',
    'feedItemId',
  ],
  data() {
    return {
      editor: null,
      blocks: null,
      selector: 'editorSimple',
      data: {
        blocks: [
          {
            type: 'header',
            data: {
              text: '',
            },
          },
        ],
      },
      hasFBSeparator: false,
      isPlaying: false,
      convertRegex: {
        toHtml: {
          break: new RegExp(/<(\/?)break\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
          paragraph: new RegExp(/<(\/?)s\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
          aliasStart: new RegExp(/<sub (\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']/g),
          aliasEnd: new RegExp(/<(\/?)sub\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
        },
        toSsml: {
          break: new RegExp(/<(\/?)break\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
          aliasStart: new RegExp(/<a (\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']/g),
          aliasEnd: new RegExp(/<(\/?)a\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
        },
        title: new RegExp(/(<a[^>]+?title=['"])([^'"]*?)(['"][^>]+?>)/g),
      },
    };
  },

  mounted() {
    this.selector = `editorSimeple${(Math.random() + 1).toString(36).substring(7)}`;
    // this.initEditor(this.setContent(this.content));
    this.enterEventListener = window.addEventListener('keyup', this.handleEnterPress);
    // this.initEditor(this.content);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.handleEnterPress);
  },

  methods: {
    handleEnterPress(event) {
      if (event.keyCode === 13) {
        console.log('enter pressed', event);
        event.stopPropagation();
        const first = this.editor.blocks.getBlockByIndex(0);
        const second = this.editor.blocks.getBlockByIndex(1);
        const contentFirst = first.holder.querySelector('.content-wrapper');
        const contentSecond = second.holder.querySelector('.content-wrapper');

        console.log('enter pressed: content', contentFirst, contentSecond, this.editor, this.editor.blocks);

        const content = contentFirst.innerHTML + contentSecond.innerHTML;

        this.editor.blocks.renderFromHTML(content);
        this.editor.blocks.delete(1);
        this.updatePlayButtons();
      }
    },
    refreshContent(inputData) {
      // console.log('refresh content', this.editor);
      const title = {
        type: 'header',
        data: {
          text: this.ssmlToHtml(inputData),
          level: 1,
          id: 'title',
        },
      };
      this.$forceUpdate();
      if (this.editor && this.editor.render instanceof Function) {
        this.editor.clear();
        this.editor.render({ blocks: [title] });
        setTimeout(() => {
          // // console.log('refresh content 2', [title], this.setContent([title]), document.querySelectorAll('.title-editor  .ce-block'));
          [title].forEach((block, index) => {
            const blockEl = document.querySelectorAll(`#${this.selector} .ce-block`)[index];
            // blockEl.id = block.id;
            if (!blockEl) return;
            if (block.language) blockEl.dataset.language = block.language;
            blockEl.dataset.id = block.id;
          });
          this.updatePlayButtons();
        }, 1000);
        return;
      }
      // console.log('refresh content 2', this.editor);
      if (!this.editor) {
        this.initEditor({ blocks: [title] });
      }
    },
    convertNodeToSSML(node) {
      let newNode = null;
      if (node.tagName === 'ALIAS' && node.getAttribute('interpret-as') && node.getAttribute('interpret-as') !== 'custom-text') {
        const type = node.getAttribute('interpret-as');
        const text = node.innerHTML;
        newNode = document.createElement('say-as');
        newNode.innerHTML = text;
        newNode.setAttribute('interpret-as', type);
        node.parentNode.replaceChild(newNode, node);
      }
      // alias
      if (node.tagName === 'ALIAS' && node.getAttribute('interpret-as') && node.getAttribute('interpret-as') === 'custom-text') {
        const alias = node.getAttribute('alias');
        const text = node.innerHTML;
        newNode = document.createElement('sub');
        newNode.innerHTML = text;
        newNode.setAttribute('alias', alias);
        node.parentNode.replaceChild(newNode, node);
      }
      // prosody
      if (node.tagName === 'PROSODY' && node.getAttribute('prosody')) {
        // const pitch = node.getAttribute('pitch');
        const prosodyValue = node.getAttribute('prosody');
        const text = node.innerHTML;
        newNode = document.createElement('prosody');
        // if (pitch) prosody.setAttribute('pitch', pitch);
        newNode.innerHTML = text;
        newNode.setAttribute('rate', prosodyValue / 100);
        node.parentNode.replaceChild(newNode, node);
      }
      if (node.tagName === 'A') {
        const alias = node.getAttribute('href');
        const text = node.innerText;
        newNode = document.createElement('say-as');
        newNode.innerText = text;
        newNode.setAttribute('alias', alias);
        // newNode.setAttribute('interpret-as', 'custom-text');
        node.parentNode.replaceChild(newNode, node);
      }
      return newNode;
    },
    converNodeToHTML(node, parentNode) {
      console.group('converNodeToHTML');
      const parent = parentNode || node.parentNode;
      console.log('converNodeToHTML', node);
      if (!node.tagName) {
        console.groupEnd();
        return document.createElement('div');
      }
      let newNode = null;
      console.log('converNodeToHTML 2', node);
      if (node.tagName === 'SUB') {
        // const type = node.getAttribute('interpret-as');

        const text = node.innerText;
        const alias = node.getAttribute('alias');
        newNode = document.createElement('ALIAS');
        newNode.innerText = text;
        newNode.setAttribute('interpret-as', 'custom-text');
        newNode.setAttribute('alias', alias);
        parent.replaceChild(newNode, node);
        console.log('converNodeToHTML 3', newNode, parent);
      }
      if (node.tagName === 'SAY-AS' && node.getAttribute('interpret-as') !== 'custom-text') {
        const type = node.getAttribute('interpret-as');
        const text = node.innerText;
        newNode = document.createElement('alias');
        newNode.innerText = text;
        newNode.setAttribute('interpret-as', type);
        newNode.setAttribute('alias', '');
        parent.replaceChild(newNode, node);
      }
      if (node.tagName === 'A' && node.getAttribute('interpret-as') === 'custom-text') {
        const alias = node.getAttribute('alias');
        const text = node.innerText;
        newNode = document.createElement('a');
        newNode.innerText = text;
        newNode.setAttribute('href', alias);
        newNode.setAttribute('interpret-as', 'custom-text');
        parent.replaceChild(newNode, node);
      }
      // prosody
      if (node.tagName === 'PROSODY' && node.getAttribute('rate')) {
        const rate = node.getAttribute('rate');
        // const pitch = node.getAttribute('pitch');
        const text = node.innerHTML;
        newNode = document.createElement('PROSODY');
        newNode.innerHTML = text;
        newNode.setAttribute('prosody', rate * 100);
        // if (pitch) newNode.setAttribute('pitch', pitch);
        parent.replaceChild(newNode, node);
      }
      console.groupEnd();
      return newNode;
    },
    htmlToSsml(html) {
      // console.log('html 2 ssml', html);
      // const nbsp = new RegExp(/(&nbsp;)(?!.*[\r\n]*.*\1)/g);
      const template = document.createElement('div');
      template.innerHTML = html;
      template.childNodes.forEach((node) => {
        console.log('node::', node.tagName, node);
        if (!node || !node.tagName) return;

        const newNode = this.convertNodeToSSML(node);
        // node.parentNode.replaceChild(newNode, node);
        console.log('xxx', node.type, '|', node.tagName, '|', typeof newNode);

        // convert children
        if (!newNode) return;
        newNode.childNodes.forEach((childNode) => {
          if (!childNode || !childNode.tagName) return;
          const newChildNode = this.convertNodeToSSML(childNode);
          console.log('xxx childNode', childNode.type, '|', childNode.tagName, '|', newChildNode);
          // childNode.parentNode.replaceChild(newChildNode, childNode);
        });
      });
      const newHtml = template.innerHTML;

      console.log('html 2 ssml after', newHtml);
      return newHtml
        // .replace(this.convertRegex.toSsml.aliasStart, '<sub alias="$2"')
        // .replace(this.convertRegex.toSsml.aliasEnd, '</sub>')
        // .replace(pReg, '<$1s$2>')
        .replace(this.convertRegex.toSsml.break, '<$1break$2 />')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('&amp;', '&')
        .replace(/\n/g, ' ')
        .replaceAll('<br>', ' ')
        .replaceAll('</break />', '')
        .replace('<span style="background-color: rgb(168, 214, 255);"> </span>', '');
      // .replaceAll('style', '')
      // .replaceAll('<sub alias=""', '<sub alias="');
      // .replace(this.convertRegex.title, '')
    },
    ssmlToHtml(ssml) {
      // const nbsp = new RegExp(/(&nbsp;)(?!.*[\r\n]*.*\1)/g);
      // console.log('ssml 2 html', ssml);
      const template = document.createElement('div');
      if (!ssml) return ssml;
      template.innerHTML = ssml
        .replace(this.convertRegex.toHtml.break, '<$1break$2 /></break>');
      template.childNodes.forEach((node) => {
        console.log('ssmlToHtml', node, node.tagName);
        const newNode = this.converNodeToHTML(node);
        if (!newNode) return;
        newNode.childNodes.forEach((childNode) => {
          console.log('ssmlToHtml child', childNode, childNode.tagName);
          this.converNodeToHTML(childNode, newNode);
        });

        // if (node.tagName === 'SUB') {
        //   // const type = node.getAttribute('interpret-as');
        //   const text = node.innerText;
        //   const alias = node.getAttribute('alias');
        //   const sayAs = document.createElement('a');
        //   sayAs.innerText = text;
        //   sayAs.setAttribute('interpret-as', 'custom-text');
        //   sayAs.setAttribute('href', alias);
        //   node.parentNode.replaceChild(sayAs, node);
        // }
        // if (node.tagName === 'SAY-AS' && node.getAttribute('interpret-as') !== 'custom-text') {
        //   const type = node.getAttribute('interpret-as');
        //   const text = node.innerText;
        //   const sayAs = document.createElement('a');
        //   sayAs.innerText = text;
        //   sayAs.setAttribute('interpret-as', type);
        //   sayAs.setAttribute('href', '');
        //   node.parentNode.replaceChild(sayAs, node);
        // }
        // if (node.tagName === 'A' && node.getAttribute('interpret-as') === 'custom-text') {
        //   const alias = node.getAttribute('alias');
        //   const text = node.innerText;
        //   const sub = document.createElement('a');
        //   sub.innerText = text;
        //   sub.setAttribute('href', alias);
        //   sub.setAttribute('interpret-as', 'custom-text');
        //   node.parentNode.replaceChild(sub, node);
        // }
        // // prosody
        // if (node.tagName === 'PROSODY' && node.getAttribute('rate')) {
        //   const rate = node.getAttribute('rate');
        //   // const pitch = node.getAttribute('pitch');
        //   const text = node.innerHTML;
        //   const prosody = document.createElement('PROSODY');
        //   prosody.innerHTML = text;
        //   prosody.setAttribute('prosody', rate * 100);
        //   // if (pitch) prosody.setAttribute('pitch', pitch);
        //   node.parentNode.replaceChild(prosody, node);
        // }
      });
      const newHtml = template.innerHTML;

      // console.log('ssml 2 html after', newHtml);
      return newHtml
        // .replace(this.convertRegex.toHtml.aliasStart, '<a href="$2"')
        // .replace(this.convertRegex.toHtml.aliasEnd, '</a>')
        .replaceAll('&nbsp;', ' ')
        .replace(/\n/g, ' ');
      // .replaceAll('style', '');
    },
    getOutput(outputData) {
      const lists = {};
      let lastListId = null;
      const contentBlocks = [];
      // const newContent =
      outputData.blocks
        .map((block, index) => {
          const blockEl = document.querySelectorAll(`#${this.selector} .ce-block`)[index];
          // console.groupCollapsed('%cBLOCK', 'color:deepskyblue');

          // // console.log('block', block, blockEl);
          const ssml = this.htmlToSsml(block.data.text);
          // const ssmlToHtml = this.ssmlToHtml(ssml);
          // console.log('%coriginal html', 'color: Lime', block.data.text);
          // console.log('%coutput ssml  ', 'color: Fuchsia', ssml);
          // console.log('%coutput html  ', 'color: yellow', ssmlToHtml);
          const newBlock = {
            id: blockEl.dataset.id,
            type: block.type,
            body: ssml,
          };
          // console.log('block', newBlock, blockEl);
          newBlock.id = blockEl.dataset.id || block.type + index;
          if (block.data.level) newBlock.level = block.data.level;

          contentBlocks.push(newBlock);

          // console.groupEnd();
          if (block.type === 'list') {
            delete newBlock.body;
            lists[newBlock.id] = newBlock;
            lastListId = newBlock.id;
            const listItemsEl = blockEl.querySelectorAll(`#${this.selector} .cdx-list__item`);
            block.data.items.forEach((item, i) => {
              // console.log('blockEl', item, listItemsEl[i]);
              const id = listItemsEl[i].id || `${lastListId}.${i}`;
              const lang = listItemsEl[i].dataset.language || '';
              const bodySsml = this.htmlToSsml(item);
              const outputBlock = {
                id,
                type: 'list-item',
                body: bodySsml,
                parent: lastListId,
                language: lang,
              };
              contentBlocks.push(outputBlock);
            });
          }
          // // console.log('ssss', newBlock.id + '-', newBlock, block);
          return newBlock;
        });

      // console.log('contentBlocks', contentBlocks, newContent);
      return contentBlocks;
    },
    setContent(inputData) {
      // console.log('input data', inputData);
      const initData = {
        time: 1554508385558,
        blocks: [],
      };

      const title = {
        type: 'header',
        data: {
          text: this.ssmlToHtml(inputData),
          level: 1,
          id: 'title',
        },
      };
      initData.blocks.push(title);
      return initData;
    },
    asyncSave() {
      return new Promise((resolve, reject) => {
        this.editor.save()
          .then((data) => {
            const contentBlocks = this.getOutput(data);
            const [content] = contentBlocks;
            if (content) {
              resolve(contentBlocks[0].body);
              return;
            }
            resolve('');
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    save() {
      // this.editor.save();
      this.editor.save().then((outputData) => {
        const contentBlocks = this.getOutput(outputData);

        // // console.log('outputData =========================================', contentBlocks, outputData);
        if (!contentBlocks[0]) return;
        this.$emit('update:content', contentBlocks[0].body);
        // console.log('contentBlocks[0].body', contentBlocks[0].body);
      });
    },
    initEditor(content) {
      Header.enableLineBreaks = true;
      ProsodyInlineTool.title = this.$t('editor.prosody');
      Header.DEFAULT_PLACEHOLDER = this.$t('editor.paragraphPlaceholder');
      this.editor = new EditorJS({
        holderId: this.selector,
        defaultBlock: 'header',
        inlineToolbar: ['alias', 'prosody', 'break', 'break5', 'break10', 'break20'],
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['alias', 'break', 'break5', 'break10', 'break20'],
            config: {
              ignoreLevel: true,
              preserveBlank: true,
            },
          },
          alias: {
            class: AliasInlineTool,
            config: {
              textType: 'Title',
              feedItemId: this.feedItemId,
              feedId: this.feedId,
            },
          },
          prosody: {
            class: ProsodyInlineTool,
            config: {
              textType: 'Content',
              feedItemId: this.feedItemId,
              feedId: this.feedId,
            },
          },
          break: {
            class: BreakTool,
            config: {
              time: 200,
            },
          },
          break5: {
            class: BreakTool,
            config: {
              time: 500,
            },
          },
          break10: {
            class: BreakTool,
            config: {
              time: 1000,
            },
          },
          break20: {
            class: BreakTool,
            config: {
              time: 2000,
            },
          },
          // delimiter: {
          //   class: Delimiter,
          // },
        },
        i18n: {
          messages: {
            ui: {
              inlineToolbar: {
                alias: {
                  'Enter alias': this.$t('editor.enterAlias'),
                },
              },
              toolbar: {
              },
            },

            tools: {
              alias: {
                'Enter alias': this.$t('editor.enterAlias'),
              },
            },
            toolNames: {
              Text: this.$t('editor.text'),
              Heading: this.$t('editor.heading'),
              Delimiter: this.$t('editor.delimiter'),
              AliasInlineTool: this.$t('editor.alias'),
            },
            blockTunes: {
            },
          },
        },
        data: content,
        onChange: (event) => {
          console.log('Now I know that Editor\'s content changed!', event, event.blocks.getBlocksCount());
          if (event.blocks.getBlocksCount() > 1) this.mergeBlocks(event);
          this.onChange();
        },
        onReady: () => {
          // console.log('%c editor ready', 'color: lime; font-size: 24px');
          this.onReady(content);
        },
      });
      this.$forceUpdate();

      setTimeout(() => {
        content.blocks.forEach((block, index) => {
          const blockEl = document.querySelectorAll(`#${this.selector} .ce-block`)[index];
          // blockEl.id = block.id;
          if (!blockEl) return;
          if (block.language) blockEl.dataset.language = block.language;
          blockEl.dataset.id = block.id;
        });
        this.hasFBSeparator = !!document.querySelectorAll(`#${this.selector} .ce-delimiter`)[0];
        this.updatePlayButtons();
      }, 1000);
    },

    addButton(block, id) {
      const playBtn = document.createElement('div');
      playBtn.classList = 'play-btn cursor-pointer absolute';
      playBtn.title = 'Play paragraph';
      playBtn.id = `pb-${id}`;
      // TODO: use template?
      playBtn.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="editor-play text-gray-400 hover:text-hm-brand h-7 w-7 -ml-1" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="editor-stop text-red-500 hover:text-hm-brand h-7 w-7 -ml-1" viewBox="0 0 20 20">
          <path id="Subtraction_32" data-name="Subtraction 32" d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM6,5A1,1,0,0,0,5,6v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1Z"/>
        </svg>
        `;

      playBtn.dataset.id = id;
      playBtn.blockId = id;
      playBtn.addEventListener('click', this.playBlock);
      block.appendChild(playBtn);
    },

    updatePlayButtons() {
      // console.groupCollapsed('updatePlayButtons START');
      const blocks = document.querySelectorAll(`#${this.selector} .ce-block`);
      // // console.log('BLOCKS', blocks);
      blocks.forEach((el) => {
        const element = el;
        // const block = el;
        const hasPlyBtn = el.querySelector(`#${this.selector} .play-btn`);
        // const hasId = el.dataset.id;
        // // console.log('block: ', hasPlyBtn, hasId, el);
        // if (!hasId) element.dataset.id = Math.random().toString(36).substr(2, 9);
        if (hasPlyBtn) return;

        this.addButton(element, element.dataset.id);
      });
      // console.groupEnd();
    },
    async playBlock(event) {
      const currentlyPlaying = document.querySelector(`#${this.selector} .play-btn.playing`);
      if (currentlyPlaying) currentlyPlaying.classList.remove('playing');
      if (currentlyPlaying) {
        this.$emit('stopBlock', { content: null, button: event.currentTarget, id: currentlyPlaying.dataset.id });
        this.isPlaying = false;
        event.currentTarget.classList.remove('playing');
        return;
      }
      event.currentTarget.classList.add('loading');
      const outputData = await this.editor.save();
      const content = this.getOutput(outputData);
      // console.log('%cplay block: ', 'color:orange;font-weight:bold', event.currentTarget.blockId);

      this.isPlaying = event.currentTarget.id;
      // console.log('%cPLAY BLOCK DATA', 'color:red; font-weight: bold', outputData, content);
      console.groupEnd();
      this.$emit('playBlock', { content, button: event.currentTarget, id: event.currentTarget.blockId });
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    clearPlay() {
      console.log(';clear');
      const currentPlaying = document.querySelector('.title-editor .ce-block .play-btn.playing');
      const currentLoading = document.querySelector('.title-editor .ce-block .play-btn.loading');
      this.currentlyPlaying = null;
      this.isPlaying = false;
      // timeout to make shure users seees that something is happening
      setTimeout(() => {
        if (currentPlaying) currentPlaying.classList.remove('playing');
        if (currentLoading) currentLoading.classList.remove('loading');
      }, 200);
    },
    async onChange() {
      // console.log('%con change', 'color:orange;font-weight:bold', data);
      this.updatePlayButtons();
      this.save();
    },
    onReady() {
      this.updatePlayButtons();
    },

    mergeBlocks(event) {
      console.log('mergeBlocks', event, event.blocks.getBlocksCount());
      const first = this.editor.blocks.getBlockByIndex(0);
      const second = this.editor.blocks.getBlockByIndex(1);
      const contentFirst = first.holder.querySelector('.content-wrapper');
      const contentSecond = second.holder.querySelector('.content-wrapper');

      const content = contentFirst.innerHTML + contentSecond.innerHTML;

      this.editor.blocks.renderFromHTML(content);
      this.editor.blocks.delete(1);
      this.updatePlayButtons();
    },
  },
};
</script>
<style>
.title-editor .ce-inline-toolbar__toggler-and-button-wrapper > :first-child {
  display: none;
}
.title-editor .ce-paragraph {
  height: 0;
}
.title-editor .ce-toolbar {
  display: none !important;
}
.title-editor.default h1 {
  @apply text-3xl font-bold pt-0 pb-0 m-0 mt-0;
  margin-top: 0px !important;
}
.title-editor.input {
  margin-top: -13px;
}
.title-editor.input .ce-block .play-btn {
  top: -5px;
  left: 2px;
  border-radius: 14px;
  position: absolute !important;
}
.title-editor.input .ce-block__content {
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 39px;
}
.title-editor .codex-editor__redactor {
  padding-bottom: 0px !important;
}
.title-editor .ce-block {
  position: relative;
}
.title-editor .ce-block .ce-header {
  padding-top: 0px;
  margin-top: 1em;
}
.title-editor .ce-block .play-btn {
  top: 2px;
  left: -15px;
  border-radius: 14px;
  position: absolute !important;
  padding-left: 3px;
}
.title-editor .ce-block .play-btn .editor-play {
  display: block;
}
.title-editor .ce-block .play-btn .editor-stop {
  display: none;
  left: 3px;
  top: 2px;
  position: relative;
}
.title-editor .ce-block .play-btn.playing .editor-play {
  display: none;
}
.title-editor .ce-block .play-btn.playing .editor-stop {
  display: block;
}

@media screen and (max-width: 768px) {
  .title-editor .ce-block__content {
    padding-left: 22px;
  }
}
</style>
