<template>
    <div class="bg min-h-screen bg-white flex flex-wrap content-center bg-[../assets/images/add-project-bg.png]">
      <div class="px-6 md:px-6 lg:px-0 pt-12 sm:pt-16 lg:pt-24 mx-auto">
        <!-- title -->
        <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8 flex flex-col">
          <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <p class="text-3xl font-bold text-black sm:text-4xl lg:text-5xl">
              {{$t('ui.paymentPackagesTitle')}}
            </p>
            <p class="text-sm sm:text-xl ">
              {{$t('ui.paymentPackagesDesc')}}
            </p>
          </div>
          <div class="relative mt-6 bg-white border-2 border-black w-auto rounded-lg p-0.5 flex self-center sm:mt-8 ">
            <button type="button" class="relative bg-black border border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-white whitespace-nowrap focus:outline-none sm:w-auto px-3 sm:px-8"
            :class="{'text-white bg-black': isMonthly === 'month', 'text-black bg-white': isMonthly === 'year'}"
            @click="isMonthly = 'month'">{{$t('ui.monthly')}}</button>
            <button type="button" class="ml-0.5 relative border border-transparent rounded-md py-2 w-1/2 text-sm font-medium  whitespace-nowrap focus:outline-none sm:w-auto px-3 sm:px-8"
            :class="{'text-white bg-black': isMonthly === 'year', 'text-black bg-white': isMonthly === 'month'}"
            @click="isMonthly = 'year'">{{$t('ui.yearly')}} <span class="text-xs font-bold bg-green-500 inline-block text-white py-0 px-1 rounded relative -top-px">{{$t('ui.saveTag')}}</span></button>
          </div>
        </div>

        <!-- packages -->
        <div class="mt-8 pb-8 sm:mt-12 lg:mt-16">
          <div class="text-center" v-if="!subscriptions.Starter">
            <svg class="spinner m-auto" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
            </svg>
          </div>
          <div class="relative" v-if="subscriptions.Starter">
            <div class=" mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 max-w-5xl mx-auto xl:grid-cols-3"
              :class="{'opacity-50 pointer-events-none': loading}">
              <Package :name="$t('packages.Starter')" :package="subscriptions.Starter" :originalUnitAmount="699" :desc="$t('packages.starterDesc')" :features="[
                { name: 'packageCharacterLimit', value: ['100 000'] },
                { name: 'extraPackagePrice', value: ['2.49', '10 000'] },
                { name: 'packageUnlimited', value: ['unlimited'] },
                { name: 'license', value: ['starter'] },
              ]" symbol="$" :isMonthly="isMonthly">
                <Button
                  class="block mx-auto"
                  :loading="loading"
                  @click.native="buyPackage(subscriptions.Starter[isMonthly])">{{$t('ui.buy')}} {{$t('packages.packageStarter')}}</Button>
              </Package>
              <Package :name="$t('packages.Business')" :popular="true" :package="subscriptions.Business" :originalUnitAmount="2900" :desc="$t('packages.businessDesc')" :features="[
                { name: 'packageCharacterLimit', value: ['250 000'] },
                { name: 'extraPackagePrice', value: ['1.49', '10 000'] },
                { name: 'packageUnlimited', value: ['unlimited'] },
                { name: 'license', value: ['full'] },
              ]" symbol="$" :isMonthly="isMonthly">
                <Button
                  type="brand"
                  class="block mx-auto"
                  :loading="loading"
                  @click.native="buyPackage(subscriptions.Business[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packageBusiness')}}</Button>
              </Package>
              <Package :name="$t('packages.Premium')" :package="subscriptions.Premium" :originalUnitAmount="9900" :desc="$t('packages.premiumDesc')" :features="[
                { name: 'packageCharacterLimit', value: ['1 000 000'] },
                { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
                { name: 'packageUnlimited', value: ['unlimited'] },
                { name: 'license', value: ['full'] },
              ]" symbol="$" :isMonthly="isMonthly">
                <Button
                  class="block mx-auto"
                  :loading="loading"
                  @click.native="buyPackage(subscriptions.Premium[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packagePremium')}}</Button>
              </Package>
              </div>
          </div>
        </div>
        <div class="text-center mb-2">
          <Button
            class="mx-auto"
            :loading="loading"
            @click.native="showPackageComparision = true">{{$t('ui.packagesCompare')}}</Button>
        </div>
        <Modal :show.sync="showPackageComparision" width="6xl">
          <div slot="header"></div>
          <PackageComparision></PackageComparision>
          <div class="text-center mt-12 mb-5">
            <Button @click.native="showPackageComparision = false">{{$t('ui.ok')}}</Button>
          </div>
        </Modal>
        <div class="" v-show="isSubscriptionActive && isTrial">
          <div class="text-black border-2 border-black rounded-xl bg-white relative md:flex justify-between p-6 mb-4">
            <div>
              <div class="text-xl font-bold">{{$t('ui.contTrialTitle')}}</div>
              <div class="text-sm font-normal">{{$t('ui.contTriallDesc')}}</div>
            </div>
            <div class="flex mt-4 md:mt-0">
              <Button @click.native="goToDashboard">{{$t('ui.contTrial')}}</Button>
              <!-- <router-link to="/subscribe" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-6 py-3 text-sm">{{$t('ui.subscribe')}}</router-link> -->
            </div>
          </div>
        </div>
        <div class="text-hm-blue cursor-pointer mx-auto mb-5 p-2 text-sm flex justify-center items-center space-x-2"
          v-show="!isSubscriptionActive"
          @click="goToDashboard">
          <span>{{$t('ui.skipToDashboard')}}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="8.118" height="13.407" viewBox="0 0 8.118 13.407">
            <g id="Group_5369" data-name="Group 5369" transform="translate(-8.21 18.409) rotate(-90)">
              <path id="Path_43237" data-name="Path 43237" d="M10,15l5.289,5.289L20.579,15" transform="translate(-3.584 -5.376)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="steps hidden sm:block sm:fixed left-3 top-auto h-screen">
        <div class="flex items-center h-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="397" viewBox="0 0 29 397">
            <g id="steps" transform="translate(-24 -311)">
              <g id="Ellipse_91431" data-name="Ellipse 91431" transform="translate(24 311)" stroke="#000" stroke-width="2">
                <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
                <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
              </g>
              <g id="Ellipse_91432" data-name="Ellipse 91432" transform="translate(24 451)" fill="#000" stroke="#000" stroke-width="2" opacity="1">
                <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
                <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
              </g>
              <g id="Ellipse_91433" data-name="Ellipse 91433" transform="translate(24 601)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
                <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
                <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
              </g>
              <text id="Account" transform="translate(43 403) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500"><tspan x="0" y="0">Account</tspan></text>
              <text id="Payment" transform="translate(43 546) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="1"><tspan x="0" y="0">Payment</tspan></text>
      isMonthly: 'month',        <text id="Dashboard" transform="translate(43 708) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Dashboard</tspan></text>
              <text id="_1" data-name="1" transform="translate(35 331)" fill="#fff" font-size="14" font-family="Inter" font-weight="700"><tspan x="0" y="0">1</tspan></text>
              <text id="_2" data-name="2" transform="translate(34 471)" fill="#fff" font-size="14" font-family="Inter" font-weight="700" opacity="1"><tspan x="0" y="0">2</tspan></text>
              <text id="_3" data-name="3" transform="translate(34 621)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">3</tspan></text>
              <line id="Line_273" data-name="Line 273" y2="29" transform="translate(37.5 411.5)" fill="none" stroke="#000" stroke-width="2" opacity="1"/>
              <line id="Line_274" data-name="Line 274" y2="29" transform="translate(37.5 560)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  data() {
    return {
      loading: true,
      isMonthly: 'month',
      showPackageComparision: false,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
      addWebsiteModel: (state) => state.website.addWebsiteModel,
      pluginLink: (state) => state.utils.pluginUrl,
      prices: (state) => state.billing.prices,
      subscriptions: (state) => state.billing.subscriptions,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      selectedWebsite: (state) => state.website.selectedWebsite,
    }),
    isTrial() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Trial';
    },
    isSubscriptionActive() {
      return this.subscriptionStatus && this.subscriptionStatus.active;
    },
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  created() {
    this.setSidebarVisibility(false);
  },
  async mounted() {
    this.setSidebarVisibility(false);
    if (!this.subscriptions) await this.fetchPrices();
    console.log('subs => ', this.subscriptions);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchPrices: 'billing/fetchPrices',
      getPaymentLink: 'billing/getPaymentLink',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),

    async buyPackage(subPackage) {
      this.loading = true;
      console.log('buyPackage', subPackage, this.customerId);
      const { product, id } = subPackage;
      const domain = `https://${window.location.host}/articles/${this.selectedWebsite.id}/list`;
      const data = {
        customer: this.customerId,
        domain,
        productId: product.id,
        priceId: id,
      };

      // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
      // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

      const result = await this.getPaymentLink(data);
      if (!result.url) return;
      window.location.replace(result.url);
      this.loading = false;
      console.log('payment link result', data, result);
    },
    goToDashboard() {
      this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list` });
    },
  },
};
</script>
