/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = () => ({
  name: 'Alias',
  list: [],
  listGlobal: [],
  recommendations: [],
  hasNextPage: true,
  pageInfo: {},
  loadings: {},
  recommendationsHelper: {},
  count: 0,
});

const mutations = {
  setList: (state, payload) => {
    Vue.set(state, 'list', payload);
  },
  setGlobal: (state, payload) => {
    const list = payload.map((el) => {
      // console.log('el', el);
      const newEl = {
        ...el,
      };
      if (!el.platforms) newEl.platforms = [];
      return newEl;
    });
    Vue.set(state, 'listGlobal', list);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setRecommendations: (state, payload) => {
    Vue.set(state, 'recommendations', payload);
    const recommendationHelper = {};

    payload.forEach((element) => {
      const key = element.feedId + element.alias + element.originalText;
      recommendationHelper[key] = {
        showAdded: false,
      };
    });

    Vue.set(state, 'recommendationsHelper', recommendationHelper);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
  clearState: (state) => {
    Vue.set(state, 'billingHistory', []);
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
    Vue.set(state, 'count', 0);
  },
  clearPagin: (state) => {
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
  },
};

const actions = {
  async fetchRecommendations({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetFeedRecommendations, {
          input: payload,
        }),
      );
      const { data } = response.data.getFeedRecommendations;
      commit('setRecommendations', data);
      return data;
    } catch (error) {
      return { ok: false, message: error };
    }
  },
  async fetch({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllTtsAliases, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allTtsAliases;
      commit('setList', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      return nodes;
    } catch (error) {
      return { ok: false, message: error };
    }
  },
  async fetchGlobal({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllTtsAliasesGlobals, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allTtsAliasesGlobals;
      commit('setGlobal', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      return nodes;
    } catch (error) {
      return { ok: false, message: error };
    }
  },
  async createAlias({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateTtsAlias, {
          input: {
            ttsAlias: payload,
          },
        }),
      );
      const { data } = response;
      return data.createTtsAlias;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'alias', value: false });
      }, 300);
    }
  },
  async saveTtsAlias({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.SaveTtsAlias, {
          input: payload,
        }),
      );
      const { data } = response;
      return data.saveTtsAlias;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'alias', value: false });
      }, 300);
    }
  },
  async createAliasGlobal({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateTtsAliasesGlobal, {
          input: {
            ttsAliasesGlobal: payload,
          },
        }),
      );
      const { data } = response;
      // return data.createTtsAliasesGlobal;
      return { ok: true, data: data.createTtsAliasesGloba };
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'alias', value: false });
      }, 300);
    }
  },
  async deleteAlias({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteTtsAlias, {
          input: {
            nodeId: payload,
          },
        }),
      );
      const { data } = response;
      return data.deleteTtsAlias;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
  async deleteAliasGlobal({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteTtsAliasesGlobal, {
          input: {
            nodeId: payload,
          },
        }),
      );
      const { data } = response;
      return data.deleteTtsAliasesGlobal;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
  async playGlobal({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.PlayGlobalAlias, {
          input: {
            originalText: payload.originalText,
            alias: payload.alias,
            language: payload.language,
            feedId: payload.feedId,
            episodeId: payload.episodeId,
          },
        }),
      );
      const { data } = response;
      return data.playGlobalAlias;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
  async playAlias({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.PlayCustomerAlias, {
          input: payload,
        }),
      );
      const { data } = response;
      return data.playCustomerAlias;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
  async updateTtsAlias({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.UpdateEditorAliasGenerationUsageByOriginalTextAndFeedIdAndVoiceAndCustomerId, {
          input: payload,
        }),
      );
      const { data } = response;
      return data.updateEditorAliasGenerationUsageByOriginalTextAndFeedIdAndVoiceAndCustomerId;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
};

export default {
  namespaced: true,
  state: state(),
  mutations,
  actions,
};
