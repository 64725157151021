/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Utlities',
  customerList: [],
  couponList: [],
  userList: [],
  regionList: [],
  hasNextPage: true,
  pageInfo: {},
  voiceList: [],
  notifiactionList: [],
  notifiactionsByFeedId: {},
  languageList: [],
  pluginUrl: '',
  imageGenerationCost: 0,
  aiGenerationCost: {
    'pl-PL': [
      {
        length: 'short',
        cost: 51200,
      },
      {
        length: 'medium',
        cost: 102400,
      },
      {
        length: 'long',
        cost: 204800,
      },
      {
        length: 'veryLong',
        cost: 409600,
      },
    ],
    'en-US': [
      {
        length: 'short',
        cost: 51200,
      },
      {
        length: 'medium',
        cost: 102400,
      },
      {
        length: 'long',
        cost: 204800,
      },
      {
        length: 'veryLong',
        cost: 409600,
      },
    ],
    'en-GB': [
      {
        length: 'short',
        cost: 51200,
      },
      {
        length: 'medium',
        cost: 102400,
      },
      {
        length: 'long',
        cost: 204800,
      },
      {
        length: 'veryLong',
        cost: 409600,
      },
    ],
    'uk-UA': [
      {
        length: 'short',
        cost: 102400,
      },
      {
        length: 'medium',
        cost: 204800,
      },
      {
        length: 'long',
        cost: 409600,
      },
      {
        length: 'veryLong',
        cost: 819200,
      },
    ],
    'de-DE': [
      {
        length: 'short',
        cost: 204800,
      },
      {
        length: 'medium',
        cost: 409600,
      },
      {
        length: 'long',
        cost: 819200,
      },
      {
        length: 'veryLong',
        cost: 1638400,
      },
    ],
    'cmn-CN': [
      {
        length: 'short',
        cost: 409600,
      },
      {
        length: 'medium',
        cost: 819200,
      },
      {
        length: 'long',
        cost: 1638400,
      },
      {
        length: 'veryLong',
        cost: 3276800,
      },
    ],
    'es-ES': [
      {
        length: 'short',
        cost: 819200,
      },
      {
        length: 'medium',
        cost: 1638400,
      },
      {
        length: 'long',
        cost: 3276800,
      },
      {
        length: 'veryLong',
        cost: 6553600,
      },
    ],
    'fr-FR': [
      {
        length: 'short',
        cost: 1638400,
      },
      {
        length: 'medium',
        cost: 3276800,
      },
      {
        length: 'long',
        cost: 6553600,
      },
      {
        length: 'veryLong',
        cost: 13107200,
      },
    ],
    'pt-PT': [
      {
        length: 'short',
        cost: 3276800,
      },
      {
        length: 'medium',
        cost: 6553600,
      },
      {
        length: 'long',
        cost: 13107200,
      },
      {
        length: 'veryLong',
        cost: 26214400,
      },
    ],
  },
  defaultVoices: {
    'de-DE': {
      contentVoice: {
        ordinalNumber: '9',
        ttsVoiceName: 'de-DE-Wavenet-E',
        ttsPlatform: 'google-tts',
        displayName: 'Elias',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'google-tts-de-DE-Wavenet-E-de-DE.mp3',
        mainLanguage: 'de-DE',
        gender: 'male',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:06.439959',
        updatedAt: '2022-01-04T10:38:06.439959',
        ttsVoiceKind: null,
      },
      titleVoice: {
        ordinalNumber: '2',
        ttsVoiceName: 'de-DE-ConradNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Conrad',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-de-DE-ConradNeural-de-DE.mp3',
        mainLanguage: 'de-DE',
        gender: 'male',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:10.501231',
        updatedAt: '2022-01-04T10:38:10.501231',
        ttsVoiceKind: null,
      },
    },
    'en-GB': {
      contentVoice: {
        ordinalNumber: '7',
        ttsVoiceName: 'en-GB-RyanNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Ryan',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-en-GB-RyanNeural-en-GB.mp3',
        mainLanguage: 'en-GB',
        gender: 'male',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:10.148946',
        updatedAt: '2022-01-04T10:38:10.148946',
        ttsVoiceKind: null,
      },
      titleVoice: {
        ordinalNumber: '6',
        ttsVoiceName: 'en-GB-LibbyNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Libby',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-en-GB-LibbyNeural-en-GB.mp3',
        mainLanguage: 'en-GB',
        gender: 'female',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:10.060793',
        updatedAt: '2022-01-04T10:38:10.060793',
        ttsVoiceKind: null,
      },
    },
    'en-US': {
      contentVoice: {
        ordinalNumber: '6',
        ttsVoiceName: 'Matthew',
        ttsPlatform: 'amazon-polly',
        displayName: 'Matthew Conversational',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'amazon-polly-Matthew-conversational-en-US.mp3',
        mainLanguage: 'en-US',
        gender: 'male',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:02.369263',
        updatedAt: '2022-01-04T10:38:02.369263',
        ttsVoiceKind: 'conversational',
      },
      titleVoice: {
        ordinalNumber: '3',
        ttsVoiceName: 'Joanna',
        ttsPlatform: 'amazon-polly',
        displayName: 'Joanna Newscaster',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'amazon-polly-Joanna-news-en-US.mp3',
        mainLanguage: 'en-US',
        gender: 'female',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:01.953451',
        updatedAt: '2022-01-04T10:38:01.953451',
        ttsVoiceKind: 'news',
      },
    },
    'uk-UA': {
      contentVoice: {
        ordinalNumber: '0',
        ttsVoiceName: 'uk-UA-OstapNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Остап',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-uk-UA-OstapNeural-uk-UA.mp3',
        mainLanguage: 'uk-UA',
        gender: 'male',
        tags: null,
        metadata: null,
        createdAt: '2022-03-01T14:50:58.819',
        updatedAt: '2022-03-01T14:50:58.819',
        ttsVoiceKind: null,
      },
      titleVoice: {
        ordinalNumber: '0',
        ttsVoiceName: 'uk-UA-PolinaNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Поліна',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-uk-UA-PolinaNeural-uk-UA.mp3',
        mainLanguage: 'uk-UA',
        gender: 'female',
        tags: null,
        metadata: null,
        createdAt: '2022-03-01T14:51:02.21',
        updatedAt: '2022-03-01T14:51:02.21',
        ttsVoiceKind: null,
      },
    },
    'pl-PL': {
      contentVoice: {
        ordinalNumber: '2',
        ttsVoiceName: 'pl-PL-MarekNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Marek',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-pl-PL-MarekNeural-pl-PL.mp3',
        mainLanguage: 'pl-PL',
        gender: 'male',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:07.152775',
        updatedAt: '2022-01-04T10:38:07.152775',
        ttsVoiceKind: null,
      },
      titleVoice: {
        ordinalNumber: '1',
        ttsVoiceName: 'pl-PL-AgnieszkaNeural',
        ttsPlatform: 'azure-speech',
        displayName: 'Agnieszka',
        ttsVoiceEngine: 'neural',
        sampleFilename: 'azure-speech-pl-PL-AgnieszkaNeural-pl-PL.mp3',
        mainLanguage: 'pl-PL',
        gender: 'female',
        tags: null,
        metadata: null,
        createdAt: '2022-01-04T10:38:07.064049',
        updatedAt: '2022-01-04T10:38:07.064049',
        ttsVoiceKind: null,
      },
    },
  },
};

const mutations = {
  setAppSettings: (state, payload) => {
    console.log('getAppSettings', state, payload);
    const {
      supportedLanguages,
      pluginUrl,
      aiGenerationCost,
      imageGenerationCost,
    } = payload;
    Vue.set(state, 'languageList', supportedLanguages);
    Vue.set(state, 'pluginUrl', pluginUrl);
    Vue.set(state, 'aiGenerationCost', aiGenerationCost);
    Vue.set(state, 'imageGenerationCost', imageGenerationCost);
    localStorage.setItem('imageGenerationCost', imageGenerationCost);
  },
  setVoices: (state, payload) => {
    const sorted = payload.sort((a, b) => a.ordinalNumber - b.ordinalNumber);
    const groups = sorted.reduce((groups, item) => ({
      ...groups,
      [item.mainLanguage]: [...(groups[item.mainLanguage] || []), item],
    }), {});

    console.log('voice list', payload, groups);
    Vue.set(state, 'voiceList', payload);
    Vue.set(state, 'defaultVoices', groups);
  },
  setLanguages: (state, payload) => {
    Vue.set(state, 'languageList', payload);
  },
  setCouponList: (state, payload) => {
    Vue.set(state, 'couponList', payload);
  },
  setNotifications: (state, payload) => {
    const notificationsWithoutMetadata = payload
      .filter((item) => !item.metadata);

    Vue.set(state, 'notifiactionList', notificationsWithoutMetadata);
    const byFeedId = {};
    payload
      .filter((item) => item.metadata)
      .forEach((item) => {
        // console.log('xxxxxx', item);
        const hasFeedId = item.metadata.feedId;

        // console.log('xxxxxx', hasFeedId, item);
        if (!byFeedId[item.metadata.feedId]) {
          byFeedId[item.metadata.feedId] = [];
        }
        if (hasFeedId) byFeedId[item.metadata.feedId].push(item);
      });

    console.log('notificationsWithoutMetadata', notificationsWithoutMetadata);
    Vue.set(state, 'notifiactionsByFeedId', byFeedId);
  },
  setUserList: (state, payload) => {
    Vue.set(state, 'userList', payload);
  },
  setRegionList: (state, payload) => {
    Vue.set(state, 'regionList', payload);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
  clearPagin: (state) => {
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
  },
};

const actions = {
  async fetchLanguages({ commit }) {
    try {
      // debugger;
      const response = await API.graphql(
        graphqlOperation(GQL.AllLanguages),
      );
      const { supportedLanguages } = response.data;
      commit('setLanguages', supportedLanguages.languages);
      return supportedLanguages.languages;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async fetchVoices({ commit }, payload) {
    try {
      // debugger;
      const response = await API.graphql(
        graphqlOperation(GQL.AllVoices, {
          input: {
            orderBy: 'ORDINAL_NUMBER_ASC',
            filter: {
              mainLanguage: {
                equalTo: payload,
              },
            },
          },
        }),
      );
      const { allVoices } = response.data;
      commit('setVoices', allVoices.nodes);
      return allVoices;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async fetchNotifications({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllNotifications, {
          input: {
            first: 125,
            orderBy: 'CREATED_AT_DESC',
          },
        }),
      );
      const { allNotifications } = response.data;
      commit('setNotifications', allNotifications.nodes);
      return allNotifications;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async markNotificationsAsRead({ commit }, payload) {
    const input = {
      readAt: new Date(),
    };

    if (payload) {
      input.notifIds = payload;
    }
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.MarkNotificationsAsRead, {
          input,
        }),
      );
      const { data } = response;
      commit('setLoadings', { type: 'dummy', value: false });
      return data;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async getVersion() {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetVersion),
      );
      const { getVersion } = response.data;
      return getVersion;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async getAppSettings({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.BuildAndVersionData),
      );
      const { buildAndVersionData } = response.data;
      commit('setAppSettings', buildAndVersionData);
      return buildAndVersionData;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
