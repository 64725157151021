<template>
  <div class="bg min-h-screen bg-white flex flex-wrap content-center bg-[../assets/images/add-project-bg.png]">
    <div class="max-w-xl mx-auto" v-show="!showVoiceSettings">
      <div class="bg-white overflow-hidden rounded-lg" >
        <div class="px-4 py-5 sm:p-6">
          <div class="mt-2 space-y-2 p-4 text-center">
            <h1 class="font-bold text-4xl">{{$t('ui.addBlogVoiceSettingsTitle')}}</h1>
            <p class=" text-1xl text-black">{{$t('ui.addBlogVoiceSettingsDescLanguage')}} </p>
            <form class="mb-6 max-w-xs mt-5 mx-auto" v-on:submit.prevent="updateLanguage">
              <!-- <label for="email" class="text-left block text-sm font-medium text-gray-700 mt-5">{{$t('ui.checkLanguageLabel')}}</label> -->
              <div class="mt-5 mb-8">
                <div class="mt-1 relative">
                  <select
                    v-model="websiteLanguage"
                    id="language" name="language" autocomplete="language" class="mt-1 w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-black border-2">
                    <option v-for="lang in languageList" :key="lang" :value="lang">{{$t('languages.' + lang)}}</option>
                  </select>
                </div>
                <div class="message text-red-500 text-xs mt-1 text-right" v-if="noLanguageError">
                  {{$t('ui.noLanguageError')}}
                </div>
                <div v-if="createError" class="message text-red-500 text-sm mt-1 mb-1 ">
                  {{createError}}
                </div>
              </div>
              <Button
                class="block w-full"
                :disabled="!isLanguageValid"
                :loading="loading"
                @click.native="showSettings">{{$t('ui.continue')}}</Button>
            </form>
          </div>
          <router-link to="/add-project/name/website" class="mx-auto text-sm -xs block text-center text-hm-blue cursor-pointer mt-15" v-show="!isProject">
            {{$t('ui.goBack')}}
          </router-link>
          <router-link to="/add-project/name/project" class="mx-auto text-sm -xs block text-center text-hm-blue cursor-pointer " v-show="isProject">
            {{$t('ui.goBack')}}
          </router-link>
        </div>
      </div>
    </div>

    <div class="max-w-3xl mx-auto w-full" v-show="showVoiceSettings">
      <div class="mt-2 space-y-2 p-4 text-center">
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto" width="64.991" height="74.359" viewBox="0 0 64.991 74.359">
            <g id="Group_5651" data-name="Group 5651" transform="translate(-1.51 0.021)">
              <path id="Path_43758" data-name="Path 43758" d="M17.293.043a7.7,7.7,0,0,0-5.135,1.145A5.97,5.97,0,0,0,10.271,6.54,5.352,5.352,0,0,0,14.88,11.4c-.155,5.2-.34,10.394-.464,15.622V45.423c0,5.847.186,11.693.278,17.509a5.413,5.413,0,0,0-2.877,1.083,5.908,5.908,0,0,0-1.763,5.259A5.6,5.6,0,0,0,16.4,74.285,6.187,6.187,0,0,0,21.345,65a5.321,5.321,0,0,0-4.114-2.011c.309-5.816.619-11.631.835-17.478,0-3.093.155-6.187.186-9.28s0-6.187,0-9.28c-.155-5.259-.495-10.425-.8-15.622,7.641-1.3,4.826-8.538,4.331-9.28A5.29,5.29,0,0,0,17.293.043Zm1.825,66.539c.65.711,2.475,4.764-2.784,4.485-1.237,0-2.537-.65-2.537-2.073s0-1.794.495-2.2,3.929-1.175,4.826-.186ZM14.2,6.23c0-1.083,0-1.794.464-2.165a4.052,4.052,0,0,1,2.629-.711c1.021,0,1.763,0,2.227.464C23.7,8.55,14.2,9.973,14.2,6.23Z" transform="translate(17.718 0)" fill="#fea900" fill-rule="evenodd"/>
              <path id="Path_43759" data-name="Path 43759" d="M5.129,18.814c-.371-3.8-.928-7.486-1.206-11.384a1.021,1.021,0,0,0-.9-1.021A1.021,1.021,0,0,0,2,7.431c-.309,4.424-.588,8.816-.464,13.24,0,2.629.309,5.259.557,7.857.371,3.929.928,7.8,1.268,11.724A1.175,1.175,0,0,0,4.572,41.4,1.206,1.206,0,0,0,5.716,40.19c0-3.867.186-7.7,0-11.569A43.574,43.574,0,0,0,5.531,23.7C5.345,22.063,5.314,20.609,5.129,18.814Z" transform="translate(0 13.462)" fill="#191919" fill-rule="evenodd"/>
              <path id="Path_43760" data-name="Path 43760" d="M7.5,9.351A1.021,1.021,0,0,0,6.476,8.33.99.99,0,0,0,5.455,9.351c-.278,1.98-.681,3.9-.835,5.908V17.8a13.973,13.973,0,0,0,.155,2.537c.186,1.98.526,3.929.8,5.908a1.176,1.176,0,0,0,2.351-.062c0-1.98.433-3.929.557-5.908V17.765a22.83,22.83,0,0,0,0-2.537C8.177,13.249,7.775,11.331,7.5,9.351Z" transform="translate(6.51 17.481)" fill="#191919" fill-rule="evenodd"/>
              <path id="Path_43761" data-name="Path 43761" d="M10.679,5.51a1.052,1.052,0,0,0-2.073,0c-.278,5.166-.681,10.332-.866,15.467V34.248c0,5.2.433,10.363.681,15.467A1.175,1.175,0,0,0,9.6,50.89a1.145,1.145,0,0,0,1.145-1.175c.217-5.166.526-10.332.681-15.467V20.977C11.328,15.842,10.926,10.676,10.679,5.51Z" transform="translate(13.041 9.753)" fill="#191919" fill-rule="evenodd"/>
              <path id="Path_43762" data-name="Path 43762" d="M18.715,18.374c-.371-3.8-.835-7.548-1.206-11.353A.99.99,0,0,0,16.488,6a1.052,1.052,0,0,0-1.021,1.052c-.247,4.362-.557,8.723-.526,13.116,0,2.629.217,5.228.4,7.826.247,3.9.681,7.764.866,11.693a1.145,1.145,0,0,0,1.145,1.175,1.175,1.175,0,0,0,1.175-1.145c.247-3.836.495-7.672.526-11.507V23.292C19.056,21.653,18.839,19.89,18.715,18.374Z" transform="translate(28.112 12.603)" fill="#191919" fill-rule="evenodd"/>
              <path id="Path_43763" data-name="Path 43763" d="M20.842,8.32a.99.99,0,0,0-1.021-.99A1.021,1.021,0,0,0,18.8,8.382c-.217,2.691-.557,5.352-.681,8.074V19.92a26.207,26.207,0,0,0,.155,3.465c.155,2.691.433,5.352.65,8.043a1.175,1.175,0,1,0,2.32,0c.278-2.691.557-5.382.711-8.1V19.859a26.674,26.674,0,0,0-.155-3.5A80.217,80.217,0,0,0,20.842,8.32Z" transform="translate(34.771 15.388)" fill="#191919" fill-rule="evenodd"/>
              <path id="Path_43764" data-name="Path 43764" d="M25.023,15.826a45.885,45.885,0,0,0-.866-6.434,1.021,1.021,0,0,0-.99-1.052,1.052,1.052,0,0,0-1.052,1.052c-.278,2.165-.65,4.269-.835,6.465V18.61a16.442,16.442,0,0,0,.155,2.753c.155,2.165.433,4.269.588,6.434A1.237,1.237,0,0,0,23.2,29a1.175,1.175,0,0,0,1.175-1.145c.247-2.165.588-4.3.742-6.5V18.61C25.116,17.682,25.116,16.754,25.023,15.826Z" transform="translate(41.386 17.502)" fill="#191919" fill-rule="evenodd"/>
            </g>
          </svg>

        </div>
        <h1 class="font-bold text-4xl">{{$t('ui.addBlogVoiceSettingsTitle')}}</h1>
        <p class=" text-1xl text-black">{{$t('ui.addBlogVoiceSettingsDesc')}}</p>
      </div>
      <div class="text-xs my-6 mx-auto text-center">
          {{$t('ui.addBlogSelectedLanguage')}}:
          <select
            v-model="websiteLanguage"
            @change="clearSelectedVoices"
            id="language" name="language" autocomplete="language" class="border-0 p-0 w-auto font-bold text-xs">
            <option v-for="lang in languageList" :key="lang" :value="lang">{{$t('languages.' + lang)}}</option>
          </select>
      </div>
      <div class="md:flex w-full flex-row items-center space-x-8">
        <!-- Title voice -->
        <div class="p-6 flex w-1/3 bg-white flex-col h-64 border-2 border-black rounded-xl justify-between">
          <div class="text-center">
            <div>
              <TitleVoiceIcon></TitleVoiceIcon>
            </div>
            <div class="mt-4 font-bold text-lg">{{ $t('ui.addBlogTitleVoice') }}</div>
            <div class=" text-xs">{{ $t('ui.addBlogTitleVoiceDesc') }}</div>
          </div>
          <div class="">
            <!-- VOICE DROPDOWN -->
            <Dropdown class="min-w-full text-xs">
              <div class="flex items-center space-x-2">
                <PlayButton
                  v-show="titleVoice.displayName"
                  @click.stop.native="previewAudio(titleVoice, -1)" :isPlaying="playing === -1" ></PlayButton>
                <div class="pointer-events-none">{{titleVoice.displayName || titleVoice.name || $t('ui.notSelected')}}</div>
              </div>
              <template slot="items">
                <div class="text-xs px-4 py-2  flex justify-between">{{ $t('ui.voices') }}</div>
                <div
                  class="px-4 py-2  text-xs flex justify-between"
                  v-for="(item, index) in filteredVoices" :key="item.createdAt">
                  <div class="flex space-x-2 items-center ">
                    <PlayButton @click.native="previewAudio(item, index)" :size="'sm'" :isPlaying="index === playing"></PlayButton>
                    <span class="cursor-pointer" @click="titleVoice = item">{{item.displayName || item.name}}</span>
                  </div>
                  <span class="space-x-2 flex items-center pursor-pointer" @click="titleVoice = item">
                    <span class="text-[11px] font-bold bg-yellow-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural' || item.ttsVoiceEngine === 'neural 2' || item.ttsVoiceEngine === 'neural news'">HQ</span>
                    <span class="text-[11px] font-bold bg-purple-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural news'">NEWS</span>
                    <span class="text-[11px] font-bold inline-block text-white py-0 px-1 rounded"
                    :class="{'bg-green-300 text-green-800': item.gender === 'female', 'bg-blue-300 text-blue-800': item.gender === 'male'}">{{$t(`ui.${item.gender}`)}}</span>
                  </span>
                </div>
              </template>
            </Dropdown>
            <!-- <div class="border-black rounded-md border-2 p-2 flex justify-between items-center">
              <div class="flex items-center space-x-2">
                <div
                  v-show="titleVoice.displayName"
                  @click="previewAudio(titleVoice, -1)" class="play-pause rounded-md h-9 w-9 flex justify-center align-middle relative items-center cursor-pointer"
                  :class="{'bg-hm-brand text-black': playing === -1, 'bg-black text-white': playing !== -1}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" width="12" height="15.999" viewBox="0 0 12 15.999">
                    <g id="triangle-right" transform="translate(0 0)">
                      <path id="Path_43134" data-name="Path 43134" d="M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z" transform="translate(-2 0)" fill="currentColor"/>
                    </g>
                  </svg>
                </div>
                <div>{{titleVoice.displayName || titleVoice.name || $t('ui.notSelected')}}</div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
                <path id="Path_43302" data-name="Path 43302" d="M10,3a1,1,0,0,1,.707.293l3,3a1,1,0,1,1-1.414,1.414L10,5.414,7.707,7.707A1,1,0,1,1,6.293,6.293l3-3A1,1,0,0,1,10,3ZM6.293,12.293a1,1,0,0,1,1.414,0L10,14.586l2.293-2.293a1,1,0,1,1,1.414,1.414l-3,3a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,0-1.414Z" transform="translate(-6 -3)" fill-rule="evenodd"/>
              </svg>
            </div> -->
          </div>
        </div>
        <!-- Content voice -->
        <div class="p-6 flex w-1/3 bg-white flex-col h-64 border-2 border-black rounded-xl justify-between">
          <div class="text-center">
            <div>
              <ContentVoiceIcon></ContentVoiceIcon>
            </div>
            <div class="mt-4 font-bold text-lg">{{ $t('ui.addBlogContentVoice') }}</div>
            <div class=" text-xs">{{ $t('ui.addBlogContentVoiceDesc') }}</div>
          </div>
          <div class="">
            <!-- VOICE DROPDOWN -->
            <Dropdown class="min-w-full text-xs">
              <div class="flex items-center space-x-2">
                <PlayButton
                  v-show="contentVoice.displayName"
                  @click.stop.native="previewAudio(contentVoice, -2)" :isPlaying="playing === -2" ></PlayButton>
                <div class="pointer-events-none">{{contentVoice.displayName || contentVoice.name || $t('ui.notSelected')}}</div>
              </div>
              <template slot="items">
                <div class="text-xs px-4 py-2  flex justify-between">{{ $t('ui.voices') }}</div>
                <div class="px-4 py-2  text-xs flex justify-between"
                  v-for="(item, index) in filteredVoices" :key="item.createdAt">
                  <div class="flex space-x-2 items-center ">
                    <PlayButton @click.native="previewAudio(item, index)" :size="'sm'" :isPlaying="index === playing"></PlayButton>
                    <span class="cursor-pointer"
                     @click="contentVoice = item">{{item.displayName || item.name}}</span>
                  </div>
                  <span class="space-x-2 flex items-center cursor-pointer"
                    @click="contentVoice = item">
                    <span class="text-[11px] font-bold bg-yellow-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural' || item.ttsVoiceEngine === 'neural 2' || item.ttsVoiceEngine === 'neural news'">HQ</span>
                    <span class="text-[11px] font-bold bg-purple-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural news'">NEWS</span>
                    <span class="text-[11px] font-bold inline-block text-white py-0 px-1 rounded"
                    :class="{'bg-green-300 text-green-800': item.gender === 'female', 'bg-blue-300 text-blue-800': item.gender === 'male'}">{{$t(`ui.${item.gender}`)}}</span>
                  </span>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
        <!-- Alternative Content voice -->
        <div class="p-6 flex w-1/3 bg-white flex-col h-64 border-2 border-black rounded-xl justify-between">
          <div class="text-center">
            <div>
              <AlternativeVoiceIcon></AlternativeVoiceIcon>
            </div>
            <div class="mt-4 font-bold text-lg">{{ $t('ui.addBlogAlternativeVoice') }}</div>
            <div class=" text-xs">{{ $t('ui.addBlogAlternativeVoiceDesc') }}</div>
          </div>
          <div class="">
            <!-- VOICE DROPDOWN -->
            <Dropdown class="min-w-full text-xs">
              <div class="flex items-center space-x-2">
                <PlayButton
                  v-show="contentAlternativeVoice.displayName"
                  @click.stop.native="previewAudio(contentAlternativeVoice, -3)" :isPlaying="playing === -3" ></PlayButton>
                <div class="pointer-events-none">{{contentAlternativeVoice.displayName || contentAlternativeVoice.name || $t('ui.notSelected')}}</div>
              </div>
              <template slot="items">
                <div class="text-xs px-4 py-2  flex justify-between">{{ $t('ui.voices') }}</div>
                <div
                  class="px-4 py-2  text-xs flex justify-between"
                  v-for="(item, index) in filteredVoices" :key="item.createdAt">
                  <div class="flex space-x-2 items-center ">
                    <PlayButton @click.native="previewAudio(item, index)" :size="'sm'" :isPlaying="index === playing"></PlayButton>
                    <span class="cursor-pointer" @click="contentAlternativeVoice = item">{{item.displayName || item.name}}</span>
                  </div>
                  <span class="space-x-2 flex items-center cursor-pointer" @click="contentAlternativeVoice = item">
                    <span class="text-[11px] font-bold bg-yellow-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural' || item.ttsVoiceEngine === 'neural 2' || item.ttsVoiceEngine === 'neural news'">HQ</span>
                    <span class="text-[11px] font-bold bg-purple-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural news'">NEWS</span>
                    <span class="text-[11px] font-bold inline-block text-white py-0 px-1 rounded"
                    :class="{'bg-green-300 text-green-800': item.gender === 'female', 'bg-blue-300 text-blue-800': item.gender === 'male'}">{{$t(`ui.${item.gender}`)}}</span>
                  </span>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
      <div class="text-center mx-auto mt-4">
        <div class="hidden">
          <audio ref="player" @ended="clearPlayer" controls="controls" id="audioPreview" style="display:none">
            <source :src="url" type="audio/mp3" />
          </audio>
        </div>
        <Button
          :disabled="!contentVoice.displayName || !titleVoice.displayName"
          :loading="loading"
          @click.native="updateLanguage"
          class="md:mt-6 mx-auto min-w-[250px]"
          >{{$t('ui.addBlogContinue')}}</Button>
      </div>
    </div>
    <div class="steps hidden sm:block sm:fixed left-3 top-auto h-screen">
      <div class="flex items-center h-full">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="397" viewBox="0 0 29 397">
          <g id="steps" transform="translate(-24 -311)">
            <g id="Ellipse_91431" data-name="Ellipse 91431" transform="translate(24 311)" stroke="#000" stroke-width="2">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <g id="Ellipse_91432" data-name="Ellipse 91432" transform="translate(24 451)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <g id="Ellipse_91433" data-name="Ellipse 91433" transform="translate(24 601)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <text id="Account" transform="translate(43 403) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500"><tspan x="0" y="0">Account</tspan></text>
            <text id="Payment" transform="translate(43 546) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Payment</tspan></text>
            <text id="Dashboard" transform="translate(43 708) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Dashboard</tspan></text>
            <text id="_1" data-name="1" transform="translate(35 331)" fill="#fff" font-size="14" font-family="Inter" font-weight="700"><tspan x="0" y="0">1</tspan></text>
            <text id="_2" data-name="2" transform="translate(34 471)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">2</tspan></text>
            <text id="_3" data-name="3" transform="translate(34 621)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">3</tspan></text>
            <line id="Line_273" data-name="Line 273" y2="29" transform="translate(37.5 411.5)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
            <line id="Line_274" data-name="Line 274" y2="29" transform="translate(37.5 560)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      loading: false,
      createError: null,
      websiteLanguage: 'en-US',
      showVoiceSettings: false,
      token: false,
      noLanguageError: false,
      saveError: null,
      createSiteInput: {
      },

      titleVoice: {},
      contentVoice: {},
      contentAlternativeVoice: {},

      selected: {},

      voiceFilter: {
        gender: 'all',
        style: 'all',
      },

      genderList: [
        'all',
        'female',
        'male',
      ],

      // player
      show: false,
      url: null,
      playing: null,

      searchQuery: '',
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
      addWebsiteModel: (state) => state.website.addWebsiteModel,
      pluginLink: (state) => state.utils.pluginUrl,
      selectedWebsite: (state) => state.website.selectedWebsite,
      currentChannel: (state) => state.website.selectedWebsitePodcast,
      languageList: (state) => state.utils.languageList,
      voices: (state) => state.utils.voiceList,
      defaultVoices: (state) => state.utils.defaultVoices,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
    }),
    isLanguageValid() {
      return this.websiteLanguage !== null;
    },
    type() {
      return this.$route.params.type;
    },
    isProject() {
      return this.type === 'project';
    },
    isSubscriptionActive() {
      return this.subscriptionStatus && this.subscriptionStatus.active && this.subscriptionStatus.plan !== 'Trial';
    },
    voiceId() {
      return (item) => {
        const kind = item.ttsVoiceKind ? `-${item.ttsVoiceKind}` : '';
        return `${item.ttsVoiceName}-${item.mainLanguage}${kind}`;
      };
    },
    voiceUrl() {
      return (voice) => {
        const url = `${this.$appConfig.samplesUrl}/${voice.sampleFilename}`;
        return url;
      };
    },
    voicesWithId() {
      if (!this.voices) return [];
      return this.voices.map((el) => ({ ...el, name: el.id }));
    },
    sortedVoices() {
      return this.voices
        .slice()
        .sort((a, b) => a.displayName.localeCompare(b.displayName));
    },
    filteredVoices() {
      return this.sortedVoices
        .filter((voice) => {
          console.log('voice => ', voice);
          if (voice.mainLanguage !== this.websiteLanguage) return false;
          const isSameGenger = this.voiceFilter.gender === 'all' || voice.gender === this.voiceFilter.gender;
          // const isSameStyle = this.voiceFilter.style === 'all' || voice.style !== this.voiceFilter.style;
          const query = this.searchQuery ? voice.displayName.toLowerCase().includes(this.searchQuery.toLowerCase()) : true;
          if (isSameGenger && query) return true;
          return false;
        });
    },
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  created() {
    this.setSidebarVisibility(false);
    this.createSiteInput = this.addWebsiteModel;
    this.websiteLanguage = this.createSiteInput.feedLang;
    this.loadVoices();
    // this.addWebsiteModel;
  },
  mounted() {
    this.setSidebarVisibility(false);
  },
  methods: {
    ...mapActions({
      fetchWebsites: 'website/fetchWebsites',
      fetchVoices: 'utils/fetchVoices',
      changeVoices: 'website/changeVoices',
      createWebsite: 'website/createWebsite',
      getTitle: 'website/getTitle',
      detectWordpress: 'website/detectWordpress',
      detectRssFeed: 'website/detectRssFeed',
      getWebsiteById: 'website/getWebsiteById',
      checkNewSite: 'website/checkNewSite',
      checkRssUrl: 'website/checkRssUrl',
      createProject: 'website/createProject',
      createProjectFeedItem: 'website/createProjectFeedItem',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
      setSavedWebsiteId: 'website/setSavedWebsiteId',
      setWebsiteModel: 'website/setWebsiteModel',
    }),
    async showSettings() {
      this.loading = true;
      await this.loadVoices();
      this.setRecomended();
      this.showVoiceSettings = true;
      this.loading = false;
    },
    async createDemoContent(id) {
      const input = {
        feedId: id,
        title: this.$t('demoArticles.en.title'),
        content: [
          {
            body: this.$t('demoArticles.en.h1'),
            id: 'h1',
            language: '',
            type: 'header',
          },
          {
            body: this.$t('demoArticles.en.p1'),
            id: 'p1',
            language: '',
            type: 'paragraph',
          },
          {
            body: this.$t('demoArticles.en.p2'),
            id: 'p2',
            language: '',
            type: 'paragraph',
          },
          {
            body: this.$t('demoArticles.en.p3'),
            id: 'p3',
            language: '',
            type: 'paragraph',
          },
        ],
        // publishEpisode: false,
      };
      // await this.createProjectFeedItem(input);
      console.log('didabled demo content', input);
    },
    async updateLanguage() {
      if (!this.isLanguageValid) {
        this.noLanguageError = true;
        return;
      }
      this.createSiteInput.feedLang = this.websiteLanguage;
      if (this.isProject) {
        this.addProject();
        return;
      }

      this.loading = true;
      this.addWebsite();
    },
    async addProject() {
      this.loading = true;
      console.log('this.createSiteInput', this.createSiteInput);
      const createResult = await this.createProject(this.createSiteInput);

      if (!createResult.ok) {
        this.createError = this.$t('ui.somethingWentWrong');
        this.loading = false;
        return;
      }

      this.fetchWebsites();
      const result = await this.getWebsiteById(createResult.feedId);
      // this.createDemoContent(createResult.feedId);
      // await this.loadVoices();
      this.loading = false;
      // console.log('createBlog', result, websites);
      this.feed = result;
      this.setSavedWebsiteId(createResult.feedId);
      localStorage.setItem(`w_${this.userId}`, createResult.feedId);
      // this.showVoiceSettings = true;
      this.save(); // save voice Settings
      // this.goTo(`/articles/${createResult.feedId}/list`);
      // this.$forceUpdate();
    },

    async addWebsite() {
      this.loading = true;
      this.createSiteInput.channelTypes = ['podcast', 'web-player'];
      const createResult = await this.createWebsite(this.createSiteInput);
      if (!createResult.ok) {
        this.createError = this.$t('ui.somethingWentWrong');
        this.loading = false;
        return;
      }
      this.fetchWebsites();
      const result = await this.getWebsiteById(createResult.feedId);
      this.createDemoContent(createResult.feedId);
      this.loading = false;
      // console.log('createAndGo', result, websites);
      this.feed = result;
      this.token = result.settings['wp-token'];
      this.setSavedWebsiteId(createResult.feedId);
      localStorage.setItem(`w_${this.userId}`, createResult.feedId);
      // this.showVoiceSettings = true;
      this.save(); // save voice Settings
      this.$forceUpdate();
    },
    async loadVoices() {
      await this.fetchVoices(this.websiteLanguage);
      this.titleVoice = {};
      this.contentVoice = {};
      if (this.contentPart) this.contentVoice = this.voiceItemById(this.contentPart.settings);
      if (this.titlePart) this.titleVoice = this.voiceItemById(this.titlePart.settings);
      this.loadingLanguages = false;
    },
    async save() {
      this.loading = true;
      this.saveError = null;
      const input = {
        feedId: this.selectedWebsite.id,
        channelId: this.currentChannel.id,
        contentVoiceId: this.contentVoice.id,
        customVoiceId: this.titleVoice.id,
        contentVoice: {
          ttsVoiceName: this.contentVoice.ttsVoiceName,
          mainLanguage: this.contentVoice.mainLanguage,
          ttsVoiceKind: this.contentVoice.ttsVoiceKind,
        },
        contentAlternateVoice: {
          ttsVoiceName: this.contentAlternativeVoice.ttsVoiceName,
          mainLanguage: this.contentAlternativeVoice.mainLanguage,
          ttsVoiceKind: this.contentAlternativeVoice.ttsVoiceKind,
        },
        customVoice: {
          ttsVoiceName: this.titleVoice.ttsVoiceName,
          mainLanguage: this.titleVoice.mainLanguage,
          ttsVoiceKind: this.titleVoice.ttsVoiceKind,
        },
      };
      if (!this.titleVoice.ttsVoiceKind) delete input.customVoice.ttsVoiceKind;
      if (!this.contentVoice.ttsVoiceKind) delete input.contentVoice.ttsVoiceKind;
      if (!this.contentAlternativeVoice.ttsVoiceKind) delete input.contentAlternateVoice.ttsVoiceKind;

      const result = await this.changeVoices(input);
      await this.getWebsiteById(this.selectedWebsite.id);
      this.loading = false;
      if (!result.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        return;
      }

      if (this.isSubscriptionActive) {
        this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list` });
        return;
      }
      this.$router.push({ path: '/add-project/membership' });
    },

    clearSelectedVoices() {
      this.titleVoice = {};
      this.contentVoice = {};
    },

    setRecomended() {
      if (!this.defaultVoices[this.websiteLanguage]) {
        [this.titleVoice, this.contentAlternativeVoice] = this.filteredVoices;
        this.contentAlternativeVoice = this.titleVoice;
        return;
      }
      const [titleVoice, contentVoice] = this.defaultVoices[this.websiteLanguage];
      this.titleVoice = titleVoice;
      this.contentVoice = contentVoice;
      this.contentAlternativeVoice = titleVoice;
    },

    // Audio preview methods
    clearPlayer() {
      this.playing = null;
      this.url = null;
      // console.log('player cleared');
    },
    play(item) {
      this.playing = item.id;
      this.url = item.url;
      this.$refs.player.player.stop();
      this.$refs.player.player.play();
    },
    stop() {
      this.$refs.player.stop();
      this.playing = null;
      this.url = null;
    },
    previewAudio(item, index) {
      const audio = this.$refs.player;
      if (this.playing === index) {
        audio.pause(); // call this to play the song right away
        this.playing = null;
        this.url = null;
        return;
      }
      this.playing = index;
      this.url = this.voiceUrl(item);
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
  },
};
</script>
<style scoped>
.voices {
  position: relative;
}

.voices:after {
  /* content: '';
  position: absolute;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, white);
  height: 15%;
  width: 100%;
  pointer-events: none; */
}
</style>
