<template>
  <div id="general-settings">
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-6 divide-y divide-gray-300">
      <div class="">
        <audio ref="player" @ended="clearPlayer" controls="controls" id="audioPreview" v-show="false">
          <source :src="audioPreviewUrl" type="audio/mp3" />
        </audio>
        <div class="bg-white space-y-6 py-4" :class="{'loading': loading}">
          <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1 ">
            {{saveError}}
          </div>
          <div class="flex flex-col space-y-4">
            <div class="col-span-3 sm:col-span-2">
              <label for="name" class="block text-sm font-medium text-gray-700">
                {{$t('ui.nameLabel')}}
              </label>
              <div class="mt-1 rounded-md flex ">
                <!-- <input type="text" name="name" id="name"
                v-model="feedPatch.displayName"
                class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300"> -->
                  <div class="title-editor-holder py-4 px-3 min-h-[46px]  focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm  border border-gray-300">
                    <title-editor
                      ref="titleEditor"
                      class="relative"
                      type="input"
                      :feedId="selectedWebsite.id"
                      :content.sync="feedPatch.displayName"
                      @playBlock="playTitle"
                      @stopBlock="stopTitle"></title-editor>
                  </div>
              </div>
            </div>
            <div class="col-span-3 sm:col-span-2">
              <label for="name" class="block text-sm font-medium text-gray-700">
                {{$t('ui.descriptionLabel')}}
              </label>
              <div class="mt-1 rounded-md flex">
                <input type="text" name="name" id="name"
                v-model="feedPatch.description"
                class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
              </div>
            </div>

            <div class="col-span-3 sm:col-span-2" v-if="!isProject">
              <label for="name" class="block text-sm font-medium text-gray-700">
                {{$t('ui.mainSiteUrl')}}
              </label>
              <div class="mt-1 rounded-md flex">
                <input type="text" name="name" id="name"
                v-model="feedPatch.url"
                class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
              </div>
            </div>

            <div class="col-span-3 flex space-x-3 items-center cursor-pointer" @click="updateWatermarkTemplate" :class="{'pointer-events-none': !isPremium}">
              <div class="mt-1">
                <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
                <button type="button"
                  :class="{'bg-gray-200': !watermarkTemplate, 'bg-blue-600': watermarkTemplate, 'pointer-events-none opacity-60': !isPremium}"
                  class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false">
                  <span class="sr-only"></span>
                  <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                  <span aria-hidden="true"
                    :class="{'translate-x-0': !watermarkTemplate, 'translate-x-5': watermarkTemplate}"
                    class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                </button>
              </div>
              <label :class="{'pointer-events-none opacity-60': !isPremium}" for="" class="block text-sm font-medium text-gray-700">{{$t('ui.settingsWaterMarkTemplate')}}</label>
              <div v-if="!isPremium">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <!-- Heroicon name: solid/information-circle -->
                    <svg class="h-4 w-4 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                    </svg>
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-xs text-blue-700">
                      {{$t('ui.watermarkInfo')}}
                    </p>
                    <p class="mt-3 text-xs md:mt-0 md:ml-6">
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-3">
              <label for="about" class="block text-sm font-medium text-gray-700">
                {{$t('ui.language')}}
              </label>
              <div class="mt-1">
                <select
                    disabled
                    v-model="feedPatch.language"
                    id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 pointer-events-none opacity-50">
                    <option v-for="lang in languageList" :key="lang" :value="lang">{{$t('languages.' + lang)}}</option>
                  </select>
                  <div class="mt-2">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg class="h-4 w-4 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-xs text-yellow-700">
                          {{$t('ui.languageSettingsChangeWarning')}}
                        </p>
                        <p class="mt-3 text-xs md:mt-0 md:ml-6">
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="py-8 md:text-right pt-4 space-x-4">
            <Button type="button" class="px-4 py-3 border border-transparent text-sm font-medium rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 cursor-pointer transition duration-500 ease-in-out mt-0 bg-gray-100 hover:bg-red-500 hover:text-white"
              @click.native="showDeleteModal = true" :loading="loading">
              {{$t('ui.deleteWebsite')}}
            </Button>
            <Button class="mt-0"
              @click.native="save" :loading="loading">
              {{$t('ui.save')}}
            </Button>
          </div>
        </div>
      </div>
    </div>
    <Modal :show.sync="showDeleteModal">
      <div slot="header">{{$t('ui.deleteWebsiteTitle')}}</div>
      <div class="text-sm text-gray-600 mb-3" v-html="$t('ui.deleteWebsiteDesc')">{{}}</div>
        <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 block w-full" v-model="deleteConfirmation">
      <div class="text-right space-x-4">
        <Button @click.native="showDeleteModal = false"
          type="default"
          class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.cancel')}}</Button>
        <Button @click.native="deleteWebsite"
          :loading="loadingDelete"
          :disabled="deleteConfirmation !== 'delete'"
          type="danger"
          class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.confirmDeleteButton')}}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      loadingDelete: false,
      confirmPassword: null,
      password: null,
      saveError: null,
      oldPassword: null,
      errorMessage: null,
      showGuideModal: false,
      showDeleteModal: false,
      deleteConfirmation: '',
      feedId: null,
      watermarkTemplate: false,
      feedPatch: {
        displayName: '',
        description: '',
        language: '',
        url: '',
        explicitContent: '',
      },
      jsonSettings: {},
      languages: [
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],

      audioPreviewUrl: null,
      playButton: null,
      activeTab: 'general',
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      customers: (state) => state.customer.list,
      selectedWebsite: (state) => state.website.selectedWebsite,
      websiteList: (state) => state.website.websiteList,
      userId: (state) => state.auth.userId,
      languageList: (state) => state.utils.languageList,
      pluginLink: (state) => state.utils.pluginUrl,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      selectedWebsiteAPTemplates: (state) => state.website.selectedWebsiteAPTemplates,
      selectedWebsiteAP: (state) => state.website.selectedWebsiteAP,
      selectedWebsiteAPTemplateOutro: (state) => state.website.selectedWebsiteAPTemplateOutro,
    }),
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    isEnterprise() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Enterprise';
    },
    isPremium() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Premium';
    },
    channelId() {
      return this.selectedWebsiteAP.id;
    },
    channelOutroTemplate() {
      if (!this.selectedWebsiteAPTemplates.outro) return {};
      return this.selectedWebsiteAPTemplates.outro;
    },
    websiteId() {
      return this.$route.params.websiteId;
    },
  },

  watch: {
    websiteId(val) {
      // console.log('no website details', val, oldVal, this.feedPatch.displayName);
      if (this.feedId !== val) {
        console.log('no website details', val, this.feedId);
        this.init();
      }
    },
  },
  validators: {
    oldPassword: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  mounted() {
    this.deleteConfirmation = '';
    this.init();
  },
  methods: {
    ...mapMutations({
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      getWebsiteById: 'website/getWebsiteById',
      updateWebsite: 'website/updateWebsite',
      fetchWebsites: 'website/fetchWebsites',
      deleteWebsiteById: 'website/deleteWebsiteById',
      generateTextPreview: 'website/generateTextPreview',
      switchTemplateStatus: 'website/switchTemplateStatus',
    }),

    async init() {
      this.loading = true;
      const result = await this.getWebsiteById(this.websiteId);
      console.log('result init', result);
      this.jsonSettings = result.settings;
      this.feedId = this.websiteId;
      this.feedPatch = {
        displayName: result.displayName,
        description: result.description,
        language: result.language,
        url: result.mainSiteUrl,
        // explicitContent: result.explicitContent,
      };
      this.$nextTick();
      this.watermarkTemplate = this.selectedWebsiteAPTemplateOutro.isUsed;
      console.log('isUsed', this.selectedWebsiteAPTemplateOutro, this.selectedWebsiteAPTemplateOutro.isUsed, this.channelOutroTemplate, result);
      this.$refs.titleEditor.refreshContent(this.feedPatch.displayName);
      this.loading = false;
    },

    async deleteWebsite() {
      this.loadingDelete = true;
      const result = await this.deleteWebsiteById(this.selectedWebsite.id);

      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.loadingDelete = false;
        return;
      }

      await this.fetchWebsites();
      const websiteCount = this.websiteList.length;
      this.loadingDelete = false;
      if (websiteCount === 0) {
        this.goTo('/add-project');
        return;
      }

      const { id } = this.websiteList[0];
      localStorage.setItem(`w_${this.userId}`, id);
      await this.getWebsiteById(id);
      this.$router.push({ path: `/articles/${this.websiteList[0].id}/list` });
    },

    async save() {
      this.saveError = null;
      this.loading = true;
      const input = {
        ...this.feedPatch,
        id: this.selectedWebsite.id,
      };
      // console.log('update website', this.feedPatch);
      const result = await this.updateWebsite(input);
      this.loading = false;
      if (!result.ok) {
        // console.log('result', result);
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        this.saveError = this.$t('ui.somethingWentWrong');
        return;
      }
      this.fetchWebsites();
      const website = await this.getWebsiteById(this.selectedWebsite.id);

      this.feedPatch = {
        displayName: website.displayName,
        description: website.description,
        language: website.language,
        url: website.mainSiteUrl,
        // explicitContent: website.explicitContent,
      };
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.websiteUpdated'),
      });
      this.loading = false;
    },

    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.tokenCopied'),
      });
    },
    clearPlayer() {
      const audio = this.$refs.player;
      // const index = this.paragraphUrls.indexOf(this.audioPreviewUrl);
      this.playing += 1;
      if (this.paragraphUrls.length > 1 && this.playing < this.paragraphUrls.length) {
        this.audioPreviewUrl = this.paragraphUrls[this.playing];
        audio.load(); // call this to just preload the audio without playing
        audio.play();
        // alert(`sdasdasd ${index}`);
        return;
      }
      this.playing = 0;
      this.audioPreviewUrl = null;
      this.playButton.classList.remove('playing');
      // console.log('player cleared', this.playButton);
      this.clearButtons();
    },
    clearButtons() {
      this.$refs.titleEditor.clearPlay();
    },
    async playTitle(data) {
      const input = {
        feedId: this.selectedWebsite.id,
        text: this.feedPatch.displayName,
      };
      this.clearButtons();
      this.playButton = data.button;
      const result = await this.generateTextPreview(input);
      data.button.classList.remove('loading');
      data.button.classList.add('playing');
      // console.log('%cPLAY TITLE', 'color: red', data, result);
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }

      this.playUrl([result.url]);
    },
    async stopTitle() {
      const audio = this.$refs.player;
      // console.log('handleStopParagraph', audio, data);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.pause(); // call this to play the song right away
      this.clearButtons();
    },
    playUrl(urls) {
      this.playing = 0;
      this.paragraphUrls = urls;
      [this.audioPreviewUrl] = urls;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    goTo(path) {
      this.$router.push({ path });
    },
    async updateWatermarkTemplate() {
      if (!this.isPremium) return;
      this.watermarkTemplate = !this.watermarkTemplate;
      const result = await this.switchTemplateStatus({
        channelId: this.channelId,
        outro: {
          enabled: this.watermarkTemplate,
        },
      });
      console.log(result);
      // await this.init();
    },
  },
};
</script>
