<template>
  <div class="bg min-h-screen bg-white flex flex-wrap content-center">
    <div class="max-w-xl mx-auto">
      <div class="bg-white overflow-hidden border-2 border-black rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <div class="mt-2 space-y-3 p-4 text-center">
            <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="93.05" height="93.951" viewBox="0 0 93.05 93.951">
              <g id="info" transform="translate(7906 2293.647)">
                <circle id="Ellipse_91456" data-name="Ellipse 91456" cx="9" cy="9" r="9" transform="translate(-7870 -2278)" fill="#fab327"/>
                <g id="Group_5623" data-name="Group 5623" transform="translate(-7751.467 -3633.176)">
                  <g id="Group_5616" data-name="Group 5616">
                    <g id="Group_5615" data-name="Group 5615">
                      <path id="Path_44140" data-name="Path 44140" d="M-109.325,1373.474c-2.627,0-9.541-2.61-9.541-8.486,0-5.056,4.593-9.491,9.828-9.491a8.873,8.873,0,0,1,3.624.769l.138.06a5.334,5.334,0,0,1,2.546,1.9,13.241,13.241,0,0,0,.941,1.141,6.6,6.6,0,0,1,2.014,5.132C-99.775,1368.85-103.122,1373.474-109.325,1373.474Zm.287-15.86c-4.035,0-7.709,3.515-7.709,7.374,0,4.383,5.694,6.368,7.422,6.368,4.879,0,7.432-3.45,7.432-6.857a4.516,4.516,0,0,0-1.478-3.724,15.306,15.306,0,0,1-1.082-1.318,3.287,3.287,0,0,0-1.679-1.193l-.141-.063A6.777,6.777,0,0,0-109.038,1357.614Z" fill="#1f2532"/>
                    </g>
                  </g>
                  <g id="Group_5618" data-name="Group 5618">
                    <g id="Group_5617" data-name="Group 5617">
                      <path id="Path_44141" data-name="Path 44141" d="M-106.261,1426.25c-25.394,0-41.113-21.19-41.113-40.823a38.633,38.633,0,0,1,38.512-38.667,39.71,39.71,0,0,1,25.082,8.512c10.328,8.4,15.138,18.214,15.138,30.885C-68.642,1407.89-85.869,1426.25-106.261,1426.25Zm-2.6-77.373a36.514,36.514,0,0,0-36.394,36.55c0,18.614,14.909,38.706,38.995,38.706,19.244,0,35.5-17.391,35.5-37.976,0-11.983-4.562-21.275-14.356-29.243A37.6,37.6,0,0,0-108.862,1348.877Z" fill="#1f2532"/>
                    </g>
                  </g>
                  <g id="Group_5620" data-name="Group 5620">
                    <g id="Group_5619" data-name="Group 5619">
                      <path id="Path_44142" data-name="Path 44142" d="M-105.934,1433.48a47.929,47.929,0,0,1-34.974-14.916,48.838,48.838,0,0,1-13.625-33.339,45.656,45.656,0,0,1,45.512-45.7,46.948,46.948,0,0,1,29.645,10.059c12.207,9.931,17.893,21.53,17.893,36.505C-61.483,1411.779-81.839,1433.48-105.934,1433.48Zm-3.087-91.833a43.535,43.535,0,0,0-43.394,43.578c0,22.189,17.771,46.138,46.481,46.138,22.947,0,42.333-20.731,42.333-45.27,0-14.287-5.437-25.365-17.112-34.863A44.828,44.828,0,0,0-109.021,1341.647Z" fill="#1f2532"/>
                    </g>
                  </g>
                  <g id="Group_5622" data-name="Group 5622">
                    <g id="Group_5621" data-name="Group 5621">
                      <path id="Path_44143" data-name="Path 44143" d="M-121.814,1411.2a1.058,1.058,0,0,1-.761-.322,1.058,1.058,0,0,1-.3-.8c.174-2.879.261-5.912.266-9.274a1.059,1.059,0,0,1,1.016-1.057q2.547-.1,5.1-.173c-.085-2.38-.124-4.974-.124-8.239q0-1.878.012-3.757c-1.842.032-3.553.09-5.2.176a1.035,1.035,0,0,1-.77-.277,1.059,1.059,0,0,1-.343-.743l-.045-1.257c-.107-2.961-.213-5.926-.283-8.893a1.059,1.059,0,0,1,.3-.762,1.055,1.055,0,0,1,.751-.322l27.11-.255a1.039,1.039,0,0,1,1.069,1.028l.3,10.471a1.062,1.062,0,0,1-.3.773,1.176,1.176,0,0,1-.767.316q-2.334-.024-4.664-.072c-.006,1.126-.019,2.255-.032,3.384-.018,1.542-.036,3.086-.036,4.63,0,1.19.011,2.271.034,3.277,1.785-.086,3.433-.183,5.009-.3a1.052,1.052,0,0,1,.786.271,1.058,1.058,0,0,1,.348.755l.282,9.8a1.059,1.059,0,0,1-1.023,1.089c-5.052.168-10.2.236-15.171.3C-113.371,1411.033-117.626,1411.091-121.814,1411.2Zm1.323-9.376c-.017,2.568-.083,4.95-.2,7.23,3.805-.095,7.664-.146,11.409-.2,4.618-.061,9.382-.125,14.075-.267l-.22-7.646c-1.58.1-3.239.194-5.041.273a1.07,1.07,0,0,1-.766-.281,1.055,1.055,0,0,1-.339-.743c-.043-1.326-.064-2.771-.064-4.418,0-1.551.018-3.1.036-4.654.017-1.483.034-2.965.035-4.441a1.059,1.059,0,0,1,.319-.756,1.258,1.258,0,0,1,.762-.3c1.538.031,3.078.061,4.619.08l-.24-8.33-24.995.235c.069,2.6.162,5.2.256,7.794l.006.186c1.669-.073,3.41-.12,5.29-.143a1.228,1.228,0,0,1,.759.308,1.062,1.062,0,0,1,.313.757l0,.257q-.012,2.284-.015,4.569c0,3.731.05,6.575.164,9.222a1.055,1.055,0,0,1-.284.767,1.055,1.055,0,0,1-.745.337Q-117.927,1401.735-120.491,1401.827Z" fill="#1f2532"/>
                    </g>
                  </g>
                </g>
              </g>
            </svg>

            <h1 class="font-bold text-4xl">{{ $t("ui.addBlogPaymentCancelTitle") }}</h1>
            <p class="text-1xl text-gray-400">{{ $t("ui.addBlogPaymentCancelDesc") }}</p>
            <div class="space-x-4">
              <Button class=" mt-10" @click.native="goToDashboard">{{ $t("ui.skipToDashboard") }}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      token: '',
      loading: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      isSidebarVisible: (state) => state.ui.isSidebarVisible,

      // websites
      createSiteInput: (state) => state.website.addWebsiteModel,
    }),
  },
  beforeMount() {
  },
  methods: {
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    goToDashboard() {
      this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list` });
    },
  },
};
</script>

<style scoped>
.bg {
  background-image: url("../assets/images/add-blog-bg.png");
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
