import Vue from 'vue';
import ClickOutside from 'vue-click-outside';
import SimpleVueValidation from 'simple-vue-validator';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import Popconfirm from 'ui-vue-antd/lib/popconfirm';
// import 'ui-vue-antd/lib/popconfirm/style/css';

import VueTippy from 'vue-tippy';
import Pagination from 'vue-pagination-2';
import VueTimeago from 'vue-timeago';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueSweetalert2 from 'vue-sweetalert2';
import TrendChart from 'vue-trend-chart';

import App from './App.vue';
import router from './router';
import store from './store';
import awsconfig from './aws-exports';
import i18n from './i18n';
import APP_CONFIG from './config';

import PlayerIcon from './components/PlayerIcon.vue';
import PodcastIcon from './components/PodcastIcon.vue';
import PackageComparision from './components/PackageComparision.vue';
import Package from './components/Package.vue';
import PackageSmall from './components/PackageSmall.vue';
import VoiceIcon from './components/VoiceIcon.vue';
import SettingsIcon from './components/SettingsIcon.vue';
import ArticlesIcon from './components/ArticlesIcon.vue';
import AliasIcon from './components/AliasIcon.vue';
import WebsiteIcon from './components/WebsiteIcon.vue';
import StatsIcon from './components/StatsIcon.vue';
import ProjectIcon from './components/ProjectIcon.vue';
import CustomersIcon from './components/CustomersIcon.vue';
import CouponsIcon from './components/CouponsIcon.vue';
import NotificationsIcon from './components/NotificationsIcon.vue';
import TopNavUserDropdown from './components/TopNavUserDropdown.vue';
import Dropdown from './components/Dropdown.vue';
import PlayButton from './components/PlayButton.vue';
import Notifications from './components/Notifications.vue';
import Sidebar from './components/Sidebar.vue';
import Modal from './components/Modal.vue';
import CustomSelect from './components/CustomSelect.vue';
import Editor from './components/Editor.vue';
import EditorSimple from './components/EditorSimple.vue';
import Editable from './components/Editable.vue';
import PlayerEmbeded from './components/PlayerEmbeded.vue';
import Button from './components/Button.vue';
import PaymentStatusAlert from './components/PaymentStatusAlert.vue';
import AlternativeVoiceIcon from './components/AlternativeVoiceIcon.vue';
import ContentVoiceIcon from './components/ContentVoiceIcon.vue';
import TitleVoiceIcon from './components/TitleVoiceIcon.vue';
import StarIcon from './components/StarIcon.vue';

import './assets/tailwindcss.css';
// import './assets/compiled.css';
import 'vue-plyr/dist/vue-plyr.css';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'sweetalert2/dist/sweetalert2.min.css';
// import './assets/sass/app.scss';

// library.add(far);
Vue.component('pagination', Pagination);
Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);
Vue.component(Popconfirm.name, Popconfirm);

Vue.component(PlayerIcon.name, PlayerIcon);
Vue.component(PodcastIcon.name, PodcastIcon);
Vue.component(Package.name, Package);
Vue.component(PackageSmall.name, PackageSmall);
Vue.component(PackageComparision.name, PackageComparision);
Vue.component(VoiceIcon.name, VoiceIcon);
Vue.component(SettingsIcon.name, SettingsIcon);
Vue.component(AliasIcon.name, AliasIcon);
Vue.component(WebsiteIcon.name, WebsiteIcon);
Vue.component(StatsIcon.name, StatsIcon);
Vue.component(ProjectIcon.name, ProjectIcon);
Vue.component(ArticlesIcon.name, ArticlesIcon);
Vue.component(TopNavUserDropdown.name, TopNavUserDropdown);
Vue.component(Notifications.name, Notifications);
Vue.component(NotificationsIcon.name, NotificationsIcon);
Vue.component(CustomersIcon.name, CustomersIcon);
Vue.component(CouponsIcon.name, CouponsIcon);
Vue.component(Sidebar.name, Sidebar);
Vue.component(Modal.name, Modal);
Vue.component(CustomSelect.name, CustomSelect);
Vue.component(Editor.name, Editor);
Vue.component(EditorSimple.name, EditorSimple);
Vue.component(Editable.name, Editable);
Vue.component(PlayerEmbeded.name, PlayerEmbeded);
Vue.component(Button.name, Button);
Vue.component(Dropdown.name, Dropdown);
Vue.component(PlayButton.name, PlayButton);
Vue.component(AlternativeVoiceIcon.name, AlternativeVoiceIcon);
Vue.component(TitleVoiceIcon.name, TitleVoiceIcon);
Vue.component(StarIcon.name, StarIcon);
Vue.component(ContentVoiceIcon.name, ContentVoiceIcon);
Vue.component(PaymentStatusAlert.name, PaymentStatusAlert);

Amplify.configure(awsconfig);

Vue.directive('ClickOutside', ClickOutside);
Vue.use(SimpleVueValidation);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueFilterDateFormat);
Vue.use(VueSweetalert2);
Vue.use(TrendChart);
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
      hide: { enabled: false },
    },
  },
});
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  locales: {
  },
});

Vue.config.productionTip = false;
Vue.prototype.$appConfig = APP_CONFIG;
Vue.filter('intFormat', (value) => {
  if (!value) return 0;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
