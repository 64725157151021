<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-16 w-16"
    width="83.364" height="84.98" viewBox="0 0 83.364 84.98">
    <g id="Group_5637" data-name="Group 5637" transform="translate(-0.225 0.015)">
      <path id="Path_43750" data-name="Path 43750" d="M73.41,24.308a140.1,140.1,0,0,0-.672-14.434,11.427,11.427,0,0,0-1.91-5.307,7.571,7.571,0,0,0-5.236-3.042A227.942,227.942,0,0,0,34.141.039,107.8,107.8,0,0,0,12.914,1.985,13.267,13.267,0,0,0,7.183,5.523,9.092,9.092,0,0,0,5.131,9.945a135.463,135.463,0,0,0-.814,20.7A257.407,257.407,0,0,0,6.475,61.313a7.075,7.075,0,0,0,2.866,4.422,14.151,14.151,0,0,0,6.545,2.476,103.018,103.018,0,0,0,13.726.566c10.613,0,23.6-.743,31.557-.955a1.132,1.132,0,0,0,1.167-1.2,1.167,1.167,0,0,0-1.2-1.167c-7.925,0-20.979.5-31.521.389A96.514,96.514,0,0,1,16.381,65.1a11.639,11.639,0,0,1-4.351-1.38,4.245,4.245,0,0,1-2.264-2.83A235.544,235.544,0,0,1,8.209,35.735a163.055,163.055,0,0,1,.884-25.083,5.024,5.024,0,0,1,1.734-2.83,9.127,9.127,0,0,1,3.219-1.875A105.5,105.5,0,0,1,34.282,4.072a237.027,237.027,0,0,1,30.849.991,4.139,4.139,0,0,1,2.936,1.592,7.571,7.571,0,0,1,1.344,3.538c.5,3.538.778,8.491.991,14.151.425,11.958.354,26.392.46,33.326a9.587,9.587,0,0,1-1.1,4.67,3.962,3.962,0,0,1-3.219,2.476,1.061,1.061,0,0,0-1.061,1.026,1.1,1.1,0,0,0,1.061,1.1,5.625,5.625,0,0,0,4.493-2.512,11.321,11.321,0,0,0,2.123-6.792C73.162,50.735,73.551,36.3,73.41,24.308Z" transform="translate(10.148 0)" fill="#191919" fill-rule="evenodd"/>
      <path id="Path_43751" data-name="Path 43751" d="M67.744,61.839a1.167,1.167,0,0,0-1.38.955,27.666,27.666,0,0,1-.849,3.538c-.425,1.2-.708,1.946-1.238,2.087a114.021,114.021,0,0,1-22,1.557,262.817,262.817,0,0,1-33.29-1.627,4.139,4.139,0,0,1-2.795-1.627,7.854,7.854,0,0,1-1.238-3.538C4.312,58.62,4.029,51.4,3.817,43.761,3.534,32.4,3.392,20.306,3.251,14.079A10.613,10.613,0,0,1,4.276,9.268,4.1,4.1,0,0,1,7.531,6.65a1.061,1.061,0,1,0,0-2.087A5.66,5.66,0,0,0,2.967,7.11,12.276,12.276,0,0,0,.809,14.185C.809,21.261.1,36.261.243,48.643A148.9,148.9,0,0,0,.951,63.572a12.135,12.135,0,0,0,2.017,5.484,7.96,7.96,0,0,0,5.484,3.219,241.341,241.341,0,0,0,28.3,1.061,137.761,137.761,0,0,0,28.3-2.476,3.75,3.75,0,0,0,2.3-2.229,18.4,18.4,0,0,0,1.45-5.413,1.167,1.167,0,0,0-1.061-1.38Z" transform="translate(0 11.573)" fill="#fea900" fill-rule="evenodd"/>
      <path id="Path_43752" data-name="Path 43752" d="M49.281,9.213a6.12,6.12,0,0,0-2.759,0c-1.45.425-2.795,1.061-4.175,1.521a9.3,9.3,0,0,1-2.441.46,18.432,18.432,0,0,1-3.078,0c-2.653-.318-2.193-2.441-3.785-4.139a2.229,2.229,0,0,0-2.3-.6,3.927,3.927,0,0,0-1.486.991c-.5.5-.955,1.238-1.309,1.663A9.941,9.941,0,0,1,26,11.018a2.123,2.123,0,0,1-1.592.46,2.724,2.724,0,0,1-1.1-.566c-1.84-1.45-1.274-2.476-1.557-3.538a3.184,3.184,0,0,0-.318-.743,1.769,1.769,0,0,0-2.476-.708,8,8,0,0,0-2.724,2.724,15.177,15.177,0,0,1-2.37,3.219,1.875,1.875,0,0,1-1.7.425c-.5,0-.849-.637-1.132-1.167a8.384,8.384,0,0,1-.814-2.335c0-3.29.389-1.946.354-2.936S9.552,4.013,8.6,5.676a5.342,5.342,0,0,0-.566.849,5.059,5.059,0,0,0,0,1.309,7.642,7.642,0,0,0,0,1.38,10.4,10.4,0,0,0,.92,3.538,4.281,4.281,0,0,0,2.618,2.37,4.634,4.634,0,0,0,4.351-.637,13.62,13.62,0,0,0,2.724-3.078h0a3.717,3.717,0,0,0,0,.425,7.5,7.5,0,0,0,1.981,2.3,6.51,6.51,0,0,0,2.9,1.415,6.05,6.05,0,0,0,3.821-.672,12.382,12.382,0,0,0,3.538-3.184h0a11.817,11.817,0,0,0,1.344,1.45,5.236,5.236,0,0,0,2.583,1.274,17.689,17.689,0,0,0,5.094,0,11.781,11.781,0,0,0,2.512-.566c4.139-1.45,2.724-1.946,6.3-1.946a1.2,1.2,0,0,0,1.2-1.2,1.344,1.344,0,0,0-.637-1.486Z" transform="translate(19.701 12.052)" fill="#191919" fill-rule="evenodd"/>
      <path id="Path_43753" data-name="Path 43753" d="M12.944,12.542c1.592.248,3.325.778,4.422.955a67.432,67.432,0,0,0,8.455.566,61.023,61.023,0,0,0,6.226-.212c2.087-.212,4.1-.354,6.156-.46l6.05-.531,2.229-.318c1.2-.177,1.238-.991.955-1.663S46.3,10.1,43.9,10.208H38.1c-2.087,0-4.175-.212-6.3-.177s-4.776,0-7.075,0-4.705.212-7.076,0c-1.309,0-3.538-.425-5.342-.318a6.191,6.191,0,0,0-2.724.6c-.708.425-.814.92-.708,1.344a1.415,1.415,0,0,0,.46.778A30.709,30.709,0,0,0,12.944,12.542Z" transform="translate(21.864 24.643)" fill="#191919" fill-rule="evenodd"/>
      <path id="Path_43754" data-name="Path 43754" d="M8.353,16.719a1.026,1.026,0,0,0,1.132.955H24.166c3.255,0,6.474-.212,9.764-.212a80.816,80.816,0,0,0,8.667-.318,15.6,15.6,0,0,0,5.979-1.733,1.2,1.2,0,1,0-1.1-2.123,12.7,12.7,0,0,1-4.917.92c-2.9,0-5.837-.46-8.491-.566H24.166c-4.917.354-9.764,1.1-14.717,1.8a1.026,1.026,0,0,0-1.1,1.274Z" transform="translate(20.546 33.238)" fill="#191919" fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ContentVoiceIcon',
  props: {
    title: {
      type: String,
      default: 'voice id user',
    },
  },
};
</script>
