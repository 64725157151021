<template>
  <div class="relative flex flex-col justify-between relative"
    :class="{'border-[3px] border-black bg-black rounded-[28px] text-white': popular, 'bg-white text-black': !popular}">
    <div>
      <div class="p-6 px-8 pb-0 mt-4">
        <h2 class="text-2xl leading-6 font-bold flex justify-between items-center">
          <span>{{name}}</span>
          <span class="text-xs font-bold bg-hm-brand h-4 inline-block text-white py-0 px-1 rounded relative -top-px" v-show="popular">{{$t('ui.packageMostPopular')}}</span>
        </h2>
        <p class="mt-2 text-sm">{{desc}}</p>
        <p class="mt-8">
          <span class="text-4xl font-extrabold">{{displayPrice}}</span>
          <span class="text-base font-medium" v-show="isMonthly === 'month'">/{{$t('ui.monthShorcut')}}</span>
          <span class="text-base font-medium" v-show="isMonthly === 'year'">/{{$t('ui.yearShortcut')}}</span>
        </p>
        <p v-if="originalUnitAmount && isMonthly === 'year'" class="font-medium text-sm mt-1">
          <span class="line-through text-gray-400">{{displayOriginalPrice}}</span> <span class="text-hm-brand">{{displayDiscountPrice}}</span> <span class="text-gray-400">/ {{$t('ui.monthShorcut')}}</span>
        </p>
      </div>
      <div class="pt-4 pb-4 px-8">
        <hr class="border-t-2 border-black mb-6">
        <h3 class="text-base font-bold tracking-wide">{{$t('ui.packageWhatsIncluded')}}</h3>
        <ul role="list" class="mt-6 mb-6 space-y-2">
          <li class="flex space-x-3 items-center" v-for="feature in features" :key="feature.name">
            <!-- Heroicon name: solid/check -->
            <!-- <svg class="flex-shrink-0 h-5 w-5 text-hm-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg> -->
            <svg class="flex-shrink-0 h-3 w-3" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.05859 8.47119C1.05859 8.47119 4.54459 6.07919 4.54459 4.99919C4.54459 3.91919 1.05859 1.52919 1.05859 1.52919" stroke="#FEA900" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span class="text-sm" v-html="$t(`packages.${feature.name}`, [...feature.value])">{{}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="px-6 pb-6 text-center w-full">
      <slot></slot>
    </div>
    <div class="absolute top-0 -bottom-2 -left-1 -right-1 pointer-events-none">
      <svg v-if="name !== 'Premium' && name !== 'Business'" height="100%" width="100%" class="block" viewBox="0 0 359 532" fill="none" xml:space="preserve" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
      <path width="100%" height="100%"  d="M41.4986 4.53958C-15.0899 5.46568 8.94771 113.357 8.94771 162.44C13.0817 224.952 8.94771 252.272 2.93817 357.848C-3.07137 463.423 20.9664 509.266 41.4986 523.62C62.0309 537.975 227.79 523.62 303.408 523.62C379.026 523.62 352.485 316.636 352.485 265.701C352.485 214.765 354.989 106.874 352.485 46.6772C349.981 -13.5195 275.865 4.53958 237.305 4.53958C198.744 4.53958 98.0871 3.61348 41.4986 4.53958Z" stroke="#000" stroke-width="3"/>
      </svg>
      <svg v-if="name === 'Premium'" width="100%" height="100%" viewBox="0 0 359 526" fill="none" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" preserveAspectRatio="none">
        <path d="M317.501 521.46C374.09 520.534 350.052 412.643 350.052 363.559C345.918 301.047 350.052 273.728 356.062 168.152C362.071 62.5761 319.532 36.3542 299 21.9996C278.468 7.64496 131.21 2.37939 55.5919 2.37939C-20.0264 2.37939 6.51511 209.363 6.51511 260.299C6.51511 311.235 4.0112 419.126 6.51511 479.322C9.01901 539.519 80.9397 521.46 119.5 521.46C158.06 521.46 260.913 522.386 317.501 521.46Z" stroke="#1F2532" stroke-width="3"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Package',
  props: {
    package: {
      type: Object,
      default: () => {},
    },
    features: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
    },
    discount: {
      type: Number,
    },
    originalUnitAmount: {
      type: Number,
    },
    name: {
      type: String,
    },
    desc: {
      type: String,
    },
    promo: {
      type: Number,
    },
    isMonthly: {
      type: String,
    },
    symbol: {
      type: String,
    },
    popular: {
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    displayPrice() {
      const price = this.selectedPackage.unit_amount / 100;
      const isInt = Number.isInteger(price);
      const priceString = isInt ? `${this.symbol}${price}.00` : `${this.symbol}${price}`;
      return priceString;
    },
    displayOriginalPrice() {
      const price = this.originalUnitAmount / 100;
      const isInt = Number.isInteger(price);
      const priceString = isInt ? `${this.symbol}${price}.00` : `${this.symbol}${price}`;
      return priceString;
    },
    displayDiscountPrice() {
      const price = (this.selectedPackage.unit_amount / 12) / 100;
      const isInt = Number.isInteger(price);
      const roundedPrice = Math.round((price + Number.EPSILON) * 100) / 100;
      const priceString = isInt ? `${this.symbol}${roundedPrice}.00` : `${this.symbol}${roundedPrice}`;
      return priceString;
    },
    selectedPackage() {
      return this.package[this.isMonthly];
    },
  },
};
</script>
