<template>
  <div class="cursor-pointer relative inline-block text-left" v-click-outside="closeUserMenu">
    <div
      @click="showDropdown = !showDropdown"
      class="border-black rounded-md border-2 p-2 flex justify-between items-center">
      <slot></slot>
      <svg class="pointer-events-none" xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14">
        <path id="Path_43302" data-name="Path 43302" d="M10,3a1,1,0,0,1,.707.293l3,3a1,1,0,1,1-1.414,1.414L10,5.414,7.707,7.707A1,1,0,1,1,6.293,6.293l3-3A1,1,0,0,1,10,3ZM6.293,12.293a1,1,0,0,1,1.414,0L10,14.586l2.293-2.293a1,1,0,1,1,1.414,1.414l-3,3a1,1,0,0,1-1.414,0l-3-3a1,1,0,0,1,0-1.414Z" transform="translate(-6 -3)" fill-rule="evenodd"/>
      </svg>
    </div>

    <!--
      Dropdown menu, show/hide based on menu state.

      Entering: "transition ease-out duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "transition ease-in duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
      name="custom-classes-transition"
      enter-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="showDropdown"
        class="absolute max-h-72 overflow-auto right-0 z-10 mt-2 w-64 origin-bottom-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
        <slot name="items"></slot>
      </div>
    </transition>
</div>
</template>
<script>
export default {
  name: 'Dropdown',
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    closeUserMenu() {
      this.showDropdown = false;
    },
  },
};
</script>
