<template>
  <div class="">
    <div v-if="code" class="text-blue-400 text-sm -mt-5 mb-3 text-center">
        {{$t('ui.loginToConfirmEmail')}}
    </div>
    <div class="message text-red-500 text-sm -mt-3 mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <form @submit.prevent="confirmAccount" class="space-y-6" v-if="showCodeForm">
      <label for="email" class="block text-sm font-medium text-gray-300">
        {{$t('ui.verificationCodeLabel')}}
      </label>
      <div class="mt-1 relative">
        <input
          v-model="mfaForm.code"
          :placeholder="$t('ui.enterVerificationCode')"
          id="code" name="code" type="text" autocomplete="code" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow min-w-0 rounded-lg sm:text-sm border-gray-300 appearance-none block w-full">
          <!-- <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" v-if="email && validation.hasError('email') && validation.isTouched('email')">
            <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div> -->
      </div>
      <!-- <div class="message text-red-500 text-xs mt-1">
        {{ validation.firstError('code') }}
      </div> -->
      <div>
        <Button type="brand"
          native-type="submit"
          :loading="isBtnLoading"
          class="w-full flex">
          {{$t('ui.confirm')}}
        </Button>
        <Button type="brand"
          :loading="isBtnLoading"
          @click.native="requestCode"
          class="w-full flex mt-2">
          {{$t('ui.requestCode')}}
        </Button>
      </div>
    </form>
    <form @submit.prevent="login" class="space-y-6" v-if="!showCodeForm">
      <div>
        <!-- <label for="email" class="block text-sm font-medium text-gray-300">
          {{$t('ui.emailLabel')}} -->
        <!-- </label> -->
        <div class="mt-1 relative">
          <input
            v-model="email"
            :placeholder="$t('ui.emailPlaceholder')"
            id="email" name="email" type="email" autocomplete="email" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow min-w-0 rounded-lg sm:text-sm border-gray-300 appearance-none block w-full"
            :class="{'ring-2 ring-green-500': email && validation.isPassed('email')}">
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" v-if="email && validation.hasError('email') && validation.isTouched('email')">
              <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
        </div>
        <div class="message text-red-500 text-xs mt-1">
          {{ validation.firstError('email') }}
        </div>
      </div>
      <div class="space-y-1">
        <!-- <label for="password"
          class="block text-sm font-medium text-gray-300">
          {{$t('ui.passwordLabel')}}
        </label> -->
        <div class="mt-1 relative">
          <input
          v-model="password"
          :placeholder="$t('ui.passwordPlaceholder')"
          id="password" name="password" type="password" autocomplete="current-password" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300appearance-none w-full"
          :class="{'ring-2 ring-green-500': password && validation.isPassed('password')}">
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
            v-if="password && validation.hasError('password') && validation.isTouched('password')">
            <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
        <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
          {{ validation.firstError('password') }}
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div class="flex items-center">
        </div>

        <div class="text-sm">
          <router-link
            :to="'/resetpassword'"
            class="font-medium text-blue-400 hover:text-blue-300"
            tag="a">{{$t('ui.forgotPasswordLink')}}</router-link>
        </div>
      </div>

      <div>
        <!-- <Button type="brand"
          @click.native="createDemoContent"
          class="w-full flex">
          {{$t('ui.createDemoContent')}}
        </Button> -->
        <Button type="brand"
          native-type="submit"
          :loading="isBtnLoading"
          class="w-full flex">
          {{$t('ui.signIn')}}
        </Button>
        <Button type="brand"
          :loading="isBtnLoading"
          @click.native="requestCode"
          v-show="showRequestBtn"
          class="w-full flex mt-2">
          {{$t('ui.requestCode')}}
        </Button>
        <Button type="white"
          :loading="isBtnLoading"
          native-type="button"
          class=" w-full flex mt-2 items-center relative" @click.native="googleLogin">
          <svg class="social-logo h-6 w-6 absolute left-4" viewBox="0 0 256 262" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid">
                <path d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027" fill="#4285F4"></path>
                <path d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1" fill="#34A853"></path>
                <path d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782" fill="#FBBC05"></path>
                <path d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251" fill="#EA4335"></path>
            </svg>
            <span>Continue with Google</span>
        </Button>
        <!-- <button type="submit"
          :class="{loading : isBtnLoading }"
          @click.native="login"
          class="text-black bg-hm-brand hover:bg-yellow-300 px-6 py-3 text-sm line-flex items-center justify-center border border-transparent font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out w-full flex">
          {{$t('ui.signIn')}}
        </button> -->
      </div>
      <div class="mt-6 mb-6 relative">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-600"></div>
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="px-2 bg-black text-gray-400">
            {{$t('ui.or')}}
          </span>
        </div>
      </div>
      <div class="text-center">
        <div class="text-white text-sm mt-4 ">{{$t('ui.registerLinkPre')}}</div>
        <router-link
          :to="'/register'"
          tag="a"
          class="items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-outblock w-full text-center font-medium text-hm-blue text-sm hover:text-blue-300">{{$t('ui.registerLink')}}</router-link>
      </div>
    </form>
    <div class="text-black bg-hm-brand hover:bg-yellow-300"></div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  name: 'login',
  data() {
    return {
      user: null,
      showRequestBtn: false,
      email: null,
      password: null,
      visibleStep: 1,
      showCodeForm: false,
      loading: false,
      isBtnLoading: false,
      errorMessage: null,
      mfaForm: {
        code: '',
      },
      error: false,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
    }),
    code() {
      return this.$route.query.code || null;
    },
  },
  validators: {
    // eslint-disable-next-line
    email: (value) => Validator.value(value).required().email(),
    // eslint-disable-next-line
    password: function (value) { return Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, this.$t('ui.invalidPassword')) },
  },
  beforeCreate() {
    // this.form = this.$form.createForm(this, { name: 'normal_login' });
    // this.formCode = this.$form.createForm(this, { name: 'verify_code' });
  },
  mounted() {
    this.clearCustomerState();
  },
  methods: {
    ...mapActions({
      fetchUser: 'auth/fetchUser',
      createDemoProject: 'website/createDemoProject',
      fetchCustomer: 'customer/fetchCustomer',
      fetchAuthCustomer: 'auth/fetchCustomer',
      fetchCredits: 'customer/fetchCredits',
      fetchWebsites: 'website/fetchWebsites',
      createProject: 'website/createProject',
      publishEpisode: 'website/publishEpisode',
      createProjectFeedItem: 'website/createProjectFeedItem',
    }),
    ...mapMutations({
      setSession: 'auth/setSession',
      clearCustomerState: 'customer/clearState',
    }),
    async initSession(session) {
      this.setSession(session);
      const userResult = await this.fetchUser(this.userId);
      const savedWebsite = localStorage.getItem(`w_${this.userId}`);
      await this.fetchCustomer(userResult.customerId);
      const websites = await this.fetchWebsites();
      await this.fetchAuthCustomer(userResult.customerId);
      this.isBtnLoading = false;
      if (savedWebsite) {
        this.$router.push({ path: `/articles/${savedWebsite}/list` });
        return;
      }
      if (!websites[0]) {
        localStorage.setItem('firstTime', 'firstTime');
        this.createDemoContent();
        // this.$router.push({ path: '/add-project' });
        return;
      }
      this.$router.push({ path: `/articles/${websites[0].id}/list` });
    },
    googleLogin() {
      const url = `${this.$appConfig.hostedAuthUrl}/authorize?identity_provider=Google&redirect_uri=${this.$appConfig.domain}/oauth&response_type=CODE&client_id=${this.$appConfig.clientId}&scope=email openid`;
      window.location.href = url;
    },
    async login() {
      const isValid = await this.$validate();
      // console.log('login', isValid);
      if (!isValid) return;
      this.loading = true;
      this.isBtnLoading = true;
      const result = await this.$Amplify.Auth.signIn(
        this.email,
        this.password,
      ).catch((error) => {
        const { message } = error;
        console.log('login error', error);
        if (message === 'Incorrect username or password.') {
          this.errorMessage = this.$t('ui.incorectUserOrPassword');
          this.isBtnLoading = false;
          return;
        }
        if (message === 'Password attempts exceeded') {
          this.errorMessage = this.$t('ui.attemptsExceeded');
          this.isBtnLoading = false;
          return;
        }
        if (message === 'User is not confirmed.' && !this.code) {
          this.showCodeForm = true;
          this.isBtnLoading = false;
          return;
        }
        if (message === 'User is not confirmed.' && this.code) {
          this.confirmSignUp(this.code, this.email);
        }
        if (message === 'User is disabled.') {
          this.isBtnLoading = false;
          this.loading = true;
          this.$router.push({ path: '/disabled' });
        }
      });

      if (!result) return;

      // console.log('login.reult', result);
      this.initSession(result.signInUserSession);
    },
    async confirmAccount() {
      this.errorMessage = null;
      this.isBtnLoading = true;
      try {
        const codeResult = await this.$Amplify.Auth.confirmSignUp(this.email, this.mfaForm.code);
        console.log('confirm result', codeResult);
        const result = await this.$Amplify.Auth.signIn(
          this.email,
          this.password,
        );
        this.initSession(result.signInUserSession);
        this.isBtnLoading = false;
      } catch (error) {
        const { message } = error;
        this.errorMessage = message;
        this.isBtnLoading = false;
      }
    },
    async requestCode() {
      this.isBtnLoading = true;
      await this.$Amplify.Auth.resendSignUp(this.email);
      this.showCodeForm = true;
      this.errorMessage = null;
      this.isBtnLoading = false;
    },
    async confirmSignUp(code, username) {
      try {
        await this.$Amplify.Auth.confirmSignUp(username, code);
        const result = await this.$Amplify.Auth.signIn(
          this.email,
          this.password,
        );
        this.initSession(result.signInUserSession);
        // console.log('confirmSignUp result', confirmResult, result);
      } catch (error) {
        const { message } = error;
        if (message === 'Invalid code provided, please request a code again.') {
          this.errorMessage = this.$t('ui.loginInvalidCode');
          this.isBtnLoading = false;
          this.showRequestBtn = true;
          return;
        }
        if (message === 'Invalid verification code provided, please try again.') {
          this.errorMessage = this.$t('ui.loginInvalidCodeProvided');
          this.isBtnLoading = false;
          this.showRequestBtn = true;
          return;
        }
        this.isBtnLoading = false;
      }
    },
    async createDemoContent() {
      const resut = await this.createDemoProject();
      localStorage.setItem('demoContentFeedId', resut.data.feedItemId);
      localStorage.setItem('firstTime', 'firstTime');
      // const feedInput = {
      //   feedLang: 'en-EN',
      //   projectTitle: 'Demo Project',
      // };
      // const createResult = await this.createProject(feedInput);
      // const input = {
      //   feedId: createResult.feedId,
      //   title: this.$t('demoArticles.en.title'),
      //   content: [
      //     {
      //       body: this.$t('demoArticles.en.h1'),
      //       id: 'h1',
      //       language: '',
      //       type: 'header',
      //     },
      //     {
      //       body: this.$t('demoArticles.en.p1'),
      //       id: 'p1',
      //       language: '',
      //       type: 'paragraph',
      //     },
      //     {
      //       body: this.$t('demoArticles.en.p2'),
      //       id: 'p2',
      //       language: '',
      //       type: 'paragraph',
      //     },
      //   ],
      //   // publishEpisode: false,
      // };
      // const result = await this.createProjectFeedItem(input);
      // const publishInput = {
      //   feedItemId: result.feedItemId,
      //   publishEpisode: true,
      //   // publishFeedItemVersion: this.selectedArticleData.currentVersion,
      // };
      // await this.publishEpisode(publishInput);
      // localStorage.setItem('demoContentFeedId', result.feedItemId);
      // // localStorage.setItem('firstTime', result.feedItemId);
      // localStorage.setItem('firstTime', 'firstTime');
      // console.log('didabled demo content', input);
      this.$router.push({ path: `/articles/${resut.data.feedId}/list` });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "signin": "Sign in",
    "password": "Enter Password",
    "emailLabel": "Email address",
    "email": "Enter your email address",
    "passwordLabel": "Enter password"
  }
}
</i18n>
