<template>
    <div>
        <div class="text-black border-2 border-hm-green rounded-xl bg-white relative flex py-2 px-4 mb-4 space-x-3 items-center" v-show="paymentStatus === 'payment-success'">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-11 h-11" width="60.355" height="48.154" viewBox="0 0 60.355 48.154">
                <g id="Group_5438" data-name="Group 5438" transform="translate(-0.014 -3.252)">
                <g id="Group_5437" data-name="Group 5437" transform="translate(0.014 3.252)">
                    <path id="Path_43356" data-name="Path 43356" d="M54.875,10.105a4.652,4.652,0,0,0-1.987-2.163,42.274,42.274,0,0,0-7.042-3.42,6.388,6.388,0,0,0-2.062-.4,3.445,3.445,0,0,0-1.71.478A16.1,16.1,0,0,0,39.56,7.666c-4.552,6.513-14.611,22.633-17.1,25.148h-.176c-1.609-.8-5.03-3.118-6.488-3.973a3.244,3.244,0,0,0-1.157-.528.88.88,0,0,0-.88.88c0,.905,1.358,1.157,1.936,1.509,1.71,1.081,4.376,2.993,5.784,3.7a2.062,2.062,0,0,0,1.861.2,35.534,35.534,0,0,0,4.476-5.558c4.426-6.262,11.04-16.095,14.234-20.4a15.315,15.315,0,0,1,1.383-1.76.679.679,0,0,1,.5,0,3.169,3.169,0,0,1,.956.251,30.6,30.6,0,0,1,3.269,1.484,35.209,35.209,0,0,1,3.722,1.76,9.077,9.077,0,0,1-.377.88C50.1,13.777,46.627,18.5,42.779,23.836c-6.086,8.3-13.052,17.78-15.215,20.622l-2.515,3.018-.8.679a2.213,2.213,0,0,1-.83-.327c-.6-.4-1.132-.93-1.786-1.308a88.168,88.168,0,0,1-7.268-5.382C11.6,38.925,8.9,36.662,7.949,35.656a1.031,1.031,0,0,1,0-.226,54.925,54.925,0,0,1,4.175-5.407c.478-.578,2.515-2.163.78-2.515a1.484,1.484,0,0,0-1.107.5,37.069,37.069,0,0,0-3.42,4c-.905,1.207-1.71,2.339-2.037,2.892a1.358,1.358,0,0,0,0,1.257,48.839,48.839,0,0,0,5.331,5.457,102.3,102.3,0,0,0,8.752,6.941c.729.478,1.333,1.157,2.062,1.559a3.9,3.9,0,0,0,1.433.5,2.641,2.641,0,0,0,1.559-.3,5.356,5.356,0,0,0,1.358-1.081l2.892-3.244C32.418,42.4,43.835,27.634,50.273,18.5a67.345,67.345,0,0,0,4.275-6.639A2.892,2.892,0,0,0,54.875,10.105Z" transform="translate(5.454 -2.482)" fill-rule="evenodd"/>
                    <path id="Path_43357" data-name="Path 43357" d="M18.723,37.88a52.81,52.81,0,0,1-6.513-5.03c-2.037-1.71-4.175-3.571-5.96-5.03-1.283-1.107-2.364-2.012-3.018-2.515,1.056-1.584,3.269-4.652,4.753-6.438.327-.428.8-.729.981-.956L11.983,19.7a.76.76,0,1,0,.855-1.257c-1.132-.855-2.188-1.635-2.892-2.112A3.269,3.269,0,0,0,8.739,15.8a3.043,3.043,0,0,0-1.484.78,72.7,72.7,0,0,0-5.684,5.885A14.712,14.712,0,0,0,.264,24.275a1.584,1.584,0,0,0,0,1.71,30.354,30.354,0,0,0,4.351,4.024c1.308,1.056,2.842,2.213,4.376,3.345a62.041,62.041,0,0,0,9.582,6.262.855.855,0,0,0,.956-.754.88.88,0,0,0-.8-.981Z" transform="translate(-0.014 7.868)" fill="#22c55d" fill-rule="evenodd"/>
                    <path id="Path_43358" data-name="Path 43358" d="M4.613,15.64h0Z" transform="translate(4.062 7.726)" fill-rule="evenodd"/>
                    <path id="Path_43359" data-name="Path 43359" d="M13.5,25.561c2.339-3.093,5.03-6.639,7.544-10.059,1.584-2.288,3.018-4.451,4.149-6.212a44.311,44.311,0,0,1,2.515-3.823,1.182,1.182,0,0,1,.654-.2,4.854,4.854,0,0,1,1.081.2l2.389.855a.754.754,0,0,0,1.006-.377.78.78,0,0,0-.352-1.031L29.977,3.733a6.715,6.715,0,0,0-1.886-.478,2.942,2.942,0,0,0-1.584.377,34.352,34.352,0,0,0-5.03,6.111c-.93,1.308-1.911,2.791-2.917,4.351-2.188,3.42-4.376,7.218-6.388,10.437a.88.88,0,0,0,1.433.981Z" transform="translate(10.687 -3.252)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
                </g>
            </svg>

            </div>
            <div class="font-bold">{{$t('ui.paymentSuccessMessage')}}</div>
        </div>
        <div class="text-black border-2 border-hm-brand rounded-xl bg-white relative flex py-2 px-4 mb-4 space-x-3 items-center" v-show="paymentStatus === 'payment-cancel'">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-11 h-11" width="52.404" height="52.438" viewBox="0 0 52.404 52.438">
                <g id="Group_5440" data-name="Group 5440" transform="translate(0.667 0.723)">
                <g id="Group_5439" data-name="Group 5439" transform="translate(0 -0.056)">
                    <path id="Path_43360" data-name="Path 43360" d="M50.818,14.732a85.7,85.7,0,0,0-.808-10.955,3.659,3.659,0,0,0-.681-1.617,5.658,5.658,0,0,0-2.7-1.127,127.2,127.2,0,0,0-21.4-1A161.961,161.961,0,0,0,3.957,1.48,5.126,5.126,0,0,0,1.3,2.586h0C.425,3.841,0,11.9,0,20.965c0,12.1.787,25.973,1.532,27.44A3.616,3.616,0,0,0,3.85,49.639a105.825,105.825,0,0,0,16.741,1.319c7.956.191,16.7,0,22.25-.4A19.676,19.676,0,0,0,48.8,49.469a3.893,3.893,0,0,0,1.6-2.127,15.868,15.868,0,0,0,.659-4.51C51.052,40.152,51.179,25.559,50.818,14.732ZM48.542,42.789a14.891,14.891,0,0,1-.191,2.829c-.128.7-.277,1.446-.787,1.744a14.89,14.89,0,0,1-4.02.681c-4.424.425-11.38.638-18.315.638a146.37,146.37,0,0,1-20.506-.957,10.3,10.3,0,0,1-1.468-.4c-.745-2-1.51-14.89-1.7-26.377-.17-8.658,0-16.379.808-17.528h0c.149-.213.808-.277,1.766-.447A162.243,162.243,0,0,1,25.249,1.863a154.8,154.8,0,0,1,18.719.787A11.1,11.1,0,0,1,47.8,3.5a15.443,15.443,0,0,1,.319,1.787c.255,2.127.4,5.573.489,9.508C48.925,25.559,48.627,40.109,48.542,42.789Z" transform="translate(0 0.056)" fill="#fea900" stroke="#fea900" stroke-width="1.333" fill-rule="evenodd"/>
                    <path id="Path_43361" data-name="Path 43361" d="M35.587,6.644c-3.255,2.68-6.871,5.85-10.4,9.147-1.106,1.042-2.127,2.127-3.255,3.169-.7-.723-1.383-1.468-2.127-2.127-4.68-4.722-9.147-8.785-11.253-10.508a.617.617,0,0,0-.893,0,.638.638,0,0,0,0,.893C9.465,8.92,12.933,12.919,17,17.3l3.127,3.361c-1.149,1.212-2.212,2.382-3.276,3.531-3.893,4.254-7.147,8-9.232,10.274a.638.638,0,0,0,0,.893.617.617,0,0,0,.893,0C11,33,15.23,28.979,19.888,24.427c.659-.638,1.34-1.34,2-2l1.532,1.553c4.424,4.531,8.679,8.636,10.955,10.763A.723.723,0,0,0,35.4,33.723c-1.957-2.127-5.36-6.02-9.232-10.232L23.59,20.7l2.935-3.084c3.446-3.51,6.849-7.041,10.019-9.955a.731.731,0,0,0-.957-1.106Z" transform="translate(4.425 3.742)" stroke="#000" stroke-width="1.333" fill-rule="evenodd"/>
                </g>
                </g>
            </svg>
            </div>
            <div class="font-bold">{{$t('ui.paymentCancelMessage')}}</div>
        </div>
        <div class="text-black border-2 border-hm-green rounded-xl bg-white relative flex py-2 px-4 mb-4 space-x-3 items-center" v-show="paymentStatus === 'subscription-updated'">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-11 h-11" width="60.355" height="48.154" viewBox="0 0 60.355 48.154">
                <g id="Group_5438" data-name="Group 5438" transform="translate(-0.014 -3.252)">
                <g id="Group_5437" data-name="Group 5437" transform="translate(0.014 3.252)">
                    <path id="Path_43356" data-name="Path 43356" d="M54.875,10.105a4.652,4.652,0,0,0-1.987-2.163,42.274,42.274,0,0,0-7.042-3.42,6.388,6.388,0,0,0-2.062-.4,3.445,3.445,0,0,0-1.71.478A16.1,16.1,0,0,0,39.56,7.666c-4.552,6.513-14.611,22.633-17.1,25.148h-.176c-1.609-.8-5.03-3.118-6.488-3.973a3.244,3.244,0,0,0-1.157-.528.88.88,0,0,0-.88.88c0,.905,1.358,1.157,1.936,1.509,1.71,1.081,4.376,2.993,5.784,3.7a2.062,2.062,0,0,0,1.861.2,35.534,35.534,0,0,0,4.476-5.558c4.426-6.262,11.04-16.095,14.234-20.4a15.315,15.315,0,0,1,1.383-1.76.679.679,0,0,1,.5,0,3.169,3.169,0,0,1,.956.251,30.6,30.6,0,0,1,3.269,1.484,35.209,35.209,0,0,1,3.722,1.76,9.077,9.077,0,0,1-.377.88C50.1,13.777,46.627,18.5,42.779,23.836c-6.086,8.3-13.052,17.78-15.215,20.622l-2.515,3.018-.8.679a2.213,2.213,0,0,1-.83-.327c-.6-.4-1.132-.93-1.786-1.308a88.168,88.168,0,0,1-7.268-5.382C11.6,38.925,8.9,36.662,7.949,35.656a1.031,1.031,0,0,1,0-.226,54.925,54.925,0,0,1,4.175-5.407c.478-.578,2.515-2.163.78-2.515a1.484,1.484,0,0,0-1.107.5,37.069,37.069,0,0,0-3.42,4c-.905,1.207-1.71,2.339-2.037,2.892a1.358,1.358,0,0,0,0,1.257,48.839,48.839,0,0,0,5.331,5.457,102.3,102.3,0,0,0,8.752,6.941c.729.478,1.333,1.157,2.062,1.559a3.9,3.9,0,0,0,1.433.5,2.641,2.641,0,0,0,1.559-.3,5.356,5.356,0,0,0,1.358-1.081l2.892-3.244C32.418,42.4,43.835,27.634,50.273,18.5a67.345,67.345,0,0,0,4.275-6.639A2.892,2.892,0,0,0,54.875,10.105Z" transform="translate(5.454 -2.482)" fill-rule="evenodd"/>
                    <path id="Path_43357" data-name="Path 43357" d="M18.723,37.88a52.81,52.81,0,0,1-6.513-5.03c-2.037-1.71-4.175-3.571-5.96-5.03-1.283-1.107-2.364-2.012-3.018-2.515,1.056-1.584,3.269-4.652,4.753-6.438.327-.428.8-.729.981-.956L11.983,19.7a.76.76,0,1,0,.855-1.257c-1.132-.855-2.188-1.635-2.892-2.112A3.269,3.269,0,0,0,8.739,15.8a3.043,3.043,0,0,0-1.484.78,72.7,72.7,0,0,0-5.684,5.885A14.712,14.712,0,0,0,.264,24.275a1.584,1.584,0,0,0,0,1.71,30.354,30.354,0,0,0,4.351,4.024c1.308,1.056,2.842,2.213,4.376,3.345a62.041,62.041,0,0,0,9.582,6.262.855.855,0,0,0,.956-.754.88.88,0,0,0-.8-.981Z" transform="translate(-0.014 7.868)" fill="#22c55d" fill-rule="evenodd"/>
                    <path id="Path_43358" data-name="Path 43358" d="M4.613,15.64h0Z" transform="translate(4.062 7.726)" fill-rule="evenodd"/>
                    <path id="Path_43359" data-name="Path 43359" d="M13.5,25.561c2.339-3.093,5.03-6.639,7.544-10.059,1.584-2.288,3.018-4.451,4.149-6.212a44.311,44.311,0,0,1,2.515-3.823,1.182,1.182,0,0,1,.654-.2,4.854,4.854,0,0,1,1.081.2l2.389.855a.754.754,0,0,0,1.006-.377.78.78,0,0,0-.352-1.031L29.977,3.733a6.715,6.715,0,0,0-1.886-.478,2.942,2.942,0,0,0-1.584.377,34.352,34.352,0,0,0-5.03,6.111c-.93,1.308-1.911,2.791-2.917,4.351-2.188,3.42-4.376,7.218-6.388,10.437a.88.88,0,0,0,1.433.981Z" transform="translate(10.687 -3.252)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
                </g>
            </svg>

            </div>
            <div class="font-bold">{{$t('ui.subUpdatedSuccessfull')}}</div>
        </div>
        <div class="text-black border-2 border-hm-green rounded-xl bg-white relative flex py-2 px-4 mb-4 space-x-3 items-center" v-show="paymentStatus === 'subscription-cancaled'">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-11 h-11" width="60.355" height="48.154" viewBox="0 0 60.355 48.154">
                <g id="Group_5438" data-name="Group 5438" transform="translate(-0.014 -3.252)">
                <g id="Group_5437" data-name="Group 5437" transform="translate(0.014 3.252)">
                    <path id="Path_43356" data-name="Path 43356" d="M54.875,10.105a4.652,4.652,0,0,0-1.987-2.163,42.274,42.274,0,0,0-7.042-3.42,6.388,6.388,0,0,0-2.062-.4,3.445,3.445,0,0,0-1.71.478A16.1,16.1,0,0,0,39.56,7.666c-4.552,6.513-14.611,22.633-17.1,25.148h-.176c-1.609-.8-5.03-3.118-6.488-3.973a3.244,3.244,0,0,0-1.157-.528.88.88,0,0,0-.88.88c0,.905,1.358,1.157,1.936,1.509,1.71,1.081,4.376,2.993,5.784,3.7a2.062,2.062,0,0,0,1.861.2,35.534,35.534,0,0,0,4.476-5.558c4.426-6.262,11.04-16.095,14.234-20.4a15.315,15.315,0,0,1,1.383-1.76.679.679,0,0,1,.5,0,3.169,3.169,0,0,1,.956.251,30.6,30.6,0,0,1,3.269,1.484,35.209,35.209,0,0,1,3.722,1.76,9.077,9.077,0,0,1-.377.88C50.1,13.777,46.627,18.5,42.779,23.836c-6.086,8.3-13.052,17.78-15.215,20.622l-2.515,3.018-.8.679a2.213,2.213,0,0,1-.83-.327c-.6-.4-1.132-.93-1.786-1.308a88.168,88.168,0,0,1-7.268-5.382C11.6,38.925,8.9,36.662,7.949,35.656a1.031,1.031,0,0,1,0-.226,54.925,54.925,0,0,1,4.175-5.407c.478-.578,2.515-2.163.78-2.515a1.484,1.484,0,0,0-1.107.5,37.069,37.069,0,0,0-3.42,4c-.905,1.207-1.71,2.339-2.037,2.892a1.358,1.358,0,0,0,0,1.257,48.839,48.839,0,0,0,5.331,5.457,102.3,102.3,0,0,0,8.752,6.941c.729.478,1.333,1.157,2.062,1.559a3.9,3.9,0,0,0,1.433.5,2.641,2.641,0,0,0,1.559-.3,5.356,5.356,0,0,0,1.358-1.081l2.892-3.244C32.418,42.4,43.835,27.634,50.273,18.5a67.345,67.345,0,0,0,4.275-6.639A2.892,2.892,0,0,0,54.875,10.105Z" transform="translate(5.454 -2.482)" fill-rule="evenodd"/>
                    <path id="Path_43357" data-name="Path 43357" d="M18.723,37.88a52.81,52.81,0,0,1-6.513-5.03c-2.037-1.71-4.175-3.571-5.96-5.03-1.283-1.107-2.364-2.012-3.018-2.515,1.056-1.584,3.269-4.652,4.753-6.438.327-.428.8-.729.981-.956L11.983,19.7a.76.76,0,1,0,.855-1.257c-1.132-.855-2.188-1.635-2.892-2.112A3.269,3.269,0,0,0,8.739,15.8a3.043,3.043,0,0,0-1.484.78,72.7,72.7,0,0,0-5.684,5.885A14.712,14.712,0,0,0,.264,24.275a1.584,1.584,0,0,0,0,1.71,30.354,30.354,0,0,0,4.351,4.024c1.308,1.056,2.842,2.213,4.376,3.345a62.041,62.041,0,0,0,9.582,6.262.855.855,0,0,0,.956-.754.88.88,0,0,0-.8-.981Z" transform="translate(-0.014 7.868)" fill="#22c55d" fill-rule="evenodd"/>
                    <path id="Path_43358" data-name="Path 43358" d="M4.613,15.64h0Z" transform="translate(4.062 7.726)" fill-rule="evenodd"/>
                    <path id="Path_43359" data-name="Path 43359" d="M13.5,25.561c2.339-3.093,5.03-6.639,7.544-10.059,1.584-2.288,3.018-4.451,4.149-6.212a44.311,44.311,0,0,1,2.515-3.823,1.182,1.182,0,0,1,.654-.2,4.854,4.854,0,0,1,1.081.2l2.389.855a.754.754,0,0,0,1.006-.377.78.78,0,0,0-.352-1.031L29.977,3.733a6.715,6.715,0,0,0-1.886-.478,2.942,2.942,0,0,0-1.584.377,34.352,34.352,0,0,0-5.03,6.111c-.93,1.308-1.911,2.791-2.917,4.351-2.188,3.42-4.376,7.218-6.388,10.437a.88.88,0,0,0,1.433.981Z" transform="translate(10.687 -3.252)" fill="#22c55d" fill-rule="evenodd"/>
                </g>
                </g>
            </svg>

            </div>
            <div class="font-bold">{{$t('ui.subCancaled')}}</div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'PaymentStatusAlert',
  data() {
    return {
      paymentStatus: null,
    };
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.state': function() {
      this.paymentStatus = this.$route.params.state;
    },
  },
  async created() {
    this.paymentStatus = this.$route.params.state;
  },
};
</script>
