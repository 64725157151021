<template>
  <div class="bg min-h-screen bg-white flex flex-wrap content-center">
    <div class="max-w-xl mx-auto">
      <div class="bg-white overflow-hidden border-2 border-black rounded-lg">
        <div class="px-4 py-5 sm:p-6">
          <div class="mt-2 space-y-3 p-4 text-center">
            <svg
              class="h-20 mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 36 36"
            >
              <g transform="matrix(0.4,0,0,0.4,0,0)">
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="1.5"
                  stroke-width="5"
                  d="M36.4735 55.2535C32.6179 55.9969 28.9002 56.6708 25.1159 57.8249C22.7933 42.577 21.0954 27.2434 20.3164 11.8394C25.8159 10.1743 31.3886 9.78162 37.1069 10.1027C36.3028 25.1327 37.0867 40.2044 36.5121 55.2469C36.4995 55.2488 36.487 55.2516 36.4735 55.2535Z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill="#ffffff"
                  fill-rule="evenodd"
                  d="M33.4006 66.1634C25.9134 64.2213 21.3452 74.465 27.8101 78.8C33.5283 82.6341 40.9371 74.996 37.223 69.3475"
                  clip-rule="evenodd"
                ></path>
                <path
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="1.5"
                  stroke-width="5"
                  d="M33.4006 66.1634C25.9134 64.2213 21.3452 74.465 27.8101 78.8C33.5283 82.6341 40.9371 74.996 37.223 69.3475"
                ></path>
                <path
                  fill="#ffffff"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="1.5"
                  stroke-width="5"
                  d="M69.6505 20.8361C67.7994 33.0296 66.3329 45.3589 63.8078 57.4405L51.4977 55.4769C51.4975 44.3475 51.4649 31.0129 52.3201 18.857C58.0808 18.8145 64.0788 19.2838 69.6505 20.8361Z"
                ></path>
                <g>
                  <path
                    fill="#ffffff"
                    fill-rule="evenodd"
                    d="M58.7249 67.2433C52.4706 65.4737 48.1696 74.8208 53.7183 78.4611C59.5675 82.299 65.5315 73.6054 61.338 68.7485"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-miterlimit="1.5"
                    stroke-width="5"
                    d="M58.7249 67.2433C52.4706 65.4737 48.1696 74.8208 53.7183 78.4611C59.5675 82.299 65.5315 73.6054 61.338 68.7485"
                  ></path>
                </g>
              </g>
            </svg>

            <h1 class="font-bold text-4xl">{{ $t("ui.addBlogPaymentCancelTitle") }}</h1>
            <p class="text-1xl text-gray-400">{{ $t("ui.addBlogPaymentCancelDesc") }}</p>
            <div class="space-x-4">
              <Button class=" mt-10" @click.native="goToDashboard">{{ $t("ui.skipToDashboard") }}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      token: '',
      loading: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapState({
      isSidebarVisible: (state) => state.ui.isSidebarVisible,
      selectedWebsite: (state) => state.website.selectedWebsite,

      // websites
      createSiteInput: (state) => state.website.addWebsiteModel,
    }),
  },
  beforeMount() {
    this.setSidebarVisibility(false);
  },
  methods: {
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    goToDashboard() {
      this.$router.push({ path: `/articles/${this.selectedWebsite.id}/list` });
    },
  },
};
</script>

<style scoped>
.bg {
  background-image: url("../assets/images/add-blog-bg.png");
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
