<template>
 <div id="reset" class="h-screen overflow-y-auto">
    <div class="min-h-screen bg-black  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="fixed grid place-items-center h-screen w-full -z-0 pointer-events-none">
        <img src="../assets/auth-bg.svg" class="min-w-full" alt="">
      </div>
        <div class="mx-auto w-full max-w-sm lg:w-96 p-8 pt-2 bg-black rounded-md shadow-lg">
          <div class="text-center">
            <img class="h-20 my-6 w-auto mx-auto" src="../assets/logo.svg">
            <h2 class="mt-6 text-3xl font-bold text-white"  v-if="visibleStep !== 3">
              {{$t('ui.passwordResetTitle')}}
            </h2>
            <h2 class="mt-6 text-3xl font-bold text-white"  v-if="visibleStep === 3">
              {{$t('ui.passwordResetSuccessMessage')}}
            </h2>
            <p class="mt-2 text-sm text-gray-400 max-w" v-if="visibleStep !== 3">
              {{$t('ui.or')}}
              <router-link
                :to="'/login'"
                tag="a"
                class="font-medium text-blue-400 hover:text-blue-300">{{$t('ui.passwordResetLoginLink')}}</router-link>
            </p>
            <div class="message text-red-500 text-xs mt-1" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form @submit.prevent="resetPasswordStepOne" class="space-y-6" v-if="visibleStep == 1">
                <div>
                  <!-- <label for="email" class="block text-sm font-medium text-gray-300">
                    {{$t('ui.emailLabel')}}
                  </label> -->
                  <div class="mt-1 relative">
                    <input
                      :placeholder="$t('ui.emailPlaceholder')"
                      v-model="email"
                      id="email" name="email" type="email" autocomplete="email" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow min-w-0 rounded-lg sm:text-sm border-gray-300 appearance-none block w-full"
                      :class="{'ring-2 ring-green-500': email && validation.isPassed('email')}">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                          v-if="email && validation.isTouched('email')">
                          <svg v-if="validation.hasError('email')" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                          <svg v-if="email && validation.isPassed('email')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                        </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1">
                    {{ validation.firstError('email') }}
                  </div>
                </div>
                <div>
                  <Button native-type="submit" type="brand" class="w-full" :loading="loading">
                    {{$t('ui.reset')}}
                  </Button>
                </div>
              </form>
              <form @submit.prevent="forgotPasswordSubmit" class="space-y-6" v-if="visibleStep == 2">
                <p class="mt-2 text-sm text-gray-400 max-w">
                  {{$t('ui.passwordResetInstructionsMessage')}}
                </p>
                <p class="mt-2 text-sm text-gray-400 max-w">
                  {{$t('ui.passwordResetCodeMessage')}}
                </p>
                <div>
                  <!-- <label for="code" class="block text-sm font-medium text-gray-300">
                    {{$t('ui.codeLabel')}}
                  </label> -->
                  <div class="mt-1 relative">
                    <input
                      v-model="code"
                      :placeholder="$t('ui.codePlaceholder')"
                      id="code" name="code" type="text" required class="appearance-none block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300"
                      :class="{'ring-2 ring-green-500': code && validation.isPassed('code')}">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        v-if="code && validation.isTouched('code')">
                        <svg v-if="validation.hasError('code')" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                        <svg v-if="code && validation.isPassed('code')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                      </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1">
                    {{ validation.firstError('code') }}
                  </div>
                </div>
                <div class="space-y-1">
                  <!-- <label for="password"
                    class="block text-sm font-medium text-gray-300">
                    {{$t('ui.passwordLabel')}}
                  </label> -->
                  <div class="mt-1 relative">
                    <input
                    v-model="password"
                    :placeholder="$t('ui.passwordNewPlaceholder')"
                    id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-if="password && validation.isTouched('password')">
                      <svg v-if="validation.hasError('password')" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                      <svg v-if="password && validation.isPassed('password')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                    </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
                    {{ validation.firstError('password') }}
                  </div>
                </div>
                <div class="space-y-1">
                  <!-- <label for="password"
                    class="block text-sm font-medium text-gray-300">
                    {{$t('ui.confirmPasswordLabel')}}
                  </label> -->
                  <div class="mt-1 relative">
                    <input
                    v-model="confirmPassword"
                    :placeholder="$t('ui.confirmPasswordPlaceholder')"
                    id="confirmPassword" name="confirmPassword" type="password" autocomplete="current-password" required class="appearance-none block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                    <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-if="confirmPassword && validation.isTouched('confirmPassword')">
                      <svg v-if="validation.hasError('confirmPassword')" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                      <svg v-if="confirmPassword && validation.isPassed('confirmPassword')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                    </div>
                  </div>
                  <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('confirmPassword')">
                    {{ validation.firstError('confirmPassword') }}
                  </div>
                </div>
                <div>
                  <button type="submit" class="w-full inline-flex items-center justify-center border border-transparent font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out text-black bg-hm-brand hover:bg-yellow-300 px-6 py-3 text-sm" :class="{loading: loading}">
                    {{$t('ui.confirm')}}
                  </button>
                </div>
              </form>
              <div v-if="visibleStep === 3">
                <router-link
                  :to="'/login'"
                  tag="a"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-hm-secondary hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">{{$t('ui.goToLoginPage')}}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      loading: false,
      visibleStep: 1,
      username: null,
      isBtnLoading: false,
      error: null,
      email: null,
      code: null,
      password: null,
      confirmPassword: null,
      errorMessage: null,
      showLoginMessage: false,
      submitted: false,
      customer: null,
    };
  },
  validators: {
    // eslint-disable-next-line
    email: (value) => Validator.value(value).required().email(),
    code: (value) => Validator.value(value).required(),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      // console.log('sss');
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  mounted() {
    this.customer = this.$route.params.customer;
  },

  methods: {
    btnText(text) {
      if (this.isBtnLoading) return 'Loading...';
      return text;
    },
    handleResetSubmit(e) {
      e.preventDefault();
      this.newPasswordForm.validateFieldsAndScroll((err, values) => {
        // console.log('reset values', values);
        if (!err) {
          this.forgotPasswordSubmit(values);
        }
      });
    },
    handleConfirmBlur(e) {
      const { value } = e.target;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const { newPasswordForm } = this;
      if (value && value !== newPasswordForm.getFieldValue('password')) {
        callback('Two passwords that you enter are inconsistent!');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const { newPasswordForm } = this;
      if (value && this.confirmDirty) {
        newPasswordForm.validateFields(['confirm'], { force: true });
      }
      callback();
    },
    // STEP ONE
    async resetPasswordStepOne() {
      const isValid = await this.$validate('email');
      // console.log('login', isValid);
      if (!isValid) return;
      this.loading = true;
      try {
        await this.$Amplify.Auth.forgotPassword(this.email);
        // console.log('%cRESULT ', 'color: lime', result, this.email);
        this.errorMessage = null;
        this.visibleStep = 2;
        this.loading = false;
      } catch (error) {
        // console.log('%cRESULT ', 'color: lime', error, this.email);
        this.errorMessage = error.message;
        this.loading = false;
      }
    },

    async forgotPasswordSubmit() {
      // console.log('forgotPasswordSubmit values', this.email);
      const isValid = await this.$validate(['code', 'password', ['confirmPassword']]);
      // console.log('login', isValid);
      if (!isValid) return;
      try {
        this.loading = true;
        await this.$Amplify.Auth.forgotPasswordSubmit(
          this.email,
          this.code,
          this.password,
        );
        this.errorMessage = null;
        this.visibleStep = 3;
        this.loading = false;
      } catch (error) {
        this.errorMessage = error.message;
        this.loading = false;
      }
    },

    goToLogin() {
      this.$router.push({ path: 'login' });
    },
  },
};
</script>
<style scoped>
.bg-hm-black {
  background-image: url('../assets/images/bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
