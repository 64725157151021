<template>
  <div>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="divide-y divide-gray-300">
          <div class="py-7">
            <div>
              <div class="font-bold">{{$t('ui.wpPluginTitle')}}</div>
              <div class="text-[14px]">{{$t('ui.installWPPluginDescription')}}</div>
            </div>
            <div class="mt-4">
              <div class="rounded-md bg-blue-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700 font-bold">
                      <span class="select-none">{{$t('ui.apikey')}}: </span>
                      <span>{{settings['wp-token']}}</span>
                    </p>
                    <p class="mt-3 text-sm md:mt-0 md:ml-6">
                      <a href="#" @click="copyCode(settings['wp-token'])" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">{{$t('ui.copyToClipboard')}}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="py-8 pt-4 space-x-4">
              <div class="md:space-x-3">
                <a :href="pluginLink" target="_blank" class="inline-flex text-white bg-black hover:bg-blue-700 items-center justify-center border border-transparent px-6 py-3 text-sm font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out">{{$t('ui.downloadPlugin')}}</a>
                <Button type="default" @click.native="showGuideModal = true">{{$t('ui.guideButton')}}</Button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Modal :show.sync="showGuideModal" :width="'2xl'">
      <div slot="header"></div>
      <Guide :token="settings['wp-token']" :pluginUrl="pluginLink"></Guide>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';
import Guide from '../components/PluginGuide.vue';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    Guide,
  },
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      loadingDelete: false,
      confirmPassword: null,
      password: null,
      saveError: null,
      oldPassword: null,
      errorMessage: null,
      showGuideModal: false,
      showDeleteModal: false,
      deleteConfirmation: '',
      feedId: null,
      watermarkTemplate: false,
      feedPatch: {
        displayName: '',
        description: '',
        language: '',
        url: '',
        explicitContent: '',
      },
      languages: [
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],

      audioPreviewUrl: null,
      playButton: null,
      activeTab: 'general',
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      customers: (state) => state.customer.list,
      selectedWebsite: (state) => state.website.selectedWebsite,
      websiteList: (state) => state.website.websiteList,
      userId: (state) => state.auth.userId,
      languageList: (state) => state.utils.languageList,
      pluginLink: (state) => state.utils.pluginUrl,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      selectedWebsiteAPTemplates: (state) => state.website.selectedWebsiteAPTemplates,
      selectedWebsiteAP: (state) => state.website.selectedWebsiteAP,
      selectedWebsiteAPTemplateOutro: (state) => state.website.selectedWebsiteAPTemplateOutro,
    }),
    settings() {
      if (!this.selectedWebsite && !this.selectedWebsite.id) return {};
      return this.selectedWebsite.settings;
    },
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    isEnterprise() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Enterprise';
    },
    isPremium() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Premium';
    },
    channelId() {
      return this.selectedWebsiteAP.id;
    },
    channelOutroTemplate() {
      if (!this.selectedWebsiteAPTemplates.outro) return {};
      return this.selectedWebsiteAPTemplates.outro;
    },
  },

  watch: {
    selectedWebsite(val) {
      // console.log('no website details', val, oldVal, this.feedPatch.displayName);
      if (this.feedPatch.displayName === '' || this.feedId !== val.id) {
        // console.log('no website details', val);
        this.init();
      }
    },
  },
  validators: {
    oldPassword: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  mounted() {
    this.deleteConfirmation = '';
    this.init();
  },
  methods: {
    ...mapMutations({
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      getWebsiteById: 'website/getWebsiteById',
      updateWebsite: 'website/updateWebsite',
      fetchWebsites: 'website/fetchWebsites',
      generateTextPreview: 'website/generateTextPreview',
      switchTemplateStatus: 'website/switchTemplateStatus',
    }),

    async init() {
      if (this.selectedWebsite.id) return;
      const result = await this.getWebsiteById(this.selectedWebsite.id);
      console.log('result', result);
    },

    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.tokenCopied'),
      });
    },
    goTo(path) {
      this.$router.push({ path });
    },
  },
};
</script>
