const appConfig = {
  apiUrl: process.env.VUE_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  playerUrl: process.env.VUE_APP_APLAYER_URL,
  audioUrl: process.env.VUE_APP_AUDIO_URL,
  audioPartsUrl: process.env.VUE_APP_AUDIO_PART_URL,
  samplesUrl: process.env.VUE_APP_AWS_VOICE_SAMPLES_URL,
  pluginUrl: process.env.VUE_APP_PLUGIN_URL,
  rssUrl: process.env.VUE_APP_UPLOAD_URL,
  wsUrl: process.env.VUE_APP_WSS_URL,
  hostedAuthUrl: process.env.VUE_APP_HOSTED_AUTH,
  domain: process.env.VUE_APP_DOMAIN,
  clientId: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  uploadUrl: process.env.VUE_APP_AWS_UPLOAD_URL,
  userPoolId: process.env.VUE_APP_AWS_USER_POOLS_ID,
};

export default appConfig;
