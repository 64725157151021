/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import GQL from '../graphql';
import APP_CONFIG from '../config';

const getDefaultState = () => {
  console.log('');
  return {
    name: 'Website',
    websiteList: [],
    websiteTypes: [
      'PODCAST',
      'FLASH_BRIEFING',
      'AUDIO_PLAYER',
    ],
    addWebsiteTypes: [
      'podcast',
      'flash-briefing',
      'web-player',
    ],
    articleStatus: [
      'NEW',
      'EXTRACTING',
      'EXTRACTED',
      'GENERATING',
      'GENERATED',
      'PENDING_APPROVAL',
      'APPROVED',
      'SCHEDULED_FOR_PUBLISHING',
      'PUBLISHING',
      'PUBLISHED',
      'FAILED',
    ],
    episodePartType: [
      'CONTENT',
      'TITLE',
      'PRERECORDED',
      'CUSTOM',
      'ADVERT',
    ],
    addWebsiteModel: {
    },
    selectedWebsite: {},
    selectedWebsiteLanguage: 'en-US',
    selectedWebsiteChannels: {},
    selectedWebsitePodcast: null,
    selectedWebsitePodcastTemplates: [],
    selectedWebsiteFB: null,
    selectedWebsiteFBTemplates: [],
    selectedWebsiteAP: null,
    selectedWebsiteAPTemplates: [],
    selectedWebsiteAPTemplateOutro: {},
    selectedWebsiteAPTemplateTitle: {},
    selectedWebsiteAPTemplateContent: {},
    selectedWebsiteAPVoiceSettings: {},
    selectedWebsiteArticles: [],
    selectedWebsiteArticlesPageData: {},
    selectedWebsiteArticle: {},

    statusColor: {
      NEW: '#aaa',
      EXTRACTING: '#aaa',
      CHECKED: '#00e83d',
      EXTRACTED: '#aaa',
      GENERATING: '#2db7f5',
      GENERATED: '#87d068',
      PENDING_APPROVAL: '#108ee9',
      APPROVED: '#00e83d',
      SCHEDULED_FOR_PUBLISHING: '#87d068',
      PUBLISHING: '#aaa',
      PUBLISHED: '#00e83d',
      FAILED: '#f50',
      MISSING: '#f50',
    },

    statusWithAudio: [
      'PENDING_APPROVAL',
      'APPROVED',
      'SCHEDULED_FOR_PUBLISHING',
      'PUBLISHING',
      'PUBLISHED',
    ],
    publishingStatus: [
      'PENDING_APPROVAL',
      'APPROVED',
      'SCHEDULED_FOR_PUBLISHING',
      'PUBLISHING',
      'GENERATING',
      'CHECKING_CHANGES',
      'ESTIMATING_COSTS',
      'CREDITS_RESERVATION',
      'GENERATING_SPEECH',
      'AUDIO_STITCHING',
      'CONSUMING_CREDIT',
    ],

    languages: [
      {
        key: 'en-US',
        label: 'United States',
      },
      {
        key: 'en-GB',
        label: 'United Kingdom',
      },
      {
        key: 'pl-PL',
        label: 'Polish',
      },
    ],
    audioUrl: APP_CONFIG.audioUrl,

    loadings: {
      websites: false,
      feeds: false,
      website: false,
      saving: false,
      detect: false,
      feedItem: false,
    },

    podcastCategoryList: [
      'Arts',
      'Business',
      'Comedy',
      'Education',
      'Fiction',
      'Government',
      'History',
      'Health & Fitness',
      'Kids & Family',
      'Leisure',
      'Music',
      'News',
      'Religion & Spirituality',
      'Science',
      'Society & Culture',
      'Sports',
      'Technology',
      'True Crime',
      'TV & Film',
    ],

    // updated 2021
    count: 0,
    hasNextPage: true,
    pageInfo: {},
    articles: [],
    articlesById: {},
    selectedArticleData: {},
    totalCount: 0,
    savedWebsiteId: null,
  };
};

const state = getDefaultState();
// const state = {
//   name: 'Website',
//   websiteList: [],
//   websiteTypes: [
//     'PODCAST',
//     'FLASH_BRIEFING',
//     'AUDIO_PLAYER',
//   ],
//   addWebsiteTypes: [
//     'podcast',
//     'flash-briefing',
//     'web-player',
//   ],
//   articleStatus: [
//     'NEW',
//     'EXTRACTING',
//     'EXTRACTED',
//     'GENERATING',
//     'GENERATED',
//     'PENDING_APPROVAL',
//     'APPROVED',
//     'SCHEDULED_FOR_PUBLISHING',
//     'PUBLISHING',
//     'PUBLISHED',
//     'FAILED',
//   ],
//   episodePartType: [
//     'CONTENT',
//     'TITLE',
//     'PRERECORDED',
//     'CUSTOM',
//     'ADVERT',
//   ],
//   addWebsiteModel: {

//   },
//   selectedWebsite: {},
//   selectedWebsiteLanguage: 'en-US',
//   selectedWebsiteChannels: {},
//   selectedWebsitePodcast: null,
//   selectedWebsitePodcastTemplates: [],
//   selectedWebsiteFB: null,
//   selectedWebsiteFBTemplates: [],
//   selectedWebsiteAP: null,
//   selectedWebsiteAPTemplates: [],
//   selectedWebsiteAPTemplateOutro: {},
//   selectedWebsiteAPTemplateTitle: {},
//   selectedWebsiteAPTemplateContent: {},
//   selectedWebsiteAPVoiceSettings: {},
//   selectedWebsiteArticles: [],
//   selectedWebsiteArticlesPageData: {},
//   selectedWebsiteArticle: {},

//   statusColor: {
//     NEW: '#aaa',
//     EXTRACTING: '#aaa',
//     CHECKED: '#00e83d',
//     EXTRACTED: '#aaa',
//     GENERATING: '#2db7f5',
//     GENERATED: '#87d068',
//     PENDING_APPROVAL: '#108ee9',
//     APPROVED: '#00e83d',
//     SCHEDULED_FOR_PUBLISHING: '#87d068',
//     PUBLISHING: '#aaa',
//     PUBLISHED: '#00e83d',
//     FAILED: '#f50',
//     MISSING: '#f50',
//   },

//   statusWithAudio: [
//     'PENDING_APPROVAL',
//     'APPROVED',
//     'SCHEDULED_FOR_PUBLISHING',
//     'PUBLISHING',
//     'PUBLISHED',
//   ],
//   publishingStatus: [
//     'PENDING_APPROVAL',
//     'APPROVED',
//     'SCHEDULED_FOR_PUBLISHING',
//     'PUBLISHING',
//     'GENERATING',
//   ],

//   languages: [
//     {
//       key: 'en-US',
//       label: 'United States',
//     },
//     {
//       key: 'en-GB',
//       label: 'United Kingdom',
//     },
//     {
//       key: 'pl-PL',
//       label: 'Polish',
//     },
//   ],
//   audioUrl: APP_CONFIG.audioUrl,

//   loadings: {
//     websites: false,
//     feeds: false,
//     website: false,
//     saving: false,
//     detect: false,
//     feedItem: false,
//   },

//   podcastCategoryList: [
//     'Arts',
//     'Business',
//     'Comedy',
//     'Education',
//     'Fiction',
//     'Government',
//     'History',
//     'Health & Fitness',
//     'Kids & Family',
//     'Leisure',
//     'Music',
//     'News',
//     'Religion & Spirituality',
//     'Science',
//     'Society & Culture',
//     'Sports',
//     'Technology',
//     'True Crime',
//     'TV & Film',
//   ],

//   // updated 2021
//   count: 0,
//   hasNextPage: true,
//   pageInfo: {},
//   articles: [],
//   articlesById: {},
//   selectedArticleData: {},
//   totalCount: 0,
//   savedWebsiteId: null,
// };

const mutations = {
  clearState: (state) => {
    Object.assign(state, getDefaultState());
    console.log('clearstate');
  },
  setArticles: (state, payload) => {
    const articlesById = {};
    console.groupCollapsed('%cSET ARTICLE DATA', 'color:lime');
    payload.forEach((article) => {
      const articleData = {
        original: article,
        originalStatus: article.status,
        isModified: false,
        published: false,
        publishing: false,
        stats: article.playsCount,
      };

      console.group('%cARTICLE', 'color:red');
      // console.log('updated: ', article.updatedAt);
      // console.log('article: ', article);
      // console.log('status: ', article.status);
      const episodes = article.episodesByFeedItemId.nodes;
      let podcast = null;
      episodes.forEach((episode) => {
        // // console.log('episode: ', episode);
        if (episode.channelByChannelId.type === 'WEB_PLAYER') podcast = episode;
      });
      // console.log('podcast: ', podcast);
      if (podcast) {
        articleData.failed = podcast.status === 'FAILED';
        articleData.published = podcast.status === 'PUBLISHED';
        articleData.publishing = state.publishingStatus.indexOf(podcast.status) !== -1;
      }

      articleData.status = 'unpublished';

      if (articleData.published) articleData.status = 'published';
      if (articleData.publishing) articleData.status = 'publishing';
      if (articleData.failed) articleData.status = 'failed';

      const acceptedVersion = article.acceptedVersionId ? article.acceptedVersionId.split(':').pop() : -1;

      const hasChanges = (acceptedVersion !== article.currentVersion) && (acceptedVersion === -1 && article.currentVersion > 1);

      articleData.isModified = hasChanges;

      articlesById[article.id] = articleData;
      // console.log('articleData: ', articleData);
      console.groupEnd();
    });
    console.groupEnd();

    // console.log('articleData: ', payload);
    Vue.set(state, 'articlesById', articlesById);
    Vue.set(state, 'articles', payload);
  },
  setWebsiteModel: (state, payload) => {
    Vue.set(state, 'addWebsiteModel', payload);
  },
  setSavedWebsiteId: (state, payload) => {
    Vue.set(state, 'savedWebsiteId', payload);
  },
  setWebsiteList: (state, payload) => {
    const websites = payload.map((website) => {
      const newWebsite = {
        id: website.id,
        customerId: website.customerId,
        url: website.url,
        displayName: website.displayName,
        mainSiteUrl: website.mainSiteUrl,
        type: website.type,
        status: website.status, // usunąc?
        types: [],
      };
      newWebsite.channels = website.channelFeedsByFeedId.nodes.map((item) => {
        const newChannel = {
          ...item.channelByChannelId,
        };
        return newChannel;
      });

      newWebsite.channels.forEach((channel) => {
        newWebsite.types.push(channel.type);
      });

      return newWebsite;
    })
      .slice()
      .sort((a, b) => {
        if (a.displayName < b.displayName) { return -1; }
        if (a.displayName > b.displayName) { return 1; }
        return 0;
      });
    Vue.set(state, 'websiteList', websites);
    // TODO: add preprocessing
  },

  setWebsiteArticles: (state, payload) => {
    const articles = payload.map((article) => {
      const newArticle = {
        id: article.id,
        status: article.status,
        title: article.title,
        authorName: article.authorName,
        extractedText: article.extractedText,
        publishedAt: article.publishedAt,
        updatedAt: article.updatedAt,
        loading: false,
      };
      newArticle.episodes = article.episodesByFeedItemId.nodes
        .map((episode) => {
          const {
            audioMetadata,
          } = episode;
          const newEpisode = {
            id: episode.id,
            status: episode.status,
            channelId: episode.channelByChannelId.id,
            type: episode.channelByChannelId.type,
            audioMetadata,
          };
          const hasAudio = state.statusWithAudio.indexOf(episode.status) !== -1;
          const hasAudioFilePath = audioMetadata && audioMetadata.filePath;

          if (hasAudioFilePath) {
            newEpisode.track = {
              src: `${state.audioUrl}${audioMetadata.filePath}`,
              name: episode.title,
              autoplay: true,
              id: episode.id,
            };
            newArticle.hasAudio = hasAudio;
          }
          if (!hasAudioFilePath && hasAudio) {
            newEpisode.track = {
              src: `${state.audioUrl}${episode.id}.mp3`,
              name: episode.title,
              autoplay: true,
              id: episode.id,
            };
            newArticle.hasAudio = hasAudio;
          }
          if (episode.channelByChannelId.type === 'WEB_PLAYER') {
            newArticle.defaultTrack = newEpisode.track;
          }
          return newEpisode;
        });
      newArticle.types = article.episodesByFeedItemId.nodes
        .map((episode) => episode.channelByChannelId.type);
      return newArticle;
    });
    Vue.set(state, 'selectedWebsiteArticles', [
      ...state.selectedWebsiteArticles,
      ...articles,
    ]);
  },

  selectArticle: (state, article) => {
    Vue.set(state, 'selectedWebsiteArticle', article);
    const articleData = {
      original: article,
      originalStatus: article.status,
      isModified: false,
      published: false,
      stats: article.playsCount,
      updatedAt: article.updatedAt,
    };

    console.group('%cARTICLE', 'color:red');
    // console.log('updated: ', article.updatedAt);
    // console.log('article: ', article);
    // console.log('status: ', article.status);
    const episodes = article.episodesByFeedItemId.nodes;
    let podcast = null;
    episodes.forEach((episode) => {
      // // console.log('episode: ', episode);
      if (episode.channelByChannelId.type === 'WEB_PLAYER') podcast = episode;
    });
    // console.log('podcast: ', podcast);
    articleData.published = podcast.status === 'PUBLISHED';
    articleData.failed = podcast.status === 'FAILED';

    const acceptedVersion = article.acceptedVersionId ? article.acceptedVersionId.split(':').pop() : -1;

    // console.log('status: ', article.status);

    const hasChanges = (acceptedVersion !== article.currentVersion) && (acceptedVersion === -1 && article.currentVersion > 1);

    articleData.publishing = state.publishingStatus.indexOf(podcast.status) !== -1;

    articleData.status = 'unpublished';

    articleData.isModified = hasChanges;

    if (articleData.published) articleData.status = 'published';
    if (articleData.publishing) articleData.status = 'publishing';
    if (articleData.failed) articleData.status = 'failed';
    articleData.updatedAt = article.updatedAt;

    // console.log('selectedArticleData: ', hasChanges, articleData.isModified, acceptedVersion, article.currentVersion, articleData);
    console.groupEnd();
    Vue.set(state, 'selectedArticleData', articleData);
  },

  updateSelectedArticle: (state, article) => {
    Vue.set(state, 'selectedWebsiteArticle', article);
    const articleData = {
      original: article,
      originalStatus: article.status,
      isModified: false,
      published: false,
      updatedAt: article.updatedAt,
      currentVersion: article.currentVersion,
    };
    // console.log('updated: ', article.updatedAt);
    // console.log('article: ', article);
    // console.log('status: ', article.status);
    const episodes = article.episodesByFeedItemId.nodes;
    let podcast = null;
    episodes.forEach((episode) => {
      // // console.log('episode: ', episode);
      if (episode.channelByChannelId.type === 'WEB_PLAYER') podcast = episode;
    });
    // console.log('podcast: ', podcast, article.currentVersion);
    articleData.published = podcast.status === 'PUBLISHED';
    articleData.failed = podcast.status === 'FAILED';

    const acceptedVersion = article.acceptedVersionId ? article.acceptedVersionId.split(':').pop() : -1;

    const hasChanges = (acceptedVersion !== article.currentVersion) && (acceptedVersion === -1 && article.currentVersion > 1);

    // articleData.isModified = articleData.published && hasChanges;

    articleData.publishing = state.publishingStatus.indexOf(podcast.status) !== -1;

    articleData.isModified = hasChanges;

    articleData.status = 'unpublished';

    if (articleData.published) articleData.status = 'published';
    if (articleData.publishing) articleData.status = 'publishing';
    if (articleData.failed) articleData.status = 'failed';

    Vue.set(state, 'selectedArticleData', articleData);

    Vue.set(state.selectedArticleData, 'status', articleData.status);
    Vue.set(state.selectedArticleData, 'acceptedVersion', articleData.acceptedVersion);
    Vue.set(state.selectedArticleData, 'isModified', articleData.isModified);
    Vue.set(state.selectedArticleData, 'originalStatus', articleData.originalStatus);
    Vue.set(state.selectedArticleData, 'publishing', articleData.publishing);
    Vue.set(state.selectedArticleData, 'episodesByFeedItemId', articleData.episodesByFeedItemId);
    Vue.set(state.selectedArticleData, 'episodes', articleData.episodesByFeedItemId.nodes);
  },

  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },

  selectWebsite: (state, payload) => {
    const hasChannels = payload.channelFeedsByFeedId.nodes.length > 0;
    if (!hasChannels) return;

    // reset variables
    Vue.set(state, 'selectedWebsitePodcast', null);
    Vue.set(state, 'selectedWebsitePodcastTemplates', []);
    Vue.set(state, 'selectedWebsiteFB', null);
    Vue.set(state, 'selectedWebsiteFBTemplates', []);
    Vue.set(state, 'selectedWebsiteAP', null);
    Vue.set(state, 'selectedWebsiteAPTemplates', []);
    Vue.set(state, 'selectedWebsiteAPTemplateOutro', {});

    Vue.set(state, 'selectedWebsitePodcastTemplateOutro', {});
    Vue.set(state, 'selectedWebsitePodcastTemplateIntro', {});

    Vue.set(state, 'selectedWebsiteChannels', {}); // clear channel list
    payload.channelFeedsByFeedId.nodes.forEach((channel) => {
      const channelDetails = channel.channelByChannelId;
      Vue.set(state.selectedWebsiteChannels, channelDetails.id, channelDetails);
      Vue.set(state.selectedWebsiteChannels, channelDetails.type, channelDetails);
      const templates = channelDetails.channelEpisodeTemplatesByChannelId.nodes
        .map((template) => {
          const {
            displayName,
            episodeTemplatePartsByEpisodeTemplateId,
          } = template.episodeTemplateByEpisodeTemplateId;
          const newTemplateObj = {
            ...template,
            displayName,
          };
          const templateParts = episodeTemplatePartsByEpisodeTemplateId
            .nodes
            .map((templatePart) => {
              const templatePartObj = {
                ...templatePart,
              };
              return templatePartObj;
            });

          newTemplateObj.parts = templateParts;
          newTemplateObj.outro = templateParts.find((item) => item.displayName === 'Outro');
          newTemplateObj.intro = templateParts.find((item) => item.displayName === 'Intro');
          newTemplateObj.title = templateParts.find((item) => item.displayName === 'Title');
          newTemplateObj.content = templateParts.find((item) => item.displayName === 'Content');
          return newTemplateObj;
        });
      switch (channelDetails.type) {
        case 'PODCAST':
          Vue.set(state, 'selectedWebsitePodcast', channelDetails);
          Vue.set(state, 'selectedWebsitePodcastTemplates', templates);
          Vue.set(state, 'selectedWebsitePodcastTemplateOutro', templates[0].outro);
          Vue.set(state, 'selectedWebsitePodcastTemplateIntro', templates[0].intro);
          Vue.set(state, 'selectedWebsitePodcastTemplateTitle', templates[0].title);
          Vue.set(state, 'selectedWebsitePodcastTemplateContent', templates[0].content);
          Vue.set(state, 'selectedWebsiteAPVoiceSettings', templates[0].content.voiceSettings);
          break;
        case 'FLASH_BRIEFING':
          Vue.set(state, 'selectedWebsiteFB', channelDetails);
          Vue.set(state, 'selectedWebsiteFBTemplates', templates);
          break;
        case 'WEB_PLAYER':
          Vue.set(state, 'selectedWebsiteAP', channelDetails);
          Vue.set(state, 'selectedWebsiteAPTemplates', templates);
          Vue.set(state, 'selectedWebsiteAPTemplateOutro', templates[0].outro);
          Vue.set(state, 'selectedWebsiteAPTemplateTitle', templates[0].title);
          Vue.set(state, 'selectedWebsiteAPTemplateContent', templates[0].content);
          Vue.set(state, 'selectedWebsiteAPVoiceSettings', templates[0].content.settings);
          console.log('templates -===> ', templates);
          break;
        default:
          break;
      }
    });
    const website = {
      ...payload,
      categories: payload.categories ? payload.categories.split(',') : '',
    };
    Vue.set(state, 'selectedWebsite', website);
    Vue.set(state, 'selectedWebsiteLanguage', website.language);
  },

  deleteWebsite: (state, payload) => {
    state.websiteList = state.websiteList.filter((website) => {
      const remove = website.id === payload;
      return !remove;
    });
  },

  deleteArticleFromCurrentWebsite: (state, payload) => {
    state.selectedWebsiteArticles = state.selectedWebsiteArticles
      .filter((item) => {
        const remove = item.id === payload.id;
        return !remove;
      });
  },

  clearWebsiteArticles: (state) => {
    Vue.set(state, 'selectedWebsiteArticles', []);
    Vue.set(state, 'selectedWebsiteArticlesPageData', {
      hasNextPage: false,
    });
  },

  updateArticleOnTheList: (state, payload) => {
    const newArticle = {
      id: payload.id,
      status: payload.status,
      title: payload.title,
      authorName: payload.authorName,
      extractedText: payload.extractedText,
      publishedAt: payload.publishedAt,
      updatedAt: payload.updatedAt,
    };
    newArticle.episodes = payload.episodesByFeedItemId.nodes
      .map((episode) => {
        const newEpisode = {
          id: episode.id,
          status: episode.status,
          channelId: episode.channelByChannelId.id,
          type: episode.channelByChannelId.type,
        };
        const hasAudio = state.statusWithAudio.indexOf(episode.status) !== -1;
        if (hasAudio) {
          newEpisode.track = {
            src: `${state.audioUrl}${episode.id}.mp3`,
            name: episode.title,
            autoplay: true,
            id: episode.id,
          };
          newArticle.hasAudio = hasAudio;
        }
        if (episode.channelByChannelId.type === 'WEB_PLAYER') {
          newArticle.defaultTrack = newEpisode.track;
        }
        return newEpisode;
      });

    newArticle.types = payload.episodesByFeedItemId.nodes
      .map((episode) => episode.channelByChannelId.type);

    state.selectedWebsiteArticles.forEach((article, index) => {
      const shouldBeUpdated = article.id === payload.id;
      // state.selectedWebsiteArticles[index] = newArticle;
      if (shouldBeUpdated) Vue.set(state.selectedWebsiteArticles[index], 'episodes', newArticle.episodes);
      // if (shouldBeUpdated) state.selectedWebsiteArticles[index];
    });
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
};

const actions = {
  async checkNewSite({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CheckNewSite, {
          input: {
            siteUrl: payload,
          },
        }),
      );
      const { checkNewSite } = response.data;
      return checkNewSite;
    } catch (error) {
      return { ok: false, err: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },
  async checkRssUrl({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CheckRssUrl, {
          input: {
            siteUrl: payload,
          },
        }),
      );
      const { checkRssUrl } = response.data;
      return checkRssUrl;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },
  async detectWordpress({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DetectWordpress, {
          input: {
            siteUrl: payload,
          },
        }),
      );
      const { detectWordpress } = response.data;
      return detectWordpress;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },
  async detectRssFeed({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DetectRSSFeed, {
          input: {
            siteUrl: payload,
          },
        }),
      );
      const { detectRssFeed } = response.data;
      return detectRssFeed;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },
  // get feeds and channel information
  async fetchWebsites({ commit }, payload) {
    console.log('%c fetch wegbsites', 'color:lime', payload);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.Feeds),
      );
      const { nodes } = response.data.allFeeds;
      commit('setWebsiteList', nodes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return nodes;
    } catch (error) {
      console.log('asdasdddddd', error);
      // this._vm.$message.error('Can`t get websites');
      if (error.data && error.data.allFeeds && error.data.allFeeds.nodes) {
        const { nodes } = error.data.allFeeds;
        const data = nodes.filter((website) => !!website); // filter out nulls
        commit('setWebsiteList', data);
      }
      return { ok: false, message: error };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },

  async doesAudioExist({ commit }, payload) {
    commit('setLoadings', { type: 'websites', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CheckEpisodeFileExists, {
          input: payload,
        }),
      );
      const { isExists, filename } = response.data.checkEpisodeFileExists;
      // const doesExist = !!this._vm.$t('basic.delete1');
      return { ok: isExists, filename };
    } catch (error) {
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },

  async fetchArticles({ commit }, payload) {
    // console.log('fetchArticles', payload);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllFeedItems, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allFeedItems;
      commit('setArticles', nodes);
      // commit('setWebsiteArticles', { nodes: nodes.map((el) => ({ ...el, attached: true })), loadMore });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      return { ok: true, data: nodes };
    } catch (error) {
      // console.log('erro', error);
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'articles', value: false });
    }
  },

  async fetchArticlesByFeedId({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedById, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.feedById;
      commit('setArticles', nodes);
      commit('setWebsiteArticles', { nodes: nodes.map((el) => ({ ...el, attached: true })), loadMore: false });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      return nodes;
    } catch (error) {
      // console.log('erro', error);
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'articles', value: false });
    }
  },

  // get feed items
  async getWebsiteArticles({ commit }, payload) {
    commit('setLoadings', { type: 'articles', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedItemsByFeedId, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allFeedItems;
      commit('setWebsiteArticles', nodes);
      // commit('setWebsiteArticles', { nodes: nodes.map((el) => ({ ...el, attached: true })), loadMore });
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      return nodes;
    } catch (error) {
      // console.log('erro', error);
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'articles', value: false });
    }
  },

  // get feed by id
  async getWebsiteById({ commit }, payload) {
    // console.log('%c GET WEBSITE', 'color: orange', payload);
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedById, {
          id: payload,
        }),
      );
      const { feedById } = response.data;
      commit('selectWebsite', feedById);
      return feedById;
    } catch (error) {
      // console.log('get website error', error);
      this._vm.$message.error('Can`t get website');
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'website', value: false });
      }, 300);
    }
  },

  // get feed by id
  async getArticleById({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedItemById, {
          id: payload,
        }),
      );
      const { feedItemById } = response.data;
      commit('selectArticle', feedItemById);
      return feedItemById;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },
  // get feed by id
  async getArticle({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedItemById, {
          id: payload,
        }),
      );
      const { feedItemById } = response.data;
      return feedItemById;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },

  async getArticleStatus({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedItemById, {
          id: payload,
        }),
      );
      const { feedItemById } = response.data;
      commit('updateSelectedArticle', feedItemById);
      return feedItemById;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },

  // get feed by id and update list
  async getArticleItemById({ commit }, payload) {
    commit('setLoadings', { type: 'article', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FeedItemById, {
          id: payload,
        }),
      );
      const { feedItemById } = response.data;
      commit('updateArticleOnTheList', feedItemById);
      return feedItemById;
    } catch (error) {
      this._vm.$message.error('Can`t get article');
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'article', value: false });
      }, 300);
    }
  },

  async createWebsite({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.CreateSite,
          { input: payload },
        ),
      );
      const { createSite } = response.data;
      if (!createSite.ok) {
        this._vm.$message.error(createSite.err);
        return null;
      }
      // commit('createSite', createSite);
      return createSite;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async createProject({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.CreateProject,
          { input: payload },
        ),
      );
      const { createProject } = response.data;
      if (!createProject.ok) {
        this._vm.$message.error(createProject.err);
        return null;
      }
      // commit('createSite', createSite);
      return createProject;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async createProjectFeedItem({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.CreateProjectFeedItem,
          { input: payload },
        ),
      );
      const { createProjectFeedItem } = response.data;
      // commit('createSite', createSite);
      return createProjectFeedItem;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async changeVoices({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.ChangeVoice,
          {
            input: payload,
          },
        ),
      );
      const { changeVoices } = response.data;
      return changeVoices;
    } catch (error) {
      return { ok: false, message: error.err };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  // delete feed item by id
  async deleteArticleById({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.DeleteFeedItem,
          {
            input: {
              feedItemId: payload,
            },
          },
        ),
      );
      const { deleteFeedItem } = response.data;
      return deleteFeedItem;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  // delete feed by id
  // TODO: REMOVE
  async deleteWebsiteById_OLD({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.DeleteFeedCompletely,
          {
            input: {
              _feedId: payload,
            },
          },
        ),
      );
      const { boolean } = response.data.deleteFeedCompletely;
      if (boolean) commit('deleteWebsite', payload);
      return boolean;
    } catch (error) {
      this._vm.$message.error('Can`t delete website');
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async deleteWebsiteById({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.DeleteSite,
          {
            input: {
              feedId: payload,
            },
          },
        ),
      );
      const { deleteSite } = response.data;
      return deleteSite;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async toggleChannel({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.ToggleChannel,
          {
            input: payload,
          },
        ),
      );
      const { toggleChannel } = response.data;
      // console.log('toggleChannel', toggleChannel);
      return toggleChannel;
    } catch (error) {
      this._vm.$message.error('Can`t enable channel');
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async regenerateRssFeed({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.RegenerateRssFeed,
          {
            input: {
              channelId: payload,
            },
          },
        ),
      );
      const { regenerateRssFeed } = response.data;
      // console.log('regenerateRssFeed', regenerateRssFeed);
      if (!regenerateRssFeed.ok) this._vm.$message.error(regenerateRssFeed.err);
      // if (regenerateRssFeed.ok) this._vm.$message.success('Request sent');
      return regenerateRssFeed;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async regenerateAllRssFeeds({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.RegenerateAllRssFeeds,
          {
            input: payload,
          },
        ),
      );
      const { regenerateAllRssFeeds } = response.data;
      // console.log('regenerateRssFeed', regenerateAllRssFeeds);
      if (!regenerateAllRssFeeds.ok) this._vm.$message.error(regenerateAllRssFeeds.err);
      // if (regenerateAllRssFeeds.ok) this._vm.$message.success('Request sent');
      return regenerateAllRssFeeds;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async reprocessAllFeedItems({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.ReprocessAllFeedItems,
          {
            input: payload,
          },
        ),
      );
      const { reprocessAllFeedItems } = response.data;
      // console.log('reprocessAllFeedItems', reprocessAllFeedItems);

      if (!reprocessAllFeedItems.ok) this._vm.$message.error(reprocessAllFeedItems.err);
      // if (reprocessAllFeedItems.ok) this._vm.$message.success('Request sent');
      return reprocessAllFeedItems;
    } catch (error) {
      // this._vm.$message.error('Can`t enable channel');
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async reprocessFeedItem({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.ReprocessFeedItem,
          {
            input: payload,
          },
        ),
      );
      const { reprocessFeedItem } = response.data;
      if (!reprocessFeedItem.ok) this._vm.$message.error(reprocessFeedItem.err);
      // if (reprocessFeedItem.ok) this._vm.$message.success('Request sent');
      return reprocessFeedItem;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async deleteEpisodeById({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.DeleteEpisode,
          {
            input: {
              id: payload,
            },
          },
        ),
      );
      const { episode } = response.data.deleteEpisodeById;
      commit('deleteEpisode', episode);
      return episode;
    } catch (error) {
      this._vm.$message.error('Can`t delete episode');
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },
  // TODO: OLD Save... DELETE?
  async save({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.SaveEpisodeDraft,
          { input: payload },
        ),
      );
      const { saveEpisodeDraft } = response.data;
      return saveEpisodeDraft;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.err };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },
  /**
   * Draft save
   *
   * @param {object} { feedItemId: UUID!, title: String, extractedText: JSON }
   * @return {OperationResult} { ok: boolean, err: String }.
   */
  async saveDraft({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.SaveDraft,
          { input: payload },
        ),
      );
      const { saveDraft } = response.data;
      return saveDraft;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.err };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },
  async discardDraft({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.DiscardChanges,
          { input: payload },
        ),
      );
      const { discardChanges } = response.data;
      return discardChanges;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.err };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },
  /**
   * Publishes episode
   *
   * @param {object} { feedItemId: UUID!, publishEpisode: boolean }
   * @return {OperationResult} Operation result { ok: boolean, err: String }.
   */
  async publishEpisode({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.GenerateEpisodesForFeedItem,
          { input: payload },
        ),
      );
      const { generateEpisodesForFeedItem } = response.data;
      return generateEpisodesForFeedItem;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.err };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },
  async publishEpisodeTo({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.GenerateEpisode,
          { input: payload },
        ),
      );
      const { generateEpisode } = response.data;
      return generateEpisode;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.err };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },

  async updateWebsite({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateFeed,
          { input: payload },
        ),
      );
      const { updateFeed } = response.data;
      // console.log('updateWebsite => ', updateFeed);
      // commit('selectWebsite', feed);
      return updateFeed;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.errors, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },
  async unpublishFeedItem({ commit }, payload) {
    commit('setLoadings', { type: 'website', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UnpublishFeedItem,
          { input: payload },
        ),
      );
      const { unpublishFeedItem } = response.data;
      // console.log('updateWebsite => ', unpublishFeedItem);
      // commit('selectWebsite', feed);
      return unpublishFeedItem;
    } catch (error) {
      // console.log('error', error);
      return { ok: false, err: error.errors, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'website', value: false });
    }
  },

  async updateArticle({ commit }, payload) {
    commit('setLoadings', { type: 'feedItem', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateFeedItem,
          { input: payload },
        ),
      );
      const { feedItem } = response.data;
      return { ok: true, data: feedItem };
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },
  async estimateEpisodeCost({ commit }, payload) {
    commit('setLoadings', { type: 'feedItem', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.EstimateEpisodeCost,
          { input: payload },
        ),
      );
      const { estimateEpisodeCost } = response.data;
      return estimateEpisodeCost;
    } catch (error) {
      return { ok: false, message: error.err };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  // TODO: OLD... DELETE?
  async saveAndRegenerateArticle({ commit }, payload) {
    commit('setLoadings', { type: 'feedItem', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.SaveAndRegenerateArticle,
          { input: payload },
        ),
      );
      const { data } = response;
      return { ok: true, data };
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  async updateEpisodeById({ commit }, payload) {
    commit('setLoadings', { type: 'episode', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateEpisodeById,
          { input: payload },
        ),
      );
      const { episode } = response.data.updateEpisodeById;
      commit('setEpisode', episode);
      return episode;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'episode', value: false });
    }
  },

  async createChannel({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.CreateChannel,
          { input: payload },
        ),
      );
      const { channel } = response.data.createChannel;
      // commit('setChannel', channel);
      return channel;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async updateChannel({ commit }, payload) {
    commit('setLoadings', { type: 'channel', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateChannel,
          { input: payload },
        ),
      );
      const { channel } = response.data.updateChannelById;
      // console.log('response.data', response.data);
      return { ok: true, channel };
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'channel', value: false });
    }
  },

  async createDemoProject() {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateDemoProject),
      );
      const { createDemoProject } = response.data;
      // commit('setFeed', feed);
      return createDemoProject;
    } catch (error) {
      return { ok: false, message: error };
    }
  },

  async connectFeed({ commit }, payload) {
    commit('setLoadings', { type: 'saving', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.ConnectFeed,
          { input: payload },
        ),
      );
      const { feed } = response.data;
      // commit('setFeed', feed);
      return feed;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'saving', value: false });
    }
  },

  async uploadChannelArtwork({ commit }, payload) {
    const apiName = 'mainApi';
    const path = '/upload';
    const token = localStorage.getItem('token');
    const body = payload.body.split(',')[1];
    const extensions = {
      '/': 'jpg',
      i: 'png',
      R: 'gif',
      U: 'webp',
    };
    const myInit = {
      body,
      headers: {
        'x-channel-id': payload.id,
        'x-img-ext': extensions[body[0]],
        'x-entity-id': payload.id,
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);
      const { uploadURL } = response;
      commit('setArtwork', uploadURL);
      return uploadURL;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'deleting', value: false });
    }
  },

  async uploadArtwork({ commit }, payload) {
    const apiName = 'mainApi';
    const path = '/upload';
    const token = localStorage.getItem('token');
    const myInit = {
      body: payload.body.split(',')[1],
      headers: {
        'x-channel-id': payload.channelId,
        'x-entity-id': payload.id,
        'x-img-ext': payload.ext,
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);
      const { url } = response;
      console.log('upload', response);
      // commit('setArtwork', url);
      return url;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'deleting', value: false });
    }
  },

  // TODO: change get url to something else
  async getWebsiteContents({ commit }, payload) {
    commit('setLoadings', { type: 'detect', value: true });
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `https://cors-anywhere.herokuapp.com/${payload.url}`, true);
      xhr.onload = () => {
        if (xhr.status !== 200) {
          resolve({ ok: false });
        } else {
          resolve(xhr.response);
        }
        // console.log('xhr response', xhr);
        commit('setLoadings', { type: 'detect', value: false });
      };
      xhr.onerror = () => {
        reject(new Error('something bad happened', xhr.statusText));
        commit('setLoadings', { type: 'detect', value: false });
      };
      xhr.send();
    });
  },

  // TODO: CHANGE IT
  async getTitle({ commit }, payload) {
    commit('setLoadings', { type: 'detect', value: true });
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', `https://textance.herokuapp.com/title/${payload.url}`, true);
      xhr.onload = () => {
        if (xhr.status !== 200) {
          resolve({ ok: false });
        } else {
          resolve(xhr.response);
        }
        // console.log('xhr response', xhr);
        commit('setLoadings', { type: 'detect', value: false });
      };
      xhr.onerror = () => {
        reject(new Error('something bad happened', xhr.statusText));
        commit('setLoadings', { type: 'detect', value: false });
      };
      xhr.send();
    });
  },

  // TODO: finish this
  async updateEpisodeTemplate({ commit }, payload) {
    commit('setLoadings', { type: 'feedItem', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateEpisodeTemplate,
          { input: payload },
        ),
      );
      const { feedItem } = response.data;
      commit('updateArticle', feedItem);
      return feedItem;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  // TODO: finish this
  async updateEpisodeTemplatePart({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.UpdateEpisodeTemplatePartById,
          { input: payload },
        ),
      );
      const { updateEpisodeTemplatePartById } = response.data;
      return { ok: true, data: updateEpisodeTemplatePartById };
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  // Create default template and template parts (intro outro)
  async createDefaultChannelTemplate({ commit }, payload) {
    commit('setLoadings', { type: 'feedItem', value: true });
    try {
      const episodeTemplateInput = {
        episodeTemplate: {
          id: uuidv4(),
          displayName: 'Default template',
        },
      };
      const channelEpisodeTemplateInput = {
        channelEpisodeTemplate: {
          id: uuidv4(),
          channelId: payload.channelId,
          episodeTemplateId: null,
        },
      };
      const episodeTemplatePartInput = {
        episodeTemplatePart: {
          id: uuidv4(),
          episodeTemplateId: null,
          ordinal: 1,
          body: '',
          isUsed: true,
          type: 'CUSTOM',
          settings: {
            gapAfter: 500,
            gapBefore: 500,
            gaps: 250,
            mainLanguage: 'en-US',
            ttsPlatform: 'amazon-polly',
            ttsVoiceEngine: 'neural',
            ttsVoiceKind: 'news',
            ttsVoiceName: 'Joanna',
          },
          displayName: 'intro',
        },
      };
      const response = await API.graphql(
        graphqlOperation(
          GQL.CreateEpisodeTemplate,
          { input: episodeTemplateInput },
        ),
      );
      const { episodeTemplate } = response.data.createEpisodeTemplate;
      // console.log('CreateEpisodeTemplate => ', episodeTemplate);
      channelEpisodeTemplateInput.channelEpisodeTemplate.episodeTemplateId = episodeTemplate.id;
      episodeTemplatePartInput.episodeTemplatePart.episodeTemplateId = episodeTemplate.id;
      // commit('updateArticle', feedItem);

      // console.log('CreateEpisodeTemplatePart  => ', episodeTemplatePartInput);
      // create intro template
      await API.graphql(
        graphqlOperation(
          GQL.CreateEpisodeTemplatePart,
          { input: episodeTemplatePartInput },
        ),
      );
      // console.log('CreateEpisodeTemplatePart 2 => ', episodeTemplatePartInput);
      // create outro template part
      episodeTemplatePartInput.episodeTemplatePart.displayName = 'outro';
      episodeTemplatePartInput.episodeTemplatePart.ordinal = 2;
      episodeTemplatePartInput.episodeTemplatePart.id = uuidv4();
      await API.graphql(
        graphqlOperation(
          GQL.CreateEpisodeTemplatePart,
          { input: episodeTemplatePartInput },
        ),
      );
      // console.log('CreateChannelEpisodeTemplate => ', channelEpisodeTemplateInput);

      // create channel episode template
      await API.graphql(
        graphqlOperation(
          GQL.CreateChannelEpisodeTemplate,
          { input: channelEpisodeTemplateInput },
        ),
      );
      // console.log('finish => ');
      // get updated website
      const responseFeed = await API.graphql(
        graphqlOperation(GQL.FeedById, {
          id: payload.feedId,
        }),
      );
      const { feedById } = responseFeed.data;
      commit('selectWebsite', feedById);
      return feedById;
    } catch (error) {
      // console.log('error => ', error);
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  async uploadAudio({ commit }, payload) {
    const apiName = 'mainApi';
    const path = '/upload';
    const token = localStorage.getItem('token');
    const myInit = {
      body: payload.body.split(',')[1],
      headers: {
        'x-channel-id': payload.channelId,
        'x-entity-id': payload.id,
        'x-img-ext': payload.ext,
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await API.post(apiName, path, myInit);
      const { uploadURL } = response;
      commit('setArtwork', uploadURL);
      return uploadURL;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'deleting', value: false });
    }
  },

  async generateTitle({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.GenerateTitle,
          { input: payload },
        ),
      );
      const { generateTitle } = response.data;
      return { ok: true, data: generateTitle };
    } catch (error) {
      return { ok: false, message: error };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  async generateParagraph({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.GenerateParagraph,
          { input: payload },
        ),
      );
      const { generateParagraph } = response.data;
      return { ok: true, data: generateParagraph };
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  async getRSSUrl({ commit }, payload) {
    commit('setLoadings', { type: 'rssFeedUrl', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.GetRSSUrl,
          { input: payload },
        ),
      );
      const { getRssFeed } = response.data;
      return getRssFeed;
    } catch (error) {
      this._vm.$message.error('Something went wrong');
      return { error: true, message: error.errors };
    } finally {
      commit('setLoadings', { type: 'rssFeedUrl', value: false });
    }
  },

  async generateTextPreview({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.GenerateTextPreview,
          { input: payload },
        ),
      );
      const { generateTextPreview } = response.data;
      return generateTextPreview;
    } catch (error) {
      return { ok: false, message: error };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },

  async switchTemplateStatus({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(
          GQL.SwitchTemplateStatus,
          { input: payload },
        ),
      );
      const { switchTemplateStatus } = response.data;
      return switchTemplateStatus;
    } catch (error) {
      return { ok: false, message: error };
    } finally {
      commit('setLoadings', { type: 'feedItem', value: false });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
