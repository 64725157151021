<template>
  <div>
    <!-- PODCAST ENABLE MESSAGE -->
    <div class="podcast-disabled" v-if="!isPodcastActive">
      <div class="max-w-xl text-center mx-auto my-16 space-y-4">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto" width="94.559" height="99.317" viewBox="0 0 94.559 99.317">
            <g id="Group_5570" data-name="Group 5570" transform="translate(-0.547 0.057)">
              <path id="Path_43664" data-name="Path 43664" d="M23.093,24.9a5.743,5.743,0,0,0,2.768-1.942,17.807,17.807,0,0,0,2.479-5.041,35.4,35.4,0,0,0,1.116-3.884c.413-1.653.62-3.1,1.24-5.536l.454-2.025,1.694,1.363c1.157,1.033,1.9,1.818,2.025,2.851a6.4,6.4,0,0,1-1.2,3.595,1.336,1.336,0,1,0,2.272,1.4,9.255,9.255,0,0,0,1.983-5.123A7.933,7.933,0,0,0,35.364,5.4L32.182,2.3l-.909-.744a2.231,2.231,0,0,0-2.892.413,8.263,8.263,0,0,0-.95,1.9c-.413,1.157-.7,2.562-.992,3.512l-2.066,8.263h-.537a7.478,7.478,0,0,0-1.653,0,7.975,7.975,0,0,0-1.694,0,4.38,4.38,0,0,0-3.553,5.371A4.834,4.834,0,0,0,23.093,24.9ZM20.9,17.8h2.686a14.338,14.338,0,0,1-.95,2.52c-.289.5-.5.992-.909,1.2s-1.777-.785-1.859-1.777A1.487,1.487,0,0,1,20.9,17.8Z" transform="translate(50.927 3.95)" fill="#fea900"/>
              <path id="Path_43665" data-name="Path 43665" d="M49.172,26.52c-3.595-1.9-10.99-3.429-12.4-4.71a4.669,4.669,0,0,1-1.2-1.735,67.887,67.887,0,0,0-2.355-6.569,21.03,21.03,0,0,0-5.578-6.032A33.053,33.053,0,0,0,16.738,2.557a1.163,1.163,0,1,0-.661,2.231A34.871,34.871,0,0,1,24.341,8.63a17.808,17.808,0,0,1,5.991,6.4,63.175,63.175,0,0,1,1.942,6.321,7.685,7.685,0,0,0,1.983,3.057,25.162,25.162,0,0,0,6.2,2.892c2.562.95,3.884,2.231,6.941,3.719a6.776,6.776,0,0,1-1.942,1.653c-3.553,2.066-8.263,2.768-11.279,5.289a4.669,4.669,0,0,0-1.611,3.181,11.9,11.9,0,0,0,0,2.4c0,.413-.248,1.157,0,1.24a6.321,6.321,0,0,1-2.6,1.4,30.492,30.492,0,0,0-4.586,1.9A7.561,7.561,0,0,0,21.9,52.219a6.735,6.735,0,0,0,1.942,6.032,17.808,17.808,0,0,0,6.032,4.669l2.933.744a8.718,8.718,0,0,1-.331,2.686A9.461,9.461,0,0,1,30.7,69.944a11.28,11.28,0,0,1-6.074,3.471c-6.032,1.529-13.428.661-18.84,2.438a1.334,1.334,0,1,0,.744,2.562c5.5-1.57,12.808-.454,18.84-1.818a14.461,14.461,0,0,0,7.974-4.132,13.593,13.593,0,0,0,2.727-4.875,11.032,11.032,0,0,0,.289-5.867,2.562,2.562,0,0,0-1.735-1.446c-.826-.248-2.851-.744-3.14-.868a13.015,13.015,0,0,1-3.719-2.892c-.868-.909-1.735-1.859-1.4-2.768a3.016,3.016,0,0,1,1.57-1.694,34,34,0,0,1,4.793-2.066,8.635,8.635,0,0,0,4.132-2.851,4.132,4.132,0,0,0,.7-2.562,15.783,15.783,0,0,0-.248-2.314c0-.331-.207-.744,0-.992C40,38.915,44.586,38.378,47.85,36.395a9.957,9.957,0,0,0,4.132-4.834C52.684,29.991,51.857,27.966,49.172,26.52Z" transform="translate(13.403 8.035)" fill="#fea900"/>
              <path id="Path_43666" data-name="Path 43666" d="M28.737,53.891a16.981,16.981,0,0,0,2.19-12.932,17.105,17.105,0,0,0-6.28-10.288s0-.331,0-.537c.372-2.892.744-5.826.992-8.759s.537-5.908.62-8.8a44.209,44.209,0,0,0,0-4.834,6.115,6.115,0,0,0-.909-2.851A13.593,13.593,0,0,0,15.928.18,11.569,11.569,0,0,0,5.6,3.155,5.536,5.536,0,0,0,4.69,6.584a37.185,37.185,0,0,0,.95,5.536l1.777,11.2c.248,1.818.578,3.677.826,5.5a.868.868,0,0,0-.413,0A22.187,22.187,0,0,0,3.7,33.564a17.188,17.188,0,0,0-2.686,6.363,21.65,21.65,0,0,0-.454,5.165A20.245,20.245,0,0,0,1.468,50.3,26.608,26.608,0,0,0,3.2,54.429a18.965,18.965,0,0,0,1.735,2.727,12.4,12.4,0,0,0,13.015,4.462A19.46,19.46,0,0,0,28.737,53.891ZM16.92,57.362a8.263,8.263,0,0,1-8.635-2.933A11.61,11.61,0,0,1,6.549,51.95a20.865,20.865,0,0,1-1.363-2.933,16.031,16.031,0,0,1-1.074-4.132,17.27,17.27,0,0,1,0-4.38A14.668,14.668,0,0,1,5.888,35.3a21.444,21.444,0,0,1,2.768-3.677v.785c.5,3.016,1.033,5.991,1.694,8.966a1.159,1.159,0,0,0,2.272-.454c-.454-2.933-.826-5.908-1.157-8.883s-.413-5.991-.785-8.966L9.69,11.624c0-.868-.454-2.272-.578-3.677s-.289-1.653,0-2.107a3.181,3.181,0,0,1,1.777-.909,13.676,13.676,0,0,1,4.545,0A9.338,9.338,0,0,1,21.754,7.41a2.933,2.933,0,0,1,.289,1.4c0,1.363,0,2.851,0,3.842v8.635c0,2.892-.331,5.784-.578,8.635A26.03,26.03,0,0,1,20.143,38.6a4.38,4.38,0,0,1-4.132,2.438,1.322,1.322,0,1,0,0,2.644,6.941,6.941,0,0,0,6.445-3.719,16.816,16.816,0,0,0,1.529-5.95A14.461,14.461,0,0,1,27.662,41.5a13.469,13.469,0,0,1-2.19,9.916,15.287,15.287,0,0,1-8.553,5.908Z" transform="translate(0 0)" fill="#fea900"/>
              <path id="Path_43667" data-name="Path 43667" d="M42.728,38.061a8.883,8.883,0,0,0-3.884-1.487,1.157,1.157,0,0,0,.5-1.033,100.437,100.437,0,0,0,0-11.734,21.113,21.113,0,0,0-2.272-8.263,10.494,10.494,0,0,0-7.561-4.958,7.561,7.561,0,0,0-8.263,4.5,27.021,27.021,0,0,0-1.818,8.924A50.861,50.861,0,0,0,20.788,37.07v.289l-2.562.413a1.116,1.116,0,0,0-.95,1.322,1.157,1.157,0,0,0,1.322.992c1.818-.248,3.595-.372,5.412-.454h5.454a49.58,49.58,0,0,1,7.065.331A6.776,6.776,0,0,1,39.959,41.2a2.355,2.355,0,0,1,.578,1.611,13.3,13.3,0,0,1-.248,4.132A12.6,12.6,0,0,1,37.728,53.1a13.015,13.015,0,0,1-6.983,2.686A9.5,9.5,0,0,1,23.6,54.423a11.982,11.982,0,0,1-3.1-4.586,16.733,16.733,0,0,1-1.322-5.826,1.281,1.281,0,0,0-1.322-1.322,1.322,1.322,0,0,0-1.322,1.322,19.584,19.584,0,0,0,1.116,6.9,15.907,15.907,0,0,0,3.76,6.2,11.031,11.031,0,0,0,5.578,2.52,4.132,4.132,0,0,0-.248,2.975c.248.868.578,1.57.868,2.4a1.322,1.322,0,0,0,1.4,1.281,1.4,1.4,0,0,0,1.281-1.363c.207-.868.5-1.57.661-2.438a3.719,3.719,0,0,0-.207-2.562,16.94,16.94,0,0,0,10-3.388,17.931,17.931,0,0,0,4.132-10.494,9.3,9.3,0,0,0-2.148-7.974ZM29.506,36.533a50.044,50.044,0,0,0-6.4.62.7.7,0,0,0,0-.413,49.085,49.085,0,0,1-.289-10.247,25.74,25.74,0,0,1,2.4-9.462,3.429,3.429,0,0,1,3.8-1.859,6.321,6.321,0,0,1,4.545,2.644,17.4,17.4,0,0,1,2.314,6.445A110.684,110.684,0,0,1,36.7,35.582a1.2,1.2,0,0,0,.289.744,59.458,59.458,0,0,0-7.478.207Z" transform="translate(50.05 32.977)" fill="#fea900"/>
            </g>
          </svg>
        </div>
        <div>
          <div class="font-bold text-base">
            {{$t('ui.enablePodcastTitle')}}
          </div>
          <div class="text-gray-500 text-sm">
            {{$t('ui.enablePodcastInfo')}}
          </div>
        </div>
        <div
          @click="enablePodcast"
          class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out  -mt-2 px-4 py-1 text-xs text-white bg-black hover:bg-blue-700">
          {{$t('ui.enablePodcast')}}
        </div>
      </div>
    </div>

    <!-- PODCAST SETTINGS -->
    <div
      v-show="isPodcastActive"
      class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="mt-4 rounded-md bg-blue-50 p-4 mb-2" v-show="!artworkImage || !channelPatch.displayName || !channelPatch.ownerEmail || !channelPatch.authorName || categories.length === 0">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="h-4 w-4 text-blue-400"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700">{{$t('ui.podcastAllFieldsRequired')}}
            </p>
            <p class="mt-3 text-xs md:mt-0 md:ml-6"></p>
          </div>
        </div>
      </div>
      <div class="">
        <audio ref="player" @ended="clearPlayer" controls="controls" id="audioPreview" v-show="false">
          <source :src="audioPreviewUrl" type="audio/mp3" />
        </audio>
        <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1 ">
          {{saveError}}
        </div>
        <!-- PODCAST SETTINGS ITEMS -->
        <div class="divide-y divide-gray-300">
          <!-- RSS URL -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div>
              <div class="font-bold">{{$t('ui.podcastUrl')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastUrlDesc')}}</div>
              <div class="text-[14px] text-blue-400" v-show="!artworkImage || !channelPatch.displayName || !channelPatch.ownerEmail || !channelPatch.authorName || categories.length === 0">{{$t('ui.podcastLinkNotVisible')}}</div>
              <a :href="feedURL" target="_blank" class="cursor-pointer text-[14px] text-blue-400" v-show="artworkImage && channelPatch.displayName && channelPatch.ownerEmail && channelPatch.authorName && categories.length !== 0">{{feedURL}}</a>
            </div>
            <div>
              <button
              v-show="artworkImage && channelPatch.displayName && channelPatch.ownerEmail && channelPatch.authorName && categories.length !== 0"
                @click="copyCode(feedURL)" type="button" class="min-w-[146px] flex space-x-2 font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out border-black text-black border-2 bg-white hover:bg-black hover:text-white px-4 py-1 text-xs text-white bg-black hover:bg-blue-700">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16">
                  <g id="Group_5572" data-name="Group 5572" transform="translate(-3 -2)">
                    <path fill="currentColor" id="Path_43669" data-name="Path 43669" d="M8,2A1,1,0,0,0,8,4h2a1,1,0,0,0,0-2Z"/>
                    <path fill="currentColor" id="Path_43670" data-name="Path 43670" d="M3,5A2,2,0,0,1,5,3,3,3,0,0,0,8,6h2a3,3,0,0,0,3-3,2,2,0,0,1,2,2v6H10.414l1.293-1.293a1,1,0,1,0-1.414-1.414l-3,3a1,1,0,0,0,0,1.414l3,3a1,1,0,1,0,1.414-1.414L10.414,13H15v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2Zm12,6h2a1,1,0,0,1,0,2H15Z"/>
                  </g>
                </svg>
                <span>{{$t('ui.copyRssUrl')}}</span>
              </button>
            </div>
          </div>
          <!-- PODCAST TITLE -->
          <div class="py-7">
            <div>
              <div class="font-bold">{{$t('ui.podcastTitle')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastTitleDesc')}}</div>
            </div>
            <div class="mt-4">
              <!-- <input type="text" v-model="selectedWebsitePodcastTemplateTitle.body" class=" w-full py-3 min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300" /> -->
              <div class="py-4 px-3 min-h-[46px]  focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm  border border-gray-300 relative z-50">
                <title-editor
                      ref="titleEditor"
                      class="relative"
                      type="input"
                      :feedId="selectedWebsite.id"
                      :content.sync="selectedWebsitePodcastTemplateTitle.body"
                      @playBlock="playTitle"
                      @stopBlock="stopTitle"></title-editor>
              </div>
              <div class="text-gray-400 text-xs mt-1" v-html="$t('ui.podcastIntroVars')">{{$t('ui.podcastIntroVars')}}</div>
            </div>
          </div>
          <!-- PODCAST OUTRO -->
          <!-- <div class="py-7">
            <div>
              <div class="font-bold">{{$t('ui.podcastOutro')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastOutroDesc')}}</div>
            </div>
            <div class="mt-4">
              <div class="py-4 px-3 min-h-[46px]  focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm  border border-gray-300">
                <title-editor
                  ref="outroEditor"
                  class="relative"
                  type="input"
                  :feedId="selectedWebsite.id"
                  :content.sync="selectedWebsitePodcastTemplateOutro.body"
                  @playBlock="playTitle(selectedWebsitePodcastTemplateOutro.body)"
                  @stopBlock="stopTitle"></title-editor>
              </div>
            </div>
            <div class="text-gray-400 text-xs mt-1" v-html="$t('ui.podcastIntroVars')">{{$t('ui.podcastIntroVars')}}</div>
          </div> -->
          <!-- AUTHOR -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastAuthor')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastAuthorDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <div class="w-full">
                <!-- <input type="text" v-model="channelPatch.authorName" class=" w-full py-3 min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300"> -->
                <div class="py-4 px-3 min-h-[46px]  focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm  border border-gray-300 relative z-40">
                  <title-editor
                    ref="authorEditor"
                    class="relative"
                    type="input"
                    :feedId="selectedWebsite.id"
                    :content.sync="channelPatch.authorName"
                    @playBlock="playTitle"
                    @stopBlock="stopTitle"></title-editor>
                </div>
              </div>
            </div>
          </div>
          <!-- AUTHOR -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastEmail')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastEmailDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <div class="w-full">
                <input type="email" v-model="channelPatch.ownerEmail" class=" w-full py-3 min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300">
              </div>
            </div>
          </div>
          <!-- PODCAST NAME -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastName')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastNameDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <div class="w-full">
                <!-- <input type="text" v-model="channelPatch.displayName" class="w-full py-3 sm:min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300"> -->
                <div class="py-4 px-3 min-h-[46px]  focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm  border border-gray-300 relative z-30">
                  <title-editor
                    ref="podcastNameEditor"
                    class="relative"
                    type="input"
                    :feedId="selectedWebsite.id"
                    :content.sync="channelPatch.displayName"
                    @playBlock="playTitle"
                    @stopBlock="stopTitle"></title-editor>
                </div>
              </div>
            </div>
          </div>
          <!-- DESCRIPTION -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastDescription')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastDescriptionDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <div class="w-full">
                <!-- <input type="text" v-model="channelPatch.description" class=" w-full py-3 min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300"> -->
                <div class="py-4 px-3 min-h-[46px]  focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm  border border-gray-300 relative z-20">
                  <title-editor
                    ref="podcastDescEditor"
                    class="relative"
                    type="input"
                    :feedId="selectedWebsite.id"
                    :content.sync="channelPatch.description"
                    @playBlock="playTitle"
                    @stopBlock="stopTitle"></title-editor>
                </div>
              </div>
            </div>
          </div>
          <!-- PODCAST SUBNAME
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastSubName')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastSubNameDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <div class="">
                <input type="text" v-model="channelPatch.displaySubname" class=" w-full py-3 min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300">
              </div>
            </div>
          </div> -->
          <!-- PODCAST NAME -->
          <!-- <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastName')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastNameDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <div class="">
                <input type="text" v-model="channelPatch.displayName" class=" w-full py-3 min-w-[360px] focus:ring-current focus:border-blue-500 flex-grow block rounded-lg sm:text-sm border-gray-300">
              </div>
            </div>
          </div> -->
          <!-- CATEGORIES -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastCategories')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastCategoriesDesc')}}</div>
            </div>
            <div class="sm:flex-1">
              <div class="flex sm:justify-end">
                <!-- <div class="cat">{{categories}}</div> -->
                <div class="text-sm">
                  <input ref="tagInput" class="customLook" />
                </div>
                <div @click="onAddButtonClick" class="flex-shrink-0 hover:bg-blue-700 bg-black cursor-pointer flex h-[34px] w-[34px] ml-3 justify-center items-center rounded-3xl">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11.649" height="11.649" viewBox="0 0 11.649 11.649">
                    <path id="Path_43668" data-name="Path 43668" d="M.173.173a.592.592,0,0,1,.837,0L3.552,2.715,6.094.173a.592.592,0,1,1,.837.837L4.39,3.552,6.931,6.094a.592.592,0,1,1-.837.837L3.552,4.39,1.011,6.931a.592.592,0,1,1-.837-.837L2.715,3.552.173,1.011A.592.592,0,0,1,.173.173Z" transform="translate(5.825 0.801) rotate(45)" fill="#fff" stroke="#fff" stroke-width="1" fill-rule="evenodd"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <!-- explicitContent -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.explicitContent')}}</div>
              <div class="text-[14px]">{{$t('ui.explicitContentDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end">
              <button type="button"
                @click="explicitContent = !explicitContent"
                :class="{'bg-gray-200': !explicitContent, 'bg-blue-600': explicitContent}"
                class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false">
                <span class="sr-only"></span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true"
                  :class="{'translate-x-0': !explicitContent, 'translate-x-5': explicitContent}"
                  class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>
          <!-- ARTWORK -->
          <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
            <div class="sm:flex-1">
              <div class="font-bold">{{$t('ui.podcastArtwork')}}</div>
              <div class="text-[14px]">{{$t('ui.podcastArtworkDesc')}}</div>
            </div>
            <div class="sm:flex-1 flex sm:justify-end min-h-[176px]">
              <div
                  class="dropzone absolute w-[180px] h-[180px] cursor-pointer text-center py-6 px-10 border-2  rounded-xl border-s hover:border-yellow-400"
                  :class="{'border-solid border-yellow-400': dragOver, 'border-dashed border-gray-300': !dragOver}"
                  @dragover="dragOver = true"
                  @dragleave="dragOver = false">
                  <div class="absolute left-1 right-1 top-1 bottom-1 rounded-md flex overflow-hidden items-center justify-center"
                    :class="{'bg-white': artworkImage}">
                    <img :src="artworkImage" alt="" class="max-h-full max-w-full">
                  </div>
                <label
                  for="uploads" slot="count"
                  :class="{'top-[50%] left-[5px] right-[5px] mt-[-25%] bg-white bg-opacity-75 absolute opacity-[0.005] hover:opacity-100 z-30 cursor-pointer': artworkImage, 'top-4 relative': !artworkImage}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10 mx-auto "
                  :class="{' text-yellow-300': dragOver, 'text-gray-400': !dragOver}"
                  viewBox="0 0 24 24"><g><path d="M19.44,12.44a.31.31,0,0,0,0,.61c1.6.07,3.8,2.41,3.61,4.88a4.93,4.93,0,0,1-6.29,4.49c-3.62-1.12-4.77-5.59-1.41-8.34A5.69,5.69,0,0,1,17.41,13a.35.35,0,0,0-.18-.68,6.72,6.72,0,0,0-2.38,1.18,5.63,5.63,0,0,0,1.52,10.07A5.86,5.86,0,0,0,24,18C24.07,15,21.41,12.44,19.44,12.44Z" fill="currentColor" fill-rule="evenodd"></path><path d="M20.21,18.09a.3.3,0,1,0,.55-.26,6.32,6.32,0,0,0-1.37-2.33c-.47-.44-1.29-.79-1.83-.41a1.92,1.92,0,0,0-.54.7,6.73,6.73,0,0,0-.83,2.1,1.19,1.19,0,0,0-.05.38.34.34,0,0,0,.69,0,5.35,5.35,0,0,1,1-1.75,7.56,7.56,0,0,0,.27,3.9c.11.46,0,.69.3.75.53.11.43-.88.47-1.53a8.08,8.08,0,0,0-.12-2.39,5.41,5.41,0,0,0,0-.84A5.86,5.86,0,0,1,20.21,18.09Z" fill="currentColor" fill-rule="evenodd"></path><path d="M7.2,2.25C5.3,2.63,4.72,3.7,5,5c.55,2.94,5.62,1.74,4.48-1.37A2,2,0,0,0,7.2,2.25ZM5.87,4.78C5.35,3,8,2.14,8.48,3.92S6.26,6.14,5.87,4.78Z" fill="currentColor"></path><path d="M18.31.83a.29.29,0,0,0-.42,0c-.14.14-.05.15,0,.73s-.52,9.88.41,9.91a.33.33,0,0,0,.35-.33c.1-1.12.22-2.39.27-3.65C19,4.62,18.6,1.07,18.31.83Z" fill="currentColor"></path><path d="M1.79,14.5c-.59-.17-.41-.15-.44-.59S1,2.73,1.38,1.5c1-.26,7.73-.39,9-.38,6.59.08,6.78.32,6.79-.21a.33.33,0,0,0-.33-.35C.56-.13.43.19.21,1.36-.17,3.41,0,14.18.54,14.9c.86,1.19,9,.87,10.44.81a.31.31,0,0,0,0-.61C10.34,15.11,4.08,15.12,1.79,14.5Z" fill="currentColor"></path><path d="M17.45,8.43c-1.29-2-3.18-3.22-5.42-2-2,1.18-3,3.72-3.85,5.84,0,.1,0,.13-.2-.23a4.39,4.39,0,0,0-2.05-1.71c-2.24-.85-3.31-.35-4.39.87-.28.35-.15,1.19.15.87,1.13-1.23,1.61-1.42,3.87-.67a4.22,4.22,0,0,1,2.3,1.78c0,.13-.22.81-.36,1.29-.07.27.59.56.7,0,1.05-3,3.5-8.21,6.3-7.4a5.69,5.69,0,0,1,2.08,1.4,5.13,5.13,0,0,0,.65.6C17.55,9.31,17.64,8.68,17.45,8.43Z" fill="currentColor"></path></g></svg>
                  <div v-html="$t('ui.uploadPocdastAvatarTip')" class="text-xs">
                    {{$t('ui.uploadPocdastAvatarTip')}}
                  </div>
                </label>
                <input type="file" id="uploads"
                  class="cursor-pointer absolute top-0 left-0 bottom-0 right-0 opacity-0"
                  accept="image/png, image/jpeg, image/gif, image/jpg"
                  @change="imageChanged($event, 1)">
              </div>
            </div>
          </div>
          <div>
            <div class="py-8 pt-4 sm:space-x-4 sm:flex items-center">
              <Button class="mt-0"
                @click.native="save" :loading="loading" :disabled="!artworkImage || !channelPatch.displayName || !channelPatch.ownerEmail || !channelPatch.authorName || categories.length === 0">
                {{$t('ui.save')}}
              </Button>
              <div class="flex mt-3 sm:mt-0">
                <div class="flex-shrink-0">
                  <svg class="h-4 w-4 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3 flex-1 md:flex md:justify-between">
                  <p class="text-xs text-blue-700" v-html="$t('ui.podcastAfterSaveInfo',['/articles/none/list'])">
                    {{$t('ui.podcastAfterSaveInfo')}}
                  </p>
                  <p class="mt-3 text-xs md:mt-0 md:ml-6">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pre class="break-words max-w-md" v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612'">
      {{customer}}
      {{$appConfig.rssUrl + '/' + encodeURIComponent(customer) + '/' + selectedWebsitePodcast.id + '/rss.xml'}}
      {{$appConfig}}
    {{hasArtworkChanged}}
    {{rssUrl}}
    ------------------
    {{selectedWebsitePodcastTemplateTitle}}
    ------------------------
    {{selectedWebsitePodcast}}
    </pre>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';
import Tagify from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';
// import vue2Dropzone from 'vue2-dropzone';
// import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const { Validator } = SimpleVueValidation;

export default {
  // components: {
  //   vueDropzone: vue2Dropzone,
  // },
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      loadingDelete: false,
      confirmPassword: null,
      password: null,
      saveError: null,
      oldPassword: null,
      errorMessage: null,
      showGuideModal: false,
      showDeleteModal: false,
      deleteConfirmation: '',
      feedId: null,
      article: null, // used for podcast title preview
      watermarkTemplate: false,
      channelPatch: {
        id: '',
        isActive: '',
        artworkUrl: '',
        authorName: '',
        ownerEmail: '',
        displaySubname: '',
        displayName: '',
        description: '',
        settings: {},
      },
      jsonSettings: {},
      languages: [
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],

      audioPreviewUrl: null,
      playButton: null,
      rssUrl: '',
      categories: [],
      explicitContent: '',

      // artwork
      dragOver: false,
      artworkImage: false,
      previewStyle: {},
      previews: {},
      hasArtworkChanged: false,
      tagify: null,
      settings: {
        callbacks: {
          invalid: this.onInvalidTag,
          change: this.categoriesChanged,
        },
        backspace: true,
        placeholder: 'add category',
        delimiters: ',',
        autoComplete: true,
        whitelist: [
          'Arts',
          'Books',
          'Design',
          'Fashion & Beauty',
          'Food',
          'Performing Arts',
          'Visual Arts',
          'Business',
          'Careers',
          'Entrepreneurship',
          'Investing',
          'Management',
          'Marketing',
          'Non-Profit',
          'Comedy',
          'Comedy Interviews',
          'Improv',
          'Stand-Up',
          'Education',
          'Courses',
          'How To',
          'Language Learning',
          'Self-Improvement',
          'Fiction',
          'Comedy Fiction',
          'Drama',
          'Science Fiction',
          'Government',
          'History',
          'Health & Fitness',
          'Alternative Health',
          'Fitness',
          'Medicine',
          'Mental Health',
          'Nutrition',
          'Sexuality',
          'Kids & Family',
          'Education for Kids',
          'Parenting',
          'Pets & Animals',
          'Stories for Kids',
          'Leisure',
          'Animation & Manga',
          'Automotive',
          'Aviation',
          'Crafts',
          'Games',
          'Hobbies',
          'Home & Garden',
          'Video Games',
          'Music',
          'Music Commentary',
          'Music History',
          'Music Interviews',
          'News',
          'Business News',
          'Daily News',
          'Entertainment News',
          'News Commentary',
          'Politics',
          'Sports News',
          'Tech News',
          'Religion & Spirituality',
          'Buddhism',
          'Christianity',
          'Hinduism',
          'Islam',
          'Judaism',
          'Religion',
          'Spirituality',
          'Science',
          'Astronomy',
          'Chemistry',
          'Earth Sciences',
          'Life Sciences',
          'Mathematics',
          'Natural Sciences',
          'Nature',
          'Physics',
          'Social Sciences',
          'Society & Culture',
          'Documentary',
          'Personal Journals',
          'Philosophy',
          'Places & Travel',
          'Relationships',
          'Sports',
          'Baseball',
          'Basketball',
          'Cricket',
          'Fantasy Sports',
          'Football',
          'Golf',
          'Hockey',
          'Rugby',
          'Running',
          'Soccer',
          'Swimming',
          'Tennis',
          'Volleyball',
          'Wilderness',
          'Wrestling',
          'Technology',
          'True Crime',
          'TV & Film',
          'After Shows',
          'Film History',
          'Film Interviews',
          'Film Reviews',
          'TV Reviews',
        ],
        dropdown: {
          position: 'text',
          enabled: 1, // show suggestions dropdown after 1 typed character
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      customers: (state) => state.customer.list,
      selectedWebsite: (state) => state.website.selectedWebsite,
      websiteList: (state) => state.website.websiteList,
      userId: (state) => state.auth.userId,
      languageList: (state) => state.utils.languageList,
      pluginLink: (state) => state.utils.pluginUrl,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      selectedWebsiteAPTemplates: (state) => state.website.selectedWebsiteAPTemplates,
      selectedWebsiteAP: (state) => state.website.selectedWebsiteAP,
      selectedWebsiteAPTemplateOutro: (state) => state.website.selectedWebsiteAPTemplateOutro,
      selectedWebsitePodcastTemplates: (state) => state.website.selectedWebsitePodcastTemplates,
      selectedWebsitePodcast: (state) => state.website.selectedWebsitePodcast,

      selectedWebsitePodcastTemplateOutro: (state) => state.website.selectedWebsitePodcastTemplateOutro,
      selectedWebsitePodcastTemplateTitle: (state) => state.website.selectedWebsitePodcastTemplateTitle,
    }),
    feedURL() {
      if (!this.selectedWebsitePodcast || !this.customer) return '';
      return `${this.$appConfig.rssUrl}/${encodeURIComponent(this.customer)}/${this.selectedWebsitePodcast.id}/rss.xml`;
    },
    isPodcastActive() {
      if (this.selectedWebsitePodcast) return this.selectedWebsitePodcast.isActive;
      return false;
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    isEnterprise() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Enterprise';
    },
    isPremium() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Premium';
    },
    channelId() {
      return this.selectedWebsitePodcast.id;
    },
    channelOutroTemplate() {
      if (!this.selectedWebsiteAPTemplates.outro) return {};
      return this.selectedWebsiteAPTemplates.outro;
    },
    websiteId() {
      return this.$route.params.websiteId;
    },
    stripTag() {
      // eslint-disable-next-line
      return (str) => {
        if (!str) return '';
        return str.replace(/(<([^>]+)>)/gi, '');
      };
    },
  },
  validators: {
    oldPassword: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  watch: {
    websiteId(val) {
      // console.log('no website details', val, oldVal, this.feedPatch.displayName);
      if (this.feedId !== val) {
        console.log('no website details', val, this.feedId);
        this.init();
      }
    },
  },
  mounted() {
    this.deleteConfirmation = '';
    this.init();
  },
  methods: {
    ...mapMutations({
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      getWebsiteById: 'website/getWebsiteById',
      updateWebsite: 'website/updateWebsite',
      fetchWebsites: 'website/fetchWebsites',
      deleteWebsiteById: 'website/deleteWebsiteById',
      generateTextPreview: 'website/generateTextPreview',
      regenerateRssFeed: 'website/regenerateRssFeed',
      switchTemplateStatus: 'website/switchTemplateStatus',
      updateChannel: 'website/updateChannel',
      uploadArtwork: 'website/uploadArtwork',
      getRSSUrl: 'website/getRSSUrl',
      updateEpisodeTemplatePart: 'website/updateEpisodeTemplatePart',
      fetch: 'website/fetchArticles',
    }),

    async loadArticles(page, enableLoading = true) {
      this.loading = enableLoading;
      const input = {
        first: 100,
        orderBy: 'PUBLISHED_AT_ASC',
        offset: 0,
        condition: {
          feedId: this.websiteId,
          // status: 'PUBLISHED'
        },
      };
      // from url
      if (this.websiteId) {
        input.condition.feedId = this.websiteId;
      }
      const result = await this.fetch(input);
      console.log('Article', result);
      let feedItem = null;
      this.loading = false;
      if (!result.ok || !result.data[0]) return;
      result.data.forEach((element) => {
        const episodes = element.episodesByFeedItemId.nodes;
        console.log('element', element);
        if (episodes.length === 0 || feedItem) return;
        console.log('element 2', element);
        if (episodes[0].channelByChannelId.type === 'PODCAST' && episodes[0].status === 'PUBLISHED') {
          feedItem = element;
        }
      });
      console.log('element feedItem', feedItem);
      this.article = feedItem;
    },

    async init() {
      const result = await this.getWebsiteById(this.websiteId);
      this.loadArticles();
      // console.log('result', result);
      this.jsonSettings = result.settings;
      this.feedId = this.websiteId;
      // this.feedPatch = {
      //   displayName: result.displayName,
      //   description: result.description,
      //   language: result.language,
      //   url: result.mainSiteUrl,
      //   // explicitContent: result.explicitContent,
      // };
      this.$nextTick();
      console.log('isUsed', this.selectedWebsitePodcast, result);

      this.getChannelRss(this.selectedWebsitePodcast);
      this.categories = result.categories || '';
      this.explicitContent = this.selectedWebsite.explicitContent;
      this.artworkImage = this.selectedWebsitePodcast.artworkUrl;
      this.channelPatch.authorName = this.selectedWebsitePodcast.authorName;
      this.channelPatch.description = this.selectedWebsitePodcast.description;
      this.channelPatch.displayName = this.stripTag(this.selectedWebsitePodcast.displayName);
      this.channelPatch.displaySubname = this.selectedWebsitePodcast.displaySubname;
      this.channelPatch.ownerName = this.selectedWebsitePodcast.ownerName;
      this.channelPatch.ownerEmail = this.selectedWebsitePodcast.ownerEmail || this.userEmail;
      this.tagify = new Tagify(this.$refs.tagInput, this.settings);
      this.tagify.loadOriginalValues(this.categories.split(','));
      // this.$refs.introEditor.refreshContent(this.selectedWebsitePodcastTemplateTitle.body);
      this.$refs.titleEditor.refreshContent(this.selectedWebsitePodcastTemplateTitle.body);
      this.$refs.podcastDescEditor.refreshContent(this.channelPatch.description);
      this.$refs.podcastNameEditor.refreshContent(this.channelPatch.displayName);
      this.$refs.authorEditor.refreshContent(this.channelPatch.authorName);
    },

    async enablePodcast() {
      this.saveError = null;
      this.loading = true;

      const input = {
        channelPatch: {
          isActive: !this.isPodcastActive,
        },
        id: this.selectedWebsitePodcast.id,
      };

      const result = await this.updateChannel(input);
      this.loading = false;
      if (!result.ok) {
        // console.log('result', result);
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        this.saveError = this.$t('ui.somethingWentWrong');
        return;
      }
      const website = await this.getWebsiteById(this.selectedWebsite.id);
      console.log('website', website);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.websiteUpdated'),
      });
    },

    async save() {
      this.saveError = null;
      this.loading = true;

      // save feed info
      this.feedPatch = {
        categories: this.categories,
        explicitContent: this.explicitContent,
      };
      const input = {
        ...this.feedPatch,
        id: this.selectedWebsite.id,
      };

      console.log('update podcast templates', this.selectedWebsitePodcastTemplateTitle);

      // hack to fix empty intro field
      const introBodyEl = document.querySelector('.title-editor h1.content-wrapper');

      const introBody = introBodyEl && introBodyEl.innerHTML === '' ? '' : this.selectedWebsitePodcastTemplateTitle.body;

      const inputTitle = {
        episodeTemplatePartPatch: {
          id: this.selectedWebsitePodcastTemplateTitle.id,
          body: introBody,
        },
        id: this.selectedWebsitePodcastTemplateTitle.id,
      };
      // const inputOutro = {
      //   episodeTemplatePartPatch: {
      //     id: this.selectedWebsitePodcastTemplateOutro.id,
      //     body: this.selectedWebsitePodcastTemplateOutro.body,
      //   },
      //   id: this.selectedWebsitePodcastTemplateOutro.id,
      // };

      const resultIntro = await this.updateEpisodeTemplatePart(inputTitle);
      // const resultOutro = await this.updateEpisodeTemplatePart(inputOutro);
      const result = await this.updateWebsite(input);

      console.log('save test', result, resultIntro);
      const inputChannelAuthor = {
        channelPatch: {
          authorName: this.channelPatch.authorName,
          description: this.channelPatch.description,
          displayName: this.stripTag(this.channelPatch.displayName),
          displaySubname: this.channelPatch.displaySubname,
          ownerName: this.channelPatch.ownerName,
          ownerEmail: this.channelPatch.ownerEmail,
        },
        id: this.selectedWebsitePodcast.id,
      };

      const resultChannelUpdate = await this.updateChannel(inputChannelAuthor);
      console.log('channel update', resultChannelUpdate);
      await this.getChannelRss(this.selectedWebsitePodcast);
      this.loading = false;
      if (!result.ok) {
        // console.log('result', result);
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        this.saveError = this.$t('ui.somethingWentWrong');
        this.regenerateRssFeed(this.selectedWebsitePodcast.id);
        return;
      }

      if (!this.hasArtworkChanged) {
        this.fetchWebsites();
        const website = await this.getWebsiteById(this.selectedWebsite.id);
        console.log('website', website);
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'success',
          title: this.$t('ui.websiteUpdated'),
        });
        this.regenerateRssFeed(this.selectedWebsitePodcast.id);
        this.loading = false;
        return;
      }

      const payload = {
        channelId: this.channelId,
        body: this.artworkImage,
        id: 'podcast-artwork',
        ext: 'image/jpg',
      };
      const inputChannel = {
        channelPatch: {
          id: this.selectedWebsitePodcast.id,
          artworkUrl: this.selectedWebsitePodcast.artworkUrl,
        },
        id: this.selectedWebsitePodcast.id,
      };
      inputChannel.channelPatch.artworkUrl = await this.uploadArtwork(payload);
      console.log('save podcast artwork');
      await this.updateChannel(inputChannel);
      this.regenerateRssFeed(this.selectedWebsitePodcast.id);
    },

    async saveTemplate(template) {
      const input = {
        episodeTemplatePartPatch: {
          id: template.id,
          body: template.body,
        },
        id: template.id,
      };

      const result = await this.updateEpisodeTemplatePart(input);
      console.log('template update result result', result);
    },

    async saveOLD() {
      const input = {
        channelPatch: {
          id: this.currentChannel.id,
          settings: this.channelCopy.settings,
          type: this.channelCopy.type,
          displayName: this.channelCopy.displayName,
          artworkUrl: this.channelCopy.artworkUrl,
        },
        id: this.currentChannel.id,
      };

      if (!this.hasArtworkChanged) {
        await this.updateChannel(input);
        this.init();
        this.editMode = false;
        return;
      }

      this.$refs.cropper.getCropData(async (data) => {
        const payload = {
          body: data,
          id: this.currentChannel.id,
          ext: 'png',
        };
        input.channelPatch.artworkUrl = await this.uploadArtwork(payload);
        await this.updateChannel(input);
        this.init();
        this.$forceUpdate();
        this.editMode = false;
      });
    },

    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.urlCopied'),
      });
    },
    clearPlayer() {
      const audio = this.$refs.player;
      // const index = this.paragraphUrls.indexOf(this.audioPreviewUrl);
      this.playing += 1;
      if (this.paragraphUrls.length > 1 && this.playing < this.paragraphUrls.length) {
        this.audioPreviewUrl = this.paragraphUrls[this.playing];
        audio.load(); // call this to just preload the audio without playing
        audio.play();
        // alert(`sdasdasd ${index}`);
        return;
      }
      this.playing = 0;
      this.audioPreviewUrl = null;
      // this.playButton.classList.remove('playing');
      // console.log('player cleared', this.playButton);
      this.clearButtons();
    },
    clearButtons() {
      // this.$refs.introEditor.clearPlay();
      this.$refs.titleEditor.clearPlay();
      this.$refs.podcastDescEditor.clearPlay();
      this.$refs.podcastNameEditor.clearPlay();
      this.$refs.authorEditor.clearPlay();
    },
    async playTitle(data) {
      console.log('%cPLAY TITLE', 'color: red', data);
      if (!data || !data.content || !data.content[0] || !data.content[0].body) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.clearButtons();
        return;
      }
      const input = {
        feedId: this.selectedWebsite.id,
        text: data.content[0].body,
      };
      if (this.article) {
        input.feedItemId = this.article.id;
      }
      console.log('data', data);
      this.clearButtons();
      this.playButton = data.button;
      const result = await this.generateTextPreview(input);
      data.button.classList.remove('loading');
      data.button.classList.add('playing');
      console.log('%cPLAY TITLE', 'color: red', data, result);
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.clearButtons();
        return;
      }

      this.playUrl([result.url]);
    },
    async stopTitle() {
      const audio = this.$refs.player;
      // console.log('handleStopParagraph', audio, data);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.pause(); // call this to play the song right away
      this.clearButtons();
    },
    playUrl(urls) {
      // console.loh('playUrl');
      this.playing = 0;
      this.paragraphUrls = urls;
      [this.audioPreviewUrl] = urls;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    goTo(path) {
      this.$router.push({ path });
    },
    async getChannelRss(channel) {
      const input = {
        channelId: channel.id,
      };

      const result = await this.getRSSUrl(input);
      const hasError = !!result.err;
      // console.log('test', result);
      if (hasError) {
        return;
      }

      this.rssUrl = result.feedUrl;
      // this.copy(result.feedUrl);
      this.$forceUpdate();
    },
    imageChanged(event) {
      this.hasArtworkChanged = true;
      const file = event.target.files[0];
      this.dragOver = false;
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(event.target.value)) {
        return false;
      }
      const reader = new FileReader();
      reader.onload = () => {
        // let data;
        const dataUrl = reader.result;
        const base64 = dataUrl.split(',')[1];
        console.log('base64', base64);
        // if (typeof e.target.result === 'object') {
        //   data = window.URL.createObjectURL(new Blob([e.target.result]));
        // } else {
        //   data = e.target.result;
        // }
        // if (num === 1) {
        //   this.artworkImage = data;
        // } else if (num === 2) {
        //   this.artworkImage = data;
        // }
        this.artworkImage = `data:image/png;base64,${base64}`;
      };
      reader.readAsDataURL(file);
      return true;
    },
    onAddButtonClick() {
      this.tagify.addEmptyTag();
    },
    onInvalidTag(e) {
      console.log('invalid', e.detail);
    },
    categoriesChanged(value) {
      console.log('categoriesChanged', value.detail);
      const tagArray = value.detail.tagify.getCleanValue()
        .map((el) => el.value);
      // this.categories = [];
      console.log('categoriesChanged', tagArray);
      this.categories = tagArray.join(',');
    },
  },
};
</script>
<style scoped>
.cropper-wrapper-avatar {
  width: 128px;
  height: 128px;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
}
.cropper-wrapper-avatar .avatar-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.cropper-wrapper {
  width: 200px;
  height: 200px;
}
.previews {
  widows: 100%;
  height: 200px;
}

</style>
