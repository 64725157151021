/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import GQL from '../graphql';
import Helpers from '../utils/helpers';
import APP_CONFIG from '../config';

const stripeProducs = process.env.NODE_ENV !== 'production' ? require('../development').default : require('../production').default;

const getDefaultState = () => {
  console.log('');
  return {
    name: 'Billing',
    billingHistory: [],
    hasNextPage: true,
    pageInfo: {},
    loadings: {},
    count: 0,
    prices: {},
    subscriptions: stripeProducs.subs,
    subscriptionStatus: {
      isTrial: false,
      subDay: 1,
      plan: '',
      params: {},
      daysToEnd: 0,
    },
    subscriptionAddOns: stripeProducs.addons,
    invoices: [],
  };
};

const state = getDefaultState();

const mutations = {
  clearBillingState: (state) => {
    Object.assign(state, getDefaultState());
    console.log('clearstate');
  },
  setBillingHistory: (state, payload) => {
    Vue.set(state, 'billingHistory', payload);
  },
  setSubscriptionStatus: (state, payload) => {
    const isTrial = payload && payload.plan === 'Trial';
    const isActive = payload.active;
    const pendingPlanUpdate = payload.params ? payload.params.pendingUpdate : {};
    const subDay = moment(payload.endDate).format('DD');
    const a = moment(payload.endDate);
    const b = moment();
    // subDay() {
    //   if (!this.subDate || !payload.endDate) return 'xxx';
    //   const [datePart] = payload.endDate.split(' ');
    //   const [year, month, day] = datePart.split('-');
    //   console.log('year, month', year, month, day, datePart);
    //   return day;
    // },
    const subStatus = {
      ...payload,
      isTrial,
      isActive,
      pendingPlanUpdate,
      subDay,
      daysToEnd: a.diff(b, 'days'),
    };
    Vue.set(state, 'subscriptionStatus', subStatus);
    console.log('sub status', subStatus);
    // eslint-disable-next-line
    $crisp.push(['set', "session:data", [[['sub-status', payload.active], ['sub-plan', `${payload.plan} ${payload.interval}`], ['sub-end', payload.endDate]]]]);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setInvoices: (state, payload) => {
    Vue.set(state, 'invoices', payload);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'count', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
  clearPagin: (state) => {
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
  },
  setPackages: (state, payload) => {
    Vue.set(state, 'prices', payload);
    const subscriptions = {
      Premium: {
        month: {},
        year: {},
      },
      Business: {
        month: {},
        year: {},
      },
      Basic: {
        month: {},
        year: {},
      },
      Starter: {
        month: {},
        year: {},
      },
      Enterprise: {
        month: {},
        year: {},
      },
    };
    const subscriptionAddOns = {
      Premium: {},
      Business: {},
      Basic: {},
      Enterprise: {},
    };
    const packageNames = ['Premium', 'Starter', 'Basic', 'Business', 'Enterprise'];
    const pricingGroups = payload
      .filter((price) => price.active && price.product.active && !!price.recurring) // filter out inactive and not recurring
      .sort((a, b) => a.unit_amount - b.unit_amount) // sort by price (TODO: remove and split it in to mo/yo packages)
      .reduce((groups, item) => ({
        ...groups,
        [item.product.name]: [...(groups[item.product.name] || []), item],
      }), {}); // group by name
    payload
      .filter((price) => price.active)
      .filter((price) => price.product.active)
      // .filter((price) => price.metadata.used === 'true')
      .filter((price) => {
        const { product, metadata } = price;
        // const { metadata } = product;

        if (product.metadata.plan && metadata.interval) {
          subscriptions[product.metadata.plan][metadata.interval] = price;
        }

        // console.log('%cprice', 'color: lime', product.name, metadata.interval, product.metadata.plan, product);
        return packageNames.indexOf(metadata.plan) > -1;
      })
      .forEach((price) => {
        const {
          product,
          id,
          recurring,
          metadata,
        } = price;
        const isSubscription = !!recurring;
        if (!isSubscription) return;

        const { name } = product;

        console.log('price => ', isSubscription, `${name} (${product.metadata.plan})`, metadata.interval, price.recurring.interval, id, price);
      });

    // Add-ons
    payload
      .filter((price) => price.active && price.product.active)
      .filter((price) => packageNames.indexOf(price.product.metadata.plan) > -1)
      .filter((price) => price.product.metadata.type === 'Add-on')
      .forEach((price) => {
        const { product } = price;
        console.log('add on => ', price, product);
        subscriptionAddOns[product.metadata.plan] = price;
      });
    console.log('%csubs: ', 'color: orange', subscriptions, subscriptionAddOns, pricingGroups);

    // TODO: fix this garbage
    // [subscriptions.Premium.month, subscriptions.Premium.year] = pricingGroups.Premium;
    // [subscriptions.Basic.month, subscriptions.Basic.year] = pricingGroups.Basic;
    // [subscriptions.Business.month, subscriptions.Business.year] = pricingGroups.Business;
    // [subscriptions.Enterprise.month, subscriptions.Enterprise.year] = pricingGroups.Enterprise;

    Vue.set(state, 'subscriptions', subscriptions);
    Vue.set(state, 'subscriptionAddOns', subscriptionAddOns);
  },
};

const actions = {
  async fetchHistory({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllCreditOperations, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allCreditOperations;
      commit('setBillingHistory', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // console.log('fetchHistory', payload, nodes);
      return nodes;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },
  async redeemCoupon({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.RedeemCoupon, {
          input: {
            couponCode: payload,
          },
        }),
      );
      const { data } = response;
      return data.redeemCoupon;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },

  // payment and packages
  async fetchPrices({ commit }) {
    console.log('vue config', this);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetStripePricesAndProducts),
      );
      const { getStripePricesAndProducts } = response.data;
      commit('setPackages', getStripePricesAndProducts.prices);
      // console.log('fetchHistory', payload, nodes);
      return getStripePricesAndProducts;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },

  async listInvoices({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.ListInvoices),
      );
      const { listInvoices } = response.data;
      commit('setInvoices', listInvoices.invoices);
      // console.log('fetchHistory', payload, nodes);
      return listInvoices;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },

  async getSubscriptionStatus({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetSubscriptionStatus),
      );
      const { getSubscriptionStatus } = response.data;
      commit('setSubscriptionStatus', getSubscriptionStatus);
      // console.log('fetchHistory', payload, nodes);
      return getSubscriptionStatus;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },

  async cancelSubscription() {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CancelSubscription),
      );
      const { cancelSubscription } = response.data;
      // commit('setSubscriptionStatus', cancelSubscription);
      // console.log('fetchHistory', payload, nodes);
      return cancelSubscription;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },

  async updateSubscription({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.UpdateSubscription, {
          input: {
            priceId: payload,
          },
        }),
      );
      const { data } = response;
      return data.updateSubscription;
    } catch (error) {
      return { ok: false, err: error };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },

  async getPaymentLink({ commit }, payload) {
    try {
      const url = `${APP_CONFIG.apiUrl}paymentProcess`;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      };
      const response = await Helpers.postData(url, {
        priceId: payload.priceId,
        productId: payload.productId,
        hmCustomerId: payload.customer,
        domain: payload.domain,
        quantity: 1,
      }, headers);
      console.log('%cgetPaymentLink', 'font-size:18px; color: lime', response);
      return response;
    } catch (error) {
      return { ok: false, message: error };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
