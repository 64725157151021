/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../../graphql';
import SelectionUtils from './selection';
import $ from './dom';
// import * as _ from './utils';

import './lang-inline.css';

require('./lang-inline.css');
/**
 * Link Tool
 *
 * Inline Toolbar Tool
 *
 * Wrap selected text with <a> tag
 */
export default class LangInlineTool {
  /**
   * @param {API} api - Editor.js API
   */
  constructor({ api, config }) {
    /**
     * Native Document's commands for link/unlink
     */
    this.commandLink = 'createLink';
    this.commandUnlink = 'unlink';
    this.config = config;
    this.langOption = {
      id: 'en-US',
      label: 'English (United States)',
    };
    /**
     * Enter key code
     */
    this.ENTER_KEY = 13;
    /**
     * Styles
     */
    this.CSS = {
      button: 'ce-inline-tool',
      buttonActive: 'ce-inline-tool--active',
      buttonModifier: 'ce-inline-tool--alias',
      buttonUnlink: 'ce-inline-tool--unalias',
      label: 'ce-inline-tool-label',
      title: 'ce-inline-tool-title',
      desc: 'ce-inline-tool-desc',
      input: 'ce-inline-tool-input',
      select: 'ce-inline-tool-select',
      inputWrapper: 'ce-inline-tool-input-wrapper',
      inputShowed: 'ce-inline-tool-input--showed',
      wrapper: 'ce-inline-tool-lang-wrapper',
    };

    /**
     * Say as options 
     */
    this.langOptions = [
      {
        id :'pl-PL',
        label: 'Polish',
      },
      {
        id :'en-US',
        label: 'English (United States)',
      },
      {
        id :'en-GB',
        label: 'English (United Kingdom)',
      },
      {
        id :'de-DE',
        label: 'German (Germany)',
      },
      {
        id :'uk-UA',
        label: 'Ukrainian (Ukraine)',
      },
      {
        id :'es-ES',
        label: 'Spanish (Spain)',
      },
      {
        id :'fr-FR',
        label: 'French (France)',
      },
      {
        id :'pt-PT',
        label: 'Portuguese (Portugal)',
      },
      {
        id: 'cmn-CN',
        label: 'Chinese (Mandarin, Simplified)',
      },
    ];

    /**
     * Elements
     */
    this.nodes = {
      button: null,
      input: null,
      wrapper: null,
      enter: null,
      title: null,
      desc: null,
      selectLabel: null,
      select: null,
      selectDesc: null,
    };
    this.safariBulshitFlag = false;
    /**
     * Input opening state
     */
    this.listeners = api.listeners;
    this.inputOpened = false;
    this.toolbar = api.toolbar;
    this.inlineToolbar = api.inlineToolbar;
    this.notifier = api.notifier;
    this.i18n = api.i18n;
    this.selection = new SelectionUtils();

    this.tag = 'LANG';
    this.api = api;
  }

  /**
   * Sanitizer Rule
   * Leave <a> tags
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      style: false,
      lang: {
        xml: true,
        'xml:lang': 'en-us',
        'xml:lang': true,
      },
    };
  }

  /**
   * Create button for Inline Toolbar
   */
  render() {
    // const text = document.createElement('span');
    // text.innerText = this.i18n.t('Lang');
    const icon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="flex-shrink-0 h-5 w-5 text-yellow-400 fill-current"><path fill-rule="evenodd" d="M7 2a1 1 0 011 1v1h3a1 1 0 110 2H9.578a18.87 18.87 0 01-1.724 4.78c.29.354.596.696.914 1.026a1 1 0 11-1.44 1.389c-.188-.196-.373-.396-.554-.6a19.098 19.098 0 01-3.107 3.567 1 1 0 01-1.334-1.49 17.087 17.087 0 003.13-3.733 18.992 18.992 0 01-1.487-2.494 1 1 0 111.79-.89c.234.47.489.928.764 1.372.417-.934.752-1.913.997-2.927H3a1 1 0 110-2h3V3a1 1 0 011-1zm6 6a1 1 0 01.894.553l2.991 5.982a.869.869 0 01.02.037l.99 1.98a1 1 0 11-1.79.895L15.383 16h-4.764l-.724 1.447a1 1 0 11-1.788-.894l.99-1.98.019-.038 2.99-5.982A1 1 0 0113 8zm-1.382 6h2.764L13 11.236 11.618 14z" clip-rule="evenodd"></path></svg>`
    this.nodes.button = document.createElement('button');
    this.nodes.button.type = 'button';
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.innerHTML = icon;
    this.nodes.button.appendChild($.svg('cross', 11, 11));
    return this.nodes.button;
  }

  /**
   * Input for the link
   */
  renderActions() {
    this.nodes.wrapper = document.createElement('div');

    // title and desc
    this.nodes.title = document.createElement('div');
    this.nodes.title.classList = this.CSS.title;
    this.nodes.desc = document.createElement('div');
    this.nodes.desc.classList = this.CSS.desc;
    this.nodes.title.innerText = this.i18n.t('Change language');
    this.nodes.desc.innerText = this.i18n.t('Change the language in which the word will be pronounced');
    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.desc);
    document.querySelector('body').addEventListener('click', (click) => {
      // console.log('click docuemnt event', click);
    })
    document.querySelector('body').addEventListener('mousedown', (click) => {
      const { target } = click;

      // console.log('click docuemnt event mousedown', target, click);
      if (target.id === 'hm-preview-btn' || target.id === 'hm-enter-btn') {
        console.log('%cclick docuemnt event target previe', 'color:orange', target, click);
        click.preventDefault();
      }
    });
    // end title and desc

    // custom text wrapper
    this.nodes.inputWrapper = document.createElement('div');
    this.nodes.inputWrapper.classList = this.CSS.inputWrapper;

    // audio
    this.nodes.audioPlayer = document.createElement('audio');
    this.nodes.audioPlayer.classList = 'hidden'
    // select
    this.nodes.select = document.createElement('select');
    this.nodes.select.classList = this.CSS.select;
    this.langOptions.forEach(item => {
      this.nodes.select.innerHTML += `<option id="${item.id}" value="${item.id}">${item.label}</option>`;
    });

    this.nodes.select.addEventListener('change', (event) => {
      const selectedAsSayOption = this.langOptions.find((el) => el.id === this.nodes.select.value);
      this.langOption = selectedAsSayOption;
      console.log('select changed value', this.nodes.select.value, selectedAsSayOption);
    });

    this.nodes.wrapper.appendChild(this.nodes.audioPlayer);
    this.nodes.wrapper.appendChild(this.nodes.select);
    // end select

  
    // this.nodes.btnWrapper = document.createElement('div');
    // this.nodes.btnWrapper.classList = 'flex justify-between items-center';

    this.nodes.enterBtn = document.createElement('button');
    this.nodes.previewBtn = document.createElement('button');
    this.nodes.previewBtn.setAttribute('tabindex', 1)
    this.nodes.wrapper.classList = `relative ${this.CSS.wrapper}`;
    this.nodes.enterBtn.classList = 'text-white font-bold border-2 border-white px-4 py-1 rounded-md cursor-pointer hover:text-hm-brand hober:border-hm-brand mt-5 relative float-right top-[-4px]';
    this.nodes.previewBtn.classList = 'text-white font-bold border-2 border-white px-4 py-1 rounded-md cursor-pointer hover:text-hm-brand hober:border-hm-brand mt-4 mr-[61px]';
    const templatePreview = document.createElement('template');
    templatePreview.innerHTML = `<span class="flex space-x-2 items-center pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14" height="18" width="18"><path fill="currentColor" fill-rule="evenodd" d="M8.41939 2.54077C8.38683 1.74465 7.98921 1.07181 7.36858 0.794796C6.71042 0.501023 5.9697 0.724485 5.46269 1.39875C5.28018 1.64146 5.1043 1.89634 4.93831 2.15681C4.61246 2.66812 4.07027 2.99882 3.48135 3.03695C3.15299 3.05821 2.87106 3.08764 2.62575 3.12255C1.44631 3.29042 0.598632 4.25862 0.404588 5.37064C0.316852 5.87343 0.25 6.44245 0.25 6.99999C0.25 7.55753 0.316852 8.12655 0.404588 8.62934C0.598632 9.74136 1.44631 10.7096 2.62575 10.8774C2.87106 10.9123 3.15299 10.9418 3.48135 10.963L3.51366 10.4641L3.48135 10.963C4.07027 11.0012 4.61246 11.3319 4.93831 11.8432C5.1043 12.1036 5.28018 12.3585 5.46269 12.6012C5.9697 13.2755 6.71042 13.499 7.36859 13.2052C7.98921 12.9282 8.38683 12.2553 8.41939 11.4592C8.46414 10.3651 8.5 8.89669 8.5 6.99999C8.5 5.10329 8.46414 3.63488 8.41939 2.54077ZM12.2094 2.77942C11.8995 2.5046 11.4255 2.53306 11.1506 2.84298C10.8758 3.1529 10.9043 3.62692 11.2142 3.90173C11.9512 4.55526 12.5009 5.59439 12.5009 6.99991C12.5009 8.40543 11.9512 9.44456 11.2142 10.0981C10.9043 10.3729 10.8758 10.8469 11.1506 11.1568C11.4255 11.4668 11.8995 11.4952 12.2094 11.2204C13.2756 10.2749 14.0009 8.82347 14.0009 6.99991C14.0009 5.17635 13.2756 3.72488 12.2094 2.77942ZM10.754 5.42748C10.4474 5.14899 9.97306 5.17179 9.69457 5.47841C9.41608 5.78504 9.43888 6.25936 9.7455 6.53785C9.83225 6.61664 9.92102 6.75913 9.92102 7.00005C9.92102 7.24096 9.83225 7.38346 9.7455 7.46224C9.43888 7.74073 9.41608 8.21506 9.69457 8.52168C9.97306 8.8283 10.4474 8.8511 10.754 8.57261C11.162 8.20204 11.421 7.65255 11.421 7.00005C11.421 6.34754 11.162 5.79806 10.754 5.42748Z" clip-rule="evenodd"></path></svg> <span>${this.i18n.t('Preview')}</span></span>`;
    this.nodes.previewBtn.appendChild(templatePreview.content.firstChild);
    this.nodes.previewBtn.setAttribute('id', 'hm-preview-btn');
    this.nodes.enterBtn.setAttribute('id', 'hm-enter-btn');

    
    this.listeners.on(this.nodes.enterBtn, 'click', async (event) => {
      await this.enterPressed(event);
    }, false);
    this.listeners.on(this.nodes.previewBtn, 'click', async (event) => {
      await this.playPreview(event);
    }, false);
    const template = document.createElement('template');
    const enterSign = `<span class="flex items-center space-x-2 pointer-events-none"><span>${this.i18n.t('Apply')}</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z" fill="currentColor"></path></g></svg></span>`;
    template.innerHTML = enterSign;
    this.nodes.enterBtn.appendChild(template.content.firstChild);
    
    // this.nodes.wrapper.appendChild(this.nodes.inputWrapper);
    this.nodes.wrapper.appendChild(this.nodes.previewBtn);
    this.nodes.wrapper.appendChild(this.nodes.enterBtn);
    // this.nodes.wrapper.appendChild(this.nodes.btnWrapper);
    return this.nodes.wrapper;
  }

  /**
   * Handle clicks on the Inline Toolbar icon
   *
   * @param {Range} range - range to wrap with link
   */
  surround(range) {
    // console.log('surround');
    if (!range) {
      return;
    }
    this.range = range;
    let termWrapper = this.api.selection.findParentTag(this.tag, LangInlineTool.CSS);
    this.selection.save();
    /**
     * Range will be null when user makes second click on the 'link icon' to close opened input
     */
    // if (range) {
    //   /**
    //    * Save selection before change focus to the input
    //    */
    //   if (!this.inputOpened) {
    //     /** Create blue background instead of selection */
    //     this.selection.setFakeBackground();
    //     this.selection.save();
    //   } else {
    //     this.selection.restore();
    //     this.selection.removeFakeBackground();
    //   }
    //   const parentAnchor = this.selection.findParentTag('A');
    //   /**
    //    * Unlink icon pressed
    //    */
    if (termWrapper) {
      this.removeLocalAlias(termWrapper);
      // this.unlink();
      this.unwrap(termWrapper);
      this.closeActions();
      this.checkState();
      // this.toolbar.close();
      return;
    }
    // }
    this.toggleActions();
    this.openActions();
  }

  async removeLocalAlias(parentAnchor) {
    const language = document.querySelector('#article-edit').dataset.language;
    const link = parentAnchor.getAttribute('alias');
    const payload = {
      originalText: parentAnchor.text,
      alias: link,
      language: language,
      feedItemId: this.config.feedItemId,
      textType: this.config.textType,
    };
    console.log('add local alias start', this.config, payload, parentAnchor, link);
    const response = await API.graphql(
      graphqlOperation(
        GQL.RemoveLocalAlias,
        { input: payload },
      ),
    );
  }

  /**
   * Check selection and set activated state to button if there are <a> tag
   *
   * @param {Selection} selection - selection to check
   */
  checkState(selection) {
    const anchorTag = this.selection.findParentTag(this.tag);
    if (anchorTag && anchorTag.getAttribute('xml:lang')) {
      this.selection.expandToTag(anchorTag);
      this.nodes.button.classList.add(this.CSS.buttonUnlink);
      this.nodes.button.classList.add(this.CSS.buttonActive);
      this.openActions();
      /**
       * Fill input value with link href
       */
      // const aliasValue = anchorTag.getAttribute('xml:lang');
      const data = anchorTag.getAttribute('xml:lang');
      // this.nodes.input.value = data !== 'null' ? data : '';
      // console.log('================== option', this.langOption);
      // this.nodes.input.value = aliasValue !== 'null' ? aliasValue : '';
      
      this.langOption = this.langOptions.find((el) => el.id === data);
      
      this.selection.save();
      this.range = this.selection.savedSelectionRange;
      if (!this.langOption) {
        this.langOption = {
          id: 'en-US',
          label: 'English (US)',
        };
      }
      this.nodes.select.value = this.langOption.id;
    } else {
      this.nodes.button.classList.remove(this.CSS.buttonUnlink);
      this.nodes.button.classList.remove(this.CSS.buttonActive);
    }
    return !!anchorTag;
  }

  /**
   * Function called with Inline Toolbar closing
   */
  clear() {
    console.log('clear', this.safariBulshitFlag, this.toolbar);
    // this.toolbar.open();
    if (this.safariBulshitFlag) {
      this.toolbar.open();
      this.safariBulshitFlag = false;
      return;
    }
    this.closeActions();
  }
  /**
   * Set a shortcut
   */

  get shortcut() {
    return 'CMD+K';
  }

  /**
   * Show/close link input
   */
  toggleActions() {
    if (!this.inputOpened) {
      this.openActions(true);
      document.querySelector('body').classList.add('disable-break');
    } else {
      this.closeActions(false);
      document.querySelector('body').classList.remove('disable-break');
    }
  }

  /**
   * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
   */
  openActions(needFocus = false) {
    console.log('open lang action');
    document.querySelector('body').classList.add('disable-break');
    this.nodes.wrapper.classList.add(this.CSS.inputShowed);
    if (needFocus) {
      this.nodes.wrapper.focus();
    }
    this.inputOpened = true;
    if (document.querySelector('.ce-inline-tool-sayas-wrapper')) document.querySelector('.ce-inline-tool-sayas-wrapper').classList.remove(this.CSS.inputShowed);
    if (document.querySelector('.ce-inline-tool-prosody-wrapper')) document.querySelector('.ce-inline-tool-prosody-wrapper').classList.remove(this.CSS.inputShowed);
  }

  /**
   * Close input
   *
   * @param {boolean} clearSavedSelection — we don't need to clear saved selection
   *                                        on toggle-clicks on the icon of opened Toolbar
   */
  closeActions(clearSavedSelection = true) {
    // console.log('close', clearSavedSelection);
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();
      currentSelection.save();
      this.selection.restore();
      this.selection.removeFakeBackground();
      // and recover new selection after removing fake background
      currentSelection.restore();
    }
    this.nodes.wrapper.classList.remove(this.CSS.inputShowed);
    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    document.querySelector('body').classList.remove('disable-break');
    this.inputOpened = false;
  }

  /**
   * Enter pressed on input
   *
   * @param {KeyboardEvent} event - enter keydown event
   */
  enterPressed(event) {
    // console.log('enterPressed', event, this.nodes);
    let value = this.nodes.select.value;

    console.log('enterPressed',  value);

    value = this.prepareLink(value);
    this.selection.restore();
    this.selection.removeFakeBackground();
    // this.insertLink(value, option);
    this.wrap(value);
    /**
     * Preventing events that will be able to happen
     */
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.selection.collapseToEnd();
    this.inlineToolbar.close();
  }

  async playPreview(event) {
    let option = this.nodes.select.value || 'en-US';
    let tagName = 'lang';
    let tagProperty = 'xml:lang';


    this.selection.restore();
    const parentAnchor = this.selection.findParentTag(this.tag);
    if (parentAnchor) {
      // found closest 'A' tag that wraps current selection
      this.selection.expandToTag(parentAnchor);
    }
    const text = window.getSelection().toString();
    const previewTag = document.createElement(tagName);
    previewTag.setAttribute(tagProperty, option);
    previewTag.innerHTML = text;
  

    // const previewText = parentAnchor.innerText;
    // const parent = previewTag.
    const range = this.selection.savedSelectionRange;
    const parent = range.commonAncestorContainer.parentElement
    let isAlternative = false;
    if (parent) {
      isAlternative = !!parent.closest('[data-alternative="true"]');
      console.log('ALETERNATIVE', text, isAlternative, parent.closest('[data-alternative="true"]'));
    }
    console.log('playPreview', text, isAlternative, previewTag, previewTag.outerHTML, option);
    const affix = isAlternative ? ' Alternative' : '';
    const payload = {
      feedId: this.config.feedId,
      text: previewTag.outerHTML,
      textType: this.config.textType || 'Content',
    };

    payload.textType += affix;
    console.log('play preview', payload);
    const response = await API.graphql(
      graphqlOperation(
        GQL.GenerateTextPreview,
        { input: payload },
      ),
    );
    const { generateTextPreview } = response.data;
    if (!generateTextPreview.ok) return;
    const { url } = generateTextPreview;

    this.nodes.audioPlayer.setAttribute('src', url);
    this.nodes.audioPlayer.load();
    this.nodes.audioPlayer.play();
    console.log('play preview response', this.nodes.audioPlayer, url);
  }

  /**
   * Detects if passed string is URL
   *
   * @param {string} str - string to validatewwe
   * @returns {boolean}
   */
  validateURL(str) {
    /**
     * Don't allow spaces
     */
    return true;
    // return !/\s/.test(str);
  }

  /**
   * Process link before injection
   * - sanitize
   * - add protocol for links like 'google.com'
   *
   * @param {string} link - raw user input
   */
  prepareLink(link) {
    link = link.trim();
    if (link === '') return ' '; // return space for empty string;
    // link = this.addProtocol(link);
    return link;
  }

  /**
   * Inserts <a> tag with "href"
   *
   * @param {string} link - "href" value
   */
  async insertLink(link, option) {
    /**
     * Edit all link, not selected part
     */
    const anchorTag = this.selection.findParentTag('A');
    // console.log('anchor tag', anchorTag, this.selection);
    if (anchorTag) {
      this.selection.expandToTag(anchorTag);
    }
    document.execCommand(this.commandLink, false, link);
    console.log('insertLink', this.commandLink, link);
    const aTag = this.selection.findParentTag('A');
    aTag.setAttribute('interpret-as', option);
    const language = document.querySelector('#article-edit').dataset.language;
    const payload = {
      originalText: aTag.text,
      alias: link,
      language: language,
      feedItemId: this.config.feedItemId,
      textType: this.config.textType,
    };
    // aTag.title = link;
    // if (option === 'custom-text') aTag.removeAttribute('alias');
  }

  /**
   * Removes <a> tag
   */
  unlink() {
    document.execCommand(this.commandUnlink, false, false);
  }

  wrap(link) {
    /**
     * Create a wrapper for highlighting
     */
    const anchorTag = this.selection.findParentTag(this.tag);

    let marker = anchorTag || document.createElement(this.tag);

    link = link.replaceAll('%', '');
    // link += '%';
    // marker.setAttribute('alias', link);
    let option = this.nodes.select.value;
    marker.setAttribute('xml:lang', option);

    // marker.classList.add('ce-inline-tool-language');

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    const range = this.range;
    
    if (!anchorTag) {
      console.log('wraped already', anchorTag);
      marker.appendChild(range.extractContents());
      range.insertNode(marker);
    }

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(marker);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    let sel = window.getSelection();
    this.range = sel.getRangeAt(0);

    let unwrappedContent = this.range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    this.range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(this.range);
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon() {
    return `<svg id="Group_89" data-name="Group 89" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_153" data-name="Rectangle 153" width="15.5" height="15.5" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_88" data-name="Group 88" clip-path="url(#clip-path)">
      <rect id="Rectangle_152" data-name="Rectangle 152" width="14" height="14" rx="4" transform="translate(0.75 0.75)" fill="none" stroke="#fff" stroke-width="1.5"/>
      <path id="Path_871" data-name="Path 871" d="M11.137,12.5a.749.749,0,0,1-.548-.238L8.8,10.344l-.03-.032L6.448,7.828l-.076-.075a1.006,1.006,0,0,0-.7-.252A.968.968,0,0,0,5,7.82L1.435,11.586a.75.75,0,0,1-1.09-1.031L3.9,6.8A2.471,2.471,0,0,1,7.38,6.642a1.969,1.969,0,0,1,.171.171L9.33,8.718l.885-.947a2.451,2.451,0,0,1,1.7-.8,2.371,2.371,0,0,1,1.783.637c.064.059.119.114.171.171l1.278,1.435a.75.75,0,0,1-1.119,1l-1.344-1.5a.975.975,0,0,0-1.369.073l-.962,1.029,1.328,1.422a.75.75,0,0,1-.548,1.262" fill="#fff"/>
      <line id="Line_6" data-name="Line 6" x2="0.009" transform="translate(9.528 5.083)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </g>
  </svg>  
  `;
  }

  static title = 'Change Language';
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns {boolean}
 */
LangInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
// LangInlineTool.title = 'Alias';
