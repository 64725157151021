<template>
  <div id="article-edit" ref="articleEdit">
    <div id="create-project-placeholder" class="bg-transparent pointer-events-none fixed top-20 left-3 w-56 h-52"></div>
    <div id="showHelp" class="fixed bottom-24 right-9 z-[999] h-9 w-9 cursor-pointer hover:text-yellow-500" @click="showHelp = !showHelp" v-tippy="{ content: 'help' }">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path fill="currentColor" fill-rule="evenodd" d="M7 0.25C5.85168 0.25 4.73112 0.35499 3.65727 0.474686C1.98532 0.661049 0.641907 2.0033 0.462704 3.67828C0.348408 4.74658 0.25 5.85964 0.25 7C0.25 8.14035 0.348408 9.25341 0.462704 10.3217C0.641908 11.9967 1.98532 13.339 3.65727 13.5253C4.73112 13.645 5.85168 13.75 7 13.75C8.14832 13.75 9.26888 13.645 10.3427 13.5253C12.0147 13.339 13.3581 11.9967 13.5373 10.3217C13.6516 9.25341 13.75 8.14035 13.75 7C13.75 5.85964 13.6516 4.74658 13.5373 3.67828C13.3581 2.0033 12.0147 0.661049 10.3427 0.474686C9.26888 0.35499 8.14832 0.25 7 0.25Z" clip-rule="evenodd"></path><path fill="#ffffff" fill-rule="evenodd" d="M5.98804 5.32589C5.98804 4.70317 6.58746 4.21124 7.19822 4.33273C7.58563 4.40979 7.91679 4.74095 7.99385 5.12836C8.03198 5.32005 8.00481 5.45148 7.95089 5.55758C7.8914 5.6746 7.7746 5.80765 7.56326 5.94886C7.2834 6.13586 6.99713 6.33122 6.78719 6.54966C6.55406 6.79223 6.37566 7.10289 6.37566 7.50946C6.37566 7.85464 6.65548 8.13446 7.00066 8.13446C7.34584 8.13446 7.62566 7.85464 7.62566 7.50946C7.62566 7.49884 7.62674 7.49657 7.628 7.49392L7.62832 7.49324C7.63166 7.48603 7.64494 7.46109 7.68844 7.41582C7.78825 7.31197 7.95689 7.18921 8.25772 6.9882C8.58501 6.76951 8.87957 6.48919 9.0652 6.12397C9.25639 5.74783 9.30809 5.32816 9.21984 4.8845C9.04414 4.00122 8.32536 3.28244 7.44208 3.10675C6.0582 2.83148 4.73804 3.91489 4.73804 5.32589C4.73804 5.67107 5.01786 5.95089 5.36304 5.95089C5.70822 5.95089 5.98804 5.67107 5.98804 5.32589ZM7.625 10.0308C7.625 9.68559 7.34518 9.40577 7 9.40577C6.65482 9.40577 6.375 9.68559 6.375 10.0308V10.31C6.375 10.6552 6.65482 10.935 7 10.935C7.34518 10.935 7.625 10.6552 7.625 10.31V10.0308Z" clip-rule="evenodd"></path></svg>
    </div>
    <div id="help-tooltip" class="fixed bottom-24 right-8 z-[999] max-w-[300px] md:max-w-[400px]" v-if="showHelp">
      <div class="flex absolute top-2 right-2 items-center space-x-3">
        <div class="cursor-pointer hover:text-yellow-500" @click="tooltipIndex--" v-show="tooltipIndex !== 0">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="none" viewBox="0 0 14 14"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M10 1C7.83685 2.77229 6.56301 3.97626 5.03897 5.75187C4.42278 6.46978 4.42278 7.53022 5.03897 8.24813C6.56301 10.0237 7.83685 11.2277 10 13"></path></svg>
        </div>
        <div class="cursor-pointer hover:text-yellow-500" @click="tooltipIndex++" v-show="tooltipIndex !== maxTooltips">
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5" fill="none" viewBox="0 0 14 14"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M4 13C6.16315 11.2277 7.43699 10.0237 8.96103 8.24813C9.57722 7.53022 9.57722 6.46978 8.96103 5.75187C7.43699 3.97626 6.16315 2.77229 4 1"></path></svg>
        </div>
        <div class="cursor-pointer opacity-20 hover:opacity-100" @click="showHelp = false">
          <svg xmlns="http://www.w3.org/2000/svg" width="32.126" height="32.138" viewBox="0 0 32.126 32.138"
                  class="hover:text-hm-blue cursor-pointer h-6 w-6">
                  <g id="Group_5436" data-name="Group 5436" transform="translate(-1623.816 -238.04)">
                    <path id="Path_266" data-name="Path 266" d="M19.563,3.5a27.326,27.326,0,0,1,7.306.821,11.292,11.292,0,0,1,5.106,2.83,11.292,11.292,0,0,1,2.83,5.106,27.326,27.326,0,0,1,.821,7.306,27.345,27.345,0,0,1-.821,7.308,11.309,11.309,0,0,1-2.829,5.11,11.294,11.294,0,0,1-5.106,2.834,27.272,27.272,0,0,1-7.307.823,27.272,27.272,0,0,1-7.307-.823A11.294,11.294,0,0,1,7.15,31.981a11.309,11.309,0,0,1-2.829-5.11A27.345,27.345,0,0,1,3.5,19.563a27.329,27.329,0,0,1,.821-7.306A11.292,11.292,0,0,1,7.15,7.151a11.292,11.292,0,0,1,5.106-2.83A27.328,27.328,0,0,1,19.563,3.5Z" transform="translate(1620.316 234.54)" fill="currentColor"/>
                    <g id="Group_5388" data-name="Group 5388" transform="translate(1640 242.687) rotate(45)">
                      <path id="Path_43306" data-name="Path 43306" d="M14.8,6.8H10A.8.8,0,0,1,9.2,6V1.2a1.2,1.2,0,1,0-2.4,0V6a.8.8,0,0,1-.8.8H1.2a1.2,1.2,0,1,0,0,2.4H6a.8.8,0,0,1,.8.8v4.8a1.2,1.2,0,1,0,2.4,0V10a.8.8,0,0,1,.8-.8h4.8a1.2,1.2,0,0,0,0-2.4Z" fill="#fff"/>
                    </g>
                  </g>
                </svg>
        </div>
      </div>
      <transition
          name="slide"
          mode="out-in"
        >
        <div class="p-5 rounded-xl bg-white shadow-lg space-y-3 pb-10"
          v-show="tooltipIndex === 0">
          <div class="title font-bold flex justify-between">
            <div>
              {{$t('helpTooltip.alternativeVoice.title')}}
            </div>
          </div>
          <div class="text-xs help-tooltip" v-html="$t('helpTooltip.alternativeVoice.description')">
            {{$t('helpTooltip.alternativeVoice.description')}}
          </div>
          <div class="text-sm max-w-full bg-gray-100 p-3 rounded-md">
            <video src="/help/alternative-voice.mp4" autoplay loop></video>
          </div>
          <!-- <div class="text-xs text-blue">Play again</div> -->
        </div>
      </transition>
      <transition
          name="slide"
          mode="out-in"
        >
        <div class="p-5 rounded-xl bg-white shadow-lg space-y-3 pb-10"
          v-show="tooltipIndex === 1">
          <div class="title font-bold flex justify-between">
            <div>
              {{$t('helpTooltip.sayAs.title')}}
            </div>
          </div>
          <div class="text-xs help-tooltip" v-html="$t('helpTooltip.alternativeVoice.description')">
            {{$t('helpTooltip.alternativeVoice.description')}}
          </div>
          <div class="text-sm max-w-full bg-gray-100 p-3 rounded-md">
            <video src="/help/say-as.mp4" autoplay loop></video>
          </div>
          <!-- <div class="text-xs text-blue">Play again</div> -->
        </div>
      </transition>
      <transition
          name="slide"
          mode="out-in"
        >
        <div class="p-5 rounded-xl bg-white shadow-lg space-y-3 pb-10"
          v-show="tooltipIndex === 2">
          <div class="title font-bold flex justify-between">
            <div>
              {{$t('helpTooltip.publishoptions.title')}}
            </div>
          </div>
          <div class="text-xs help-tooltip" v-html="$t('helpTooltip.publishoptions.description')">
            {{$t('helpTooltip.publishoptions.description')}}
          </div>
          <div class="text-sm max-w-full bg-gray-100 p-3 rounded-md">
            <video src="/help/publishoptions.mp4" autoplay loop></video>
          </div>
          <!-- <div class="text-xs text-blue">Play again</div> -->
        </div>
      </transition>
      <div class="flex items-center relative -top-9 left-5">
        <input id="empty-project" v-model="neverShowTooltips" name="empty-project" type="checkbox" class="h-4 w-4 text-black focus:ring-black border-gray-300 rounded">
        <label for="empty-project" class="ml-2 block text-sm text-gray-900">
          Don't show this again
        </label>
      </div>
    </div>
    <header class="py-8">
      <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}`" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.articles')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" v-show="articleId !== 'new'" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.articleEdit')}}</a>
                  <a href="#" v-show="articleId === 'new'" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.creatItemTitle')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            <span v-show="articleId !== 'new'">{{$t('ui.editArticle')}}</span>
            <span v-show="articleId === 'new'">{{$t('ui.creatItemTitle')}}</span>
          </h1>
          <div class="mt-2 flex flex-col sm:flex-row sm:justify-between w-1/3 min-w-[200px]" style="min-width: 360px;" v-show="!selectedArticleData.status">
            <div class="sm:flex w-full md:space-x-2 space-y-2 md:space-y-0">
              <div class="w-1/3 h-5 rounded-sm bg-gray-300 animate-pulse "></div>
              <div class="w-2/4 h-5 rounded-sm bg-gray-300 animate-pulse"></div>
              <div class="w-1/3 h-5 rounded-sm bg-gray-300 animate-pulse"></div>
            </div>
          </div>
          <div id="status-bar" class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-4 shepherd-enabled shepherd-target pl-2 pr-2 pb-2 -mb-2 -mr-2 -ml-2" v-if="articleId !== 'new'"  v-show="selectedArticleData.status">
            <div class="mt-2 flex items-center text-sm text-gray-500">
              <div
                v-show="!forcePublishing || selectedArticleData.status === 'published'"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase"
                :class="selectedArticleData.status">
                {{$t('ui.' + selectedArticleData.status)}}
              </div>
              <div
                v-show="forcePublishing && selectedArticleData.status !== 'published'"
                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase"
                :class="'publishing'">
                {{$t('ui.publishing')}}
              </div>
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-500"
              v-tippy="{content: moment.utc(selectedArticleData.updatedAt).format('DD-MM-YYYY HH:mm')}">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" x-description="Heroicon name: solid/calendar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path>
                </svg>
                <span class="mr-1" >{{$t('ui.updatedAt')}}</span>{{moment.utc(selectedArticleData.updatedAt).fromNow() }}
            </div>

            <router-link tag="div" :to="`/articles/${selectedWebsite.id}/stats`" class="mt-2 flex items-center text-sm text-gray-500 cursor-pointer" v-if="selectedArticle.playsCount">
              <svg v-tippy="{content: $t('ui.statsTotal') + ' ' + selectedArticle.playsCount.total + '<br> ' + $t('ui.statsMonth') + ' ' + selectedArticle.playsCount.month + '<br>' + $t('ui.statsSeven') + ' ' + selectedArticle.playsCount.sevenDays}" xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd" />
              </svg>
              <span class="relative -top-px">{{selectedArticle.playsCount.total}}</span>
            </router-link>
            <div class="mt-2 flex items-center text-sm text-gray-500" v-if="!isProject" v-tippy="{content: $t('ui.goToOriginalArticle')}">
              <a :href="item.url" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                  <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
                </svg>
              </a>
            </div>
            <div class="mt-2 flex items-center text-sm text-gray-500"
              v-if="selectedArticle.status === 'DRAFT' && selectedArticle.acceptedVersionId">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
              {{$t('ui.unpublishedChanges')}}
            </div>
            <div class="mt-2 flex items-center text-sm text-yellow-500" v-tippy="{content: $t('ui.podcastPublished')}"
              v-if="isPodcastPublished">
              <PodcastIcon class="h-4 w-4"></PodcastIcon>
            </div>
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <div class="ml-0 lg:text-right text-left">
            <!-- <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full "
              :class="item.status">
              {{item.status}}
            </p>
            <p class="mt-2 flex items-center text-sm text-gray-500">
              <span class="mr-1">{{$t('ui.updatedAt')}}</span><timeago :datetime="item.updatedAt"></timeago>
            </p> -->
          </div>
        </div>
      </div>
    </header>
    <div class="">
      <audio ref="playerTitle" @ended="handleTitlePlaybackEnd" controls="controls" id="audioTitlePreview" v-show="false">
        <source :src="audioTitlePreviewUrl" type="audio/mp3" />
      </audio>
      <audio ref="player" @ended="handlePlaybackEnd" controls="controls" id="audioPreview" v-show="false">
        <source :src="audioPreviewUrl" type="audio/mp3" />
      </audio>
      <!-- <vue-plyr ref="previewPlayer">
          <audio controls crossorigin playsinline>
            <source
                :src="audioTrack.src"
                type="audio/mp3"
            />
          </audio>
        </vue-plyr> -->
    </div>
    <div id="article-content-wrapper" class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-1 -mt-2">
      <!-- AUDIO PREVIEW -->
      <div class="mt-6 rounded" v-show="hasAudio && (audioTrack.src || audioPodcastTrack.src)">
        <label for="content" class=" block text-xs font-medium text-gray-400 uppercase tracking-wider">{{$t('ui.preview')}}</label>
        <div class="flex justify-between">
          <div class="flex relative top-1">
            <div class="inline-flex h-[26px] font-bold items-center justify-center border border-transparent rounded-l-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out px-4 text-xs hover:bg-hm-brand hover:text-black"
              :class="{'bg-black text-white': activePlayer === 'web', 'bg-gray-200 text-black': activePlayer !== 'web'}"
              @click="playPreview('web')"
            >
              Web player
            </div>
            <div class="inline-flex h-[26px] font-bold items-center justify-center border border-transparent rounded-r-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out px-4 text-xs hover:bg-hm-brand hover:text-black"
              :class="{'bg-black text-white': activePlayer === 'podcast', 'bg-gray-200 text-black': activePlayer !== 'podcast'}"
              @click="playPreview('podcast')"
              >
              Podcast
            </div>
          </div>
          <div>
            <Button
              size="sm"
              @click.native="copy(shareLink)"
              v-tippy="{content: $t('ui.shareAudio')}"
              class="relative top-1 inline-block">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
              </svg>
            </Button>
            <div class="ml-1 relative z-20 inline-block" v-click-outside="closeDownloadDropdown">
              <Button size="sm"
                @click.native="showDownloadDropdown = !showDownloadDropdown"
                v-tippy="{content: $t('ui.downloadAudioFiles')}"
                class="mr-1 relative top-1 inline-block">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clip-rule="evenodd" />
                </svg>
              </Button>
              <transition
                name="custom-classes-transition"
                enter-class="transform opacity-0 scale-95"
                enter-active-class="transition ease-out duration-100"
                enter-to-class="transform opacity-100 scale-100"
                leave-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-to-class="transform opacity-0 scale-95"
              >
                <div
                  v-show="showDownloadDropdown"
                  class="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg pt-0 pb-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                  <div class="">
                    <div class="px-1">
                      <a :href="audioTrack.src"
                        :download="item.title"
                        target="_blank"
                        class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md pl-2 min-w-[220px]">
                        <span>{{$t('ui.downloadWeb')}}</span>
                      </a>
                      <!-- <a :href="audioPodcastTrack.src"
                        download
                        target="_blank"
                        class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md pl-2 min-w-[220px]">
                        <span>{{$t('ui.downloadPodcast')}}</span>
                      </a> -->
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <Button size="sm" class=" -mt-2" @click.native="showEmbedModal = true">{{$t('ui.showEmbedCode')}}</Button>
          </div>
        </div>
        <div class="border border-gray-200 mt-2 rounded-md" v-show="audioFilename || audioPodcastFilename">
          <!-- <vue-plyr ref="plyr">
            <audio controls crossorigin playsinline>
              <source
                  :src="audioTrack.src"
                  type="audio/mp3"
              />
            </audio>
          </vue-plyr> -->
          <div v-show="(activePlayer === 'podcast' && isPodcastPublished) || (activePlayer === 'web' && isWebPublished)">
            <PlayerEmbeded :playerStyle="playerStyle" :url="audioTrack.src || audioPodcastTrack.src" :title="this.stripTag(item.title)" ref="plyr" v-show="audioFilename || audioPodcastFilename" v-if="audioTrack.src"></PlayerEmbeded>
          </div>
        </div>
        <div class="no-webaudio-message bg-black rounded-lg text-center py-9 relative z-30" v-show="activePlayer === 'web' && !isWebPublished">
          <div class="text-xs text-gray-500 mt-1">{{$t('ui.webNotPublishedMessage')}}</div>
        </div>
        <div class="no-podcast-message bg-black rounded-lg text-center py-9 relative z-30" v-show="activePlayer === 'podcast' && !isPodcastPublished">
          <div class="text-xs text-gray-500 mt-1">{{$t('ui.podcastNotPublishedMessage')}}</div>
        </div>
        <div class="text-xs text-gray-500 mt-1" v-html="$t('ui.playerLinkInfo')">{{$t('ui.playerLinkInfo')}}</div>
      </div>
      <div class="rounded-md bg-blue-50 p-4 mt-5"
        v-show="isGenerating || selectedArticleData.status === 'publishing' || (forcePublishing && selectedArticleData.status !== 'published')">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/information-circle -->
            <svg class="animate-spin -ml-1 h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-blue-700 font-medium">
              {{$t('ui.audioIsGenerated')}}
            </p>
            <p class="mt-3 text-sm md:mt-0 md:ml-6">
            </p>
          </div>
        </div>
      </div>
      <div class="mt-4 grid grid-cols-12 gap-6">
          <div class="col-span-12">
            <label for="title" class="mt-6 flex justify-between space-x-2 text-sm font-medium text-gray-700 h-6">
              <div class="block text-xs font-medium text-gray-400 uppercase tracking-wider">
                <span>{{$t('ui.titleLabel')}}</span>
              </div>
            </label>
            <div class="relative" v-if="loading">
              <div class="mt-2 sm:flex sm:justify-between w-full">
                <div class="sm:flex w-full md:space-x-2 space-y-2 md:space-y-0">
                  <div class="w-1/12 h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                  <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                  <div class="w-1/12 h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                  <div class="w-1/5 h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                </div>
              </div>
            </div>
            <div class="relative" v-if="!loading">
              <title-editor
                ref="titleEditor"
                class="py-4 px-3"
                type="default"
                :feedItemId="articleId"
                :feedId="feedId"
                :content.sync="item.title"
                @playBlock="playTitle"
                @stopBlock="stopTitle"></title-editor>
            </div>
          </div>

          <div class="col-span-12 mt-4 pb-10 md:min-h-screen" v-if="feedId">
            <label for="content" class="-mb-3 block text-xs font-medium text-gray-400 uppercase tracking-wider">{{$t('ui.contentLabel')}}</label>
            <div v-if="!loading">
              <article-editor
                v-if="imageGenerationCost"
                ref="editor"
                class="py-4 px-3"
                :feedItemId="articleId"
                :feedId="feedId"
                :clientId="customerId"
                :language="feedLang"
                :content.sync="initData"
                :costs="aiGenerationCost"
                :imageCost="imageGenerationCost"
                @change="contentChange"
                @playParagraph="handlePlayParagraph"
                @stopParagraph="handleStopParagraph"></article-editor>
                <div class="hidden" id="article-lang" :language="feedLang"></div>
            </div>
            <ul class="bg-white min-h-[50vh] mt-6" v-if="loading" key="loading-list">
              <li v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]" :key="i">
                <div class="py-1">
                  <div class="flex items-center  w-full md:space-x-2">
                    <div class="w-1/ h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/2': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                    <div class="w-1/5 h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                    <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/6': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between w-full">
                    <div class="sm:flex w-full md:w-1/2 md:space-x-2 space-y-2 md:space-y-0">
                      <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/6': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                      <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/6': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                      <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/6': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
      </div>
      <!-- <pre>
        {{initData}}
      </pre> -->
    </div>
    <Modal :show.sync="showEmbedModal">
      <div slot="header">{{$t('ui.embedPlayer')}}</div>
      <div class="text-sm text-gray-400 mb-3">{{$t('ui.embedPlayerArticle')}}</div>
      <div class="flex text-sm space-x-2 items-center">
        <span class="text-gray-700">{{$t('ui.playerStyle')}}: </span>
        <div class="mt-2">
          <label class="inline-flex items-center">
            <input type="radio" class="form-radio" v-model="playerSettings.style" name="playerStyle" value="standard">
            <span class="ml-2">{{$t('ui.compact')}}</span>
          </label>
          <label class="inline-flex items-center ml-6">
            <input type="radio" class="form-radio" v-model="playerSettings.style" name="playerStyle" value="minimal">
            <span class="ml-2">{{$t('ui.mini')}}</span>
          </label>
        </div>
      </div>
      <div class="relative text-xs p-4 rounded border-2 border-gray-300 border-dashed space-y-4">
        <pre class="whitespace-pre-wrap">{{code}}</pre>
        <Button size="sm" class="absolute right-3 top-0" @click.native="copy(code)">{{$t('ui.copyCode')}}</Button>
      </div>
      <div class="text-right">
        <Button @click.native="showEmbedModal = false" class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.ok')}}</Button>
      </div>
    </Modal>
    <div v-html="style"></div>
    <!-- <div>{{hasAudio}} | {{audioFilename}} | {{selectedArticleData.status || '-'}} | {{webEpisode}}</div> -->
    <div class="flex">
      <pre class="break-words max-w-md" v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612' || userId === '181646fb-01c8-41b2-8bf2-da10989f56e6'">
        <div class="test bg-black text-white p-2" @click="test">test</div>
        {{feedId}} {{feedLang}}
        <!-- -- {{selectedWebsiteAPVoiceSettings}} --
        <div>{{selectedArticleData.status}} => {{forcePublishing}}</div> -->
        <div>{{isContentChanged}}</div>
        PODCAST: {{podcastEpisode.id}}
        ------------------------------
        WEB: {{webEpisode.id}}
        {{selectedArticleData}}
        <!-- {{cleanTitle}}
        {{titleCharCount}} -->
        <!-- {{itemOriginal.extractedText[5]}}
        {{content[5]}} -->
        <!-- {{initData}} -->
        <!-- {{item.title}} -->
        <!-- {{isContentChanged}}
        {{content}}
        {{itemOriginal.extractedText}} -->
        <!-- {{selectedWebsite}} -->
        <!-- {{playerChannel}} -->
        <!-- {{selectedArticle}} -->
        <!-- {{audioTrack.src}}
        {{audioFilename}}
        {{audioTrack}} -->
        <!-- {{fileNumber}} - {{hasAudio}}
        {{audioTrack}} -->
        <!-- {{hasAudio}}
        {{$appConfig}}
        {{audioTrack}}
        {{item}} -->
      </pre>
    <!-- <pre class=" max-w-md">{{content}}</pre> -->
    </div>
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="md:fixed md:bottom-0 md:py-3 md:pt-5 gradient-white-b-t full-width md:max-w-4xl md:z-20 -ml-2 pl-2">
        <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1">
          {{saveError}}
        </div>
        <!-- isContentChanged -->
        <div class="md:mt-0">
          <div class="sm:space-x-4 space-y-4 md:space-y-0 sm:block flex-col flex"
            v-show="articleId === 'new'">
            <Button
              @click.native="saveNewItem"
              :loading="loadingDraft"
              class="inline-flex items-center justify-center md:py-2 md:px-3">
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 inline-block relative -top-0.5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg> -->
              {{$t('ui.saveNewItem')}}
            </Button>
          </div>
          <div class="sm:space-x-4 space-y-4 md:space-y-0 sm:block flex-col flex" v-show="articleId !== 'new'">
            <div class="font-bold text-green-500 hidden items-center ml-5 text-xs space-x-2 mb-1 animate-in slide-in-from-bottom fade-in" ref="publishedMessage">
              <div class="relative">
                <svg xmlns="http://www.w3.org/2000/svg" class="animate-ping absolute" fill="none" viewBox="0 0 48 48" height="16" width="16"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="relative" fill="none" viewBox="0 0 48 48" height="16" width="16"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
              </div>
              <span>Content published</span>
            </div>
            <div id="publish-btn" class="inline-flex items-center relative -t-[2px] -left-4">
              <Button type="secondary"
                @click.native="prePublish('all')"
                class="flex-1 pr-3 rounded-r-none"
                :class="{'pointer-events-none opacity-50 select-none':false}"
                :disabled="forcePublishing || selectedArticleData.status === 'publishing'">
                <div>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="-ml-1 mr-2 h-4 w-4 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg> -->
                    <span v-if="!(forcePublishing || selectedArticleData.status === 'publishing')">{{$t('ui.savePublish')}}</span>
                    <span
                      v-tippy="{ content: $t('ui.audioIsGenerated')}"
                      v-if="(forcePublishing || selectedArticleData.status === 'publishing')" class="flex items-center space-x-2 relative">
                      <svg class="absolute animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span class="pl-5">{{$t('ui.publishingBtn')}}</span>
                    </span>
                  </div>
              </Button>
              <div class="ml-1 relative" v-click-outside="closeUserMenu">
                <div>
                  <Button type="secondary"
                    v-tippy="{content: $t('ui.publishBtnTooltip')}"
                    @click.native="showDropdown = !showDropdown"
                    class="relative px-1 -ml-[4px] rounded-l-none flex items-center bg-green-600"
                    :class="{'pointer-events-none opacity-50 select-none':false}"
                    :disabled="forcePublishing || selectedArticleData.status === 'publishing'">
                      <div class="">
                        <!-- <div class="w-px bg-white opacity-40 absolute top-1 bottom-1 -left-0.5"></div> -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-[20px]">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                      </div>
                  </Button>
                </div>
                <transition
                  name="custom-classes-transition"
                  enter-class="transform opacity-0 scale-95"
                  enter-active-class="transition ease-out duration-100"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <div
                    v-show="showDropdown"
                    class="bottom-14 origin-bottom-right absolute right-0 mt-2 rounded-md shadow-lg pt-1 pb-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none min-w-[200px]" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                    <div class="">
                      <div class="px-1">
                        <a href="#"
                          @click="prePublish('web')"
                          class="flex items-center font-bold p-2 px-4 text-sm transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
                          <span>{{$t('ui.publishWeb')}}</span>
                        </a>
                        <a href="#"
                          @click="prePublish('podcast')"
                          class="flex items-center font-bold p-2 px-4 text-sm transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
                          <span>{{$t('ui.publishPodcast')}}</span>
                        </a>
                        <a href="#" @click="unpublish"
                          class="flex items-center font-bold p-2 px-4 text-sm transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
                          <span>{{$t('ui.unpublish')}}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            <Button
              id="save-draft-btn"
              @click.native="saveDraft"
              :class="{'pointer-events-none opacity-50 select-none':false}"
              :loading="loadingDraft">
                {{$t('ui.saveDraft')}}
            </Button>
            <!-- <Button type="default"
              @click.native="discard"
              v-tippy="{content: $t('ui.discardUnpublished')}"
              :loading="loadingDiscard">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4  mr-2 relative text-red-600" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
                </svg>
                <span>{{$t('ui.restore')}}</span>
            </Button> -->
            <router-link tag="a"
              :class="{'pointer-events-none opacity-50' : !isContentChanged}"
              :to="`/articles/${selectedWebsite.id}`" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out text-black border-black block px-6 py-3 text-sm border-2 hover:bg-black hover:text-white">{{$t('ui.discard')}}</router-link>
            <div id="publish-cost" class="sm:inline-flex items-start md:space-y-0 space-y-4 text-sm mb-0 cursor-pointer"
              @click="showGuideModal = true"
              v-tippy="{content: $t('ui.publishCostGuideTooltip')}">
              {{$t('ui.estimatedPublishCost')}}
                <strong class="ml-2 mr-1"
                  v-show="!forcePublishing && selectedArticleData.status !== 'publishing'"
                > {{cost | intFormat}} </strong>
                <strong class="ml-2 mr-1"
                  v-show="forcePublishing || selectedArticleData.status === 'publishing'"
                > 0</strong>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
              </svg>
            </div>
          </div>
      </div>
        <button
          @click="test"
          v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612' || userId === '181646fb-01c8-41b2-8bf2-da10989f56e6'"
          type="button" class="inline-flex justify-center btn-default">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-1 mr-2 text-red-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
          </svg>
          <span>{{$t('ui.test')}} WS</span>
        </button>
        <button
          @click="test2"
          v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612' || userId === '181646fb-01c8-41b2-8bf2-da10989f56e6'"
          type="button" class="inline-flex justify-center btn-default">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-1 mr-2 text-red-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
          </svg>
          <span>{{$t('ui.test')}} WS Send Msg</span>
        </button>
        <button
          @click="test2"
          v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612' || userId === '181646fb-01c8-41b2-8bf2-da10989f56e6'"
          type="button" class="inline-flex justify-center btn-default">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 -ml-1 mr-2 text-red-600" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z" clip-rule="evenodd" />
          </svg>
          <span>{{$t('ui.test2')}}</span>
        </button>
      </div>
    </div>
    <Modal :show.sync="showGuideModal">
      <div slot="header"></div>
      <Guide></Guide>
      <div class="text-center mt-12 mb-5">
        <Button @click.native="showGuideModal = false">{{$t('ui.ok')}}</Button>
      </div>
    </Modal>
    <Modal :show.sync="showPublishModal">
      <div slot="header"></div>
      <svg xmlns="http://www.w3.org/2000/svg" class="m-auto" width="122.788" height="94.367" viewBox="0 0 122.788 94.367">
        <g id="Group_5643" data-name="Group 5643" transform="translate(8042 2287)">
          <circle id="Ellipse_91458" data-name="Ellipse 91458" cx="5" cy="5" r="5" transform="translate(-7986 -2260)" fill="#fab327"/>
          <circle id="Ellipse_91459" data-name="Ellipse 91459" cx="6" cy="6" r="6" transform="translate(-7968 -2260)" fill="#fab327"/>
          <circle id="Ellipse_91460" data-name="Ellipse 91460" cx="6" cy="6" r="6" transform="translate(-7948 -2260)" fill="#fab327"/>
          <g id="Group_5628" data-name="Group 5628" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44153" data-name="Path 44153" d="M404.146,344.847h-.054a1.06,1.06,0,0,1-1.005-1.111q.031-.633.031-1.267c0-1.869-.189-3.795-.371-5.658-.187-1.911-.381-3.886-.381-5.852,0-12.4,8.8-20.413,22.423-20.413q.636,0,1.272.023a1.059,1.059,0,0,1,1.021,1.1,1.073,1.073,0,0,1-1.1,1.021c-.4-.015-.8-.021-1.2-.021-12.524,0-20.3,7.009-20.3,18.294,0,1.863.188,3.786.371,5.646.187,1.913.381,3.892.381,5.864,0,.457-.01.915-.033,1.373A1.059,1.059,0,0,1,404.146,344.847Z" fill="#1f2532"/>
          </g>
          <g id="Group_5629" data-name="Group 5629" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44154" data-name="Path 44154" d="M475.407,386.511a1.051,1.051,0,0,1-.271-.035c-7.541-1.994-18.383-6.166-27.44-14.742-2.077.049-4.293.075-6.6.075-34.206,0-37.258-6.225-37.258-24.012a1.059,1.059,0,0,1,2.118,0c0,16.268,1.7,21.893,35.14,21.893,2.447,0,4.8-.028,6.981-.085a1.041,1.041,0,0,1,.762.3,60.212,60.212,0,0,0,23.633,13.6,50.033,50.033,0,0,1-7.171-14.026,1.058,1.058,0,0,1,.829-1.373c20.578-3.489,20.578-12.546,20.578-28.99,0-20.35-2.6-27.471-31.784-27.471-3.593,0-7.256.131-10.8.259-3.522.127-7.164.259-10.715.259a1.059,1.059,0,1,1,0-2.118c3.513,0,7.135-.131,10.639-.257,3.561-.129,7.243-.262,10.874-.262,30.836,0,33.9,8.564,33.9,29.59,0,16.128,0,26.885-21.119,30.859a46.149,46.149,0,0,0,8.49,14.783,1.059,1.059,0,0,1-.795,1.758Z" fill="#1f2532"/>
          </g>
          <g id="Group_5630" data-name="Group 5630" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44155" data-name="Path 44155" d="M424.19,347.3a1.052,1.052,0,0,1-.472-.112c-1.645-.822-3.691-1.846-3.691-4.944,0-5.264,4.285-7.718,7.663-6.807a1.059,1.059,0,1,1-.551,2.045,3.349,3.349,0,0,0-.868-.115c-1.6,0-4.126,1.324-4.126,4.877,0,1.714.818,2.2,2.52,3.049a1.059,1.059,0,0,1-.475,2.007Z" fill="#1f2532"/>
          </g>
          <g id="Group_5631" data-name="Group 5631" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44156" data-name="Path 44156" d="M427.747,348.633a1.059,1.059,0,0,1-.043-2.117,4.278,4.278,0,0,0,4-3.979,3.265,3.265,0,0,0-.883-2.073,6.007,6.007,0,0,1-.963-1.669,1.059,1.059,0,1,1,2.01-.67,4.238,4.238,0,0,0,.656,1.081,5.249,5.249,0,0,1,1.3,3.331A6.422,6.422,0,0,1,427.747,348.633Z" fill="#1f2532"/>
          </g>
          <g id="Group_5632" data-name="Group 5632" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44157" data-name="Path 44157" d="M441.42,340.63a1.059,1.059,0,0,1-1.041-1.259c.453-2.348,2.493-3.75,5.459-3.75a9.521,9.521,0,0,1,2.162.252,1.059,1.059,0,1,1-.489,2.061,7.342,7.342,0,0,0-1.673-.195c-1.116,0-3.038.264-3.379,2.032A1.059,1.059,0,0,1,441.42,340.63Z" fill="#1f2532"/>
          </g>
          <g id="Group_5633" data-name="Group 5633" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44158" data-name="Path 44158" d="M444.977,349.634c-3.145,0-5.8-2.366-5.8-5.167a4.893,4.893,0,0,1,.374-1.849,1.059,1.059,0,1,1,1.962.8,2.787,2.787,0,0,0-.218,1.05c0,1.8,1.941,3.049,3.684,3.049a1.059,1.059,0,1,1,0,2.118Z" fill="#1f2532"/>
          </g>
          <g id="Group_5634" data-name="Group 5634" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44159" data-name="Path 44159" d="M448.979,349.412a1.059,1.059,0,0,1-.5-1.993,5.555,5.555,0,0,0,2.807-4.72,3.411,3.411,0,0,0-1.368-2.934c-.126-.117-.247-.226-.354-.334a1.059,1.059,0,1,1,1.5-1.5c.086.087.183.174.285.267a5.5,5.5,0,0,1,2.058,4.5,7.652,7.652,0,0,1-3.925,6.587A1.056,1.056,0,0,1,448.979,349.412Z" fill="#1f2532"/>
          </g>
          <g id="Group_5635" data-name="Group 5635" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44160" data-name="Path 44160" d="M461.5,345.465a1.059,1.059,0,0,1-.949-.586,4.146,4.146,0,0,1-.392-1.944c0-3.176,1.975-6.461,5.28-6.461,1.11,0,1.761.268,2.05.844a1.059,1.059,0,0,1-1.6,1.308,2.906,2.906,0,0,0-.449-.034c-2.181,0-3.162,2.54-3.162,4.343a2.046,2.046,0,0,0,.169,1,1.058,1.058,0,0,1-.946,1.532Zm4.1-7.2v0Z" fill="#1f2532"/>
          </g>
          <g id="Group_5636" data-name="Group 5636" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44161" data-name="Path 44161" d="M465.764,349.447a6.533,6.533,0,0,1-3.844-1.236,1.058,1.058,0,1,1,1.237-1.718,4.435,4.435,0,0,0,2.607.836,5.1,5.1,0,0,0,4.883-5.029,3.523,3.523,0,0,0-1.2-2.059c-.132-.146-.259-.287-.378-.425a1.059,1.059,0,0,1,1.609-1.378c.106.124.221.251.339.381a5.282,5.282,0,0,1,1.747,3.481A7.179,7.179,0,0,1,465.764,349.447Z" fill="#1f2532"/>
          </g>
          <g id="Group_5637" data-name="Group 5637" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44162" data-name="Path 44162" d="M383.293,403.888a1.06,1.06,0,0,1-.812-1.74,37.675,37.675,0,0,0,5.679-9.292h-.144c-10.977,0-15.21-5.5-16.55-21.711-3.412-.923-5.423-3.379-5.423-6.695a7.951,7.951,0,0,1,4.911-7.149q0-.942-.005-1.916c0-9.82,0-28.1,28.148-28.1,1.386,0,2.861.044,4.51.133a1.06,1.06,0,0,1-.115,2.116c-1.611-.089-3.048-.131-4.4-.131-26.03,0-26.03,15.636-26.03,25.983q0,1.333.01,2.606a1.058,1.058,0,0,1-.684,1c-2.046.774-4.231,2.615-4.231,5.46,0,3.558,3.145,4.541,4.5,4.8a1.058,1.058,0,0,1,.858.96c1.317,17.458,5.725,20.53,14.5,20.53q.84,0,1.718-.006a1.125,1.125,0,0,1,.879.458,1.061,1.061,0,0,1,.115.984,41.567,41.567,0,0,1-4.164,8.052,45.012,45.012,0,0,0,12.727-9.467,1.054,1.054,0,0,1,.713-.333c18.288-1.023,40.088-5.233,40.088-19.476v-.193a1.059,1.059,0,0,1,1.051-1.067,1.036,1.036,0,0,1,1.067,1.052v.208c0,12.7-14.02,19.955-41.667,21.566a46.976,46.976,0,0,1-16.867,11.3A1.069,1.069,0,0,1,383.293,403.888Z" fill="#1f2532"/>
          </g>
          <g id="Group_5638" data-name="Group 5638" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44163" data-name="Path 44163" d="M372.85,355.839a1.06,1.06,0,0,1-.892-1.63c.987-1.544,2.05-3.074,3.079-4.553,2.5-3.6,5.091-7.325,6.653-11.407a1.06,1.06,0,0,1,1.6-.488,26.7,26.7,0,0,0,11.3,4.713,30.507,30.507,0,0,1-1.316-6.127,1.058,1.058,0,0,1,1.23-1.172,51.994,51.994,0,0,0,8.767.828l.549,0a1.087,1.087,0,0,1,1.076,1.043,1.059,1.059,0,0,1-1.042,1.075l-.583.005a51.48,51.48,0,0,1-7.7-.63,23.569,23.569,0,0,0,1.6,5.8,1.06,1.06,0,0,1-1.044,1.48,27.63,27.63,0,0,1-12.96-4.54,62.942,62.942,0,0,1-6.391,10.632c-1.017,1.462-2.068,2.975-3.033,4.485A1.059,1.059,0,0,1,372.85,355.839Z" fill="#1f2532"/>
          </g>
          <g id="Group_5639" data-name="Group 5639" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44164" data-name="Path 44164" d="M391.782,367.551a3.486,3.486,0,0,1-2.847-1.832,3.062,3.062,0,0,1-.419-.85,4.452,4.452,0,0,1-.3-1.566,3.883,3.883,0,0,1,.2-1.225,12.787,12.787,0,0,0-3.989,4,1.059,1.059,0,0,1-1.745-1.2c1.389-2.02,4.2-5.41,7.4-5.41a4.574,4.574,0,0,1,3.252,1.363,3.146,3.146,0,0,1,.668.66,3.272,3.272,0,0,1,.682,2.191C394.677,366.222,393.221,367.551,391.782,367.551Zm-1.1-3.027a.74.74,0,0,0,.7.081,1.052,1.052,0,0,1,.447-.651,1.52,1.52,0,0,0,.3-.256,2.218,2.218,0,0,0,.05-.465,1.333,1.333,0,0,0-.1-.508,2.609,2.609,0,0,0-.428-.026,1.057,1.057,0,0,0-1.185,1.084,1.751,1.751,0,0,0,.04.366A3.146,3.146,0,0,0,390.687,364.524Z" fill="#1f2532"/>
          </g>
          <g id="Group_5640" data-name="Group 5640" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44165" data-name="Path 44165" d="M383.039,358.895a1.059,1.059,0,0,1-1.059-1.059v-.169a1.06,1.06,0,0,1,.994-1.058c2.653-1.739,5.333-3.228,8.555-3.228a1.059,1.059,0,1,1,0,2.118c-2.845,0-5.262,1.458-7.9,3.218A1.062,1.062,0,0,1,383.039,358.895Z" fill="#1f2532"/>
          </g>
          <g id="Group_5641" data-name="Group 5641" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44166" data-name="Path 44166" d="M408.56,379.008a17.2,17.2,0,0,1-10.173-2.988,1.059,1.059,0,1,1,1.226-1.728,15.127,15.127,0,0,0,8.947,2.6,27.864,27.864,0,0,0,8.292-1.386,1.059,1.059,0,1,1,.634,2.02A29.914,29.914,0,0,1,408.56,379.008Z" fill="#1f2532"/>
          </g>
          <g id="Group_5642" data-name="Group 5642" transform="translate(-8408.043 -2596.521)">
            <path id="Path_44167" data-name="Path 44167" d="M408.849,382.837h-2.378a1.059,1.059,0,1,1,0-2.118h2.378a1.059,1.059,0,0,1,0,2.118Z" fill="#1f2532"/>
          </g>
        </g>
      </svg>
      <div class="text-xl text-center mt-4" v-html="$t('ui.generateArticle', {cost})"></div>
      <div class="form-check text-center">
        <div class="flex justify-center mt-3 text-sm">
          <input
            @change="handleDisablePublishModal"
            v-model="disablePublishModal"
            class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-black checked:border-black focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" type="checkbox" value="" id="flexCheckDefault">
          <label class="form-check-label inline-block text-gray-800" for="flexCheckDefault">
            {{$t('ui.dontShowPublishModal')}}
          </label>
        </div>
      </div>
      <div class="dont-show"></div>
      <div class="text-center mt-8 -mb-3 space-x-3">
        <Button @click.native="showPublishModal = false">{{$t('ui.cancel')}}</Button>
        <Button @click.native="publish(); showPublishModal = false" type="default">{{$t('ui.publish')}}</Button>
      </div>
    </Modal>
    <div v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612' || userId === '181646fb-01c8-41b2-8bf2-da10989f56e6'"
      class="max-w-4xl mx-auto mb-16">
      <div class="shadow-xl p-6 border border-gray-100 rounded-lg relative">
        <div>
          <div class="titl font-bold text-lg">Generate Paragraph</div>
          <div class="desc text-sm text-gray-500 mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
          <div class="close absolute top-3 right-3 text-gray-200 hover:text-black cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.718" height="19.725" viewBox="0 0 19.718 19.725">
              <g id="Group_5665" data-name="Group 5665" transform="translate(-1676.815 -263.04)" >
                <path id="Path_266" data-name="Path 266" d="M13.359,3.5a16.772,16.772,0,0,1,4.484.5,6.931,6.931,0,0,1,3.134,1.737,6.931,6.931,0,0,1,1.737,3.134,16.772,16.772,0,0,1,.5,4.484,16.784,16.784,0,0,1-.5,4.485,6.941,6.941,0,0,1-1.736,3.136,6.932,6.932,0,0,1-3.134,1.739,16.739,16.739,0,0,1-4.485.505,16.739,16.739,0,0,1-4.485-.505A6.932,6.932,0,0,1,5.74,20.981,6.941,6.941,0,0,1,4,17.844a16.784,16.784,0,0,1-.5-4.485A16.774,16.774,0,0,1,4,8.875,6.931,6.931,0,0,1,5.74,5.741,6.931,6.931,0,0,1,8.875,4,16.773,16.773,0,0,1,13.359,3.5Z" transform="translate(1673.315 259.54)" fill="currentColor"/>
                <g id="Group_5388" data-name="Group 5388" transform="translate(1686.749 265.892) rotate(45)">
                  <path id="Path_43306" data-name="Path 43306" d="M9.084,4.174H6.138a.491.491,0,0,1-.491-.491V.737a.737.737,0,1,0-1.473,0V3.683a.491.491,0,0,1-.491.491H.737a.737.737,0,1,0,0,1.473H3.683a.491.491,0,0,1,.491.491V9.084a.737.737,0,1,0,1.473,0V6.138a.491.491,0,0,1,.491-.491H9.084a.737.737,0,1,0,0-1.473Z" transform="translate(0 0)" fill="#fff"/>
                </g>
              </g>
            </svg>
          </div>
          <div class="help absolute bottom-6 left-6 text-gray-400 hover:text-blue-500 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="19.201" height="19.201" viewBox="0 0 19.201 19.201">
              <path id="Path_43779" data-name="Path 43779" d="M9.851.25A42.794,42.794,0,0,0,5.1.57,5.165,5.165,0,0,0,.553,5.126a44.286,44.286,0,0,0-.3,4.725,44.285,44.285,0,0,0,.3,4.724A5.165,5.165,0,0,0,5.1,19.132a42.792,42.792,0,0,0,4.754.32,42.791,42.791,0,0,0,4.754-.32,5.165,5.165,0,0,0,4.544-4.557,44.284,44.284,0,0,0,.3-4.724,44.284,44.284,0,0,0-.3-4.725A5.165,5.165,0,0,0,14.6.57,42.792,42.792,0,0,0,9.851.25Zm.282,5.807A1.464,1.464,0,0,0,8.411,7.469a.889.889,0,0,1-1.778,0,3.218,3.218,0,0,1,6.375-.628,2.665,2.665,0,0,1-.22,1.763,3.312,3.312,0,0,1-1.148,1.229,5.3,5.3,0,0,0-.81.608.452.452,0,0,0-.086.11h0a.042.042,0,0,0,0,.022.889.889,0,1,1-1.778,0A1.925,1.925,0,0,1,9.548,9.21a6.762,6.762,0,0,1,1.1-.855A1.563,1.563,0,0,0,11.2,7.8a.893.893,0,0,0,.061-.61A1.485,1.485,0,0,0,10.133,6.057Zm.607,8.1a.889.889,0,1,0-1.778,0v.4a.889.889,0,0,0,1.778,0Z" transform="translate(-0.25 -0.25)" fill="currentColor" fill-rule="evenodd"/>
            </svg>

          </div>

          <div>
            <div class=" mb-1 label text-sm font-bold">Subject</div>
            <div class="">
              <input type="text" class="border border-gray-200 rounded-lg py-2 px-3 w-full" />
            </div>
          </div>
          <div class="mt-3 mb-5">
            <div class="label mb-1 text-sm font-bold">Length</div>
            <div class="flex w-full">
              <div class=" grid grid-cols-1 gap-y-6 sm:grid-cols-4 sm:gap-x-4 w-full">
                <!--
                  Checked: "border-transparent", Not Checked: "border-gray-300"
                  Active: "border-blue-500 ring-2 ring-blue-500"
                -->
                <!-- Short -->
                <label class="relative flex cursor-pointer rounded-lg border bg-white hover:ring-2 hover:ring-blue-500 p-3 w-full py-2 focus:outline-none">
                  <input type="radio" name="project-type" value="short" class="sr-only" aria-labelledby="project-type-0-label" aria-describedby="project-type-0-description-0 project-type-0-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span id="project-type-0-label" class="block text-sm font-bold text-gray-900">Short</span>
                      <span id="project-type-0-description-1" class="mt-1 text-[11px] font-normal text-gray-800">up to 256 chars</span>
                    </span>
                  </span>
                  <!--
                    Not Checked: "invisible"

                    Heroicon name: mini/check-circle
                  -->
                  <svg class="h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                  </svg>
                  <!--
                    Active: "border", Not Active: "border-2"
                    Checked: "border-blue-500", Not Checked: "border-transparent"
                  -->
                  <span class="pointer-events-none absolute -inset-px rounded-lg border-1" aria-hidden="true"></span>
                </label>
                <!-- Medium -->
                <label class="relative flex cursor-pointer rounded-lg border bg-white hover:ring-2 hover:ring-blue-500 p-3 w-full py-2 focus:outline-none">
                  <input type="radio" name="project-type" value="medium" class="sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-0-description-0 project-type-0-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span id="project-type-0-label" class="block text-sm font-bold text-gray-900">Medium</span>
                      <span id="project-type-0-description-1" class="mt-1 text-sm font-normal text-gray-800">up to 1000 chars</span>
                    </span>
                  </span>
                  <!--
                    Not Checked: "invisible"

                    Heroicon name: mini/check-circle
                  -->
                  <svg class="h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                  </svg>
                  <!--
                    Active: "border", Not Active: "border-2"
                    Checked: "border-blue-500", Not Checked: "border-transparent"
                  -->
                  <span class="pointer-events-none absolute -inset-px rounded-lg border-1" aria-hidden="true"></span>
                </label>
                <!-- Long -->
                <label class="relative flex cursor-pointer rounded-lg border bg-white hover:ring-2 hover:ring-blue-500 p-3 w-full py-2 focus:outline-none">
                  <input type="radio" name="project-type" value="medium" class="sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-0-description-0 project-type-0-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span id="project-type-0-label" class="block text-sm font-bold text-gray-900">Long</span>
                      <span id="project-type-0-description-1" class="mt-1 text-sm font-normal text-gray-800">up to 2000 chars</span>
                    </span>
                  </span>
                  <!--
                    Not Checked: "invisible"

                    Heroicon name: mini/check-circle
                  -->
                  <svg class="h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                  </svg>
                  <!--
                    Active: "border", Not Active: "border-2"
                    Checked: "border-blue-500", Not Checked: "border-transparent"
                  -->
                  <span class="pointer-events-none absolute -inset-px rounded-lg border-1" aria-hidden="true"></span>
                </label>

                <!-- Very Long -->
                <label class="relative flex cursor-pointer rounded-lg border bg-white hover:ring-2 hover:ring-blue-500 p-3 w-full py-2 focus:outline-none">
                  <input type="radio" name="project-type" value="medium" class="sr-only" aria-labelledby="project-type-1-label" aria-describedby="project-type-0-description-0 project-type-0-description-1">
                  <span class="flex flex-1">
                    <span class="flex flex-col">
                      <span id="project-type-0-label" class="block text-sm font-bold text-gray-900">Very Long</span>
                      <span id="project-type-0-description-1" class="mt-1 text-sm font-normal text-gray-800">up to 4000 chars</span>
                    </span>
                  </span>
                  <!--
                    Not Checked: "invisible"

                    Heroicon name: mini/check-circle
                  -->
                  <svg class="h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                  </svg>
                  <!--
                    Active: "border", Not Active: "border-2"
                    Checked: "border-blue-500", Not Checked: "border-transparent"
                  -->
                  <span class="pointer-events-none absolute -inset-px rounded-lg border-1" aria-hidden="true"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="footer flex justify-end space-x-3 mt-10">
            <div id="generated-cancel-btn" class=" bg-gray-100 font-bold p-2 px-4 rounded-md w-32 text-center hover:bg-yellow-500 cursor-pointer transition-colors">Cancel</div>
            <div id="generated-cancel-btn" class=" bg-black text-white rounded-md p-2 w-32 text-center hover:bg-yellow-500 cursor-pointer transition-colors">Generate</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import Vue from 'vue';
import moment from 'moment';
import _ from 'underscore';
import { mapState, mapMutations, mapActions } from 'vuex';
import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';
import confetti from 'canvas-confetti';
// import { ApiGatewayManagementApi, ApiGatewayManagementApiClient, DeleteConnectionCommand } from "@aws-sdk/client-apigatewaymanagementapi";
// impor
import Guide from '../components/PublishCostGuide.vue';

export default {
  components: {
    Guide,
  },
  data() {
    return {
      ws: null, // websocket for AI generated text
      loading: true,
      tooltipIndex: 0,
      maxTooltips: 2,
      showHelp: false,
      neverShowTooltips: false,
      moment,
      showDownloadDropdown: false,
      showDropdown: false,
      showEmbedModal: false,
      loadingDraft: false,
      publishing: false,
      saveError: null,
      contentChanged: true,
      showGuideModal: false,
      publishChannelType: 'all',

      // paragraph preview
      loadingRegenerate: false,
      loadingDiscard: false,
      playing: 0,
      paragraphUrls: [],
      cost: 0,
      charCount: 0,
      audioPreviewUrl: null,
      audioTitlePreviewUrl: null,
      playButton: null,
      tour: null,
      item: {
        id: 1,
        title: '',
        author: '',
        date: new Date(),
        updatedAt: new Date(),
        status: '',
        audioUrl: '',
        content: '',
        episodesByFeedItemId: {},
      },
      itemOriginal: {},
      content: [], // current content
      initData: [],
      statusWithAudio: [
        'PENDING_APPROVAL',
        'APPROVED',
        'SCHEDULED_FOR_PUBLISHING',
        'PUBLISHING',
        'PUBLISHED',
      ],
      audioFilename: false,
      audioPodcastFilename: false,
      intervalRefresh: null,
      checkInterval: null,
      playingInterval: null,
      currentPreviewTime: 0,
      isTitlePlaying: false,
      forcePublishing: false,
      activePlayer: 'web',
      isPlayerInitialized: false,

      showPublishModal: false,
      disablePublishModal: false,
      currentIndex: 0,
      blocksToPlay: [],
    };
  },
  computed: {
    ...mapState({
      selectedArticle: (state) => state.website.selectedWebsiteArticle,
      isInUseAsMode: (state) => state.ui.isInUseAsMode,
      aiGenerationCost: (state) => state.utils.aiGenerationCost,
      imageGenerationCost: (state) => state.utils.imageGenerationCost,
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customer,
      isSuperAdmin: (state) => state.auth.isSuperAdmin,
      selectedArticleData: (state) => state.website.selectedArticleData,
      playerChannel: (state) => state.website.selectedWebsiteAP,
      selectedWebsite: (state) => state.website.selectedWebsite,
      credits: (state) => state.customer.combinedCredits,
      selectedWebsiteAPVoiceSettings: (state) => state.website.selectedWebsiteAPVoiceSettings,
      selectedWebsiteAPTemplateContent: (state) => state.website.selectedWebsiteAPTemplateContent,
    }),
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    feedLang() {
      if (!this.selectedWebsite && !this.selectedWebsite.language) return 'en-US';
      return this.selectedWebsite.language;
    },
    isContentChanged() {
      if (!this.itemOriginal || !this.itemOriginal.extractedText) return false;
      const content = this.content.map((el) => { const newEl = { ...el }; delete newEl.id; delete newEl.type; delete newEl.language; return newEl; });
      const contentOriginal = this.itemOriginal.extractedText.map((el) => { const newEl = { ...el }; delete newEl.id; delete newEl.type; delete newEl.language; return newEl; });

      console.log('contentChanged \n\r', content, contentOriginal, _.isEqual(content, contentOriginal));
      const isEqual = content
        .filter((el, i) => {
          if (!el.body) return true;
          if (!contentOriginal[i] || !contentOriginal[i].body) return true;
          // console.group();
          // // console.log(el, contentOriginal[i]);
          // // console.log(el.body);
          // // console.log(contentOriginal[i].body);
          // // console.log(el.body.trim() === contentOriginal[i].body.trim());
          // console.groupEnd();
          return el.body.trim() === contentOriginal[i].body.trim();
        }).length === contentOriginal.length;

      // // console.log('content dif', isEqual, content, contentOriginal);
      return !(isEqual && _.isEqual(this.item.title, this.itemOriginal.title));
      // return JSON.stringify(this.content) !== JSON.stringify(this.itemOriginal.extractedText) || this.item.title !== this.itemOriginal.title;
    },

    playerSettings() {
      return this.playerChannel ? this.playerChannel.settings.player : {};
    },

    articleId() {
      return this.$route.params.id.replaceAll('|', '/').replace(']', '?');
    },
    episodes() {
      // console.log('%c get episodes', 'color: red');
      return (this.selectedArticle.episodesByFeedItemId && this.selectedArticle.episodesByFeedItemId.nodes) || [];
    },
    podcastEpisode() {
      return this.episodes
        .find((el) => el.channelByChannelId.type === 'PODCAST') || {};
    },
    podcastChannel() {
      return this.podcastEpisode ? this.podcastEpisode.channelByChannelId : {};
    },
    isPodcastPublished() {
      return this.podcastEpisode && this.podcastEpisode.status === 'PUBLISHED';
    },
    isWebPublished() {
      return this.webEpisode && this.webEpisode.status === 'PUBLISHED';
    },
    webEpisode() {
      return this.episodes
        .find((el) => el.channelByChannelId.type === 'WEB_PLAYER') || {};
    },
    webChannel() {
      return this.webEpisode ? this.webEpisode.channelByChannelId : {};
    },
    fileNumber() {
      return this.webEpisode.version;
    },
    episodeStatus() {
      if (this.webEpisode) return this.webEpisode.status;
      return '----';
    },
    podcastEpisodeStatus() {
      if (this.podcastEpisode) return this.podcastEpisode.status;
      return '----';
    },
    isGenerating() {
      return this.episodeStatus === 'GENERATING' && this.selectedArticleData.status !== 'published';
    },
    hasAudio() {
      if (!this.webEpisode && !this.podcastEpisode) return false;
      return (this.selectedArticleData.status === 'published' && this.audioFilename) || (this.isPodcastPublished && this.audioPodcastFilename);
    },
    hasPodcastAudio() {
      if (!this.podcastEpisode) return false;
      return this.selectedArticleData.status === 'published' && this.audioPodcastFilename;
    },
    paragraphAudioUrl() {
      return (filename) => `${this.$appConfig.audioPartsUrl}${filename}`;
    },
    audioTrack() {
      if (!this.webEpisode || !this.webEpisode.id) return {};
      return {
        src: `${this.$appConfig.audioUrl}${this.webEpisode.id}-${this.fileNumber}.mp3`,
        name: this.webEpisode.title,
        autoplay: true,
        id: this.webEpisode.id,
      };
    },
    audioPodcastTrack() {
      if (!this.podcastEpisode || !this.podcastEpisode.id) return {};
      return {
        src: `${this.$appConfig.audioUrl}${this.podcastEpisode.id}-${this.fileNumber}.mp3`,
        name: this.podcastEpisode.title,
        autoplay: true,
        id: this.podcastEpisode.id,
      };
    },
    playerStyle() {
      return {
        backgroundColor: this.playerSettings.backgroundColor,
        accentColor: this.playerSettings.accentColor,
        textColor: this.playerSettings.textColor,
        type: 'standard',
        style: this.playerSettings.style,
        borderRadius: this.playerSettings.borderRadius,
        audioTitle: this.stripTag(this.selectedArticle.title),
      };
    },
    alternativeVoice() {
      return {
        ttsPlatform: this.selectedWebsiteAPVoiceSettings.alternateTtsPlatform || this.selectedWebsiteAPVoiceSettings.ttsPlatform,
        ttsVoiceName: this.selectedWebsiteAPVoiceSettings.alternateTtsVoiceName || this.selectedWebsiteAPVoiceSettings.ttsVoiceName,
      };
    },
    // eslint-disable-next-line
    stripTag() {
      return (str) => {
        const cleanStr = str ? str.replace(/(<([^>]+)>)/gi, '') : '';
        return cleanStr;
      };
    },
    episodeId() {
      if (this.webEpisode && this.webEpisode.id) return this.webEpisode.id;
      return 'no-id';
    },
    feedId() {
      return this.$route.params.websiteId;
    },
    style() {
      return `
        <style>
        </style>
      `;
    },
    code() {
      if (!this.webEpisode || !this.webEpisode.id) return '';
      // eslint-disable-next-line
      return `<div id="player"></div>
<script src="${this.$appConfig.playerUrl}"><\/script>
<script>
  (function() {
     let player = new hearme.Player({
      selector: '#player',
      type: '${this.playerSettings.style || 'standard'}',
      channelId: '${this.webChannel.id}',
      style: {
        accentColor: '${this.playerSettings.accentColor}',
        backgroundColor: '${this.playerSettings.backgroundColor}',
        textColor: '${this.playerSettings.textColor}',
        borderRadius: '${this.playerSettings.borderRadius}px',
      },
      
      audio: '${this.audioTrack.src}',
      audioTitle: '${this.stripTag(this.selectedArticle.title).replaceAll("'", '&#39;')}',
    });
  })();
<\/script>`;
    },
    //     code() {
    //       // eslint-disable-next-line
    //       return `<div id="player"></div>
    // <script src="${this.$appConfig.playerUrl}"><\/script>
    // <script>
    //   (function() {
    //     let player = new hearme.Player({
    //       selector: '#player',
    //       style: {
    //         accentColor: '${this.playerSettings.accentColor}',
    //         backgroundColor: '${this.playerSettings.backgroundColor}',
    //         textColor: '${this.playerSettings.textColor}',
    //         menuRadius: 3,
    //       },
    //       audio: '${this.audioTrack.src}',
    //       audioTitle: '${this.selectedArticle.title}',
    //     });
    //   })();
    // <\/script>`;
    //     },
    shareLink() {
      return `https://play.hearme.ai?e=${this.episodeId}`;
    },
  },
  watch: {
    // eslint-disable-next-line
    selectedWebsite: function () {
      console.log('article edit selected article data ', this.selectedWebsite);
      this.setLanguage(this.selectedWebsite);
    },
    // eslint-disable-next-line
    webEpisode: async function () {
      this.checkAudio();
    },
    // eslint-disable-next-line
    podcastEpisode: async function () {
      this.checkAudio();
    },
    // eslint-disable-next-line
    'selectedArticleData.status': async function (newStatus, oldStatus) {
      // console.log('%cselectedArticleData.status', 'color: lime; font-size:24px;', oldStatus, newStatus);
      if (oldStatus === 'publishing' && newStatus === 'published') {
        this.forcePublishing = false;
        this.confetti();
      }
      this.checkAudio();
    },
    neverShowTooltips(newVal) {
      localStorage.setItem('neverShowHelp', newVal);
      if (!this.neverShowTooltips) localStorage.removeItem('neverShowHelp');
    },
    // eslint-disable-next-line
    forcePublishing: async function (newValue, oldValue) {
      if (!newValue && oldValue) {
        // this.$router.go();
        const contentBlocks = this.content.map((el) => {
          const data = { ...el };
          if (el.body) data.body = el.body.trim();
          return data;
        });
        const titleSSML = await this.$refs.titleEditor.asyncSave();
        const result = await this.estimateEpisodeCost({
          episodeId: this.webEpisode.id,
          episodeTitle: titleSSML,
          extractedText: contentBlocks,
        });
        console.log('watch forcePublishing', newValue, oldValue, result);
      }
    },
    // eslint-disable-next-line
    // audioTrack: function (newVal) {
    //   // console.log('dupa', newVal, this.$refs);
    // },
  },
  async mounted() {
    // console.log('%cinit REFS', 'color:lime; font-size: 20px', this.$refs);
    const neverShowHelp = localStorage.getItem('neverShowHelp');
    if (neverShowHelp) this.neverShowTooltips = true;
    this.init(false);
    this.intervalRefresh = window.setInterval(this.updateStatus, 8000);
    this.playingInterval = window.setInterval(this.checkPlaying, 50);
    this.setLanguage(this.selectedWebsite);

    // help tooltip
    if (this.neverShowTooltips) return;
    setTimeout(() => {
      // init tooltip help
      const p = document.querySelector('#editorjs .ce-block:nth-child(2) .content-wrapper');
      console.log('ppppp', p);
      p.addEventListener('focus', () => {
        console.log('ppppp focus');
        this.showHelp = true;
      });
      const publishBtn = document.querySelector('#publish-btn');
      publishBtn.addEventListener('mouseover', () => {
        console.log('ppppp focus');
        if (this.neverShowTooltips) return;
        setTimeout(() => {
          this.tooltipIndex = 2;
          this.showHelp = true;
        }, 1000);
      });
    }, 4000);
  },
  beforeDestroy() {
    clearInterval(this.playingInterval);
    clearInterval(this.checkInterval);
    clearInterval(this.intervalRefresh);
  },
  beforeRouteLeave(to, from, next) {
    // console.log('before leave', to, from, next);
    if (this.isContentChanged && to.name !== 'Login') {
      this.$swal.fire({
        title: '',
        text: this.$t('ui.areYouSureQuit'), // "All unsaved changes will be lost. ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then((result) => {
        // console.log('result ', result);
        next(false);
        if (result.isConfirmed) {
          next();
        }
        next(false);
      });
    } else {
      next();
    }
  },
  methods: {
    ...mapActions({
      fetch: 'website/getArticleById',
      createDemoProject: 'website/createDemoProject',
      fetchSimple: 'website/getArticle',
      updateArticle: 'website/updateArticle',
      estimateEpisodeCost: 'website/estimateEpisodeCost',
      doesAudioExist: 'website/doesAudioExist',
      generateParagraph: 'website/generateParagraph',
      generateTitle: 'website/generateTitle',
      save: 'website/saveDraft',
      discardDraft: 'website/discardDraft',
      publishEpisode: 'website/publishEpisode',
      publishEpisodeTo: 'website/publishEpisodeTo',
      getArticleStatus: 'website/getArticleStatus',
      createProjectFeedItem: 'website/createProjectFeedItem',
      unpublishFeedItem: 'website/unpublishFeedItem',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
      setSidebar: 'ui/setSidebar',
    }),
    handleTitlePlaybackEnd() {
      // const audio = this.$refs.playerTitle;
      this.$refs.titleEditor.clearPlay();
    },
    handlePlaybackEnd() {
      this.currentIndex += 1;
      console.log('play next end', this.currentIndex, this.blocksToPlay);
      if (this.blocksToPlay && this.currentIndex === this.blocksToPlay.length) {
        this.clearPlayer();
        return;
      }
      const nextBlock = this.blocksToPlay[this.currentIndex];
      this.playBlock(nextBlock);
    },
    initHelp() {

    },
    setTour() {
      this.setSidebar(false);
      // if (this.title !== 'Demo article') return;
      setTimeout(() => {
        const container = document.getElementById('content-right');
        this.tour = new Shepherd.Tour({
          useModalOverlay: true,
          stepsContainer: container,
          exitOnEsc: true,
          keyboardNavigation: true,
          scrollTo: true,
          defaultStepOptions: {
            classPrefix: 'my-tour-',
            classes: 'shadow-md bg-purple-dark',
            // scrollTo: true,
            when: {
              show() {
                const currentStepElement = this.tour.currentStep.el;
                const footer = currentStepElement.querySelector('.shepherd-footer');
                const progress = document.createElement('span');
                progress.className = 'shepherd-progress text-xs flex items-center';
                progress.innerText = `${this.tour.steps.indexOf(this.tour.currentStep) + 1} of ${this.tour.steps.length}`;
                footer.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:last-child'));
              },
            },
          },
        });

        // Title content
        this.tour.addStep({
          id: 'example-step1',
          title: this.$t('tour.editTour.step1.title'),
          text: this.$t('tour.editTour.step1.description'),
          modalOverlayOpeningPadding: 10,
          arrow: false,
          scrollTo: true,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '.title-editor',
            on: 'bottom',
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: '',
              classes: 'opacity-0',
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });

        // Article content
        this.tour.addStep({
          id: 'example-step2',
          title: this.$t('tour.editTour.step2.title'),
          text: this.$t('tour.editTour.step2.description'),
          modalOverlayOpeningPadding: 20,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '.article-editor',
            on: 'top',
          },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // Alias
        this.tour.addStep({
          id: 'example-ste3',
          title: this.$t('tour.editTour.step3.title'),
          text: this.$t('tour.editTour.step3.description'),
          modalOverlayOpeningPadding: 5,
          scrollTo: true,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '#article-edit .ce-paragraph alias',
            on: 'bottom',
          },
          floatingUIOptions: {
            middlewares: [offset(-20)],
          },
          // when: {
          //   show: () => {
          //     this.selectTitleWord();
          //     window.scrollTo(0, 0);
          //   },
          // },
          classes: 'example-step-extra-class',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // Break
        this.tour.addStep({
          id: 'example-step4',
          title: this.$t('tour.editTour.step4.title'),
          text: this.$t('tour.editTour.step4.description'),
          modalOverlayOpeningPadding: 5,
          arrow: false,
          scrollTo: true,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '#editorjs break',
            on: 'right',
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // Alternative voice
        this.tour.addStep({
          id: 'example-step5',
          title: this.$t('tour.editTour.step5.title'),
          text: this.$t('tour.editTour.step5.description'),
          arrow: false,
          cancelIcon: {
            enabled: true,
          },
          scrollTo: true,
          attachTo: {
            element: '#editorjs .ce-block:nth-child(2)',
            on: 'top',
          },
          when: {
            'before-show': () => {
              this.showTuneToureOption();
            },
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // Save draft
        this.tour.addStep({
          id: 'example-step6',
          title: this.$t('tour.editTour.step6.title'),
          text: this.$t('tour.editTour.step6.description'),
          modalOverlayOpeningPadding: 10,
          arrow: false,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '#save-draft-btn',
            on: 'top',
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // Character count
        this.tour.addStep({
          id: 'example-step7',
          title: this.$t('tour.editTour.step7.title'),
          text: this.$t('tour.editTour.step7.description'),
          modalOverlayOpeningPadding: 5,
          arrow: false,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '#publish-cost',
            on: 'top',
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // publish & save
        this.tour.addStep({
          id: 'example-step8',
          title: this.$t('tour.editTour.step8.title'),
          text: this.$t('tour.editTour.step8.description'),
          modalOverlayOpeningPadding: 5,
          arrow: false,
          cancelIcon: {
            enabled: true,
          },
          attachTo: {
            element: '#publish-btn',
            on: 'top',
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });
        // player
        this.tour.addStep({
          id: 'example-step9',
          title: this.$t('tour.editTour.step9.title'),
          text: this.$t('tour.editTour.step9.description'),
          modalOverlayOpeningPadding: 5,
          arrow: false,
          cancelIcon: {
            enabled: true,
          },
          scrollTo: true,
          attachTo: {
            element: '#player2',
            on: 'top',
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: 'Back',
              action: this.tour.back,
            },
            {
              text: 'Next',
              action: this.tour.next,
            },
          ],
        });

        // New project
        this.tour.addStep({
          id: 'example-step10',
          title: this.$t('tour.editTour.step10.title'),
          text: this.$t('tour.editTour.step10.description'),
          arrow: false,
          cancelIcon: {
            enabled: true,
          },
          when: {
            'before-show': () => {
              console.log('showsidebar', this);
              this.setSidebar(true);
            },
          },
          modalOverlayOpeningPadding: 10,
          attachTo: {
            element: '#create-project-placeholder',
            on: 'right',
          },
          classes: 'example-step-extra-class shepherd-bottom-transform',
          buttons: [
            {
              text: '',
              classes: 'opacity-0',
            },
            {
              text: 'End',
              action: this.tour.next,
            },
          ],
        });
        this.tour.start();
      }, 20);
    },
    showTuneToureOption() {
      const p = document.querySelector('#editorjs .ce-block:nth-child(2)');
      const tune = document.querySelector('#editorjs .ce-toolbar');
      const tuneActions = document.querySelector('#editorjs .ce-toolbar__actions');
      // const tuneActionsSettings = document.querySelector('#editorjs .ce-settings');
      tune.classList.add('ce-toolbar--opened');
      tuneActions.classList.add('ce-toolbar__actions--opened');
      // tuneActionsSettings.classList.add('ce-settings--opened');
      tune.style.top = `${p.offsetTop + 8}px`;
      // console.log('xxxxxxxx', p, tune, tuneActions, this.$refs.editor);
      p.focus();
      // this.$refs.editor.editor.blocks.toggleBlockSettings(true);
    },
    selectTitleWord() {
      const range = new Range();
      const p = document.querySelector('#editorjs .content-wrapper.paragraph');
      range.setStart(p.firstChild, 34);
      range.setEnd(p.firstChild, 43);
      document.getSelection().addRange(range);
    },
    checkPlaying() {
      // const audio = this.$refs.player;
      // const btn = document.querySelector('.play-btn.playing');
      // const isPlaying = this.currentPreviewTime < audio.currentTime;
      // this.currentPreviewTime = audio.currentTime;
      // console.log('playing ....', this.isPlaying, isPlaying, this.currentIndex, btn);
    },
    clearPlayer() {
      console.log('clearPlayer', this.currentIndex);
      const audio = this.$refs.player;
      // const index = this.paragraphUrls.indexOf(this.audioPreviewUrl);
      this.playing += 1;
      if (this.paragraphUrls.length > 1 && this.playing < this.paragraphUrls.length) {
        // // console.log(`
        //   index ${index}
        //   this.playing ${this.playing}
        //   this.audioPreviewUrl ${this.audioPreviewUrl}
        //   this.paragraphUrls ${this.paragraphUrls}
        //   this.paragraphUrls.length ${this.paragraphUrls.length}
        //   his.paragraphUrls.indexOf(this.audioPreviewUrl); ${this.paragraphUrls.indexOf(this.audioPreviewUrl)}
        // `);
        this.audioPreviewUrl = this.paragraphUrls[this.playing];
        audio.load(); // call this to just preload the audio without playing
        audio.play();
        // alert(`sdasdasd ${index}`);
        return;
      }
      this.playing = 0;
      this.audioPreviewUrl = null;
      this.playButton.classList.remove('playing');
      this.$refs.editor.isPlaying = false;
      // console.log('player cleared', this.playButton);
      this.clearButtons();
    },
    stopPlayer() {
      const audio = this.$refs.player;
      audio.pause();
    },
    clearButtons() {
      console.log('clearButtons', this.currentIndex);
      this.$refs.titleEditor.clearPlay();
      this.$refs.editor.clearPlay();
    },
    playUrl(urls) {
      this.playing = 0;
      this.paragraphUrls = urls;
      [this.audioPreviewUrl] = urls;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    playNext() {

    },
    async playTitle(data) {
      if (this.credits === 0) {
        this.showNoCreditsMessage('block_generating_no_credits');
        return;
      }
      const input = {
        episodeId: this.webEpisode.id,
        articleTitle: this.item.title.replaceAll('&nbsp;', ' '),
      };
      console.log('play title', input);
      this.clearButtons();
      this.playButton = data.button;
      this.isTitlePlaying = true;
      const result = await this.generateTitle(input);
      data.button.classList.remove('loading');
      data.button.classList.add('playing');
      // console.log('%cPLAY TITLE', 'color: red', data, result);
      if (!result.ok || result.data.err) {
        this.showNoCreditsMessage(result.data.err);
        return;
      }

      this.playing = 0;
      this.audioTitlePreviewUrl = result.data.url;
      const audio = this.$refs.playerTitle;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    async stopTitle() {
      const audio = this.$refs.playerTitle;

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.pause(); // call this to play the song right away
      this.$refs.titleEditor.clearPlay();
    },
    async handleStopParagraph() {
      const audio = this.$refs.player;
      // console.log('handleStopParagraph', audio, data);
      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.pause(); // call this to play the song right away
      this.clearButtons();
    },
    handlePlayParagraph(payload) {
      this.currentIndex = 0;
      this.blocksToPlay = payload.playlist;
      // console.log('%cPLAY:', 'font-wight: bold; color: lime', payload);
      // const data = payload.first;
      this.playBlock(payload.first);
    },
    async playBlock(data) {
      console.log('data', data);
      console.log('playBlock', data);
      const titleAudioPlayer = this.$refs.playerTitle;
      titleAudioPlayer.pause();
      const currentBtn = document.querySelector('#editorjs .play-btn.playing');
      const currentBtnTitle = document.querySelector('.title-editor .play-btn.playing');
      if (currentBtn) currentBtn.classList.remove('playing');
      if (currentBtnTitle) currentBtnTitle.classList.remove('playing');
      if (!data) return;
      this.playButton = data.button;
      // generate paragraph
      const paragraphInput = {
        episodeId: this.webEpisode.id,
        paragraphText: data.contentSSML,
        isPrevParagraph: data.hasPrevParagraph,
        textType: data.type,
      };
      if (data.isAleternative) {
        paragraphInput.voiceSettings = this.alternativeVoice;
      }
      const paragraphResult = await this.generateParagraph(paragraphInput);
      data.button.classList.remove('loading');
      console.log('xxxx ', paragraphResult.data.err === '{\"blockGenerating\":\"no_credits\"}', paragraphResult.data.ok, paragraphResult.data.err);
      if (!paragraphResult.data.ok && paragraphResult.data.err) {
        this.showNoCreditsMessage(paragraphResult.data.err);
        return;
      }
      if (!paragraphResult.data.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      data.button.classList.add('playing');
      // // console.log('handlePlayParagraph - article update', paragraphResult);
      const { fileUrls } = paragraphResult.data;

      // const url = this.paragraphAudioUrl(audio);
      this.playUrl(fileUrls);
      // // console.log('handlePlayParagraph - generateParagraph', fileUrls);
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async checkAudio() {
      if (this.webEpisode.id) {
        const result = await this.doesAudioExist({ episodeId: this.webEpisode.id });

        if (!result.ok) {
          this.audioFilename = result.filename;
        }
        console.log('checkAudio', result);
        if (this.audioFilename !== result.filename) {
          this.audioFilename = result.filename;
          console.log('update audio', this.audioFilename, this.$refs);
          this.$refs.plyr.initPlayer('edit');
          this.$refs.plyr.player.playTrack({
            title: this.stripTag(this.selectedArticle.title),
            file: `${this.$appConfig.audioUrl}${this.audioFilename}`,
          });
        }
      }

      if (this.podcastEpisode.id) {
        const resultPodcast = await this.doesAudioExist({ episodeId: this.podcastEpisode.id });
        if (resultPodcast.ok) {
          // this.podcastEpisode = resultPodcast.data;
          this.audioPodcastFilename = resultPodcast.filename;
        } else {
          this.audioPodcastFilename = false;
        }
      }

      if (!this.audioFilename && this.audioPodcastFilename && !this.isPlayerInitialized) {
        this.activePlayer = 'podcast';
        this.isPlayerInitialized = true;
        this.$forceUpdate();
        this.$refs.plyr.initPlayer('edit');
        this.$refs.plyr.player.playTrack({
          title: this.stripTag(this.selectedArticle.title),
          file: `${this.$appConfig.audioUrl}${this.audioPodcastFilename}`,
        });
      }
      // this.$refs.plyr.player.source.player.source = {
      //   type: 'audio',
      //   title: this.stripTag(this.selectedArticle.title),
      //   sources: [
      //     {
      //       src: `${this.$appConfig.audioUrl}${this.audioFilename}`,
      //       type: 'audio/mp3',
      //     },
      //   ],
      // };
    },
    playPreview(type) {
      this.activePlayer = type;
      const fileName = type === 'web' ? this.audioFilename : this.audioPodcastFilename;

      console.log('playPreview', fileName);
      // this.$refs.plyr.player.stop();
      this.$refs.plyr.player.playTrack({
        title: this.stripTag(this.selectedArticle.title),
        file: `${this.$appConfig.audioUrl}${fileName}`,
      });
      document.querySelector('.hearme-play-pause').classList.remove('hearme-playing');
    },
    async saveNewItem() {
      this.loadingDraft = true;
      const titleSSML = await this.$refs.titleEditor.asyncSave();
      const dataSSML = await this.$refs.editor.asyncSave();

      const input = {
        feedId: this.selectedWebsite.id,
        title: titleSSML,
        content: dataSSML.map((el) => {
          const data = { ...el };
          if (el.body) data.body = el.body.trim();
          return data;
        }),
        // publishEpisode: false,
      };

      const saveResult = await this.createProjectFeedItem(input);
      this.loadingDraft = false;
      if (!saveResult.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        return;
      }
      // this.goToArticles(saveResult.feedItemId);
      this.goTo(`/articles/${this.selectedWebsite.id}/edit/${saveResult.feedItemId}`);
    },
    goToArticles() {
      this.goTo(`/articles/${this.selectedWebsite.id}/list`);
    },
    async saveDraft() {
      this.saveError = null;
      this.loadingDraft = true;

      const titleSSML = await this.$refs.titleEditor.asyncSave();
      const dataSSML = await this.$refs.editor.asyncSave();

      const input = {
        feedItemId: this.articleId,
        title: titleSSML,
        // generateEpisode: false,
        // isDiscard: false,
        extractedText: dataSSML.map((el) => {
          const data = { ...el };
          if (el.body) data.body = el.body.trim();
          return data;
        }),
        // publishEpisode: false,
      };
      const saveResult = await this.save(input);
      this.loadingDraft = false;
      if (!saveResult.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        return;
      }
      const article = await this.fetchSimple(this.articleId);
      Vue.set(this, 'itemOriginal', JSON.parse(JSON.stringify(article)));
      // this.init(true);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.draftSaved'),
      });
      // console.log('%csaveDraft', 'color:lime; fonts-size: 24px', article, this.initData, this.item);
    },
    playParagraph() {
      // console.log('paragraph', paragraph);
    },
    async unpublish() {
      console.log('unpublish');
      const result = await this.unpublishFeedItem({ feedItemId: this.articleId });

      if (!result.ok) {
        // this.saveError = this.$t('ui.somethingWentWrong');
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      await this.fetchSimple(this.articleId);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.articleUnpublished'),
      });
    },
    async prePublish(channelType) {
      this.publishChannelType = channelType;
      this.publishing = true;

      console.log('publish: prepublish', this.publishChannelType);
      this.disablePublishModal = localStorage.getItem('disablePublishModal') !== null;
      console.log(this.disablePublishModal);

      try {
        document.querySelector('#editorjs .ce-block:last-child .content-wrapper').innerHTML += ' ';
      } catch {
        console.log('no last element');
      }
      const titleSSML = await this.$refs.titleEditor.asyncSave();
      const dataSSML = await this.$refs.editor.asyncSave();

      console.log('publish: prepublish2', channelType);
      const input = {
        feedItemId: this.articleId,
        title: titleSSML,
        // generateEpisode: false,
        // isDiscard: false,
        extractedText: dataSSML.map((el) => {
          const data = { ...el };
          if (el.body) data.body = el.body.trim();
          return data;
        }),
        // publishEpisode: false,
      };
      const saveResult = await this.save(input);
      console.log('saveResult', saveResult);
      try {
        document.querySelector('#editorjs .ce-block:last-child .content-wrapper').innerHTML += ' ';
      } catch {
        console.log('no last element');
      }
      if (!this.disablePublishModal) {
        if (!this.webEpisode.id || !this.$refs.titleEditor.editor) {
          this.publishing = false;
          return;
        }

        const result = await this.estimateEpisodeCost({
          episodeId: this.webEpisode.id,
          episodeTitle: titleSSML,
          extractedText: input.extractedText,
        });
        if (!result.ok) {
          this.publishing = false;
          return;
        }
        this.cost = result.estimatedCost;
        this.showPublishModal = true;
        this.publishing = false;
        return;
      }
      this.publish();
    },
    async publish() {
      // this.publishChannelType is set outside this function in prePublish
      console.log('publish: channelType', this.publishChannelType);
      this.saveError = null;
      if (this.cost > this.credits) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: 'warning',
          title: this.$t('ui.notEnoughCreditsError'),
        });
        this.publishing = false;
        return;
      }
      this.publishing = true;
      // await this.delay(1000); // TODO: fix it
      // console.log('saveBeforePublish', this.item.title);

      // If cintent changed force 'publishing' status
      this.forcePublishing = true;
      setTimeout(() => {
        this.forcePublishing = false;
      }, 20000);

      const titleSSML = await this.$refs.titleEditor.asyncSave();
      const dataSSML = await this.$refs.editor.asyncSave();
      // console.log('%cdata', 'color:lime', titleSSML, dataSSML);

      if (titleSSML === '') {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.titleRequiredError'),
        });
        this.saveError = this.$t('ui.titleRequiredError');
        this.publishing = false;
        this.forcePublishing = false;
        return;
      }

      const input = {
        feedItemId: this.articleId,
        title: titleSSML,
        extractedText: dataSSML.map((el) => {
          const data = { ...el };
          if (el.body) data.body = el.body.trim();
          return data;
        }),
      };
      const saveResult = await this.save(input);
      if (!saveResult.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.publishing = false;
        return;
      }
      let publishResult = { ok: false };
      let publishInput = {};
      switch (this.publishChannelType) {
        case 'podcast':
          console.log('publish: podcast');
          publishInput = {
            episodeId: this.podcastEpisode.id,
            publishEpisode: true,
          };
          publishResult = await this.publishEpisodeTo(publishInput);
          break;
        case 'web':
          console.log('publish: web');
          publishInput = {
            episodeId: this.webEpisode.id,
            publishEpisode: true,
          };
          publishResult = await this.publishEpisodeTo(publishInput);
          break;
        default:
          console.log('publish: default');
          publishInput = {
            feedItemId: this.articleId,
            publishEpisode: true,
            // publishFeedItemVersion: this.selectedArticleData.currentVersion,
          };
          publishResult = await this.publishEpisode(publishInput);
          break;
      }

      this.publishing = false;
      if (!publishResult.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      const article = await this.fetchSimple(this.articleId);
      Vue.set(this, 'itemOriginal', JSON.parse(JSON.stringify(article)));
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.published'),
      });
      setTimeout(() => {
        this.updateStatus();
      }, 3000);
      // this.init(true);
    },
    /**
     * Gets episode cost and assigns returned value to variable cost
     *
     */
    async getEpisodeCost(content) {
      // console.log('get cost');
      if (!this.webEpisode.id || !this.$refs.titleEditor.editor) return;
      // try {
      //   const isSafari = navigator.vendor
      //     && navigator.vendor.indexOf('Apple') > -1
      //     && navigator.userAgent
      //     && navigator.userAgent.indexOf('CriOS') === -1
      //     && navigator.userAgent.indexOf('FxiOS') === -1;
      //   if (!isSafari) document.querySelector('#editorjs .ce-block:last-child .content-wrapper').innerHTML += ' ';
      // } catch {
      //   console.log('no last element');
      // }
      const titleSSML = await this.$refs.titleEditor.asyncSave();
      const result = await this.estimateEpisodeCost({
        episodeId: this.webEpisode.id,
        episodeTitle: titleSSML,
        extractedText: content,
      });
      if (!result.ok) {
        return;
      }
      this.cost = result.estimatedCost;
      this.charCount = result.charCount;
    },
    async test() {
      const resut = await this.createDemoProject();

      console.log('test', resut);
    },
    test2() {
      console.log('test2');
      this.setTour();
    },
    async restore() {
      this.loadingDiscard = true;
      const alertResult = await this.$swal({
        title: this.$t('ui.restoreTitle'),
        text: this.$t('ui.restoreText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('ui.restoreOkText'),
        cancelButtonText: this.$t('ui.cancel'),
        customClass: {
          confirmButton: 'btn',
          cancelButton: 'btn-default',
        },
      });
      this.stopPlayer();
      this.clearPlayer();
      if (!alertResult.isConfirmed) {
        this.loadingDiscard = false;
        return;
      }
      const input = {
        feedItemId: this.articleId,
        // title: this.webEpisode.title,
        // generateEpisode: false,
        // isDiscard: true,
        // extractedText: this.webEpisode.body,
        // publishEpisode: false,
      };

      const result = await this.discardDraft(input);
      this.loadingDiscard = false;
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
      }
      // this.$swal(
      //   'Deleted!',
      //   'Your file has been deleted.',
      //   'success',
      // );
      this.init(true);
      // this.goTo('/articles/list');
    },
    async init() {
      const result = await this.fetch(this.articleId);
      if (!result.id) return;

      Vue.set(this, 'item', JSON.parse(JSON.stringify(result)));
      Vue.set(this, 'itemOriginal', JSON.parse(JSON.stringify(result)));
      Vue.set(this, 'content', JSON.parse(JSON.stringify(result.extractedText)));
      this.$forceUpdate();
      const rawBlocks = result.extractedText ? result.extractedText : [];

      // this.updateStatus();
      this.$forceUpdate();
      // console.log('%cinit article blocks', 'color:lime; font-size: 20px', this.$refs, this.$refs.editor, shouldRefresh, rawBlocks);
      Vue.set(this, 'initData', rawBlocks);

      this.loading = false;
      await this.delay(1000);

      this.$refs.editor.refreshContent(rawBlocks);
      this.$refs.titleEditor.refreshContent(this.item.title);

      await this.delay(1000);
      this.updateStatus();
      this.content = this.itemOriginal.extractedText;
      this.$forceUpdate();

      const showTour = localStorage.getItem('showTour');
      if (showTour) {
        this.setTour();
        localStorage.removeItem('showTour');
      }
    },
    goTo(path) {
      this.$router.push({ path });
    },
    copy(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.codeCopied'),
      });
    },
    async testAlert() {
      const result = await this.$swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      if (result.isConfirmed) {
        // this.$swal(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success',
        // );
      }
    },
    contentChange(content) {
      // // console.log('%c sadad', 'color: lime', content);
      Vue.set(this, 'content', content);
      this.contentChanged = true;
    },

    async updateStatus() {
      // // console.log('%c updateStatus', 'color: lime');
      // const titleSSML = await this.$refs.titleEditor.asyncSave();
      const dataSSML = await this.$refs.editor.asyncSave();
      const contentBlocks = dataSSML.map((el) => {
        const data = { ...el };
        if (el.body) data.body = el.body.trim();
        return data;
      });
      this.getEpisodeCost(contentBlocks);
      await this.getArticleStatus(this.articleId);
      this.$forceUpdate();
      // if (!articleResponse.ok) return;
    },
    closeDownloadDropdown() {
      this.showDownloadDropdown = false;
    },
    handleDisablePublishModal(toogleState) {
      console.log('toggle state', this.disablePublishModal, toogleState);
      if (this.disablePublishModal) localStorage.setItem('disablePublishModal', 'true');
      if (!this.disablePublishModal) localStorage.removeItem('disablePublishModal');
    },
    setLanguage(feed) {
      if (!feed.language) return;
      const articleDiv = this.$refs.articleEdit;
      // console.log('setLanguage', articleDiv, articleDiv.data, feed);
      if (!articleDiv) return;
      articleDiv.dataset.language = feed.language;
    },
    confetti() {
      console.log('confetti');
      this.$refs.publishedMessage.style.display = 'flex';
      const count = 100;
      const defaults = {
        origin: { y: 1, x: 0.2 },
        colors: ['#13b981', '#fea800', '#1a72f5'],
      };

      function fire(particleRatio, opts) {
        // eslint-disable-next-line
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        }));
      }
      function fire2(particleRatio, opts) {
        // eslint-disable-next-line
        confetti(Object.assign({}, {...defaults, origin: { y: 1, x: 0.8 },}, opts, {
          particleCount: Math.floor(count * particleRatio),
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });

      fire2(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire2(0.2, {
        spread: 60,
      });
      fire2(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire2(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire2(0.1, {
        spread: 120,
        startVelocity: 45,
      });
      setTimeout(() => {
        this.$refs.publishedMessage.style.display = 'none';
      }, 4000);
    },
    showNoCreditsMessage(error) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        iconHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24"><g><path d="M23.54,11.55a44.23,44.23,0,0,0-4.92-5.84A57.77,57.77,0,0,0,13.1.47a2,2,0,0,0-1-.47,1.19,1.19,0,0,0-.4.16c-.25.15-.63.42-.92.64a.33.33,0,0,0-.08.47.34.34,0,0,0,.47.08l.94-.62c0,.09.31.15.52.31A57.45,57.45,0,0,1,18,6.29a57.49,57.49,0,0,1,4.26,5.09,3.89,3.89,0,0,1,.66,1.11c-.05.07-.2.28-.35.45-.5.58-1.37,1.43-2.37,2.4C17.43,18,13.56,21.42,12.84,22a5.55,5.55,0,0,1-.77.61c-.18.12-.36.25-.53.2A5.08,5.08,0,0,1,10.45,22c-1.18-1-2.9-2.82-4.57-4.62a53.31,53.31,0,0,1-4.69-5.58c-.12-.19-.14-.42-.21-.46A36.78,36.78,0,0,1,4.3,7.69c1.82-1.86,4-4,5.66-5.4a.31.31,0,0,0,0-.43.3.3,0,0,0-.42,0C7.88,3.23,5.63,5.31,3.76,7.14a28.41,28.41,0,0,0-3.66,4,1.37,1.37,0,0,0,.23,1A40,40,0,0,0,4,16.85C5.85,19,8,21.26,9.44,22.61a6.66,6.66,0,0,0,1.76,1.33,1.48,1.48,0,0,0,1-.07,5.36,5.36,0,0,0,1.39-1c.72-.63,4.59-4.1,7.34-6.79a29.56,29.56,0,0,0,2.69-2.87,1.27,1.27,0,0,0,.3-.65A2.16,2.16,0,0,0,23.54,11.55Z" fill="#fe0303" fill-rule="evenodd"></path><path d="M10,9.28a20.65,20.65,0,0,0,.73,3.12,1.58,1.58,0,0,0,1.6,1,1.77,1.77,0,0,0,.94-.49,4.66,4.66,0,0,0,1-2.31A17.77,17.77,0,0,0,14.49,7a1.87,1.87,0,0,0-.41-1.14,2.22,2.22,0,0,0-.95-.66,2.8,2.8,0,0,0-1-.16,2.25,2.25,0,0,0-1.85,1,2.82,2.82,0,0,0-.44,1A7.85,7.85,0,0,0,10,9.28Zm.89-2a1.87,1.87,0,0,1,.31-.59,1.35,1.35,0,0,1,.46-.39,1.37,1.37,0,0,1,.53-.12,1.73,1.73,0,0,1,.56.09,1.2,1.2,0,0,1,.48.31.81.81,0,0,1,.17.48,16.53,16.53,0,0,1,0,3.35,3.86,3.86,0,0,1-.66,1.94,1,1,0,0,1-.5.31.74.74,0,0,1-.86-.5,18.57,18.57,0,0,1-.46-3A7.13,7.13,0,0,1,10.86,7.28Z" fill="#fe0303" fill-rule="evenodd"></path><path d="M11.84,15.94a.3.3,0,0,0,.23-.36.3.3,0,0,0-.35-.23c-1.41.16-1.79,1-1.75,1.63a1.82,1.82,0,0,0,.66,1.21,2.29,2.29,0,0,0,1.21.52,2,2,0,0,0,2-1.05,1.65,1.65,0,0,0-.46-2.1.34.34,0,0,0-.44.52A.87.87,0,0,1,13,17.2a1,1,0,0,1-1,.38,1.27,1.27,0,0,1-.86-.47c-.12-.14-.24-.29-.2-.46S11.27,16.11,11.84,15.94Z" fill="#fe0303" fill-rule="evenodd"></path></g></svg>',
        // title: this.$t('ui.noCreditsForGenerating'),
        title: this.$t(`backend.${error}`),
      });
      this.clearButtons();
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
  },
};
</script>
<style >
.cdx-block:focus-visible {
  outline: none;
}
.hearme-controls-wrapper {
  padding-bottom: 12px !important;
}
@media screen and (min-width: 1022px) {
  .full-width {
    width: calc(100% - 224px);
  }
}
.shepherd-button{
  background:#000;border:0;border-radius:5px;color:#fff;cursor:pointer;margin-right:.5rem;padding:.5rem 1.5rem;transition:all .5s ease;
  font-size: 14px;
}
.shepherd-button:not(:disabled):hover{background:#fea900;color:#000}
.shepherd-button.shepherd-button-secondary{background:#f1f2f3;color:rgba(0,0,0,.75)}
.shepherd-button.shepherd-button-secondary:not(:disabled):hover{background:#d6d9db;color:rgba(0,0,0,.75)}.shepherd-button:disabled{cursor:not-allowed}
.shepherd-footer{
  border-bottom-left-radius:5px;border-bottom-right-radius:5px;display:flex;
  justify-content: space-between;
    padding: 10px 9px;}.shepherd-footer .shepherd-button:last-child{margin-right:0}
.shepherd-cancel-icon{background:transparent;border:none;color:hsla(0,0%,50%,.75);cursor:pointer;font-size:2em;font-weight:400;margin:0;padding:0;transition:color .5s ease}.shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}.shepherd-has-title .shepherd-content .shepherd-cancel-icon{color:hsla(0,0%,50%,.75)}.shepherd-has-title .shepherd-content .shepherd-cancel-icon:hover{color:rgba(0,0,0,.75)}
.shepherd-title{
  display:flex;flex:1 0 auto;font-size:1rem;font-weight:bold;margin:0;padding:0}
.shepherd-header{
  align-items:center;
  border-top-left-radius:5px;border-top-right-radius:5px;display:flex;justify-content:flex-end;
  line-height:2em;padding:.75rem .75rem 0}
.shepherd-has-title .shepherd-content .shepherd-header{padding:0.5em}
.shepherd-text{
  color: #000;
    font-size: 13px;
    line-height: 1.5;
    padding: 0.5em 0.75em;
}
.shepherd-text p{margin-top:0}
.shepherd-text p:last-child{margin-bottom:0}
.shepherd-content{
  border-radius:7px;
  outline:none;
  padding: 7px 12px;
}
.shepherd-element{background:#fff;border-radius:5px;max-width:400px;opacity:0;outline:none;transition:opacity .3s,visibility .3s;visibility:hidden;width:100%;z-index:9999}.shepherd-enabled.shepherd-element{opacity:1;visibility:visible}.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered){opacity:0;pointer-events:none;visibility:hidden}.shepherd-element,.shepherd-element *,.shepherd-element :after,.shepherd-element :before{box-sizing:border-box}.shepherd-arrow,.shepherd-arrow:before{height:16px;position:absolute;width:16px;z-index:-1}.shepherd-arrow:before{background:#fff;content:"";transform:rotate(45deg)}.shepherd-element[data-popper-placement^=top]>.shepherd-arrow{bottom:-8px}.shepherd-element[data-popper-placement^=bottom]>.shepherd-arrow{top:-8px}.shepherd-element[data-popper-placement^=left]>.shepherd-arrow{right:-8px}.shepherd-element[data-popper-placement^=right]>.shepherd-arrow{left:-8px}.shepherd-element.shepherd-centered>.shepherd-arrow{opacity:0}.shepherd-element.shepherd-has-title[data-popper-placement^=bottom]>.shepherd-arrow:before{background-color:#e6e6e6}.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,.shepherd-target-click-disabled.shepherd-enabled.shepherd-target *{pointer-events:none}
.shepherd-modal-overlay-container {
  fill: #000;
  height:0;
  left:0;
  opacity:0;overflow:hidden;pointer-events:none;position:fixed;
  top:0;transition:all .3s ease-out,height 0ms .3s,opacity .3s 0ms;width:100vw;z-index:9997}
.shepherd-modal-overlay-container.shepherd-modal-is-visible{
  height:100vh;opacity:.5;transform:translateZ(0);transition:all .3s ease-out,height 0s 0s,opacity .3s 0s}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path{pointer-events:all}
.shepherd-bottom-transform {
  transform: translate(0px, -10px);
}
.shepherd-text svg, .help-tooltip svg {
    display: inline-block;
    position: relative;
    top: 1px;
    fill: re;
    padding: 2px;
    box-shadow: 0px 0px 2px 0px #888;
    border-radius: 4px;
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  100% {
    opacity: 1;
  }
}
</style>
