<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <router-view/>
  </transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      test: 'xxx',
      websiteurl: '',
      fedUrl: null,
      stage: 1,
      showAdvance: false,
      prefered: '',
      rssError: null,
      createError: null,
      showNoWpMessage: false,
      addAsProject: false,
      projectName: null,
      token: '',
      createSiteInput: {
        feedType: 'rss',
        feedUrl: null,
        channelTypes: ['podcast', 'web-player'],
        name: null,
      },
      error: null,
      feed: {},
      loading: false,
      websiteLanguage: null,
      noLanguageError: false,
      projectModel: {
        feedLang: 'en-US',
        projectTitle: 'Sample project',
      },
      languages: [
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],
    };
  },
  validators: {
    projectName: (value) => Validator.value(value).required(),
    // eslint-disable-next-line
    websiteurl: (value) => Validator.value(value).required().regex(/^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i, 'Please enter valid URL'),
    // eslint-disable-next-line
    'createSiteInput.feedUrl': (value) => Validator.value(value).required().regex(/^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i, 'Please enter valid URL'),
  },
  computed: {
    ...mapState({
      isSidebarVisible: (state) => state.ui.isSidebarVisible,
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
      selectedWebsite: (state) => state.website.selectedWebsite,
      languageList: (state) => state.utils.languageList,
      // websites
      pluginLink: (state) => state.utils.pluginUrl,
    }),
    isLanguageValid() {
      return this.websiteLanguage !== null;
    },
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  created() {
    this.setSidebarVisibility(false);
  },
  methods: {
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
  },
};
</script>

<style scoped>
.bg {
  background-image: url('../assets/images/add-project-bg.png');
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
