<template>
  <svg id="Group_5378" data-name="Group 5378" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="88.24" height="68.669" viewBox="0 0 88.24 68.669">
    <defs>
    <clipPath id="clip-path">
        <rect id="Rectangle_2638" data-name="Rectangle 2638" width="88.24" height="68.669"/>
    </clipPath>
    </defs>
    <g id="Group_5377" data-name="Group 5377" clip-path="url(#clip-path)">
    <path id="Path_43247" data-name="Path 43247" d="M6.648.165A6.281,6.281,0,0,0,.377,6.4Q.311,19.767.193,33.19C.119,42.581.043,52.292,0,61.805a6.832,6.832,0,0,0,6.841,6.864c.11,0,.221,0,.331-.008,15.049-.734,30.3-.822,45.055-.908,8.986-.052,18.278-.107,27.442-.305a8.7,8.7,0,0,0,8.474-8.722c-.045-6.071,0-12.238.039-18.2.074-10.544.149-21.446-.259-32.206A8.31,8.31,0,0,0,79.874.351C63.466-.118,46.792-.015,30.667.084c-7.868.048-16,.1-24.02.081M30.68,2.146c16.106-.1,32.763-.2,49.134.267A6.241,6.241,0,0,1,85.861,8.4c.407,10.714.331,21.593.258,32.114-.041,5.971-.085,12.145-.039,18.231a6.626,6.626,0,0,1-6.456,6.646c-9.148.2-18.432.253-27.409.3-14.774.086-30.05.175-45.144.911a4.771,4.771,0,0,1-5.008-4.786c.043-9.51.119-19.22.193-28.609Q2.361,19.8,2.44,6.415A4.216,4.216,0,0,1,6.648,2.227c8.023.019,16.161-.033,24.033-.081" transform="translate(0 0)"/>
    <path id="Path_43248" data-name="Path 43248" d="M8.825,9.476c.331,8.185.1,16.374-.116,24.3a295.577,295.577,0,0,0,.181,29.6,1.031,1.031,0,0,0,1.027.957l.063,0c6.665-.4,15.572-.4,27.9-.392H42.7c5.973,0,12.042.089,17.91.177s11.95.176,17.937.176q2.969,0,5.941-.032a1.031,1.031,0,0,0,1.018-1.067c-.168-4.824-.25-9.962-.25-15.707,0-7.728.151-15.607.3-23.226.1-5.325.207-10.832.261-16.253a1.032,1.032,0,0,0-1.028-1.041C59.47,6.88,34.205,7.654,9.825,8.4a1.033,1.033,0,0,0-1,1.073m1.946,24.352c.212-7.621.43-15.49.155-23.4C34.634,9.7,59.166,8.97,83.741,9.016c-.056,5.075-.153,10.214-.249,15.195-.145,7.629-.3,15.518-.3,23.266,0,5.352.071,10.181.216,14.722q-2.433.022-4.863.021c-5.972,0-12.04-.09-17.907-.176s-11.952-.177-17.941-.177H33.687c-9.651,0-17.018.034-22.81.34-.628-9.572-.363-19.127-.106-28.377" transform="translate(-2.633 -2.172)"/>
    <path id="Path_43249" data-name="Path 43249" d="M36.815,37c-.025,1.45-.05,2.9-.065,4.338a1.033,1.033,0,0,0,1.031,1.042c12.76-.019,29.544-.179,46.23-1.262a1.032,1.032,0,0,0,.957-1.155c-.385-3.125-.582-6.32-.773-9.409-.174-2.823-.354-5.742-.677-8.625a1.031,1.031,0,0,0-1.023-.916c-4.477-.006-8.96-.037-13.444-.067-4.893-.034-9.953-.069-14.931-.069-6.267,0-11.483.054-16.413.171a1.031,1.031,0,0,0-1.005,1.09c.133,2.307.2,4.756.2,7.707,0,2.383-.041,4.809-.081,7.155m17.3-14.061c4.971,0,10.027.035,14.917.069q6.269.044,12.53.067c.258,2.537.417,5.11.571,7.607.171,2.766.346,5.616.657,8.448-15.818.989-31.659,1.161-43.969,1.186q.022-1.636.052-3.281c.041-2.354.082-4.789.082-7.19,0-2.544-.045-4.722-.143-6.76,4.6-.1,9.5-.146,15.3-.146" transform="translate(-11.468 -6.523)"/>
    <path id="Path_43250" data-name="Path 43250" d="M37,61.325h.049c3.226-.154,6.559-.17,9.782-.185,2.157-.01,4.389-.021,6.565-.072a1.032,1.032,0,0,0,1.007-1.056,1.016,1.016,0,0,0-1.055-1.006c-2.157.051-4.378.061-6.526.072-3.245.015-6.6.031-9.87.186A1.031,1.031,0,0,0,37,61.325" transform="translate(-11.241 -18.438)"/>
    <path id="Path_43251" data-name="Path 43251" d="M36.254,66.774l.212,0c5.59-.126,11.371-.258,17-.642a1.031,1.031,0,0,0-.14-2.058c-5.586.381-11.342.512-16.909.638l-.189,0a1.031,1.031,0,0,0,.023,2.062" transform="translate(-11.007 -20.02)"/>
    <path id="Path_43252" data-name="Path 43252" d="M35.88,72.43c2.278,0,4.59-.042,6.825-.083,3.336-.061,6.782-.123,10.168-.047a1.018,1.018,0,0,0,1.055-1.008,1.032,1.032,0,0,0-1.008-1.054c-3.42-.077-6.9-.014-10.252.046-2.226.041-4.528.082-6.788.082a1.031,1.031,0,1,0,0,2.063" transform="translate(-10.89 -21.938)"/>
    <path id="Path_43253" data-name="Path 43253" d="M53.68,75.634C47.868,75.5,41.964,75.5,36.254,75.5a1.031,1.031,0,1,0,0,2.063c5.7,0,11.591,0,17.38.129a1.031,1.031,0,0,0,.045-2.062" transform="translate(-11.007 -23.594)"/>
    <path id="Path_43254" data-name="Path 43254" d="M16.942,23.755c1.318,0,2.708.1,4.054.191,1.38.1,2.807.2,4.2.2a1.031,1.031,0,0,0,0-2.063c-1.317,0-2.708-.1-4.053-.19-1.381-.1-2.808-.2-4.2-.2a1.031,1.031,0,0,0,0,2.063" transform="translate(-4.972 -6.778)"/>
    <path id="Path_43255" data-name="Path 43255" d="M16.942,29h7.606a1.031,1.031,0,0,0,0-2.063H16.942a1.031,1.031,0,0,0,0,2.063" transform="translate(-4.972 -8.419)"/>
    <path id="Path_43256" data-name="Path 43256" d="M16.617,33.633c1.057-.05,2.131-.019,3.211.011.689.019,1.38.038,2.069.038a21.466,21.466,0,0,0,3.066-.188,1.031,1.031,0,1,0-.292-2.041,19.538,19.538,0,0,1-2.775.166c-.67,0-1.341-.019-2.012-.037-1.132-.032-2.26-.063-3.367-.01a1.031,1.031,0,1,0,.1,2.06" transform="translate(-4.855 -9.825)"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProjectIcon',
};
</script>
