var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"flex-shrink-0 play-pause rounded-md flex justify-center align-middle relative items-center -ml-0.5",class:{
  'h-9 w-9': _vm.size === '',
  'h-5 w-5': _vm.size === 'sm',
  'h-6 w-6': _vm.size === 'md',
  'px-6 py-3 text-base': _vm.size === 'md',
  'text-white bg-black hover:bg-hm-brand hover:text-black': !_vm.isPlaying,
  'bg-hm-brand text-black': _vm.isPlaying,
  'loading': _vm.loading,
  'pointer-events-none opacity-50': _vm.disabled,
  }},[(!_vm.isPlaying)?_c('svg',{class:{
    'h-2 w-2': _vm.size === 'sm',
    'h-3 w-3': _vm.size === 'md',
    },attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"15.999","viewBox":"0 0 12 15.999"}},[_c('g',{attrs:{"id":"triangle-right","transform":"translate(0 0)"}},[_c('path',{attrs:{"id":"Path_43134","data-name":"Path 43134","d":"M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z","transform":"translate(-2 0)","fill":"currentColor"}})])]):_vm._e(),(_vm.isPlaying)?_c('svg',{class:{
    'h-2 w-2': _vm.size === 'sm',
    'h-3 w-3': _vm.size === 'md',
    },attrs:{"id":"button-pause","xmlns":"http://www.w3.org/2000/svg","width":"12","height":"12","viewBox":"0 0 12 12"}},[_c('rect',{attrs:{"id":"Rectangle_2499","data-name":"Rectangle 2499","width":"4","height":"12","rx":"0.5","transform":"translate(8)","fill":"#212121"}}),_c('rect',{attrs:{"id":"Rectangle_2500","data-name":"Rectangle 2500","width":"4","height":"12","rx":"0.5","fill":"currentColor"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }