/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../../graphql';
import SelectionUtils from './selection';
import $ from './dom';
// import * as _ from './utils';

import './prosody.css';

require('./prosody.css');
/**
 * Prosody Tool
 *
 * Inline Toolbar Tool
 *
 * Wrap selected text with <a> tag
 */
export default class ProsodyInlineTool {
  /**
   * @param {API} api - Editor.js API
   */
  constructor({ api, config }) {
    /**
     * Native Document's commands for link/unlink
     */
    this.commandLink = 'createLink';
    this.commandUnlink = 'unlink';
    this.config = config;
    /**
     * Enter key code
     */
    this.ENTER_KEY = 13;
    /**
     * Styles
     */
    this.CSS = {
      button: 'ce-inline-tool',
      buttonActive: 'ce-inline-tool--active',
      buttonModifier: 'ce-inline-tool--prosody',
      buttonUnlink: 'ce-inline-tool--unprosody',
      input: 'ce-inline-tool-prosody-input',
      label: 'ce-inline-tool-label',
      title: 'ce-inline-tool-title',
      desc: 'ce-inline-tool-desc',
      wrapper: 'ce-inline-tool-prosody-wrapper',
      sliderWrapper: 'ce-inline-tool-prosody-slider-wrapper',
      pitchWrapper: 'ce-inline-tool-prosody-pitch-wrapper flex items-center space-x-1',
      inputShowed: 'ce-inline-tool-input--showed',
      pitchBtnActive: 'pitch-btn-active',
    };


    this.prosodyOptions = ['high', 'low', 'medium', 'x-high', 'x-low'];
    this.pitch = null;
    /**
     * Elements
     */
    this.nodes = {
      button: null,
      input: null,
      wrapper: null,
      enter: null,
    };
    this.range = null;
    /**
     * Input opening state
     */
    this.listeners = api.listeners;
    this.inputOpened = false;
    this.toolbar = api.toolbar;
    this.inlineToolbar = api.inlineToolbar;
    this.notifier = api.notifier;
    this.i18n = api.i18n;
    this.selection = new SelectionUtils();

    this.tag = 'PROSODY';
    this.api = api;
  }

  /**
   * Sanitizer Rule
   * Leave <a> tags
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      style: false,
      a: {
        href: true,
        title: false,
        dataset: false,
        'prosody': true,
        'pitch': true,
        target: '_blank',
        rel: 'nofollow',
      },
    };
  }

  /**
   * Create button for Inline Toolbar
   */
  render() {
    const text = document.createElement('span');
    text.innerText = this.i18n.t('Prosody');
    this.nodes.button = document.createElement('button');
    this.nodes.button.type = 'button';
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.appendChild(text);
    this.nodes.button.appendChild($.svg('cross', 11, 11));
    return this.nodes.button;
  }

  /**
   * Input for the link
   */
  renderActions() {

    this.nodes.wrapper = document.createElement('div');
    this.nodes.wrapper.classList = `relative ${this.CSS.wrapper}`;
    // this.nodes.wrapper.classList.add('ce-inline-tool-input--showed');
    this.nodes.title = document.createElement('div');
    this.nodes.title.classList = this.CSS.title;
    this.nodes.desc = document.createElement('div');
    this.nodes.desc.classList = this.CSS.desc;
    this.nodes.title.innerText = this.i18n.t('Prosody');
    this.nodes.desc.innerText = '';
    this.nodes.wrapper.appendChild(this.nodes.title);
    this.nodes.wrapper.appendChild(this.nodes.desc);
    this.nodes.enter = document.createElement('button');
    this.nodes.enter.classList = 'text-white font-bold border-2 border-white px-4 py-1 rounded-md cursor-pointer hover:text-hm-brand hober:border-hm-brand mt-6 mb-5 float-right';
    this.nodes.input = document.createElement('input');
    this.nodes.input.placeholder = this.i18n.t('Enter speaking rate');
    this.nodes.input.classList.add(this.CSS.input);

    this.nodes.rateTitle = document.createElement('div');
    this.nodes.rateTitle.classList = this.CSS.label;
    this.nodes.rateTitle.innerText = this.i18n.t('Rate');
    this.nodes.rateDesc = document.createElement('span');
    this.nodes.rateDesc.classList = this.CSS.label;
    this.nodes.rateDesc.innerText = '100%';
    this.nodes.rateDesc2 = document.createElement('div');
    this.nodes.rateDesc2.classList = this.CSS.desc;
    this.nodes.rateDesc2.innerText = this.i18n.t('Speaking rate of selected text (in %)');
    this.nodes.pitchTitle = document.createElement('div');
    this.nodes.pitchTitle.classList = this.CSS.label; + ' mt-2'; 
    this.nodes.pitchTitle.innerText = this.i18n.t('Pitch');
    // this.nodes.rateDesc2 = document.createElement('span');
    // this.nodes.rateDesc2.classList = this.CSS.label;
    // this.nodes.rateDesc2.innerText = '';
    this.nodes.pitchDesc = document.createElement('div');
    this.nodes.pitchDesc.classList = this.CSS.desc;
    this.nodes.pitchDesc.innerText = 'Level and intensity of the speech output.';
    this.nodes.pitchWrapper = document.createElement('div');
    this.nodes.pitchWrapper.classList = this.CSS.pitchWrapper;

    this.prosodyOptions.forEach((option) => {
      const btn = document.createElement('button');
      btn.innerText = option;
      btn.id = option;
      btn.classList = 'text-white text-sm font-bold border-2 border-white px-2 py-1 rounded-md cursor-pointer hover:text-hm-brand hover:border-hm-brand';
      btn.addEventListener('click', (event) => {
        this.pitch = option;
        this.nodes.rateDesc2.innerText = option;
        const activeBtn = document.querySelector('.' + this.CSS.pitchBtnActive);
        if (activeBtn) activeBtn.classList.remove(this.CSS.pitchBtnActive);

        event.target.classList.add(this.CSS.pitchBtnActive);
      });
      this.nodes.pitchWrapper.appendChild(btn);
    });

    this.nodes.sliderContainer = document.createElement('div');
    this.nodes.sliderContainer.classList = this.CSS.sliderWrapper;
    this.nodes.slider = document.createElement('input');
    this.nodes.slider.setAttribute('type', 'range');
    this.nodes.slider.setAttribute('min', 10);
    this.nodes.slider.setAttribute('max', 200);
    this.nodes.slider.value = 100;

    this.nodes.slider.addEventListener('input', (event) => {
      console.log('slider value', this.nodes.slider.value);
      this.nodes.rateDesc.innerText = this.nodes.slider.value + '%'
    });
    

    this.nodes.input.addEventListener('keydown', (event) => {
      if (event.keyCode === this.ENTER_KEY) {
        this.enterPressed(event);
      }
    });
    this.nodes.input.addEventListener('keydown', (event) => {
      if (event.keyCode === this.ENTER_KEY) {
        this.enterPressed(event);
      }
    });
    this.listeners.on(this.nodes.enter, 'click', async (event) => {
      await this.enterPressed(event);
    }, false);
    const template = document.createElement('template');
    const enterSign = `<span class="flex items-center space-x-2"><span>${this.i18n.t('Apply')}</span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z" fill="currentColor"></path></g></svg></span>`;
    template.innerHTML = enterSign;
    this.nodes.enter.appendChild(template.content.firstChild);
    // this.nodes.wrapper.appendChild(this.nodes.input);
    this.nodes.wrapper.appendChild(this.nodes.rateTitle);
    this.nodes.rateTitle.appendChild(this.nodes.rateDesc);
    this.nodes.wrapper.appendChild(this.nodes.rateDesc2);
    this.nodes.sliderContainer.appendChild(this.nodes.slider);
    this.nodes.wrapper.appendChild(this.nodes.sliderContainer);
    // this.nodes.wrapper.appendChild(this.nodes.pitchTitle);
    // this.nodes.pitchTitle.appendChild(this.nodes.rateDesc2);
    // this.nodes.wrapper.appendChild(this.nodes.pitchDesc);
    // this.nodes.wrapper.appendChild(this.nodes.pitchWrapper);
    this.nodes.wrapper.appendChild(this.nodes.enter);
    return this.nodes.wrapper;
  }

  /**
   * Handle clicks on the Inline Toolbar icon
   *
   * @param {Range} range - range to wrap with link
   */
  surround(range) {
    if (!range) {
      return;
    }
    this.range = range;
    let termWrapper = this.api.selection.findParentTag(this.tag, ProsodyInlineTool.CSS);
      /**
           * If start or end of selection is in the highlighted block
           */
      // if (termWrapper) {
      //   this.unwrap(termWrapper);
      // } else {
      //   this.wrap(range);
      // }
      // if (!this.inputOpened) {
      //   /** Create blue background instead of selection */
      //   this.selection.setFakeBackground();
      //   this.selection.save();
      // } else {
      //   this.selection.restore();
      //   this.selection.removeFakeBackground();
      // }
      // const parentAnchor = this.selection.findParentTag('A');
      /**
       * Unlink icon pressed
       */
      if (termWrapper) {
        this.unwrap(termWrapper);
        this.closeActions();
        this.checkState();
        this.toolbar.close();
        return;
      }
      console.log('soround', range);

    // this.toggleActions();
    this.openActions();
  }

  wrap(link) {
    /**
     * Create a wrapper for highlighting
     */
    let marker = document.createElement(this.tag);

    link = link.replaceAll('%', '');
    // link += '%';
    marker.setAttribute('prosody', link);
    marker.setAttribute('pitch', this.pitch);

    marker.classList.add(ProsodyInlineTool.CSS);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    marker.appendChild(this.range.extractContents());
    this.range.insertNode(marker);

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(marker);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    let sel = window.getSelection();
    let range = sel.getRangeAt(0);

    let unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check selection and set activated state to button if there are <a> tag
   *
   * @param {Selection} selection - selection to check
   */
  checkState(selection) {
    console.log('checkState', selection);
    const anchorTag = this.selection.findParentTag(this.tag);
    if (anchorTag && anchorTag.getAttribute('prosody')) {
      this.nodes.button.classList.add(this.CSS.buttonUnlink);
      this.nodes.button.classList.add(this.CSS.buttonActive);
      // this.openActions();
      /**
       * Fill input value with link href
       */
      const prosodyValue = anchorTag.getAttribute('prosody');
      this.pitch = anchorTag.getAttribute('pitch');
      console.log('checkState', prosodyValue, this.pitch);
      const pitchBtn = document.querySelector('#' + this.pitch);
      this.nodes.rateDesc.innerText = prosodyValue;
      if (pitchBtn) {
        pitchBtn.classList.add(this.CSS.pitchBtnActive);
        this.nodes.rateDesc2.innerText = this.pitch;
      }
      // const data = anchorTag.dataset.alias;
      // this.nodes.input.value = data !== 'null' ? data : '';
      this.nodes.input.value = prosodyValue !== 'null' ? prosodyValue : '';
      this.nodes.slider.value = prosodyValue !== 'null' ? prosodyValue : 100;
      this.nodes.rateDesc.innerText = this.nodes.slider.value + '%'
      this.selection.save();
    } else {
      this.nodes.button.classList.remove(this.CSS.buttonUnlink);
      this.nodes.button.classList.remove(this.CSS.buttonActive);
    }
    return !!anchorTag;
  }

  /**
   * Function called with Inline Toolbar closing
   */
  clear() {
    // console.log('clear');
    this.closeActions();
  }
  /**
   * Set a shortcut
   */

  get shortcut() {
    return 'CMD+K';
  }

  /**
   * Show/close link input
   */
  toggleActions() {
    if (!this.inputOpened) {
      this.openActions(true);
    } else {
      this.closeActions(false);
    }
  }

  /**
   * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
   */
  openActions(needFocus = false) {
    console.log('opan prosody action');
    this.nodes.wrapper.classList.add(this.CSS.inputShowed);
    if (needFocus) {
      this.nodes.slider.focus();
    }
    this.inputOpened = true;
    document.querySelector('.ce-inline-tool-sayas-wrapper').classList.remove(this.CSS.inputShowed);
    if (document.querySelector('.ce-inline-tool-lang-wrapper')) document.querySelector('.ce-inline-tool-lang-wrapper').classList.remove(this.CSS.inputShowed);
  }

  /**
   * Close input
   *
   * @param {boolean} clearSavedSelection — we don't need to clear saved selection
   *                                        on toggle-clicks on the icon of opened Toolbar
   */
  closeActions(clearSavedSelection = true) {
    // console.log('close', clearSavedSelection);
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();
      currentSelection.save();
      this.selection.restore();
      this.selection.removeFakeBackground();
      // and recover new selection after removing fake background
      currentSelection.restore();
    }
    this.nodes.wrapper.classList.remove(this.CSS.inputShowed);
    this.nodes.input.value = '';
    this.nodes.slider.value = '';
    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    this.inputOpened = false;
  }

  /**
   * Enter pressed on input
   *
   * @param {KeyboardEvent} event - enter keydown event
   */
  enterPressed(event) {
    // console.log('enterPressed', event, this.nodes);
    let value = this.nodes.slider.value || '';
    if (value === '') {
      this.selection.restore();
      const parentAnchor = this.selection.findParentTag(this.tag);
      if (parentAnchor) {
        // found closest 'A' tag that wraps current selection
        this.selection.expandToTag(parentAnchor);
      }
      this.unwrap();
      return;
    }
    if (!value.trim()) {
      this.selection.restore();
      this.unlink();
      event.preventDefault();
      this.closeActions();
      return;
    }
    // if (!this.validateURL(value)) {
    //   this.notifier.show({
    //     message: 'Pasted link is not valid.',
    //     style: 'error',
    //   });
    //   _.log('Incorrect Link pasted', 'warn', value);
    //   return;
    // }
    value = this.prepareLink(value);
    this.selection.restore();
    this.selection.removeFakeBackground();
    this.wrap(value);
    
    /**
     * Preventing events that will be able to happen
     */
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.selection.collapseToEnd();
    this.inlineToolbar.close();
  }

  /**
   * Detects if passed string is URL
   *
   * @param {string} str - string to validate
   * @returns {boolean}
   */
  validateURL(str) {
    /**
     * Don't allow spaces
     */
    return !/\s/.test(str);
  }

  /**
   * Process link before injection
   * - sanitize
   * - add protocol for links like 'google.com'
   *
   * @param {string} link - raw user input
   */
  prepareLink(link) {
    link = link.trim();
    // link = this.addProtocol(link);
    return link;
  }

  /**
   * Inserts <a> tag with "href"
   *
   * @param {string} link - "href" value
   */
  async insertLink(link) {
    /**
     * Edit all link, not selected part
     */
    const anchorTag = this.selection.findParentTag('A');
    // let marker = document.createElement(this.tag);
    // console.log('anchor tag', anchorTag, this.selection);
    if (anchorTag) {
      this.selection.expandToTag(anchorTag);
    }
    document.execCommand(this.commandLink, false, link);
    const aTag = this.selection.findParentTag('A');
    link = link.replaceAll('%', '');
    // link += '%';
    aTag.setAttribute('prosody', link);
    aTag.setAttribute('href', link);
    aTag.setAttribute('pitch', this.pitch);
    this.wrap(link);
    // const language = document.querySelector('#article-edit').dataset.language;
    // const payload = {
    //   originalText: aTag.text,
    //   alias: link,
    //   language: language,
    //   feedItemId: this.config.feedItemId,
    //   textType: this.config.textType,
    // };
    // console.log('add local alias start', this.config, payload, aTag, link);
    // const response = await API.graphql(
    //   graphqlOperation(
    //     GQL.AddLocalAlias,
    //     { input: payload },
    //   ),
    // );
    // console.log('add local alias', response);
    // aTag.title = link;
    // aTag.removeAttribute('href');
  }

  /**
   * Removes <a> tag
   */
  unlink() {
    document.execCommand(this.commandUnlink, false, false);
  }

  static title = 'Link';
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns {boolean}
 */
 ProsodyInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
// ProsodyInlineTool.title = 'Alias';
