/* eslint-disable */
import MuteUtils from './mute-utils';
/**
 * Build styles
 */
// import './index.css';

/**
 * GenerateImage Tool for the Editor.js
 *
 * Allows to wrap inline fragment and style it somehow.
 */
 export default class GenerateImage {
  /**
   * Class name for term-tag
   *
   * @type {string}
   */
  static get CSS() {
    return 'cdx-generate-image';
  };

  /**
   * @param {{api: object}}  - Editor.js API
   */
  constructor({api}) {
    this.api = api;

    /**
     * Toolbar Button
     *
     * @type {HTMLElement|null}
     */
    this.button = null;

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = 'MARK';

    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive
    };
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true;
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.button.classList.add(this.iconClasses.base);
    this.button.innerHTML = this.toolboxIcon;

    return this.button;
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return;
    }

    let termWrapper = this.api.selection.findParentTag(this.tag, GenerateImage.CSS);

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.wrap(range);
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    const text = range.extractContents();
    const textContent = text.textContent;
    console.log('wrap', textContent);
    /**
     * Create a wrapper for highlighting
     */
    let marker = document.createElement(this.tag);

    marker.classList.add(GenerateImage.CSS);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    // marker.appendChild(text);
    range.insertNode(text);

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.blocks.insert('generatedImg', { prompt: textContent, generated: true, });
    setTimeout(() => {
      const newBlock = document.querySelector('.cdx-simple-image-generated');
      if (newBlock) {
        this._scrollTo('.cdx-simple-image-generated', -50);
      }
      this.api.toolbar.close();
    }, 300);
  }

  _scrollTo(selector, yOffset = 0){
    const contentHolder = document.querySelector('#content-right');
    let parentPos = document.getElementById('content-right').getBoundingClientRect(),
    childPos = document.querySelector(selector).getBoundingClientRect(),
    relativePos = {};

    relativePos.top = childPos.top - parentPos.top,
    relativePos.right = childPos.right - parentPos.right,
    relativePos.bottom = childPos.bottom - parentPos.bottom,
    relativePos.left = childPos.left - parentPos.left;
    // console.log('yyyy', el, el.getBoundingClientRect().top, contentHolder.scrollTop, contentHolder.scrollTop - el.getBoundingClientRect().top, y);
    contentHolder.scrollBy({
      top: relativePos.top + yOffset,
      behavior: 'smooth'
    });
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    console.log('unwrap');
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    let sel = window.getSelection();
    let range = sel.getRangeAt(0);

    let unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    console.log('check state');
    const termTag = this.api.selection.findParentTag(this.tag, GenerateImage.CSS);

    this.button.classList.toggle(this.iconClasses.active, !!termTag);
  }

  /**
   * Get Tool icon's SVG
   * @return {string}
   */
  get toolboxIcon() {
    return `<svg id="Group_89" data-name="Group 89" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_153" data-name="Rectangle 153" width="15.5" height="15.5" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_88" data-name="Group 88" clip-path="url(#clip-path)">
      <rect id="Rectangle_152" data-name="Rectangle 152" width="14" height="14" rx="4" transform="translate(0.75 0.75)" fill="none" stroke="#fff" stroke-width="1.5"/>
      <path id="Path_871" data-name="Path 871" d="M11.137,12.5a.749.749,0,0,1-.548-.238L8.8,10.344l-.03-.032L6.448,7.828l-.076-.075a1.006,1.006,0,0,0-.7-.252A.968.968,0,0,0,5,7.82L1.435,11.586a.75.75,0,0,1-1.09-1.031L3.9,6.8A2.471,2.471,0,0,1,7.38,6.642a1.969,1.969,0,0,1,.171.171L9.33,8.718l.885-.947a2.451,2.451,0,0,1,1.7-.8,2.371,2.371,0,0,1,1.783.637c.064.059.119.114.171.171l1.278,1.435a.75.75,0,0,1-1.119,1l-1.344-1.5a.975.975,0,0,0-1.369.073l-.962,1.029,1.328,1.422a.75.75,0,0,1-.548,1.262" fill="#fff"/>
      <line id="Line_6" data-name="Line 6" x2="0.009" transform="translate(9.528 5.083)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </g>
  </svg>  
  `;
  }

  get shortcut() {
    return 'CMD+G';
  }

  static title = 'Generate Image';

  /**
   * Sanitizer rule
   * @return {{mark: {class: string}}}
   */
  static get sanitize() {
    return {
      mark: {
        class: GenerateImage.CSS
      }
    };
  }
}
