<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="mx-auto h-16 w-16"
    width="86.017"
    height="78.802"
    viewBox="0 0 86.017 78.802"
  >
    <g id="Group_5634" data-name="Group 5634" transform="translate(0.007 -1)">
      <path
        id="Path_43738"
        data-name="Path 43738"
        d="M82.1,6.267a9.352,9.352,0,0,0-6.557-3.583,56.038,56.038,0,0,0-9.782.43C55.733,3.508,45.808,1.609,35.848,1H28.108a111.4,111.4,0,0,0-11.824.824,19.6,19.6,0,0,0-8.1,2.759,18.309,18.309,0,0,0-6.27,12.326C.017,27.156,1.7,40.772,1.45,46.791A166.757,166.757,0,0,0,.017,66.856a13.042,13.042,0,0,0,2.938,9.065,14.332,14.332,0,0,0,6.915,2.866,77.643,77.643,0,0,0,10.283.717c4.3.287,8.635.322,12.9.287,10,0,19.957-.752,29.954-.681a.967.967,0,0,0,1.039-.967,1.039,1.039,0,0,0-1-1c-10-.251-19.993.251-29.99.215a108.161,108.161,0,0,1-12.72-.5,88.5,88.5,0,0,1-9.961-.86A11.609,11.609,0,0,1,4.962,73.7a9.961,9.961,0,0,1-1.827-6.987A162.493,162.493,0,0,1,4.854,46.97c.287-5.912-1.111-19.348.681-29.381a14.8,14.8,0,0,1,4.8-9.889,16.661,16.661,0,0,1,6.521-2.042A112.187,112.187,0,0,1,28.252,4.87c10-.466,19.957,1.362,29.99,1.756h7.7a56.5,56.5,0,0,1,9.316-.573,6.306,6.306,0,0,1,4.228,2.114,16.159,16.159,0,0,1,3.332,9.459,98.353,98.353,0,0,1-1.577,16.41c-.322,2.866-.466,5.733-.573,8.6-.287,6.629,0,13.257-.43,19.886v7.166a9.674,9.674,0,0,1-.9,4.085,4.765,4.765,0,0,1-2.723,1.756,37.3,37.3,0,0,1-5.984,1.147,1.122,1.122,0,1,0,.322,2.221,28.986,28.986,0,0,0,7.453-1.577,6.02,6.02,0,0,0,3.046-2.4,11.179,11.179,0,0,0,.967-3.726c.358-2.795,0-6.091.251-8.384.394-6.629.394-13.257.717-19.886a66.182,66.182,0,0,1,.717-8.384,104.444,104.444,0,0,0,1.9-16.948A19.133,19.133,0,0,0,82.1,6.267Z"
        transform="translate(0 0)"
        fill="#fea900"
        fill-rule="evenodd"
      />
      <path
        id="Path_43739"
        data-name="Path 43739"
        d="M27.98,8.726A1.147,1.147,0,1,0,28.8,6.613,21.821,21.821,0,0,0,18.771,4.57a30.778,30.778,0,0,0-6.127.788C9.706,6,6.947,7.078,4.26,7.795a1.039,1.039,0,0,0-.824,1.147.967.967,0,0,0,1.29.788c3.189-.251,6.593-.537,10-.967-.251,1.5-.5,3.046-.645,4.586a20.718,20.718,0,0,0,0,3.01v3.01c0,2.329.43,4.622.573,6.951A1.147,1.147,0,0,0,15.761,27.5a1.182,1.182,0,0,0,1.147-1.111c.287-2.365.573-4.658.752-7.166V13.241A46.13,46.13,0,0,0,17.195,8.3,38.016,38.016,0,0,1,21.208,7.9,20.137,20.137,0,0,1,27.98,8.726Z"
        transform="translate(8.871 9.185)"
        fill="#191919"
        fill-rule="evenodd"
      />
      <path
        id="Path_43740"
        data-name="Path 43740"
        d="M22.514,8.935l4.694-.322a52.168,52.168,0,0,1,7.166-.287,1.147,1.147,0,0,0,1.29-.967,1.182,1.182,0,0,0-.967-1.29A37.084,37.084,0,0,0,27.53,5.029a30.171,30.171,0,0,0-3.117,0,28.952,28.952,0,0,0-3.081.358c-2.4.43-4.73,1.147-6.987,1.72a1,1,0,0,0-.86,1.111.967.967,0,0,0,1.111.86C17.032,8.97,19.719,9.042,22.514,8.935Z"
        transform="translate(34.831 10.303)"
        fill="#191919"
        fill-rule="evenodd"
      />
      <path
        id="Path_43741"
        data-name="Path 43741"
        d="M12.42,12.583a1,1,0,0,0,1.147.824c3.332-.251,6.449-.179,9.6-.287l4.765-.251c1.577,0,3.153-.251,4.765-.287s3.153,0,4.765,0a1.147,1.147,0,0,0,1.254-1,1.111,1.111,0,0,0-1-1.254A65.714,65.714,0,0,0,29.117,9H25.534a24.279,24.279,0,0,0-3.583.358,83.159,83.159,0,0,0-8.42,1.9,1.039,1.039,0,0,0-1.111,1.326Z"
        transform="translate(31.991 20.664)"
        fill="#191919"
        fill-rule="evenodd"
      />
      <path
        id="Path_43742"
        data-name="Path 43742"
        d="M5.256,18.48c3.583-.43,7.166-.681,10.749-.86,4.12-.215,8.313-.215,12.469-.287s8.348-.287,12.5-.358h4.407c2.221,0,4.479,0,6.736.215h3.906a9.567,9.567,0,0,1,2.293.287,1.075,1.075,0,0,0,1.4-.681,1.111,1.111,0,0,0-.681-1.433,10.463,10.463,0,0,0-2.15-.5c-1.541-.215-3.225,0-4.622-.322-2.616-.251-5.231-.609-7.847-.824-1.147,0-2.293-.179-3.583-.215H31.842c-3.046,0-6.019.215-8.993.466A148.337,148.337,0,0,0,4.933,16.581a1,1,0,0,0-.824,1.147A1.039,1.039,0,0,0,5.256,18.48Z"
        transform="translate(10.599 32.288)"
        fill="#191919"
        fill-rule="evenodd"
      />
      <path
        id="Path_43743"
        data-name="Path 43743"
        d="M57.167,18.964A111.074,111.074,0,0,0,41.259,17.28h-7.99c-2.687,0-5.267.287-7.954.5-4.049.43-8.133.752-12.182,1.362A65.426,65.426,0,0,0,5,20.791a1.039,1.039,0,0,0-.752,1.218,1,1,0,0,0,1.218.717,59.372,59.372,0,0,1,7.954-.9c4.049-.251,8.062-.215,12.182-.358,3.189-.215,6.306-.322,9.388-.466s6.234-.322,9.388-.358a124.042,124.042,0,0,1,12.5.573,1.075,1.075,0,0,0,1.254-.932,1.147,1.147,0,0,0-.967-1.326Z"
        transform="translate(10.927 42.052)"
        fill="#191919"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TitleVoiceIcon',
  props: {
    title: {
      type: String,
      default: 'voice id user',
    },
  },
};
</script>
