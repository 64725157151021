<template>
  <div class="bg min-h-screen bg-white flex flex-wrap content-center bg-[../assets/images/add-project-bg.png]">
  <div class="max-w-lg mx-auto">
      <div class="bg-white overflow-hidden border-2 border-black rounded-lg" >
        <div class="px-4 py-5 sm:p-6">
          <div class="mt-2 p-4">
            <Website-icon v-show="!isProject" ></Website-icon>
            <Project-icon v-show="isProject" ></Project-icon>
            <h1 v-show="!isProject" class="my-5 text-3xl font-bold text-black sm:text-4xl lg:text-5xl">{{$t('ui.addBlogNameWebsiteTitle')}}</h1>
            <h1 v-show="isProject" class="my-5 text-3xl font-bold text-black sm:text-4xl lg:text-5xl">{{$t('ui.addBlogNameProjectTitle')}}</h1>
            <p v-show="!isProject" class=" text-1xl text-black">{{$t('ui.addBlogNameWebsiteDesc')}}</p>
            <p v-show="isProject" class=" text-1xl text-black">{{$t('ui.addBlogNameProjectDesc')}}</p>
            <p class="text-red-500" v-show="error">{{$t('ui.noFeedError')}}</p>
            <form class="" v-on:submit.prevent="submit">
              <label v-show="!isProject" for="websiteurl" class="text-left block text-sm font-medium text-black mt-5">{{$t('ui.enterBlogUrl')}}</label>
              <label v-show="isProject" for="projectName" class="text-left block text-sm font-medium text-black mt-5">{{$t('ui.enterProjectName')}}</label>
              <div class="mt-1 mb-8">
                <div v-show="!isProject">
                  <div class="mt-1 relative rounded-md">
                    <input type="text" v-model="websiteurl" name="websiteurl" id="websiteurl" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-black border-2 w-full px-4" placeholder="www.example.com"
                      :class="{error: validation.isTouched('websiteurl') && validation.firstError('websiteurl')}"/>
                  </div>
                  <div class="message text-red-500 text-xs mt-1 text-right" v-if="validation.isTouched('websiteurl')">
                    {{ validation.firstError('websiteurl') }}
                  </div>
                </div>
                <div v-show="isProject">
                  <div class="mt-1 relative rounded-md">
                    <input type="text" v-model="projectName" name="projectName" id="projectName" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-black border-2 w-full px-4" :placeholder="$t('ui.projectNamePlaceholder')"
                      :class="{error: validation.isTouched('projectName') && validation.firstError('projectName')}"/>
                  </div>
                  <div class="message text-red-500 text-xs mt-1 text-right" v-if="validation.isTouched('projectName')">
                    {{ validation.firstError('projectName') }}
                  </div>
                </div>
              </div>
              <Button
                :disabled="(!isProject && websiteurl === '') || (isProject && !projectName)"
                :loading="loading"
                native-type="submit"
                class="block w-full md:mt-6"
                >{{$t('ui.submit')}}</Button>
            </form>
          </div>
          <!-- <div class="text-center btn-link text-hm-blue cursor-pointer mt-15" @click="goToArticles" v-show="selectedWebsite.id">
            {{$t('ui.goBackToArticleList')}}
          </div> -->
        </div>
      </div>
      <router-link to="/add-project/type" class="mx-auto text-sm -xs block text-center text-hm-blue cursor-pointer mt-6">
        {{$t('ui.goBack')}}
      </router-link>
    </div>
    <div class="steps hidden sm:block sm:fixed left-3 top-auto h-screen">
      <div class="flex items-center h-full">
        <svg xmlns="http://www.w3.org/2000/svg" width="29" height="397" viewBox="0 0 29 397">
          <g id="steps" transform="translate(-24 -311)">
            <g id="Ellipse_91431" data-name="Ellipse 91431" transform="translate(24 311)" stroke="#000" stroke-width="2">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <g id="Ellipse_91432" data-name="Ellipse 91432" transform="translate(24 451)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <g id="Ellipse_91433" data-name="Ellipse 91433" transform="translate(24 601)" fill="#fff" stroke="#000" stroke-width="2" opacity="0.4">
              <circle cx="14.5" cy="14.5" r="14.5" stroke="none"/>
              <circle cx="14.5" cy="14.5" r="13.5" fill="none"/>
            </g>
            <text id="Account" transform="translate(43 403) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500"><tspan x="0" y="0">Account</tspan></text>
            <text id="Payment" transform="translate(43 546) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Payment</tspan></text>
            <text id="Dashboard" transform="translate(43 708) rotate(-90)" fill="#101010" font-size="14" font-family="Inter" font-weight="500" opacity="0.4"><tspan x="0" y="0">Dashboard</tspan></text>
            <text id="_1" data-name="1" transform="translate(35 331)" fill="#fff" font-size="14" font-family="Inter" font-weight="700"><tspan x="0" y="0">1</tspan></text>
            <text id="_2" data-name="2" transform="translate(34 471)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">2</tspan></text>
            <text id="_3" data-name="3" transform="translate(34 621)" fill="#101010" font-size="14" font-family="Inter" font-weight="700" opacity="0.4"><tspan x="0" y="0">3</tspan></text>
            <line id="Line_273" data-name="Line 273" y2="29" transform="translate(37.5 411.5)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
            <line id="Line_274" data-name="Line 274" y2="29" transform="translate(37.5 560)" fill="none" stroke="#000" stroke-width="2" opacity="0.4"/>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;
export default {
  data() {
    return {
      test: 'xxx',
      websiteurl: '',
      fedUrl: null,
      stage: 1,
      showAdvance: false,
      prefered: '',
      rssError: null,
      createError: null,
      showNoWpMessage: false,
      addAsProject: false,
      projectName: null,
      token: '',
      createSiteInput: {
        feedType: 'rss',
        feedUrl: null,
        channelTypes: ['podcast', 'web-player'],
        name: null,
      },
      projectModel: {
        feedLang: 'en-US',
        projectTitle: 'Sample project',
      },
      error: null,
      feed: {},
      loading: false,
      websiteLanguage: null,
      noLanguageError: false,
    };
  },
  validators: {
    projectName: (value) => Validator.value(value).required(),
    // eslint-disable-next-line
    websiteurl: (value) => Validator.value(value).required().regex(/^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i, 'Please enter valid URL'),
  },
  computed: {
    ...mapState({
      isSidebarVisible: (state) => state.ui.isSidebarVisible,
      userId: (state) => state.auth.userId,
      customerId: (state) => state.auth.customerId,
    }),
    isLanguageValid() {
      return this.websiteLanguage !== null;
    },
    type() {
      return this.$route.params.type;
    },
    isCorrctTypeSet() {
      const allowedTypes = [
        'website',
        'project',
      ];
      return allowedTypes.indexOf(this.$route.params.type) !== -1;
    },
    isProject() {
      return this.type === 'project';
    },
  },
  created() {
    this.setSidebarVisibility(false);
  },
  beforeDestroy() {
    this.setSidebarVisibility(true);
  },
  mounted() {
    this.setSidebarVisibility(false);
    console.log('project name route', this.isCorrctTypeSet, this.$route);
    if (!this.isCorrctTypeSet) this.$router.push({ path: '/add-project/type/' });
  },
  methods: {
    ...mapActions({
      createWebsite: 'website/createWebsite',
      getTitle: 'website/getTitle',
      detectWordpress: 'website/detectWordpress',
      detectRssFeed: 'website/detectRssFeed',
      getWebsiteById: 'website/getWebsiteById',
      checkNewSite: 'website/checkNewSite',
      checkRssUrl: 'website/checkRssUrl',
      createProject: 'website/createProject',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
      setWebsiteModel: 'website/setWebsiteModel',
    }),
    async submit() {
      // console.log('submit', this.websiteurl);
      const isValid = await this.$validate('websiteurl');
      const isProjectNameValid = await this.$validate('projectName');
      if (this.isProject && isProjectNameValid) {
        this.projectModel.projectTitle = this.projectName.trim();
        this.setWebsiteModel(this.projectModel);
        this.$router.push({ path: '/add-project/settings/project' });
        return;
      }
      if (!isValid) return;

      this.loading = true;
      const url = this.websiteurl.trim();
      // const titleResult = this.websiteurl; // await this.getTitle({ url });

      const wpResult = await this.checkNewSite(url);
      this.createSiteInput = {
        ...wpResult,
      };
      if (!wpResult.ok) {
        this.error = true;
        this.loading = false;
        return;
      }
      delete this.createSiteInput.ok;
      delete this.createSiteInput.err;
      // console.log('%ccheckNewSite', 'color:lime', wpResult, this.createSiteInput);

      this.setWebsiteModel(this.createSiteInput);
      if (wpResult.feedType === 'wordpress') this.prefered = 'wp';
      if (wpResult.feedType !== 'wordpress') this.showNoWpMessage = true;
      if (wpResult.feedType === 'wordpress' && !wpResult.feedUrl) this.showAdvance = true;
      if (!wpResult.feedUrl) {
        this.showAdvance = true;
        this.prefered = 'rss';
      }
      // if (!wpResult.ok) {
      //   this.goTo('/add-blog-contact');
      //   return;
      // }

      this.websiteLanguage = wpResult.feedLang;
      this.loading = false;
      // this.stage = 2;
      this.$router.push({ path: '/add-project/content/website' });
    },
  },
};
</script>
