<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.pronunciation')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.pronunciation')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <div class="px-4 py-2 text-xs border border-transparent font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block cursor-pointer" v-if="activeTab === 'general'" @click="showAddForm" :class="{disabled: showForm}">{{$t('ui.addAlias')}}</div>
          <!-- <div class="flex items-center space-x-2" v-if="activeTab === 'recommended'">
            <span class="text-sm">{{$t('ui.enableAutoAdd')}}</span>
            <span v-tippy="{content: $t('ui.enableAutoDesc')}">
              <svg data-v-c96a18be="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="h-5 w-5"><path data-v-c96a18be="" fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
            </span>
            <button type="button"
              @click="updateSettings(selectedWebsite)"
              :class="{'bg-gray-200': !blockAutoAdd, 'bg-blue-600': blockAutoAdd}"
              class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false">
              <span class="sr-only"></span>
              <span aria-hidden="true"
                :class="{'translate-x-0': !blockAutoAdd, 'translate-x-5': blockAutoAdd}"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
          </div> -->
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-4">
      <div class="text-sm font-medium text-center text-black border-b border-gray-200">
        <ul class="flex flex-wrap -mb-px">
            <li class="mr-2">
                <a @click="activeTab = 'general'" :class="{'border-b-2 border-black font-bold active': isActive('general')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500">{{$t('ui.general')}}</a>
            </li>
            <li class="mr-2">
                <a @click="activeTab = 'recommended'; markAsRead()" :class="{'border-b-2 border-black font-bold active': isActive('recommended')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500" aria-current="page">{{$t('ui.recommended')}}</a>
            </li>
        </ul>
      </div>
    </div>
    <div v-show="activeTab === 'general'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56" :class="{loading: loading}">
      <!-- SEARCH -->
      <div>
        <div class=" pb-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <input id="search" v-model="searchQuery" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" :placeholder="$t('ui.search')" type="text">
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.utterance')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.pronouncedAs')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-[100px]">
                      {{$t('ui.voice')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.language')}}
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <!-- {{$t('ui.balance')}} -->
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="new-entry" v-if="showForm">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="inputModel.originalText">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap">
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="inputModel.alias">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <div class="relative flex items-start cursor-pointer" @click="inputModel.title = !inputModel.title">
                        <div class="flex h-5 items-center">
                          <div class="h-4 w-4 rounded border-2 border-black text-black focus:ring-black">
                            <div class="h-2 w-2 rounded-sm mt-0.5 ml-0.5 bg-black" v-show="inputModel.title"></div>
                          </div>
                        </div>
                        <div class="ml-3 text-sm">{{$t('ui.title')}} {{$t('ui.voice')}}
                        </div>
                      </div>
                      <div class="relative flex items-start cursor-pointer" @click="inputModel.content = !inputModel.content">
                        <div class="flex h-5 items-center">
                          <div class="h-4 w-4 rounded border-2 border-black text-black focus:ring-black">
                            <div class="h-2 w-2 rounded-sm mt-0.5 ml-0.5 bg-black" v-show="inputModel.content"></div>
                          </div>
                        </div>
                        <div class="ml-3 text-sm">{{$t('ui.content')}} {{$t('ui.voice')}}
                        </div>
                      </div>
                      <div class="relative flex items-start cursor-pointer" @click="inputModel.alternative = !inputModel.alternative">
                        <div class="flex h-5 items-center">
                          <div class="h-4 w-4 rounded border-2 border-black text-black focus:ring-black">
                            <div class="h-2 w-2 rounded-sm mt-0.5 ml-0.5 bg-black" v-show="inputModel.alternative"></div>
                          </div>
                        </div>
                        <div class="ml-3 text-sm">{{$t('ui.alternative')}} {{$t('ui.voice')}}
                        </div>
                      </div>
                    </td>
                    <td class="text-right space-x-1">
                      <Button
                        size="sm"
                        class="px-4 py-2"
                        @click.native="addAlias"
                        :loading="loading"
                        :disabled="!inputModel.originalText">{{$t('ui.save')}}</Button>
                      <Button
                        size="sm"
                        type="default"
                        class="px-4 py-2"
                        @click.native="cancelAddForm">{{$t('ui.cancel')}}</Button>
                    </td>
                  </tr>
                  <tr
                    v-for="(item, index) in filteredVoices" :key="item.nodeIds[0] + index">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.originalText}}</span>
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="item.originalText" v-if="currentlyEdited === item.nodeId">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.alias}}</span>
                      <!-- <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="item.alias" v-if="currentlyEdited === item.nodeId"> -->
                    </td>
                    <td class="px-6 pl-0 py-4 max-w-[100px] text-sm ">
                      <div
                        class="flex items-center"
                        v-for="(voice, index) in item.voices" :key="voice.ttsVoiceName + index">
                        <span
                          class="flex-end mr-2"
                          :class="{loading: loadingPlay === item.nodeId}">
                          <svg
                            @click="play(item, voiceItemById(voice))"
                            xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                          </svg>
                        </span>
                        <span>{{voiceItemById(voice).displayName}}</span>
                      </div>
                    </td>
                    <td class="text-right space-x-1">
                      <Button type="danger" size="sm2" class=""
                        v-if="currentlyEdited !== item.nodeId"
                        @click.native="deleteAlias(item.nodeIds)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="max-w-7xl mx-auto pt-10 divide-y divide-gray-300" v-if="items.length > 0">
        <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
      </div>
      <audio ref="player" controls="controls" id="audioPreview" v-show="false">
        <source :src="audioPreviewUrl" type="audio/mp3" />
      </audio>
      <pre v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612'">
        {{contentVoice}}
        {{feedId}}
        {{selectedWebsite.language}}
        {{customer}}
        {{items.length > 0}}
      </pre>
    </div>
    <div v-show="activeTab === 'recommended'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56" :class="{loading: loading}">
      <!-- PODCAST ENABLE MESSAGE -->
      <div class="podcast-disabled" v-if="recommendations.length === 0">
        <div class="max-w-xl text-center mx-auto my-16 space-y-4">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-16 w-16" viewBox="0 0 24 24"><g><path d="M24,12.5c-.22-2.39-4.1-2.58-6.42-2-1.12.28-1.12,2.42-1.53,2.63-.08,0-8.25.9-9-1.46-.43-1.33-1-2-2.73-1.69-2.66.51-4.2.48-4.29,2.44,0,.49.62,5.13,1.17,5.47a.31.31,0,1,0,.33-.52,44,44,0,0,1-.76-4.51c0-.54,0-1,.34-1.26A6.16,6.16,0,0,1,3.32,11c1.08-.11,2-.48,2.42.09s.56,2.53,1.6,2.78c1.35.31,8,.89,9.22.31.9-.44,1-2.1,1.33-2.53,1.15-.07,4.77-.62,4.94,1a11.77,11.77,0,0,1-.54,2.78c-.05.3-.23,1.33-.5,1.47a43.9,43.9,0,0,1-5.55.37C2.57,17.81,2.4,17.37,2.4,18a.34.34,0,0,0,.35.35c.23,0,9.88,0,15.46-.18,4.41-.13,4.64.05,5.2-2.55A12.53,12.53,0,0,0,24,12.5Z" fill="#fea900"></path><path d="M2.15,9.76A13.34,13.34,0,0,0,3.36,8.27c.35-.46.72-.9,1.08-1.35,3,0,12.69-.29,14.35-.27,1,0,1.05.29,1.58,1.09a10.92,10.92,0,0,0,1.19,1.74c.39.42.92-.11.73-.56a13.38,13.38,0,0,0-1.15-1.83,2.85,2.85,0,0,0-1.3-1.29c-.64-.24.09-.16-14.4.1A5.62,5.62,0,0,0,4.08,6c-.37.12-.54.62-.71.92-.51.87-1.22,1.64-1.66,2.55A.28.28,0,0,0,2.15,9.76Z" fill="#fea900"></path></g></svg>
          </div>
          <div>
            <div class="font-bold text-base">
              {{$t('ui.noRecommendationsTitle')}}
            </div>
            <div class="text-gray-500 text-sm">
              {{$t('ui.noRecommendationsDesc')}}
            </div>
          </div>
        </div>
      </div>
       <!-- SEARCH -->
       <div v-if="recommendations.length > 0">
        <div class=" pb-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <input id="search" v-model="searchQueryRecommended" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" :placeholder="$t('ui.search')" type="text">
          </div>
        </div>
      </div>
      <div class="flex flex-col" v-if="recommendations.length > 0">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.utterance')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.pronouncedAs')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.language')}}
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.voice')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <!-- {{$t('ui.balance')}} -->
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="(item) in filteredRecommendations" :key="item.alias + item.originalText + index">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span>{{item.originalText}}</span>
                      <span v-show="aliasNotifications.indexOf(item.originalText) !== -1" class="inline-flex items-center rounded bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800 ml-2">NEW</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span>{{item.alias}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 max-w-[100px] text-sm ">
                      <div v-for="(voice, index) in item.voices" :key="index + voice.ttsVoiceName + index" class="flex items-center">
                        <span
                          class="flex-end mr-2"
                          :class="{loading: loadingPlay === item.nodeId}">
                          <svg
                            @click="play(item, voiceItemById(voice))"
                            xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                          </svg>
                        </span>
                        <span>{{voiceItemById(voice).displayName}}</span>
                      </div>
                    </td>
                    <td class="text-right space-x-1">
                      <div class=" flex items-center justify-end space-x-1">
                        <Button type="danger" size="sm2" class=""
                          @click.native="resetRecomendation(item)">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                        </Button>
                        <div class="flex items-center space-x-1 justify-end"  v-show="recommendationsHelper[item.feedId + item.alias + item.originalText] && recommendationsHelper[item.feedId + item.alias + item.originalText].showAddForm">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" width="12.818" height="12.539" viewBox="0 0 12.818 12.539">
                            <g id="Group_5622" data-name="Group 5622" transform="translate(-35.659 0.02)">
                              <path id="Path_43733" data-name="Path 43733" d="M23.856,2.261a6.449,6.449,0,0,0-10.322.644c-1.83,2.7-.881,6.652.937,7.827a.339.339,0,0,0,.373-.565c-1.446-1.016-2.1-4.517-.463-6.652a5.444,5.444,0,0,1,8.482-.44c2.677,3.174,1.129,8-3.625,8.549a6.245,6.245,0,0,1-2.586-.181.384.384,0,0,0-.237.723,6.979,6.979,0,0,0,2.9.316,6.178,6.178,0,0,0,4.54-10.221Z" transform="translate(23.113 0)" fill="#22c55d" fill-rule="evenodd"/>
                              <path id="Path_43734" data-name="Path 43734" d="M17.071,8.52c1.31.068,2.778-3.467,3.32-4.619a1.129,1.129,0,0,0,.3-.644.4.4,0,0,0-.723-.237c-.271.316-3.027,4.325-2.959,4.416-.215,0-1.321-1.287-1.491-1.4s-.644.192-.4.576C15.569,7.312,16.325,8.475,17.071,8.52Z" transform="translate(23.438 0.371)" fill="#22c55d" fill-rule="evenodd"/>
                            </g>
                          </svg>
                          <span class="font-bold text-green-500 text-sm">{{$t('ui.added')}}</span>
                        </div>
                        <div
                          v-show="recommendationsHelper[item.feedId + item.alias + item.originalText] && !recommendationsHelper[item.feedId + item.alias + item.originalText].showAddForm"
                          class="px-4 py-1.5 text-xs border border-transparent font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block cursor-pointer"
                          @click="addRecomendation(item)">{{$t('ui.add')}}</div>
                      </div>
                    </td>
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
// import _ from 'lodash';
import moment from 'moment';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      moment,
      // items: [
      //   {
      //     date: new Date(),
      //     operation: 'Credits added',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     operation: 'Article generated',
      //     amount: -12,
      //     balance: 88,
      //   },
      // ],
      activeTab: 'general',
      copy: {},
      loading: false,
      audioPreviewUrl: null,
      loadingPlay: false,
      currentlyEdited: null,
      showForm: false,
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      selectedOrder: 'CREATED_AT',
      orderDirString: 'DESC',
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      inputModel: {
        alias: '',
        originalText: null,
        feedIds: null,
        title: true,
        content: true,
        alternative: true,
        // language: 'en-US',
      },
      blockAutoAdd: false,
      contentVoice: {},
      titleVoice: {},
      totalCount: 0,
      searchQuery: '',
      searchQueryRecommended: '',
      languages: [
        {
          id: 0,
          name: 'All',
          code: 'all',
        },
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedWebsite: (state) => state.website.selectedWebsite,
      userId: (state) => state.auth.userId,
      items: (state) => state.alias.list,
      customer: (state) => state.auth.customer,
      voices: (state) => state.utils.voiceList,
      recommendations: (state) => state.alias.recommendations,
      recommendationsHelper: (state) => state.alias.recommendationsHelper,
      templates: (state) => state.website.selectedWebsitePodcastTemplates,
      selectedWebsiteLanguage: (state) => state.website.selectedWebsiteLanguage,
      customerMetadata: (state) => state.customer.customerMetadata,
      notifiactionsByFeedId: (state) => state.utils.notifiactionsByFeedId,
    }),
    aliasNotifications() {
      return this.feedNotifications
        .filter((item) => item.text.startsWith('New alias suggestion') && !item.readAt)
        .map((item) => {
          const alias = item.text.replace('New alias suggestion for ', '');
          return alias;
        });
    },
    feedNotifications() {
      return this.notifiactionsByFeedId[this.websiteId] || [];
    },
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    feedId() {
      return this.selectedWebsite.id;
    },
    websiteId() {
      return this.selectedWebsite ? this.selectedWebsite.id : null;
    },
    voiceId() {
      return (item) => {
        const kind = item.ttsVoiceKind ? `-${item.ttsVoiceKind}` : '';
        return `${item.ttsVoiceName}-${item.mainLanguage}${kind}`;
      };
    },
    voiceItemById() {
      return (voiceSettings) => this.voices.find((el) => this.voiceId(el) === this.voiceId(voiceSettings)) || {};
    },
    filteredVoices() {
      const helper = {};
      const aliases = this.items.reduce((r, o) => {
        const key = `${o.alias}-${o.originalText}`;

        if (!helper[key]) {
          // eslint-disable-next-line
          helper[key] = Object.assign({}, o); // create a copy of o
          helper[key].voices = [];
          helper[key].nodeIds = [];
          helper[key].voices.push(o.voice);
          helper[key].nodeIds.push(o.nodeId);
          r.push(helper[key]);
        } else {
          helper[key].voices.push(o.voice);
          helper[key].nodeIds.push(o.nodeId);
        }
        return r;
      }, []);

      console.log('result group', aliases);

      if (this.searchQuery) {
        return aliases
          .filter((item) => item.alias.toLowerCase().includes(this.searchQuery.toLowerCase()) || item.originalText.toLowerCase().includes(this.searchQuery.toLowerCase()))
          .slice(this.offset, this.offset + this.pageSize);
      }
      console.log('offset', this.offset, this.pageSize, this.page);
      return aliases.slice(this.offset, this.offset + this.pageSize);
    },
    filteredRecommendations() {
      if (this.searchQueryRecommended) {
        return this.recommendations
          .filter((item) => item.alias.toLowerCase().includes(this.searchQueryRecommended.toLowerCase()) || item.originalText.toLowerCase().includes(this.searchQueryRecommended.toLowerCase()));
      }
      return this.recommendations;
    },
    languageName() {
      return (code) => {
        const [lang] = this.languages.filter((el) => el.code === code);
        if (!lang) return { name: '' };
        return lang;
      };
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    defaultTemplate() {
      if (!this.templates || this.templates.length === 0) return { episodeTemplatePartsByEpisodeTemplateId: {} };
      // console.log('templates', this.templates);
      return this.templates[0].episodeTemplateByEpisodeTemplateId || {};
    },
    templeParts() {
      return this.defaultTemplate.episodeTemplatePartsByEpisodeTemplateId.nodes || [];
    },
    contentPart() {
      return this.templeParts.find((el) => el.displayName === 'Content');
    },
    titlePart() {
      return this.templeParts.find((el) => el.displayName === 'Title');
    },
    queryParams() {
      const input = {
        // first: 25,
        orderBy: this.orderByString,
        // offset: this.offset,
        condition: {
          feedIds: this.selectedWebsite.id,
        },
      };

      // if (this.searchQuery) {
      //   input.condition.originalText = {
      //     _: this.searchQuery,
      //   };
      // }

      return input;
    },
    isNegative() {
      return (item) => item.creditsAfter - item.creditsBefore;
    },
  },
  created() {
    // this.getWebsiteById(this.websiteId);
  },
  watch: {
    activeTab() {
      this.searchQuery = '';
    },
    // eslint-disable-next-line
    searchQuery: function (newVal) {
      if (newVal === '') this.totalCount = this.items.length;
      else this.totalCount = this.filteredVoices.length;
      this.page = 1;
      this.offset = 0;
    },
    customerMetadata(customer) {
      if (!customer || !customer.metadata) {
        this.blockAutoAdd = false;
        return;
      }
      const { metadata } = customer;
      this.blockAutoAdd = metadata.blockedAutoAddAlias;
    },
    // eslint-disable-next-line
    customer: function () {
      // this.clearState();
      this.loadData(this.page);
    },
    // eslint-disable-next-line
    contentPart: function(newVal) {
      this.contentVoice = newVal.settings;
    },
    // eslint-disable-next-line
    titlePart: function(newVal) {
      this.titleVoice = newVal.settings;
    },
    selectedWebsite() {
      // console.log('no website details', val);
      // const { settings } = this.selectedWebsite;
      // this.blockAutoAdd = settings.blockedAutoAddAlias;

      this.loadData(1);
      // this.getWebsiteById(this.websiteId);
    },
  },
  async mounted() {
    await this.loadVoices();
    await this.getWebsiteById(this.websiteId);
    this.loadData(1);
  },
  methods: {
    ...mapActions({
      fetch: 'alias/fetch',
      fetchRecommendations: 'alias/fetchRecommendations',
      createAlias: 'alias/createAlias',
      saveTtsAlias: 'alias/saveTtsAlias',
      delete: 'alias/deleteAlias',
      playAlias: 'alias/playAlias',
      fetchVoice: 'utils/fetchVoices',
      getWebsiteById: 'website/getWebsiteById',
      updateWebsite: 'website/updateWebsite',
      markNotificationsAsRead: 'utils/markNotificationsAsRead',
      updateTtsAlias: 'alias/updateTtsAlias',
    }),
    ...mapMutations({
      clearState: 'billing/clearState',
    }),
    async deleteAlias(ids) {
      this.loading = true;
      // eslint-disable-next-line
      for await (const id of ids) {
        const result = await this.delete(id);
        console.log('deleteAlias result', result, id);
      }

      this.refresh();
      this.loading = false;
    },
    markAsRead() {
      const notifIds = this.feedNotifications
        .filter((item) => item.text.startsWith('New alias suggestion'))
        .map((item) => item.id);
      console.log('aliasNotifications', this.feedNotifications, notifIds);
      setTimeout(() => {
        this.markNotificationsAsRead(notifIds);
      }, 5000);
    },
    async markAllNotificationsAsRead() {
      await this.markNotificationsAsRead();
    },
    async loadVoices() {
      if (!this.selectedWebsiteLanguage) return;
      if (this.selectedWebsiteLanguage === 'en-EN') this.selectedWebsiteLanguage = 'en-US';
      await this.fetchVoice(this.selectedWebsiteLanguage);
    },
    async addRecomendation(alias) {
      this.loading = true;
      const input = {
        alias: alias.alias,
        originalText: alias.originalText,
        feedIds: null,
      };
      input.customerId = this.customer;
      input.feedIds = this.feedId;
      // input.language = this.selectedWebsite.language;
      let isOk = true;
      // eslint-disable-next-line
      for await (const voice of alias.voices) {
        const data = {
          ...input,
          voice: {
            mainLanguage: voice.mainLanguage,
            ttsPlatform: voice.ttsPlatform,
            ttsVoiceName: voice.ttsVoiceName,
          },
        };
        if (voice.ttsVoiceKind) data.voice.ttsVoiceKind = voice.ttsVoiceKind;
        console.log('voice', voice, data);
        const result = await this.createAlias(data);
        if (!result.ttsAlias || !result.ttsAlias.alias) isOk = false;
      }
      this.loading = false;
      if (isOk) this.recommendationsHelper[alias.feedId + alias.alias + alias.originalText].showAddForm = true;
      if (!isOk) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
      }

      console.log('create alias result');
      setTimeout(() => {
        this.refresh();
      }, 1000);
    },
    async addAlias() {
      this.loading = true;
      const input = {
        customerId: this.customer,
        feedIds: this.feedId,
        originalText: this.inputModel.originalText,
        alias: this.inputModel.alias,
      };
      input.customerId = this.customer;
      input.feedIds = this.feedId;
      // input.language = this.selectedWebsite.language;
      if (this.inputModel.content) {
        input.voice = {
          mainLanguage: this.contentVoice.mainLanguage,
          ttsPlatform: this.contentVoice.ttsPlatform,
          ttsVoiceName: this.contentVoice.ttsVoiceName,
        };
        if (this.contentVoice.ttsVoiceKind) input.voice.ttsVoiceKind = this.contentVoice.ttsVoiceKind;
        await this.createAlias(input);
      }

      if (this.inputModel.alternative) {
        input.voice = {
          mainLanguage: this.contentVoice.mainLanguage,
          ttsPlatform: this.contentVoice.alternateTtsPlatform,
          ttsVoiceName: this.contentVoice.alternateTtsVoiceName,
        };
        if (this.contentVoice.alternateTtsVoiceKind) input.voice.ttsVoiceKind = this.contentVoice.alternateTtsVoiceKind;
        await this.createAlias(input);
      }

      if (this.inputModel.title) {
        input.voice = {
          mainLanguage: this.titleVoice.mainLanguage,
          ttsPlatform: this.titleVoice.ttsPlatform,
          ttsVoiceName: this.titleVoice.ttsVoiceName,
        };
        if (this.titleVoice.ttsVoiceKind) input.voice.ttsVoiceKind = this.titleVoice.ttsVoiceKind;
        await this.createAlias(input);
      }

      console.log('create alias result');
      this.refresh();
      this.loading = false;
      this.cancelAddForm();
    },
    cancelAddForm() {
      this.inputModel = {
        alias: '',
        origianlText: null,
        title: true,
        content: true,
        alternative: true,
        // language: 'en-US',
      };
      this.showForm = false;
    },
    edit(item) {
      // console.log('edit', item);
      this.copy = JSON.parse(JSON.stringify(item));
      this.currentlyEdited = item.nodeId;
    },
    cancelEdit(item) {
      let el = item;
      el = this.copy;
      console.log(el);
      this.currentlyEdited = null;
    },
    goTo(path) {
      this.$router.push({ path });
    },
    showAddForm() {
      this.inputModel = {
        alias: '',
        originalText: null,
        title: true,
        content: true,
        alternative: true,
        // language: 'en-US',
      };
      this.showForm = true;
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },
    async updateSettings() {
      const feedPatch = {
        settings: this.selectedWebsite.settings,
      };

      feedPatch.settings.blockedAutoAddAlias = this.blockAutoAdd;

      const input = {
        ...feedPatch,
        id: this.selectedWebsite.id,
      };
      // console.log('update website', this.feedPatch);
      const result = await this.updateWebsite(input);
      console.log('result', result);
    },

    async refresh() {
      this.loadData(this.page);
    },

    async loadData(page) {
      this.loading = true;
      if (!this.feedId) return;
      this.offset = page * this.pageSize - this.pageSize;

      // console.log('page', page, this.queryParams);
      await this.fetch(this.queryParams);
      const helper = {};
      const aliases = this.items.reduce((r, o) => {
        const key = `${o.alias}-${o.originalText}`;

        if (!helper[key]) {
          // eslint-disable-next-line
          helper[key] = Object.assign({}, o); // create a copy of o
          helper[key].voices = [];
          helper[key].nodeIds = [];
          helper[key].voices.push(o.voice);
          helper[key].nodeIds.push(o.nodeId);
          r.push(helper[key]);
        } else {
          helper[key].voices.push(o.voice);
          helper[key].nodeIds.push(o.nodeId);
        }
        return r;
      }, []);
      this.totalCount = aliases.length;
      await this.fetchRecommendations({
        customerId: this.customer,
        feedId: this.feedId,
      });
      this.loading = false;
    },

    // player stuff
    async play(item, voice) {
      this.loadingPlay = item.nodeId;
      const {
        ttsPlatform,
        ttsVoiceKind,
        ttsVoiceName,
        mainLanguage,
      } = voice;
      const input = {
        alias: item.alias,
        originalText: item.originalText,
        voice: {
          ttsVoiceKind,
          ttsPlatform,
          ttsVoiceName,
          mainLanguage,
        },
      };

      const result = await this.playAlias(input);

      this.loadingPlay = false;
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      // this.playing = 0;
      this.audioPreviewUrl = result.url;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },

    async resetRecomendation(alias) {
      this.loading = true;
      console.log('resetRecomendation', this.customer, this.feedId, alias);
      await this.asyncForEach(alias.voices, async (voice) => {
        const input = {
          editorAliasGenerationUsagePatch: {
            usageCounter: 0,
          },
          originalText: alias.originalText,
          customerId: this.customer,
          feedId: this.feedId,
          voice: {},
        };
        input.voice = {
          mainLanguage: voice.mainLanguage,
          ttsPlatform: voice.ttsPlatform,
          ttsVoiceName: voice.ttsVoiceName,
        };
        if (voice.ttsVoiceKind) input.voice.ttsVoiceKind = voice.ttsVoiceKind;
        await this.updateTtsAlias(input);
      });
      setTimeout(() => {
        this.refresh();
      }, 500);
    },
    async asyncForEach(array, callback) {
      const { length } = array;
      /* eslint-disable no-await-in-loop */
      for (let index = 0; index < length; index += 1) {
        await callback(array[index], index, array);
      }
    },
  },
};
</script>
