/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../../graphql';
/**
 * Build styles
 */
 require('./image.css');

 /**
  * SimpleImage Tool for the Editor.js
  * Works only with pasted image URLs and requires no server-side uploader.
  *
  * @typedef {object} SimpleImageData
  * @description Tool's input and output data format
  * @property {string} url — image URL
  * @property {string} caption — image caption
  * @property {boolean} withBorder - should image be rendered with border
  * @property {boolean} generated - was generated by ai
  * @property {boolean} withBackground - should image be rendered with background
  * @property {boolean} stretched - should image be stretched to full width of container
  */
 export default class SimpleImage {
   /**
    * Render plugin`s main Element and fill it with saved data
    *
    * @param {{data: SimpleImageData, config: object, api: object}}
    *   data — previously saved data
    *   config - user config for Tool
    *   api - Editor.js API
    *   readOnly - read-only mode flag
    */
   constructor({ data, config, api, readOnly }) {
     /**
      * Editor.js API
      */
     this.api = api;
     this.readOnly = readOnly;
 
     /**
      * When block is only constructing,
      * current block points to previous block.
      * So real block index will be +1 after rendering
      *
      * @todo place it at the `rendered` event hook to get real block index without +1;
      * @type {number}
      */
     this.blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;
 
     /**
      * Styles
      */
     this.CSS = {
       baseClass: this.api.styles.block,
       loading: this.api.styles.loader,
       input: this.api.styles.input,
       settingsButton: this.api.styles.settingsButton,
       settingsButtonActive: this.api.styles.settingsButtonActive,
 
       /**
        * Tool's classes
        */
       wrapper: 'cdx-simple-image',
       imageHolder: 'cdx-simple-image__picture',
       caption: ['cdx-simple-image__caption', 'content-wrapper'],
     };
 
     /**
      * Nodes cache
      */
     this.nodes = {
       wrapper: null,
       imageHolder: null,
       image: null,
       caption: null,
     };
 
     /**
      * Tool's initial data
      */
     this.data = {
       url: data.url || '',
       caption: data.caption || '',
       generated: data.generated !== undefined ? data.generated : false,
       withBorder: data.withBorder !== undefined ? data.withBorder : false,
       withBackground: data.withBackground !== undefined ? data.withBackground : false,
       stretched: data.stretched !== undefined ? data.stretched : false,
     };
 
     /**
      * Available Image settings
      */
     this.settings = [
     ];
   }
 
   /**
    * Creates a Block:
    *  1) Show preloader
    *  2) Start to load an image
    *  3) After loading, append image and caption input
    *
    * @public
    */
   render() {
    const isGenerated = this.data.generated;
     const wrapper = this._make('div', [this.CSS.baseClass, this.CSS.wrapper]),
         loader = this._make('div', this.CSS.loading),
         imageHolder = this._make('div', this.CSS.imageHolder),
         image = this._make('img'),
         retryBtn = this._make('div', ['hidden', 'cdx-button', 'mr-1', 'relative', '-top-[42px]', 'float-right', 'shadow-none', 'border-0', 'bg-black', 'text-white', 'rounded-md', 'p-2', 'w-32', 'text-center', 'hover:bg-yellow-500', 'cursor-pointer', 'transition-colors']),
         caption = this._make('div', [...this.CSS.caption, this.CSS.input], {
           contentEditable: !isGenerated,
           innerHTML: this.data.caption || '',
         });
     caption.dataset.placeholder = 'Enter a caption';
     retryBtn.innerText = 'Retry';
     console.log('image', isGenerated, this.data);
     wrapper.appendChild(loader);
 
     if (this.data.url) {
       image.src = this.data.url;
     }
 
     image.onload = () => {
       wrapper.classList.remove(this.CSS.loading);
       imageHolder.appendChild(image);
       wrapper.appendChild(imageHolder);
       wrapper.appendChild(caption);
       wrapper.appendChild(retryBtn);
       loader.remove();
       this._acceptTuneView();
     };

     if (isGenerated) {
      retryBtn.addEventListener('click', () => {
        this.reGenerateImage();
       });
      const decodedUrl = decodeURIComponent(this.data.url);
      let date =  decodedUrl.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/);
      if (date && Array.isArray(date)) {
        date = date[0];
      }

      console.log('image date', this.data.url, date, decodedUrl.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/));
      const finalImgUrl = this.data.url.replace('.png', '-final.png');
      // this.checkImage(finalImgUrl);
      this.checkImageExistenceOrElapsedTime(date, finalImgUrl);
     }
 
     image.onerror = (e) => {
       // @todo use api.Notifies.show() to show error notification
       // console.log('Failed to load an image', e);
     };
 
     this.nodes.imageHolder = imageHolder;
     this.nodes.wrapper = wrapper;
     this.nodes.image = image;
     this.nodes.caption = caption;
     this.nodes.retryBtn = retryBtn;
 
     return wrapper;
   }

   getDateAndFinalImage() {
    const decodedUrl = decodeURIComponent(this.data.url);
    let date =  decodedUrl.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/);
    if (date && Array.isArray(date)) {
      date = date[0];
    }

    console.log('image getDateAndFinalImage', this.data.url, date, decodedUrl.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/));
    const finalImgUrl = this.data.url.replace('.png', '-final.png');
    // this.checkImage(finalImgUrl);
    return {date, finalImgUrl };
   }

   checkImage(url) {
    const image = new Image();
    image.onload = function() {
      if (this.width > 0) {
        console.log("image exists");
      }
    }
    image.onerror = function() {
      console.log("image doesn't exist");
    }
    image.src = url;
  }
  secondsBetweenNowAndDatetime(datetime) {
    const datetimeUTC = Date.parse(datetime);
    const datetimeLocal = new Date(datetimeUTC);
    const nowLocal = new Date();
  
    // Calculate the difference in seconds between the datetime and now
    const differenceInSeconds = Math.floor((nowLocal - datetimeLocal) / 1000);
    
    const offsetInSeconds = nowLocal.getTimezoneOffset() * 60;
    return differenceInSeconds + offsetInSeconds;
  }

  checkImageExistenceOrElapsedTime(date, imageUrl) {
    const elapsedTimeInSeconds = this.secondsBetweenNowAndDatetime(date);
  
    // Check if the image exists
    const img = new Image();
    img.onload = () => {
      console.log(`image loaded: ${imageUrl}`);
      this.nodes.image.src = imageUrl;
    };
    img.onerror = () => {
      console.log(`image not loaded: ${elapsedTimeInSeconds}`);
      // If image doesn't exist, start checking elapsed time
      if (elapsedTimeInSeconds < 60) {
        setTimeout(() => {
          this.checkImageExistenceOrElapsedTime(date, imageUrl);
        }, 5000 );
        return;
      }
      this.nodes.retryBtn.classList.remove('hidden');
    };
    img.src = imageUrl;
  }

  extractFilenameFromUrl(url) {
    let decodedUrl = decodeURIComponent(url);
    const queryIndex = decodedUrl.indexOf('?');
    if (queryIndex !== -1) {
      decodedUrl = decodedUrl.substring(0, queryIndex);
    }
    const lastSlashIndex = decodedUrl.lastIndexOf('/');
    return decodedUrl.substring(lastSlashIndex + 1);
  }
  
  async reGenerateImage() {
    this.nodes.retryBtn.classList.add('loading');
    console.log('reGenerateImage', this.data);
    const { caption, url } = this.data;
    const payload = {
      prompt: caption,
      fileName: this.extractFilenameFromUrl(url),
    };
    const response = await API.graphql(
      graphqlOperation(
        GQL.RecrateImageFromText,
        { input: payload },
      ),
    );
    this.nodes.retryBtn.classList.remove('loading');
    const { date, finalImgUrl } = this.getDateAndFinalImage();
    this.checkImageExistenceOrElapsedTime(date, finalImgUrl);
  }
 
   /**
    * @public
    * @param {Element} blockContent - Tool's wrapper
    * @returns {SimpleImageData}
    */
   save(blockContent) {
     const image = blockContent.querySelector('img'),
         caption = blockContent.querySelector('.' + this.CSS.input);
 
     if (!image) {
       return this.data;
     }
 
     return Object.assign(this.data, {
       url: image.src,
       caption: caption.innerHTML,
     });
   }
 
   /**
    * Sanitizer rules
    */
   static get sanitize() {
     return {
       url: {},
       withBorder: {},
       generated: {},
       withBackground: {},
       stretched: {},
       caption: {
         br: true,
       },
     };
   }
 
   /**
    * Notify core that read-only mode is suppoorted
    *
    * @returns {boolean}
    */
   static get isReadOnlySupported() {
     return true;
   }
 
   /**
    * Read pasted image and convert it to base64
    *
    * @static
    * @param {File} file
    * @returns {Promise<SimpleImageData>}
    */
   onDropHandler(file) {
     const reader = new FileReader();
 
     reader.readAsDataURL(file);
 
     return new Promise(resolve => {
       reader.onload = (event) => {
         resolve({
           url: event.target.result,
           caption: file.name,
         });
       };
     });
   }
 
   /**
    * On paste callback that is fired from Editor.
    *
    * @param {PasteEvent} event - event with pasted config
    */
   onPaste(event) {
     switch (event.type) {
       case 'tag': {
         const img = event.detail.data;
 
         this.data = {
           url: img.src,
         };
         break;
       }
 
       case 'pattern': {
         const { data: text } = event.detail;
 
         this.data = {
           url: text,
         };
         break;
       }
 
       case 'file': {
         const { file } = event.detail;
 
         this.onDropHandler(file)
           .then(data => {
             this.data = data;
           });
 
         break;
       }
     }
   }
 
   /**
    * Returns image data
    *
    * @returns {SimpleImageData}
    */
   get data() {
     return this._data;
   }
 
   /**
    * Set image data and update the view
    *
    * @param {SimpleImageData} data
    */
   set data(data) {
     this._data = Object.assign({}, this.data, data);
 
     if (this.nodes.image) {
       this.nodes.image.src = this.data.url;
     }
 
     if (this.nodes.caption) {
       this.nodes.caption.innerHTML = this.data.caption;
     }
   }
 
   /**
    * Specify paste substitutes
    *
    * @see {@link ../../../docs/tools.md#paste-handling}
    * @public
    */
   static get pasteConfig() {
     return {
       patterns: {
         image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png|webp)$/i,
       },
       tags: [ 'img' ],
       files: {
         mimeTypes: [ 'image/*' ],
       },
     };
   }
 
   /**
    * Makes buttons with tunes: add background, add border, stretch image
    *
    * @returns {HTMLDivElement}
    */
   renderSettings() {
     const wrapper = document.createElement('div');
 
     this.settings.forEach(tune => {
       const el = document.createElement('div');
 
       el.classList.add(this.CSS.settingsButton);
       el.innerHTML = tune.icon;
 
       el.addEventListener('click', () => {
         this._toggleTune(tune.name);
         el.classList.toggle(this.CSS.settingsButtonActive);
       });
 
       el.classList.toggle(this.CSS.settingsButtonActive, this.data[tune.name]);
 
       wrapper.appendChild(el);
     });
 
     return wrapper;
   };
 
   /**
    * Helper for making Elements with attributes
    *
    * @param  {string} tagName           - new Element tag name
    * @param  {Array|string} classNames  - list or name of CSS classname(s)
    * @param  {object} attributes        - any attributes
    * @returns {Element}
    */
   _make(tagName, classNames = null, attributes = {}) {
     const el = document.createElement(tagName);
 
     if (Array.isArray(classNames)) {
       el.classList.add(...classNames);
     } else if (classNames) {
       el.classList.add(classNames);
     }
     for (const attrName in attributes) {
       el[attrName] = attributes[attrName];
     }
 
     return el;
   }
 
   /**
    * Click on the Settings Button
    *
    * @private
    * @param tune
    */
   _toggleTune(tune) {
     this.data[tune] = !this.data[tune];
     this._acceptTuneView();
   }
 
   /**
    * Add specified class corresponds with activated tunes
    *
    * @private
    */
   _acceptTuneView() {
     this.settings.forEach(tune => {
       this.nodes.imageHolder.classList.toggle(this.CSS.imageHolder + '--' + tune.name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`), !!this.data[tune.name]);
 
       if (tune.name === 'stretched') {
         this.api.blocks.stretchBlock(this.blockIndex, !!this.data.stretched);
       }
     });
   }
 }
 