/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import GQL from '../graphql';

const getDefaultState = () => {
  console.log('clear state for customer');
  return {
    name: 'Customer',
    customerId: null,
    customer: null,
    userProfile: {},
    userCustomer: {},
    credits: 0,
    combinedCredits: 0,
    additionalCredits: 0,
    reservedCredits: 0,
    customerMetadata: {},

    // BILLING HISTORY
    billingHistory: [],
    hasNextPage: true,
    pageInfo: {},
    loadings: {},
    count: 0,
    subscriptionStatus: {
      isTrial: false,
      subDay: 1,
      plan: '',
      params: {},
      daysToEnd: 0,
    },
  };
};

const state = getDefaultState();

const getters = {
};

const mutations = {
  clearState: (state) => {
    Object.assign(state, getDefaultState());
    console.log('clearstate');
  },
  setSubscriptionStatus: (state, payload) => {
    const isTrial = payload && payload.plan === 'Trial';
    const isActive = payload.active;
    const pendingPlanUpdate = payload.params ? payload.params.pendingUpdate : {};
    const subDay = moment(payload.endDate).format('DD');
    const a = moment(payload.endDate);
    const b = moment();
    // subDay() {
    //   if (!this.subDate || !payload.endDate) return 'xxx';
    //   const [datePart] = payload.endDate.split(' ');
    //   const [year, month, day] = datePart.split('-');
    //   console.log('year, month', year, month, day, datePart);
    //   return day;
    // },
    const subStatus = {
      ...payload,
      isTrial,
      isActive,
      pendingPlanUpdate,
      subDay,
      daysToEnd: a.diff(b, 'days'),
    };
    Vue.set(state, 'subscriptionStatus', subStatus);
    console.log('sub status', subStatus);
    // eslint-disable-next-line
    $crisp.push(['set', "session:data", [[['sub-status', payload.active], ['sub-plan', `${payload.plan} ${payload.interval}`], ['sub-end', payload.endDate]]]]);
  },
  setUserCustomer: (state, payload) => {
    Vue.set(state, 'userCustomer', payload);
    Vue.set(state, 'credits', payload.credits);
    Vue.set(state, 'additionalCredits', payload.additionalCredits);
    Vue.set(state, 'customerMetadata', payload.metadata);
    Vue.set(state, 'billingDetails', payload.metadata.billingDetails);
    Vue.set(state, 'reservedCredits', payload.reservedCredits);
    const combinedCredits = parseInt(payload.credits, 10) + parseInt(payload.additionalCredits, 10);
    Vue.set(state, 'combinedCredits', combinedCredits);
    localStorage.setItem('c', combinedCredits);
    console.log('set Customer', state);
  },
  setCredits: (state, payload) => {
    Vue.set(state, 'credits', payload.credits);
    Vue.set(state, 'reservedCredits', payload.reservedCredits);
    Vue.set(state, 'additionalCredits', payload.additionalCredits);
    const combinedCredits = parseInt(payload.credits, 10) + parseInt(payload.additionalCredits, 10);
    Vue.set(state, 'combinedCredits', combinedCredits);
    localStorage.setItem('c', combinedCredits);
  },

  setBillingHistory: (state, payload) => {
    Vue.set(state, 'billingHistory', payload);
  },
};

const actions = {
  async fetchUser({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchUser, {
          id: payload,
        }),
      );
      commit('setUserProfile', response.data.userById);
      return response.data.userById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomer({ commit }, payload) {
    if (!payload) return {};
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const { customerById } = response.data;
      commit('setUserCustomer', customerById);
      commit('setSubscriptionStatus', customerById.metadata.subscription);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // // console.log('fetchCustomer', customerById);
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // // console.log('fetchCustomer err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async updateCustomer({ commit }, payload) {
    if (!payload) return {};
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.UpdateCustomer, { input: payload }),
      );
      const { customer } = response.data.updateCustomerById;
      commit('setUserCustomer', customer);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // // console.log('fetchCustomer', customerById);
      return { ok: true, message: customer };
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // // console.log('fetchCustomer err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCredits({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const { customerById } = response.data;
      commit('setCredits', customerById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log('fetchCredits', customerById);
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },

  async addCredits({ dispatch }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreditsAdd, {
          input: payload,
        }),
      );
      const { addCredits } = response.data;
      await dispatch('fetchCustomer', payload.customerId);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return addCredits;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async removeCredits({ dispatch }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreditsRemove, {
          input: payload,
        }),
      );
      const { addCredits } = response.data;
      await dispatch('fetchCustomer', payload.customerId);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return addCredits;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
