<template>
  <div class="text-black border-2 border-black rounded-xl bg-white relative flex flex-col justify-between md:w-64 h-full min-h-[425px] max-h-[500px]"
    :class="{'border-black': current}">
    <div>
      <div class="p-6 pb-0">
        <h2 class="text-base leading-6 font-bold">
          {{name}}
          <span class="absolute text-xs font-bold bg-black inline-block text-white py-1 px-3 rounded -top-3 left-6" v-show="current">{{$t('ui.currentPlan')}}</span>
        </h2>
        <p class="mt-3 text-xs">{{desc}}</p>
        <p class="mt-6">
          <span class="text-2xl font-extrabold">{{displayPrice}}</span>
          <span class="text-sm font-medium" v-show="isMonthly === 'month'">/{{$t('ui.monthShorcut')}}</span>
          <span class="text-sm font-medium" v-show="isMonthly === 'year'">/{{$t('ui.yearShortcut')}}</span>
        </p>
      </div>
      <div class="pt-6 pb-4 px-6">
        <hr class="border-t-2 border-black mb-6">
        <h3 class="text-xs font-bold tracking-wide">{{$t('ui.packageWhatsIncluded')}}</h3>
        <ul role="list" class="mt-3 mb-3 space-y-2">
          <li class="flex space-x-3" v-for="feature in features" :key="feature.name">
            <!-- Heroicon name: solid/check -->
            <svg class="flex-shrink-0 h-5 w-5 text-hm-green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
            </svg>
            <span class="text-xs" v-html="$t(`packages.${feature.name}`, [...feature.value])">{{}}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="px-6 pb-6 h-8">
      <div class="-mt-6"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PackageSmall',
  props: {
    package: {
      type: Object,
      default: () => {},
    },
    features: {
      type: Array,
      default: () => [],
    },
    price: {
      type: Number,
    },
    name: {
      type: String,
    },
    desc: {
      type: String,
    },
    promo: {
      type: Number,
    },
    isMonthly: {
      type: String,
    },
    symbol: {
      type: String,
    },
    current: {
      type: Boolean,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    displayPrice() {
      const price = this.selectedPackage.unit_amount / 100;
      const isInt = Number.isInteger(price);
      const priceString = isInt ? `${this.symbol}${price}.00` : `${this.symbol}${price}`;
      return priceString;
    },
    selectedPackage() {
      return this.package[this.isMonthly];
    },
  },
};
</script>
