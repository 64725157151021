<template>
  <div>
    <header class="py-8">
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.websiteSettings')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.websiteSettings')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-sm font-medium text-center text-black border-b border-gray-200">
        <ul class="flex flex-wrap -mb-px">
            <router-link :to="`/website-settings/${websiteId}/general`" tag="li" class="mr-2 border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500"
              exact-active-class="border-b-2 border-black font-bold active">
                <a @click="activeTab = 'general'"  class="inline-block p-4 rounded-t-lg cursor-pointer ">{{$t('ui.general')}}</a>
            </router-link>
            <router-link :to="`/website-settings/${websiteId}/podcast`" tag="li" class="mr-2 border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500"
              exact-active-class="border-b-2 border-black font-bold active">
                <a @click="activeTab = 'podcast'" class="inline-block p-4 rounded-t-lg cursor-pointer " aria-current="page">{{$t('ui.podcast')}}</a>
            </router-link>
            <router-link :to="`/website-settings/${websiteId}/wordpress`" v-show="selectedWebsite.type === 'WORDPRESS'" tag="li" class="mr-2 border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500"
              exact-active-class="border-b-2 border-black font-bold active">
                <a @click="activeTab = 'wordpress'"  class="inline-block p-4 rounded-t-lg cursor-pointer ">{{$t('ui.wordpress')}}</a>
            </router-link>
        </ul>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      loadingDelete: false,
      confirmPassword: null,
      password: null,
      saveError: null,
      oldPassword: null,
      errorMessage: null,
      showGuideModal: false,
      showDeleteModal: false,
      deleteConfirmation: '',
      feedId: null,
      feedPatch: {
        displayName: '',
        description: '',
        language: '',
        url: '',
        explicitContent: '',
      },
      jsonSettings: {},
      languages: [
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],

      audioPreviewUrl: null,
      playButton: null,
      activeTab: 'general',
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      customers: (state) => state.customer.list,
      selectedWebsite: (state) => state.website.selectedWebsite,
      websiteList: (state) => state.website.websiteList,
      userId: (state) => state.auth.userId,
      languageList: (state) => state.utils.languageList,
      pluginLink: (state) => state.utils.pluginUrl,
      subscriptionStatus: (state) => state.customer.subscriptionStatus,
      selectedWebsiteAPTemplates: (state) => state.website.selectedWebsiteAPTemplates,
      selectedWebsiteAP: (state) => state.website.selectedWebsiteAP,
      selectedWebsiteAPTemplateOutro: (state) => state.website.selectedWebsiteAPTemplateOutro,
    }),
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    isEnterprise() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Enterprise';
    },
    isPremium() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Premium';
    },
    channelId() {
      return this.selectedWebsiteAP.id;
    },
    websiteId() {
      return this.$route.params.websiteId;
    },
  },

  mounted() {
    this.deleteConfirmation = '';
    // this.init();
  },
  methods: {
    ...mapMutations({
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      updateWebsite: 'website/updateWebsite',
      deleteWebsiteById: 'website/deleteWebsiteById',
      generateTextPreview: 'website/generateTextPreview',
      switchTemplateStatus: 'website/switchTemplateStatus',
    }),

    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.tokenCopied'),
      });
    },
    goTo(path) {
      this.$router.push({ path });
    },
  },
};
</script>
