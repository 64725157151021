/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../../graphql';
import appConfig from '../../config';
/**
 * Build styles
 */

/**
 * GeneratedImage Tool for the Editor.js
 * Works only with pasted image URLs and requires no server-side uploader.
 *
 * @typedef {object} GeneratedImageData
 * @description Tool's input and output data format
 * @property {string} url — image URL
 * @property {string} prompt — image prompt
 * @property {boolean} withBorder - should image be rendered with border
 * @property {boolean} withBackground - should image be rendered with background
 * @property {boolean} stretched - should image be stretched to full width of container
 */
export default class GeneratedImage {
  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: GeneratedImageData, config: object, api: object}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   *   readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    /**
     * Editor.js API
     */
    console.log('generateImage', data, config, api, readOnly);
    this.api = api;
    this.readOnly = readOnly;
    this.config = config;

    /**
     * When block is only constructing,
     * current block points to previous block.
     * So real block index will be +1 after rendering
     *
     * @todo place it at the `rendered` event hook to get real block index without +1;
     * @type {number}
     */
    this.blockIndex = this.api.blocks.getCurrentBlockIndex() + 1;

    /**
     * Styles
     */
    this.CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      wrapper: 'cdx-simple-image-generated',
      imageHolder: 'cdx-simple-image__picture',
      prompt: ['cdx-simple-image__prompt', 'content-wrapper'],
    };

    /**
     * Nodes cache
     */
    this.nodes = {
      wrapper: null,
      imageHolder: null,
      image: null,
      prompt: null,
    };

    /**
     * Tool's initial data
     */
    this.data = {
      url: data.url || '',
      prompt: data.prompt || '',
      withBorder: data.withBorder !== undefined ? data.withBorder : false,
      withBackground: data.withBackground !== undefined ? data.withBackground : false,
      stretched: data.stretched !== undefined ? data.stretched : false,
    };

    /**
     * Available Image settings
     */
    this.settings = [
    ];
  }

  /**
   * Creates a Block:
   *  1) Show preloader
   *  2) Start to load an image
   *  3) After loading, append image and prompt input
   *
   * @public
   */
  render() {
    const wrapper = this._make('div', [this.CSS.baseClass, this.CSS.wrapper, 'content-wrapper']),
        loader = this._make('div', this.CSS.loading),
        imageHolder = this._make('div', this.CSS.imageHolder),
        image = this._make('img'),
        messageHolder = this._make('div', ['text-sm', 'text-center']),
        prompt = this._make('div', [...this.CSS.prompt, this.CSS.input], {
          contentEditable: !this.readOnly,
          innerHTML: this.data.prompt || '',
        });
        wrapper.setAttribute('style', 'scroll-margin: -250px;');
    // prompt.dataset.placeholder = 'Enter a prompt';
    // hack to get cost of the image
    const cost = localStorage.getItem('imageGenerationCost');
    messageHolder.innerHTML = `Generating image based on selection will cost you <strong>${cost}</strong> credits. <br> Please make sure that the written phrase doesn't contain specific words that may suggest nudity, violence!`;

    const mainDiv = document.createElement('div');
      mainDiv.classList.add('bg-gray-100', 'p-8', 'border', 'border-gray-100', 'rounded-lg', 'relative');
    const actionDiv = document.createElement('div');
      actionDiv.classList.add('footer', 'flex', 'justify-center', 'space-x-3', 'mt-10');
    
    const graphic = document.createElement('div');
    graphic.classList.add('flex', 'justify-center');
    graphic.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="57.5" height="55.489" viewBox="0 0 57.5 55.489">
    <g id="Group_5668" data-name="Group 5668" transform="translate(-0.041 -0.46)">
      <path id="Path_43791" data-name="Path 43791" d="M40.109,21.9c-.649-.433-.962-.264-2.981,3.726C36.7,16.8,29.147,11.367,21.6,13.747c-9.76,3.317-11.683,11.587-7.62,18.029a12.332,12.332,0,0,0,7.668,5.914c.938.192,4.519.938,4.688-.337,0-.481-.24-1.01-1.274-1.034a8.462,8.462,0,0,1-5.144-1.226,12.38,12.38,0,0,1-3.87-4.5c-2.981-5.264-1.13-11.635,6.539-14.3,6.034-2.212,12.668,2.188,13.053,10.168,0,.264.673.481-4.808-2.572a.721.721,0,1,0-.889,1.13,59.908,59.908,0,0,0,5.1,4.327c.649.433,1.394,1.2,2.4.986s1.2-.913,2.572-5.481C40.4,23.387,41.479,22.353,40.109,21.9Z" transform="translate(16.758 17.867)" fill="#191919" fill-rule="evenodd"/>
      <path id="Path_43792" data-name="Path 43792" d="M10.419,2.551c-4.567.913-5.962,3.582-5.337,6.611,1.3,7.067,13.462,4.183,10.745-3.293a4.808,4.808,0,0,0-5.409-3.317ZM7.246,8.537C6,4.258,12.462,2.287,13.5,6.565S8.183,11.926,7.246,8.537Z" transform="translate(6.88 2.837)" fill="#fea900"/>
      <path id="Path_43793" data-name="Path 43793" d="M19.155,1.212a.745.745,0,0,0-1.034,0c-.337.337,0,.385,0,1.755,0,1.755-1.274,23.75.962,23.822A.841.841,0,0,0,19.948,26c.24-2.692.529-5.745.625-8.774C20.741,10.322,19.852,1.789,19.155,1.212Z" transform="translate(24.876 0.763)" fill="#191919"/>
      <path id="Path_43794" data-name="Path 43794" d="M4.318,34.836c-1.442-.385-.986-.361-1.082-1.418S2.467,6.566,3.309,3.609c2.4-.649,18.606-.962,21.635-.938,15.818.192,16.3.769,16.322-.5a.817.817,0,0,0-.793-.841C1.361-.333,1.025.436.52,3.249-.417,8.177.111,34.091,1.313,35.8c2.043,2.861,21.635,2.091,25.1,1.947a.745.745,0,0,0,0-1.466C24.871,36.3,9.8,36.326,4.318,34.836Z" transform="translate(0 0)" fill="#191919"/>
      <path id="Path_43795" data-name="Path 43795" d="M39.962,12.182c-3.1-4.808-7.62-7.74-13-4.808-4.808,2.837-7.212,8.942-9.279,14.039,0,.24,0,.313-.481-.553a10.481,10.481,0,0,0-4.808-4.111c-5.409-2.043-7.981-.841-10.577,2.091-.673.841-.337,2.861.385,2.091,2.6-2.861,3.774-3.2,9.207-1.514A10.241,10.241,0,0,1,16.933,23.7c0,.313-.553,1.947-.865,3.1-.192.649,1.418,1.346,1.659,0,2.548-7.212,8.438-19.736,15.168-17.789a14.159,14.159,0,0,1,5,3.389,12.019,12.019,0,0,0,1.563,1.418C40.2,14.3,40.443,12.783,39.962,12.182Z" transform="translate(1.977 8.062)" fill="#fea900"/>
    </g>
  </svg>
  `;
    mainDiv.appendChild(graphic);
    mainDiv.appendChild(messageHolder);
    const cancelBtn = document.createElement('div');
    cancelBtn.id = 'generated-cancel-btn';
    cancelBtn.classList.add('cdx-button', 'shadow-none', 'border-0', 'bg-white', 'hover:text-white', 'font-bold', 'p-2', 'px-4', 'rounded-md', 'w-32', 'text-center', 'hover:bg-black', 'cursor-pointer', 'transition-colors');
    cancelBtn.innerText = 'Cancel';

    cancelBtn.addEventListener('click', () => this.deleteBlock());

    const generateBtn = document.createElement('div');
    generateBtn.id = 'generated-generate-btn';
    generateBtn.classList.add('cdx-button', 'shadow-none', 'border-0', 'bg-black', 'text-white', 'rounded-md', 'p-2', 'w-32', 'text-center', 'hover:bg-yellow-500', 'cursor-pointer', 'transition-colors');
    generateBtn.innerText = 'Generate';
    generateBtn.addEventListener('click', () => {
      this.generateImage();
    });
    
    actionDiv.appendChild(cancelBtn);
    actionDiv.appendChild(generateBtn);
    mainDiv.appendChild(actionDiv);

    
    imageHolder.appendChild(image);
    wrapper.appendChild(imageHolder);
    // wrapper.appendChild(prompt);
    wrapper.appendChild(mainDiv);

    if (this.data.url) {
      image.src = this.data.url;
    }

    image.onload = () => {
      wrapper.classList.remove(this.CSS.loading);
      imageHolder.appendChild(image);
      wrapper.appendChild(mainDiv);
      wrapper.appendChild(imageHolder);
      wrapper.appendChild(prompt);
      loader.remove();
      this._acceptTuneView();
    };

    image.onerror = (e) => {
      // @todo use api.Notifies.show() to show error notification
      // console.log('Failed to load an image', e);
    };
    

    this.nodes.imageHolder = imageHolder;
    this.nodes.wrapper = wrapper;
    this.nodes.image = image;
    this.nodes.prompt = prompt;
    this.nodes.cancelBtn = cancelBtn;

    return wrapper;
  }
  async generateImage() {
    console.log('generateImage', this.data);
    const { prompt } = this.data;
    const payload = {
      prompt,
    };
    const response = await API.graphql(
      graphqlOperation(
        GQL.CreateImageFromText,
        { input: payload },
      ),
    );
    const { createImageFromText } = response.data;

    if (!createImageFromText || !createImageFromText.imageName) return;
    console.log('response', appConfig.uploadUrl, createImageFromText.imageName);
      const url = `${appConfig.uploadUrl}/${encodeURIComponent(createImageFromText.imageName)}`;
    this.deleteBlock();
    this.api.blocks.insert('img', { caption: prompt, url, readOnly: true, generated: true });
  }

  /**
   * @public
   * @param {Element} blockContent - Tool's wrapper
   * @returns {GeneratedImageData}
   */
  save(blockContent) {
    const image = blockContent.querySelector('img');

    if (!image) {
      return this.data;
    }

    return Object.assign(this.data, {
      url: image.src,
    });
  }

  deleteBlock() {
    // this.blockIndex = this.api.blocks.getCurrentBlockIndex();
    this.api.blocks.delete(this.blockIndex);
   }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      url: {},
      withBorder: {},
      withBackground: {},
      stretched: {},
      prompt: {
        br: true,
      },
    };
  }

  /**
   * Notify core that read-only mode is suppoorted
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Read pasted image and convert it to base64
   *
   * @static
   * @param {File} file
   * @returns {Promise<GeneratedImageData>}
   */
  onDropHandler(file) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    return new Promise(resolve => {
      reader.onload = (event) => {
        resolve({
          url: event.target.result,
          prompt: file.name,
        });
      };
    });
  }

  /**
   * On paste callback that is fired from Editor.
   *
   * @param {PasteEvent} event - event with pasted config
   */
  onPaste(event) {
  }

  /**
   * Returns image data
   *
   * @returns {GeneratedImageData}
   */
  get data() {
    return this._data;
  }

  /**
   * Set image data and update the view
   *
   * @param {GeneratedImageData} data
   */
  set data(data) {
    this._data = Object.assign({}, this.data, data);

    if (this.nodes.image) {
      this.nodes.image.src = this.data.url;
    }

    if (this.nodes.prompt) {
      this.nodes.prompt.innerHTML = this.data.prompt;
    }
  }

  /**
   * Specify paste substitutes
   *
   * @see {@link ../../../docs/tools.md#paste-handling}
   * @public
   */
  static get pasteConfig() {
    return {
      patterns: {
        image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png|webp)$/i,
      },
      tags: [ 'img' ],
      files: {
        mimeTypes: [ 'image/*' ],
      },
    };
  }

  /**
   * Makes buttons with tunes: add background, add border, stretch image
   *
   * @returns {HTMLDivElement}
   */
  renderSettings() {
    const wrapper = document.createElement('div');

    this.settings.forEach(tune => {
      const el = document.createElement('div');

      el.classList.add(this.CSS.settingsButton);
      el.innerHTML = tune.icon;

      el.addEventListener('click', () => {
        this._toggleTune(tune.name);
        el.classList.toggle(this.CSS.settingsButtonActive);
      });

      el.classList.toggle(this.CSS.settingsButtonActive, this.data[tune.name]);

      wrapper.appendChild(el);
    });

    return wrapper;
  };

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @returns {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }
    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Click on the Settings Button
   *
   * @private
   * @param tune
   */
  _toggleTune(tune) {
    this.data[tune] = !this.data[tune];
    this._acceptTuneView();
  }

  /**
   * Add specified class corresponds with activated tunes
   *
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach(tune => {
      this.nodes.imageHolder.classList.toggle(this.CSS.imageHolder + '--' + tune.name.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`), !!this.data[tune.name]);

      if (tune.name === 'stretched') {
        this.api.blocks.stretchBlock(this.blockIndex, !!this.data.stretched);
      }
    });
  }
}

// module.exports = GeneratedImage;